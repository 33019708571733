import { useSubheader } from "../../../../../_metronic/layout/index.js"
import { useDispatch, useSelector } from "react-redux"
import React, { useEffect, useState } from "react"
import { CmsList } from "./tab/CmsList.jsx"
import { FoList } from "./tab/FoList.jsx"
import { Tab, Tabs } from "@material-ui/core"
import { Popup } from "../../../../../component/atoms/Popup/index.jsx"
import { ROLE } from "../../../../../constants/InitTypeConstants.js"

export const RoleList = ({
  history,
  match: {
    params: { type }
  }
}) => {
  const subHeader = useSubheader()
  const dispatch = useDispatch()
  const [showPopupSuccess, setShowPopupSuccess] = useState(false)
  const { General } = useSelector((state) => state)

  useEffect(() => {
    subHeader.setButton(false)
    subHeader.setTitle("role list")
  }, [dispatch])

  useEffect(() => {
    if (General.isFinishUpdateOrDelete && General?.actionInitType === ROLE.ASSIGN_PRIVILEGE) {
      setShowPopupSuccess(true)
    }
  }, [General.isFinishUpdateOrDelete])

  const getTabContents = () => {
    const labelList = ["CMS", "FO Apps"]
    const contentList = [
      <CmsList key={1} history={history} />,
      <FoList key={2} history={history} />
    ]

    return {
      labels: labelList,
      contents: contentList
    }
  }
  const [value, setValue] = useState(0)

  function handleChange(event, newValue) {
    setValue(newValue)
  }

  useEffect(() => {
    if (type && type == "fo") {
      setValue(1)
    }
    if (type && type == "cms") {
      setValue(0)
    }
  }, [type])

  return (
    <>
      <Popup
        type={"berhasil"}
        body={<span>Hak akses berhasil diedit. </span>}
        centered={true}
        persistent={true}
        show={showPopupSuccess}
        onClick={() => {
          setShowPopupSuccess(false)
          //reload request by mas hendra
          window.location.reload()
        }}
      />
      <div className="bg-white p-3">
        <Tabs value={value} indicatorColor="primary" textColor="primary" onChange={handleChange}>
          {getTabContents().labels.map((item, i) => (
            <Tab key={i} label={item} />
          ))}
        </Tabs>
        <hr />
        {getTabContents().contents[value]}
      </div>
    </>
  )
}