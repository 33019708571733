import { useLocation } from "react-router"
import { useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router-dom"
import Querystring from "querystring"
import querystring from "querystring"

export const useUrlParam = () => {
  const history = useHistory()
  const location = useLocation()
  const { search } = useLocation()
  const [getUrlParamsObj, setGetUrlParamObj] = useState(undefined)
  const searchParams = useMemo(() => new URLSearchParams(search), [search])
  const resetStringFilter = () => {
    if (searchParams.get("string_filter")) {
      history.replace({
        string_filter: ""
      })
    }
  }

  function resetKeyword() {
    if (searchParams.get("keyword")) {
      history.replace({
        keyword: ""
      })
    }
  }

  useEffect(() => {
    const result = location?.search.replace("?", "")
    setGetUrlParamObj(Querystring.parse(result))
  }, [location])

  const stringifyObj = data => {
    return "?" + querystring.stringify(data)
  }

  return {
    resetStringFilter,
    getUrlParamsObj,
    stringifyObj,
    resetKeyword
  }
}
