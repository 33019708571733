import React, { useEffect, useRef, useState } from "react"
import { DataRole } from "./step/DataRole.jsx"
import { TabsInputComponent } from "../../../../../component/index.jsx"
import * as Yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import { useSubheader } from "../../../../../_metronic/layout/index.js"
import { PopupQuestion } from "../../../../../component/atoms/Popup/index.jsx"
import {
  assignPrivilegeToRole,
  fetchPrivilegeByRole,
  getListRole
} from "../../../../../redux/actions/RoleAction.jsx"
import { initialFilterTable } from "../../../../../component/atoms/Tabel/index.jsx"

export const EditRole = ({
  history,
  match: {
    params: { type, id }
  }
}) => {
  const [detail, setDetail] = useState({})
  const [isSubmit] = useState(false)
  const [showPopupSubmit, setShowPopupSubmit] = useState(false)
  const [privilegeSubmit, setPrivilegeSubmit] = useState({
    role: null,
    privilege_list: null
  })
  const { General, Role } = useSelector((state) => state)
  const subHeader = useSubheader()
  const dispatch = useDispatch()
  const formRef = useRef()
  const [initialValue, setInitialValue] = useState({
    privilege_header: [],
    privilege_list: []
  })
  const [stepsTitle] = useState([null])
  const stepsDescription = [null]
  useEffect(() => {
    dispatch(getListRole({ pageReq: initialFilterTable }))
  }, [dispatch])

  useEffect(() => {
    const getID = id
    setDetail(Role.listRole.find(({ id }) => id == getID))
  }, [Role.listRole])

  const addRoleFlow = [
    <DataRole
      key={1}
      isReadOnly={false}
      type={type}
      role={detail?.display_name}
      id={id}
      setInitialValue={setInitialValue}
      initialValue={initialValue}
      innerRef={formRef}
    />
  ]

  const RoleEditSchema = [
    Yup.object().shape({
      privilege_list: Yup.array().min(1)
    })
  ]

  useEffect(() => {
    subHeader.setBreadcrumbs([
      {
        title: "Admin Setting",
        pathname: id
      },
      {
        pathname: "/role/list/" + type,
        title: "role " + type?.toUpperCase()
      },
      {
        title: detail?.display_name,
        pathname: id
      },
      {
        title: "Setting Privilege"
      }
    ])
    subHeader.setButton(buttonSubmited)
    dispatch(fetchPrivilegeByRole(id))
  }, [detail])

  useEffect(() => {
    setInitialValue((prevState) => ({
      ...prevState,
      privilege_list: Role.listPrivilegeById
    }))
  }, [Role.listPrivilegeById])

  useEffect(() => {
    if (General.isFinishUpdateOrDelete) {
      history.push("/role/list/" + type)
    }
  }, [General.isFinishUpdateOrDelete])

  const buttonSubmited = (
    <button
      type="button"
      disabled={isSubmit}
      className="btn btn-primary"
      onClick={() => {
        if (formRef.current) {
          formRef.current.handleSubmit()
        }
      }}>
      Submit
    </button>
  )

  const onSubmitForm = (e) => {
    const { values, submited } = e
    if (submited) {
      const data = {
        role: detail?.name,
        privilege_list: values.privilege_list
      }
      setShowPopupSubmit(true)
      setPrivilegeSubmit(data)
    }
  }

  return (
    <>
      <PopupQuestion
        title={`SETTING PRIVILEGE`}
        body={
          <span>
            Apakah anda yakin akan mengubah hak akses dari <b> {detail?.display_name} </b> ?
          </span>
        }
        centered={true}
        persistent={true}
        show={showPopupSubmit}
        textOk="Submit"
        onCancel={() => {
          setPrivilegeSubmit({ role: null, privilege_list: null })
          setShowPopupSubmit(false)
        }}
        onOk={() => {
          dispatch(assignPrivilegeToRole(privilegeSubmit))
        }}
      />
      <TabsInputComponent
        isEditTabs={false}
        steps={stepsTitle}
        stepDescription={stepsDescription}
        formInitialValues={initialValue}
        formId="editRole"
        innerRef={formRef}
        componentTab={addRoleFlow}
        validationSchema={RoleEditSchema}
        onSubmitForm={onSubmitForm}
      />
    </>
  )
}