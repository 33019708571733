import React from "react"
import { EmptyCard, TableSkeleton, TableView } from "../../../../../../component/index.jsx"
import { useTranslation } from "react-i18next"
import { formatterRowComponent } from "../../../../../../component/atoms/Tabel/index.jsx"
import { sortCaret } from "../../../../../../_metronic/_helpers/index.js"
import { timeStampToDate } from "../../../../../helpers/DateHelper.js"
import { toMeter } from "../../../../../helpers/TextHelper.js"
import { Card } from "react-bootstrap"
import { CardBody } from "../../../../../../_metronic/_partials/controls/index.js"
import { useSelector } from "react-redux"
import { RoutesConstants } from "../../../../../../constants/RoutesConstants.js"
import { linkWithCtrl } from "../../../../../helpers/Utils.js"
import { loadUserLogin } from "../../../../../service/userManagementAction.js"
import { useParams } from "react-router"
import { useHistory } from "react-router-dom"
import { PRIVILEGE_ENUM } from "../../../../../../constants/PrivilegeConstants.js"

export function MacSprayingDoneTab({ changePage, activeFilter, data }) {
  const { t } = useTranslation()
  const { General } = useSelector((state) => state)
  const privileges = loadUserLogin().privilege
  const params = useParams()
  const history = useHistory()
  const columns = [
    {
      dataField: "code",
      text: t("table_header.request_id"),
      sort: true,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiCode
      },
      sortCaret: sortCaret
    },
    {
      dataField: "user",
      text: t("table_header.user_mac"),
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiUserMac
      },
      sortCaret: sortCaret
    },
    {
      dataField: "land_area",
      text: t("table_header.land_area"),
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiLandArea
      },
      sortCaret: sortCaret
    },
    {
      dataField: "full_address",
      text: t("table_header.full_address_mac"),
      sort: false,
      formatter: formatterRowComponent,
      sortCaret: sortCaret,
      headerStyle: () => {
        return { width: "20%" }
      }
    },
    {
      dataField: "plant_type",
      text: t("table_header.plant_type"),
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: (e) => <div>{e?.plant_type ? e?.plant_type : "-"}</div>
      },
      headerStyle: () => {
        return { width: "15%" }
      }
    },
    {
      dataField: "request_date",
      text: t("table_header.activity_date"),
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiDate
      },
      sortCaret: sortCaret
    }
  ]

  function uiCode(e) {
    return (
      <div className={"text-dark font-weight-bolder  font-size-lg"}>
        {e?.schedule_code ? e?.schedule_code : "-"}
      </div>
    )
  }

  function uiDate(e) {
    return <div>{e.activity_date ? timeStampToDate(e.activity_date, "dd-MM-yyyy") : "-"}</div>
  }

  function uiUserMac(e) {
    return (
      <div>
        <div className={"text-dark font-weight-bolder  font-size-lg mb-1"}>
          {e?.merchant_user_name ? e.merchant_user_name : "-"}
        </div>
        <div>{e.merchant_user_code ? e.merchant_user_code : "-"}</div>
      </div>
    )
  }

  function uiLandArea(e) {
    return <div>{e?.land_area ? toMeter(e.land_area) : "-"}</div>
  }

  return (
    <div>
      <Card>
        <CardBody>
          {General.loading ? (
            <TableSkeleton column={6} row={10} />
          ) : data?.pageData?.length === 0 ? (
            <EmptyCard
              body={
                activeFilter
                  ? t("messages.response.data_not_found")
                  : t("messages.response.empty_state")
              }
            />
          ) : (
            ""
          )}
          <TableView
            dataTable={data?.pageData ?? []}
            loadingGetData={false}
            callbackAfterPageChange={changePage}
            columnProperties={columns}
            currentPage={data?.page?.currentPage}
            currentElement={data?.page?.currentElement}
            totalCount={data?.page?.totalCount}
            rowClasses={() => {
              return "cursor-pointer"
            }}
            rowEvents={{
              onClick: (e, row) => {
                if (privileges.indexOf(PRIVILEGE_ENUM.DETAIL_ORDER)) {
                  const url = RoutesConstants.MAC.SPRAYING_DETAIL(params?.tab, row?.id)
                  if (e.ctrlKey) {
                    linkWithCtrl(url)
                  } else {
                    history.push(url)
                  }
                }
              }
            }}
          />
        </CardBody>
      </Card>
    </div>
  )
}