import React from "react"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers"
import { ICON_CONSTANS } from "../../../../constants/ImageConstants.js"

export const EditSvgIcon = () => {
  return (
    <span className="svg-icon svg-icon-md svg-icon-secondary">
      <SVG src={toAbsoluteUrl(ICON_CONSTANS.IC_EDIT_LIST)} />
    </span>
  )
}