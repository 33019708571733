import React from "react"
import {
  CardDetail,
  DetailText,
  FlatIconAndText,
  SwitchToggle
} from "../../../../../component/index.jsx"
import { RowModule } from "../../../../../component/atoms/RowModule/RowModule.jsx"
import { CardBody, CardHeader } from "../../../../../_metronic/_partials/controls/index.js"
import { Card } from "react-bootstrap"
import { Skeleton } from "@material-ui/lab"
import { useSelector } from "react-redux"
import { ICON_CONSTANS } from "../../../../../constants/ImageConstants.js"

export const DetailOfficer = ({ data, activeUser, inactiveUser, role }) => {
  const { General } = useSelector((state) => state)
  const subTitle = () => (
    <>
      <div className="d-flex flex-column">
        <div className="d-flex flex-wrap mb-4">
          {!role && (
            <FlatIconAndText
              flatIcon="flaticon2-setting"
              text={data?.role?.replaceAll("_", " ") ?? "-"}
              svg={ICON_CONSTANS.IC_ROLE_ACCESS}
            />
          )}

          <FlatIconAndText
            flatIcon="flaticon2-email"
            text={data?.email ?? "-"}
            svg={ICON_CONSTANS.IC_MAIL}
          />
          <FlatIconAndText
            flatIcon="flaticon2-phone"
            text={data?.phone_number ?? "-"}
            svg={ICON_CONSTANS.IC_PHONE}
          />
        </div>
        {General.loading ? (
          <>
            <Skeleton animation="wave" variant={"text"} width={250} />
            <Skeleton animation="wave" variant={"text"} width={200} />
          </>
        ) : (
          <span className="font-weight-bold text-dark-50">{data?.full_address ?? "-"}</span>
        )}
      </div>
    </>
  )

  const descriptionRight = (
    <>
      <div className="d-flex flex-column ">
        <SwitchToggle
          checked={activeUser === "ACTIVE"}
          label="Status User"
          onChange={inactiveUser}
          withLabel={true}
          withStatusText={true}
          statusText={activeUser === "ACTIVE" ? "Active" : "Inactive"}
          disabled={activeUser === "INACTIVE" ? true : false}
        />
      </div>
    </>
  )

  return (
    <>
      <RowModule>
        {activeUser === "INACTIVE" && (
          <RowModule>
            <Card style={{ background: "#F8F8F8" }}>
              <CardHeader title={"Status Change Reason"} className="mb-0 pb-2 bg-gray-card" />
              <CardBody>{data?.field_officer_deactivation_reason}</CardBody>
            </Card>
          </RowModule>
        )}
      </RowModule>
      <RowModule withoutSeparator={true}>
        <CardDetail
          titleImage={data?.url_profile}
          titleCardDetail={data?.name}
          descriptionLeft={subTitle()}
          descriptionRight={descriptionRight}
          wrap={false}
        />
      </RowModule>
      {data?.url_profile && (
        <RowModule withoutSeparator={true} customColumnCss="col-lg-6 col-sm-6 col-md-6">
          <DetailText
            title="FOTO PROFILE"
            menu="AVATAR"
            value={data?.url_profile}
            type="image"
            withoutSeparator={true}
          />
        </RowModule>
      )}
    </>
  )
}