import React, { useCallback, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  getAreaPendingList,
  getAreaPendingUnassignList
} from "../../../../redux/actions/AreaAction.jsx"
import { clearDataDetail, getAllMitra } from "../../../../redux/actions/MitraAction.jsx"
import { useSubheader } from "../../../../_metronic/layout/index.js"
import { Card, CardBody, CardHeader } from "../../../../_metronic/_partials/controls/index.js"
import { RowModule } from "../../../../component/atoms/RowModule/RowModule.jsx"
import { AreaListPending } from "./tab/AreaListPending.jsx"
import { FilterCard } from "../../../../component/molecule/index.jsx"
import {
  LocationMolecule,
  SelectInput,
  TabsWithIcon,
  TextInput
} from "../../../../component/index.jsx"
import { initialFilterTable } from "../../../../component/atoms/Tabel/index.jsx"
import { AreaListPendingUnassign } from "./tab/AreaListPendingUnassign.jsx"
import { currentUrl } from "../../../helpers/UrlParamHelper.js"
import {
  resetUrlParam,
  setDataTabMultiple,
  setDataValueAction,
  SetFilterAction,
  setPageStringActions,
  setSearchAction
} from "../../../../redux/actions/FilterAction.js"
import { ICON_CONSTANS } from "../../../../constants/ImageConstants.js"

export const ListAreaPending = ({ history }) => {
  const subHeader = useSubheader()
  const dispatch = useDispatch()
  const { General, Area, Mitralist, UrlParam } = useSelector((state) => state)
  const formRef = useRef()

  const [search, setSearch] = useState({
    string_filter: ""
  })
  const [stateMitra, setStateMitra] = useState()
  const [showFilter, setShowFilter] = useState(false)
  const [filterOn, setFilterOn] = useState(false)
  const [initialValue, setInitialValue] = useState({
    mitra_id: "",
    province_id: "",
    regency_id: "",
    district_id: "",
    sub_district_id: "",
    min_area: "",
    max_area: ""
  })
  const isReadOnly = false
  const [isFilter, setIsFilter] = useState(false)
  const [reset, setReset] = useState(false)
  const [mitra, setMitra] = useState("")
  const [min, setMin] = useState("")
  const [max, setMax] = useState("")
  const [tabActive, setTabActive] = useState(UrlParam.dataMultipleTab)
  const [btnFilterDisable, setBtnFilterDisable] = useState(true)
  const [isActiveSearch, setIsActiveSearch] = useState(false)
  const filterValue = UrlParam.data
  const urlCurrent = currentUrl()

  const fetchData = (param) => {
    if (tabActive === 1) {
      return dispatch(getAreaPendingUnassignList(param))
    } else {
      return dispatch(getAreaPendingList(param))
    }
  }

  useEffect(() => {
    if (UrlParam.pageString !== urlCurrent) {
      dispatch(resetUrlParam())
    }
  }, [])

  useEffect(() => {
    if (UrlParam.search) {
      setIsActiveSearch(true)
    } else {
      setIsActiveSearch(false)
    }
  }, [UrlParam])

  useEffect(() => {
    if (UrlParam.search) {
      setSearch({
        string_filter: UrlParam.search
      })
    } else {
      setSearch({
        string_filter: ""
      })
    }
  }, [UrlParam.search, useCallback])

  useEffect(() => {
    const dataPage = {
      ...initialFilterTable
    }
    dispatch(setPageStringActions(urlCurrent))
    if (filterValue !== null && UrlParam.pageString === urlCurrent) {
      setMitra(filterValue?.mitra_id)
      const data = {
        ...initialFilterTable,
        ...UrlParam.dataValue,
        string_filter: UrlParam.search,
        mitra_id: filterValue?.mitra_id,
        province_id: filterValue.province_id,
        regency_id: filterValue.regency_id,
        district_id: filterValue.district_id,
        sub_district_id: filterValue.sub_district_id,
        max_area: filterValue.max_area,
        min_area: filterValue.min_area
      }
      if (data) {
        setFilterOn(true)
        setInitialValue(data)
        setShowFilter(false)
        fetchData({ pageReq: data })
      }
    }
    if (UrlParam.pageString === urlCurrent) {
      if (UrlParam.dataValue && !UrlParam.search) {
        setSearch({
          string_filter: UrlParam.search
        })

        let param = {
          ...UrlParam.dataValue,
          string_filter: UrlParam.search
        }
        fetchData({ pageReq: param })
      }
    } else {
      fetchData({ pageReq: dataPage })
    }
  }, [UrlParam, dispatch])

  useEffect(() => {
    const checkValidation = () => {
      if (
        formRef.current.values.mitra_id ||
        formRef.current.values.province_id ||
        formRef.current.values.min_area ||
        formRef.current.values.max_area
      ) {
        if (tabActive === 1 && formRef.current.values.mitra_id) {
          return true
        }
        return false
      } else {
        return true
      }
    }
    if (
      formRef.current.values.mitra_id ||
      formRef.current.values.province_id ||
      formRef.current.values.min_area ||
      formRef.current.values.max_area
    )
      setBtnFilterDisable(checkValidation())
    else setBtnFilterDisable(checkValidation())
  }, [formRef.current, mitra, min, max, tabActive])

  useEffect(() => {
    subHeader.setButton(false)
  }, [tabActive])

  const changePage = useCallback(
    (param) => {
      setSearch({
        string_filter: UrlParam.search
      })
      let newParam = {
        ...initialValue,
        ...search,
        ...param
      }
      if (param.sortField) {
        newParam = {
          ...param,
          ...newParam,
          ...search,
          sort: param.sortField + "," + param.sortOrder
        }
      } else if (UrlParam.search) {
        newParam = {
          ...initialValue,
          ...search,
          ...param
        }
      } else if (!UrlParam.search) {
        newParam = {
          ...initialValue,
          ...search,
          ...param
        }
      }
      fetchData({ pageReq: newParam })
      dispatch(setDataValueAction(newParam))
    },
    [dispatch, initialValue, search]
  )

  // mitralist
  useEffect(() => {
    dispatch(getAllMitra())
    return () => {
      dispatch(clearDataDetail())
    }
  }, [])

  useEffect(() => {
    if (Mitralist.data) {
      setStateMitra(Mitralist.data.map((value) => ({ label: value.name, value: value.id_mitra })))
    }
  }, [Mitralist.data])

  useEffect(() => {
    subHeader.setButton(false)
    subHeader.setTitle("Lahan Sedang Menunggu")
  }, [])

  const filterOnSubmitForm = (e) => {
    setReset(false)
    const { values, submited } = e
    setMin(values.min_area)
    setMax(values.max_area)
    setIsFilter(true)
    if (submited === true) {
      const mitraCheck = () => {
        if (tabActive === 1) {
          return ""
        } else {
          return values?.mitra_id
        }
      }
      let param = {
        ...initialFilterTable,
        mitra_id: mitraCheck(),
        province_id: values.province_id,
        regency_id: values.regency_id,
        district_id: values.district_id,
        sub_district_id: values.sub_district_id,
        min_area: values.min_area,
        max_area: values.max_area,
        string_filter: search.string_filter
      }

      if (param) {
        setInitialValue(param)
        fetchData({ pageReq: param })
        dispatch(SetFilterAction(param))
        setShowFilter(false)
        setFilterOn(true)
      }
    }
  }

  const onReset = () => {
    dispatch(SetFilterAction())
    setReset(true)
    setMitra("")
    setMin("")
    setMax("")
    setShowFilter(false)
    setIsFilter(false)
    setFilterOn(false)
    setInitialValue({
      mitra_id: "",
      province_id: "",
      regency_id: "",
      district_id: "",
      sub_district_id: "",
      min_area: "",
      max_area: ""
    })
    let param = {
      ...initialFilterTable,
      mitra_id: "",
      province_id: "",
      regency_id: "",
      district_id: "",
      sub_district_id: "",
      min_area: "",
      max_area: "",
      string_filter: search.string_filter
    }
    fetchData({ pageReq: param })
  }

  const seacrhFunc = (values) => {
    let param = {
      ...initialFilterTable,
      ...initialValue,
      string_filter: values.string_filter
    }
    setSearch({
      string_filter: values.string_filter
    })
    dispatch(setSearchAction(values?.string_filter))
    fetchData({ pageReq: param })
    setIsFilter(true)
  }

  const handleReset = () => {
    dispatch(setSearchAction())
    setIsFilter(false)
    setSearch({
      string_filter: ""
    })
    let param = {
      ...initialFilterTable,
      ...initialValue,
      string_filter: ""
    }
    fetchData({ pageReq: param })
  }

  const handleOpenCloseFilter = (value) => {
    setShowFilter(value)
  }

  function onChangeMitra(e) {
    setMitra(e)
  }

  const filterComponent = (
    <>
      {tabActive === 0 && (
        <>
          <Card className="card-custom card-shadowless">
            <CardHeader title="Mitra" className="px-0" />
            <CardBody className="px-0">
              <RowModule>
                <SelectInput
                  name="mitra_id"
                  onChange={onChangeMitra}
                  editable={!isReadOnly}
                  label="Nama Mitra"
                  placeholder="Pilih Salah Satu"
                  options={stateMitra}
                  withoutFeedback={true}
                  clearNow={true}
                  value={mitra}
                />
              </RowModule>
            </CardBody>
          </Card>
        </>
      )}

      <LocationMolecule
        resetLocation={reset}
        name="filter"
        withoutFeedback={true}
        isReadOnly={isReadOnly}
        addressTitle="Lokasi"
        removeHorizontalPadding={true}
      />
      <Card className="card-custom card-shadowless">
        <CardHeader title="Luas Lahan (m2)" className="px-0" />
        <CardBody className="px-0">
          <RowModule>
            <TextInput
              editable={!isReadOnly}
              label="Minimal"
              name="min_area"
              placeholder="Nilai Minimal"
              withoutFeedback={true}
              value={min}
              onChangeValue={setMin}
            />
          </RowModule>
          <RowModule>
            <TextInput
              editable={!isReadOnly}
              label="Maksimal"
              name="max_area"
              placeholder="Nilai Maksimal"
              withoutFeedback={true}
              value={max}
              onChangeValue={setMax}
            />
          </RowModule>
        </CardBody>
      </Card>
    </>
  )

  const getTabContents = () => {
    const labelList = [
      {
        label: "Request Register",
        labelTooltip: "Registrasi lahan baru",
        icon: ICON_CONSTANS.IC_INFO_2
      },
      {
        label: "Unassign Request",
        labelTooltip: "Persetujuan hapus petani dari lahan.",
        icon: ICON_CONSTANS.IC_INFO_2
      }
    ]

    const componentList = [
      <AreaListPending
        key={0}
        data={Area}
        general={General}
        changePage={changePage}
        history={history}
        initialFilterTable={initialFilterTable}
        isActiveSearch={isActiveSearch}
        isFilter={isFilter}
      />,
      <AreaListPendingUnassign
        key={1}
        data={Area}
        general={General}
        changePage={changePage}
        history={history}
        initialFilterTable={initialFilterTable}
        isActiveSearch={isActiveSearch}
        isFilter={isFilter}
      />
    ]

    return {
      labels: labelList,
      contents: componentList
    }
  }

  useCallback(() => {
    const dataPage = {
      ...initialFilterTable
    }
    fetchData({ pageReq: dataPage })
  }, [tabActive])

  const changeTabs = (data) => {
    setInitialValue({
      mitra_id: "",
      province_id: "",
      regency_id: "",
      district_id: "",
      sub_district_id: "",
      min_area: "",
      max_area: ""
    })
    dispatch(resetUrlParam())
    setIsActiveSearch(false)
    setFilterOn(false)
    dispatch(setDataTabMultiple(data))
    setTabActive(data)
  }

  return (
    <>
      <FilterCard
        // seacrh
        isActiveSearch={isActiveSearch}
        placeholder={"Masukkan nama lahan"}
        onSubmitForm={seacrhFunc}
        initialValues={search}
        name={"string_filter"}
        innerRef={formRef}
        trigerReset={handleReset}
        // filter
        filterInnerRef={formRef}
        filterHandleSubmit={filterOnSubmitForm}
        filterFormInitialValues={initialValue}
        filterComponent={filterComponent}
        filterOnReset={onReset}
        showFilter={showFilter}
        handleOpenCloseFilter={handleOpenCloseFilter}
        filterOn={filterOn}
        btnDisable={btnFilterDisable}></FilterCard>

      <Card>
        <RowModule>
          <TabsWithIcon
            labelTab={getTabContents().labels}
            componentTab={getTabContents().contents}
            changeTab={changeTabs}
            initialFilterTable={initialFilterTable}
            initialValue={initialValue}
            search={search}
            initValue={tabActive}
          />
        </RowModule>
      </Card>
    </>
  )
}