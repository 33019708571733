import React, { useState, useCallback } from "react"
import { Button, Col, Modal, Row } from "react-bootstrap"
import TableView, { initialFilterTable } from "../../../component/atoms/Tabel"
import { Card, CardBody } from "../../../_metronic/_partials/controls"

export const PopupTable = ({
  show = false,
  title,
  titleCustom,
  showButton = true,
  closeButton = false,
  centered = true,
  textOk = "Ok",
  onCancel = () => {},
  dataTable,
  columns
}) => {
  const [currentPage, setCurrentPage] = useState(initialFilterTable.pageNumber)
  const [pageSize, setPageSize] = useState(initialFilterTable.pageSize)

  const changePage = useCallback(param => {
    setCurrentPage(param?.pageNumber)
    setPageSize(param?.pageSize)
  }, [])
  return (
    <Modal
      show={show}
      centered={centered}
      contentClassName="modal-image"
      onHide={onCancel}
      size="lg">
      {(title || titleCustom) && (
        <Modal.Header closeButton={closeButton}>
          {title ? <h6 className="mb-0">{title}</h6> : titleCustom}
        </Modal.Header>
      )}

      {dataTable && (
        <Modal.Body>
          <Card>
            <CardBody>
              <TableView
                callbackAfterPageChange={changePage}
                columnProperties={columns}
                dataTable={
                  dataTable &&
                  dataTable.filter((item, idx) =>
                    idx >= (currentPage - 1) * pageSize &&
                    idx < (currentPage - 1) * pageSize + pageSize
                      ? item
                      : null
                  )
                }
                currentPage={currentPage - 1}
                currentElement={pageSize}
                totalCount={dataTable.length}
              />
            </CardBody>
          </Card>
        </Modal.Body>
      )}
      {showButton && (
        <Row className="justify-content-center mt-1 mb-3">
          <Col sm="9" className="pl-10"></Col>

          <Col sm="3" className="pr-10">
            <Button className="font-weight-bold" block={true} onClick={onCancel}>
              {textOk}
            </Button>
          </Col>
        </Row>
      )}
    </Modal>
  )
}
