import { createSlice } from "@reduxjs/toolkit"

const initState = {
  listPrivilege: [],
  listRole: [],
  listRoleFo: [],
  data: null,
  action: false,
  listPrivilegeById: []
}

export const RoleSlice = createSlice({
  name: "role",
  initialState: initState,
  reducers: {
    getRole: (state, action) => {
      state.listRole = action.payload
    },
    getRoleFo: (state, action) => {
      state.listRoleFo = action.payload
    },
    getPrivilege: (state, action) => {
      state.listPrivilege = action.payload
    },
    getPrivilegeById: (state, action) => {
      state.listPrivilegeById = action.payload
    },
    setData: (state, action) => {
      const { pageData, page } = action.payload
      state.pageData = pageData
      state.page = page
    }
  }
})
