import React from "react"
import { useSelector } from "react-redux"
import { Skeleton } from "@material-ui/lab"

export const ApprovalDetailHeader = ({ area_name, activity_name, title, detailHeader }) => {
  const { General } = useSelector((state) => state)
  return (
    <>
      <h2 className="card-label mb-10">
        {General.loading ? (
          <Skeleton animation="wave" variant={"text"} height={40} width={250} />
        ) : (
          area_name + " (" + activity_name + ")"
        )}
      </h2>
      <div className="overview pb-8 mb-8 border-bottom">
        <h4 className="mb-6">
          {General.loading ? (
            <Skeleton animation="wave" variant={"text"} height={32} width={250} />
          ) : (
            title
          )}
        </h4>
        {detailHeader.map((item, i) => {
          return (
            <div className="row mb-3" key={i}>
              <div className="col-3 color-gray">
                {General.loading ? <Skeleton animation="wave" variant={"text"} /> : item.title}
              </div>
              <div className="col-9">
                <div className="d-flex">
                  <span className={"mr-2"}>:</span>
                  <span>
                    {General.loading ? (
                      <Skeleton animation="wave" variant={"text"} height={24} width={250} />
                    ) : (
                      item.value
                    )}
                  </span>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}
