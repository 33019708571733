import { createSlice } from "@reduxjs/toolkit"
import { initialFilterTable } from "../../component/atoms/Tabel"

const initState = {
  pageData: [],
  pageDataActivity: [],
  pageActivity: initialFilterTable,
  page: initialFilterTable,
  combobox: [],
  data: undefined,
  actions: false,
  plantingEvent: null,
  rejectReasons: [],
  activityList: [],
  historyList: [],
  plantingStatusEnum: [],
  confirm: undefined,
  mapDetail: undefined,
  responsePost: undefined,
  bulkList: []
}

export const AreaSlice = createSlice({
  name: "area",
  initialState: initState,
  reducers: {
    getPage: (state, action) => {
      state.data = action.payload
    },
    getMapDetail: (state, action) => {
      state.mapDetail = action.payload
    },
    getPlantingEvent: (state, action) => {
      state.plantingEvent = action.payload
    },
    getRejectReason: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.rejectReasons = action.payload
      }
    },
    setCombobox: (state, action) => {
      state.combobox = action.payload
    },
    setPageData: (state, action) => {
      const { pageData, page } = action.payload
      state.pageData = pageData
      state.page = page
    },
    setPageDataActivity: (state, action) => {
      const { pageData, page } = action.payload
      state.pageDataActivity = pageData
      state.pageActivity = page
    },
    setActions: (state, action) => {
      state.actions = action.payload
    },
    setActivityList: (state, action) => {
      state.activityList = action.payload
    },
    setHistoryList: (state, action) => {
      state.historyList = action.payload
    },
    setConfirm: (state, action) => {
      state.confirm = action.payload
    },
    setPlantingStatusEnum: (state, action) => {
      state.plantingStatusEnum = action.payload
    },
    setResponsePost: (state, action) => {
      state.responsePost = action.payload
    },
    setBulkList: (state, action) => {
      state.bulkList = action.payload
    }
  }
})
