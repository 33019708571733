import React from "react"
import { Card, Image } from "react-bootstrap"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../_metronic/_helpers"
import { useSelector } from "react-redux"
import { Skeleton } from "@material-ui/lab"

export function CardDetailHeader({
  titleImage,
  titleCardDetail,
  descriptionLeft,
  descriptionRight,
  wrap = true,
  svgImage
}) {
  const { General } = useSelector((state) => state)
  const getInitials = (name) => {
    if (name) {
      let initials = name.split(" ")

      if (initials.length > 1) {
        initials = initials.shift().charAt(0) + initials.pop().charAt(0)
      } else {
        initials = name.substring(0, 2)
      }

      return initials.toUpperCase()
    }
    return "MAC"
  }
  const imageData = (imageUrl) => {
    if (imageUrl) {
      return <Image src={imageUrl} alt="image" />
    }
    return (
      <span className="font-size-h3 symbol-label font-weight-boldest">
        {getInitials(titleCardDetail)}
      </span>
    )
  }
  const imageSvg = (imageUrl) => {
    if (imageUrl) {
      return <SVG className="mr-2 mb-1" src={toAbsoluteUrl(imageUrl)} />
    }
  }
  return (
    <Card>
      <Card.Body>
        {/*begin::Details*/}
        <div className="d-flex justify-content-between align-items-center">
          {/*begin::Pic*/}
          <div className="flex-shrink-0 mr-7 mt-lg-0 mt-3">
            <div className="symbol symbol-50 symbol-lg-120">
              {General.loading ? (
                <Skeleton animation="wave" variant={"rect"} height={60} width={60} />
              ) : svgImage ? (
                imageSvg(svgImage)
              ) : (
                imageData(titleImage)
              )}
            </div>
          </div>
          {/*end::Pic*/}
          {/*begin::Info*/}
          <div className="flex-1">
            {/*begin::Title*/}
            <div className="">
              <div className="d-flex w-100 align-items-center">
                <div
                  className={`d-flex text-capitalize text-dark-75 font-size-h4 font-weight-bolder`}>
                  {titleCardDetail}
                </div>
              </div>
            </div>
            {/*end::Title*/}
            {/*begin::Content*/}
            <div
              className={
                wrap
                  ? "d-flex flex-wrap justify-content-between mt-1"
                  : "d-flex flex-nowrap justify-content-between mt-1"
              }>
              <div className="d-flex flex-column flex-grow-1 pr-8">{descriptionLeft}</div>
              <div className="d-flex justify-content-end align-items-center w-25 flex-fill float-right">
                {descriptionRight}
              </div>
            </div>
            {/*end::Content*/}
          </div>
          {/*end::Info*/}
        </div>
        {/*end::Details*/}
      </Card.Body>
    </Card>
  )
}
