import React from "react"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers"
import { ICON_CONSTANS } from "../../../../constants/ImageConstants.js"

export const CalenderSvgIcon = () => {
  return (
    <span className="svg-icon svg-icon-lg svg-icon-inherit">
      <SVG src={toAbsoluteUrl(ICON_CONSTANS.IC_CALENDER)} />
    </span>
  )
}