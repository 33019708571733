import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSubheader } from "../../../../../_metronic/layout/index.js"
import { useDispatch, useSelector } from "react-redux"
import { RoutesConstants } from "../../../../../constants/RoutesConstants.js"
import { FlatIconAndText, TabsComponent } from "../../../../../component/index.jsx"
import { getDetailProfile, resetDetailMac } from "../../../../../redux/actions/MacAction.jsx"
import { Detail } from "./component/Detail.jsx"
import { Document } from "./component/Document.jsx"
import { ICON_CONSTANS } from "../../../../../constants/ImageConstants.js"

export const ProfileDetail = ({
  match: {
    params: { id }
  }
}) => {
  const { t } = useTranslation()
  const subHeader = useSubheader()
  const dispatch = useDispatch()
  const [detail, setDetail] = useState({})
  const { General, Mac } = useSelector((state) => state)

  useEffect(() => {
    dispatch(getDetailProfile(id))
    return () => {
      dispatch(resetDetailMac())
    }
  }, [])

  useEffect(() => {
    if (Mac?.detail) {
      setDetail(Mac?.detail)
    }
  }, [Mac?.detail])

  useEffect(() => {
    subHeader.setBreadcrumbs([
      {
        pathname: RoutesConstants.MAC.LIST_PROFILE(),
        title: "MAC"
      },
      {
        pathname: RoutesConstants.MAC.LIST_PROFILE(),
        title: "Profil"
      },
      {
        title: General.loading ? "" : detail?.name
      }
    ])
    subHeader.setTitle(null)
  }, [detail])

  const descriptionLeft = (
    <>
      <div className="d-flex flex-row">
        <div className="d-flex flex-wrap">
          <FlatIconAndText
            text={detail?.code ? detail?.code : "-"}
            svg={ICON_CONSTANS.IC_ACCOUNT}
          />
        </div>
      </div>
    </>
  )

  const information = {
    title: t("label.mac_profile_information"),
    data: [
      {
        label: t("label.company_name"),
        value: detail?.name ?? "-"
      },
      {
        label: t("label.company_phone"),
        value: detail?.phone ?? "-"
      },
      {
        label: t("label.npwp"),
        value: detail?.document_number ?? "-"
      },
      {
        label: t("label.company_full_address"),
        value: detail?.address ?? "-"
      },
      {
        label: t("label.company_email"),
        value: detail?.email ?? "-"
      }
    ]
  }

  const doc = [
    {
      label: t("label.company_document"),
      url: detail?.document_url
    }
  ]

  const renderContents = () => {
    const labels = ["DETAIL PROFIL", "DOKUMEN"]
    const components = [
      <Detail
        data={detail}
        descriptionLeft={descriptionLeft}
        informasiProfile={information}
        key={""}
      />,
      <Document document={doc} key={""} />
    ]

    return {
      labels,
      components
    }
  }

  return (
    <>
      <TabsComponent
        labelTab={renderContents().labels}
        componentTab={renderContents().components}
      />
    </>
  )
}