import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useSubheader } from "../../../../../_metronic/layout/index.js"
import { Card } from "../../../../../_metronic/_partials/controls/index.js"
import { RowModule } from "../../../../../component/atoms/RowModule/RowModule.jsx"
import { TableList } from "./table/TableList.jsx"
import { EmptyCard } from "../../../../../component/atoms/index.jsx"
import { getCategoryReason } from "../../../../../redux/actions/MasterDataAction.jsx"

export const ManageReasonList = ({ history }) => {
  const dispatch = useDispatch()
  const subHeader = useSubheader()
  const { MasterData, General } = useSelector((state) => state)
  const [reasonCategory, setReasonCategory] = useState("")

  useEffect(() => {
    dispatch(getCategoryReason())
  }, [])

  useEffect(() => {
    if (MasterData.categoryReason) {
      setReasonCategory(MasterData.categoryReason)
    }
  }, [MasterData])

  useEffect(() => {
    subHeader.setButton(null)
    subHeader.setBreadcrumbs(null)
    subHeader.setTitle("Manage Reason")
  }, [])
  return (
    <>
      {General.loading === false && reasonCategory.length < 1 ? (
        <EmptyCard body={<h6>Belum ada Treatment.</h6>} />
      ) : (
        <Card>
          <RowModule>
            <TableList data={reasonCategory} history={history} />
          </RowModule>
        </Card>
      )}
    </>
  )
}