import React, { useEffect, useRef, useState } from "react"
import { CardDetail } from "../../../../../component/atoms/CardDetail/index.jsx"
import { RowModule } from "../../../../../component/atoms/RowModule/RowModule.jsx"
import { useDispatch, useSelector } from "react-redux"
import {
  CheckboxLabelButton,
  EmptyCard,
  Filter,
  FlatIconAndText,
  Text2Row
} from "../../../../../component/index.jsx"
import { AREA_ACTIVITY_TYPE } from "../../../../../constants/InitTypeConstants.js"
import { Card } from "react-bootstrap"
import { CardBody, CardHeader } from "../../../../../_metronic/_partials/controls/index.js"
import { getListActivity } from "../../../../../redux/actions/AreaAction.jsx"
import { CalenderSvgIcon } from "../../../../../component/atoms/Icons/SVG/calender.jsx"
import { DateRange } from "react-date-range"
import { format } from "date-fns"
import { dateSelect, timeStampToDate } from "../../../../helpers/DateHelper.js"
import { loadUserLogin } from "../../../../service/userManagementAction.js"
import { getActivityTypeList } from "../../../../../redux/actions/MasterDataAction.jsx"
import { Skeleton } from "@material-ui/lab"
import { PRIVILEGE_ENUM } from "../../../../../constants/PrivilegeConstants.js"
import { ACTIVITY_ICONS, ICON_CONSTANS } from "../../../../../constants/ImageConstants.js"

export const ActivityTab = ({ id, status, history }) => {
  const [isType, setIsType] = useState([])

  const privileges = loadUserLogin().privilege
  const formRef = useRef()
  const dispatch = useDispatch()
  const { General, Area, MasterData } = useSelector((state) => state)
  const [showFilter, setShowFilter] = useState(false)
  const [filterOn, setFilterOn] = useState(false)
  const [initialValue, setInitialValue] = useState({
    type: "",
    start_date: "",
    end_date: ""
  })
  const [dateRange, setDateRange] = useState("")
  const [openDate, setOpenDate] = useState(false)
  const [filterDate, setFilterDate] = useState(false)
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection"
    }
  ])
  const [typeValue, setTypeValue] = useState([])

  useEffect(() => {
    if (id) {
      dispatch(getListActivity(id, ""))
      dispatch(getActivityTypeList())
    }
  }, [])

  useEffect(() => {
    if (MasterData.activityTypeList) {
      setIsType(
        MasterData.activityTypeList.map((item) => {
          return {
            enum: item.id,
            label: item.name,
            checked: false
          }
        })
      )
    }
  }, [MasterData.activityTypeList])

  const descriptionLeft = (item) => {
    return (
      <>
        {item?.plant_type?.toLowerCase() === "padi" ? (
          <FlatIconAndText text={item?.variety_name} svg={ICON_CONSTANS.IC_PADDY} />
        ) : (
          <FlatIconAndText text={item?.variety_name} svg={ICON_CONSTANS.IC_CORN} />
        )}
      </>
    )
  }

  const Text2Rows = (item) => {
    let param = "DETAIL_ACTIVITY_" + item?.type.toUpperCase()
    const dateStart = item?.activity_date ? timeStampToDate(item?.activity_date, "dd-MM-yyyy") : "-"
    const dateEnd = item?.activity_end_date
      ? timeStampToDate(item?.activity_end_date, "dd-MM-yyyy")
      : "-"
    let activityDate =
      item?.type.toUpperCase() === AREA_ACTIVITY_TYPE.HARVEST_PREDICTION ||
      item?.type.toUpperCase() === AREA_ACTIVITY_TYPE.HARVEST
        ? dateStart + " s/d " + dateEnd
        : dateStart

    return (
      <>
        <Text2Row
          title="Tanggal Aktivitas:"
          description={activityDate}
          svg={ICON_CONSTANS.IC_FARMER_BIG}
        />
        <Text2Row
          title="HST:"
          description={`${item?.hst} HST` ?? "-"}
          svg={ICON_CONSTANS.IC_MITRA_2}
        />
        <Text2Row
          title="Submitted by:"
          description={item?.submitted_by}
          svg={ICON_CONSTANS.IC_MITRA_2}
        />
        <Text2Row
          title="Submitted time:"
          description={timeStampToDate(item?.submitted_date, "dd-MM-yyyy - HH:mm:ss")}
          svg={ICON_CONSTANS.IC_MITRA_2}
        />
        {privileges.indexOf(PRIVILEGE_ENUM[param]) > -1 && (
          <div className={"d-flex justify-content-end"}>
            {General.loading ? (
              <Skeleton animation="wave" variant={"text"} width={100} />
            ) : (
              <a
                className={"link"}
                onClick={() =>
                  history.push(
                    "/lahan/" + status + "/" + id + "/" + item?.type.toLowerCase() + "/" + item.id
                  )
                }>
                Lihat Detail
              </a>
            )}
          </div>
        )}
      </>
    )
  }
  const titleImage = (item) => {
    switch (item?.type) {
      case AREA_ACTIVITY_TYPE.SEEDLING:
        return ACTIVITY_ICONS.IC_SEMAI
      case AREA_ACTIVITY_TYPE.TILLAGE:
        return ACTIVITY_ICONS.IC_OLAH_TANAH
      case AREA_ACTIVITY_TYPE.PRE_PLANTING:
        return ACTIVITY_ICONS.IC_PENYEMPROTAN
      case AREA_ACTIVITY_TYPE.PLANTING:
        return ACTIVITY_ICONS.IC_PENANAMAN
      case AREA_ACTIVITY_TYPE.SPRAYING:
        return ACTIVITY_ICONS.IC_PENYEMPROTAN
      case AREA_ACTIVITY_TYPE.FERTILIZATION:
        return ACTIVITY_ICONS.IC_PEMUPUKAN
      case AREA_ACTIVITY_TYPE.HARVEST_PREDICTION:
        return ACTIVITY_ICONS.IC_ESTIMASI_PANEN
      case AREA_ACTIVITY_TYPE.DRAFT_HARVEST:
        return ACTIVITY_ICONS.IC_ESTIMASI_PANEN
      case AREA_ACTIVITY_TYPE.HARVEST:
        return ACTIVITY_ICONS.IC_PANEN
      default:
        return false
    }
  }
  const selectDate = (
    <div className={"d-flex justify-content-end"}>
      <div
        onClick={() => setOpenDate(!openDate)}
        className={"select-date d-flex justify-content-between align-items-center cursor-pointer"}>
        <label className={"mb-0 txt-12 cursor-pointer"}>
          {dateRange ? dateRange : "Pilih Range Tanggal"}
        </label>
        <CalenderSvgIcon />
      </div>
    </div>
  )
  const submitLog = () => {
    const valueArray = 0
    let selectedDate =
      dateSelect(format(state[valueArray].startDate, "yyyy-MM-dd")) +
      " s/d " +
      dateSelect(format(state[valueArray].endDate, "yyyy-MM-dd"))
    setDateRange(selectedDate)
    setFilterDate(true)
    setOpenDate(false)
  }
  const resetDate = () => {
    setOpenDate(false)
    setFilterDate(false)
    setDateRange("")
    setState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection"
      }
    ])
  }
  const dateRangePicker = (
    <div className={"position-relative"}>
      <div className={"date-picker-custom"}>
        <DateRange
          onChange={(item) => setState([item.selection])}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={1}
          ranges={state}
          direction="horizontal"
          preventSnapRefocus={true}
          calendarFocus="backwards"
        />
        <div className={"action-wrapper d-flex justify-content-end py-4 px-4 border-top"}>
          <button onClick={resetDate} type={"button"} className={"btn btn-outline-primary mx-4"}>
            Reset Tanggal
          </button>
          <button onClick={submitLog} type={"button"} className={"btn btn-primary mx-4"}>
            Terapkan
          </button>
        </div>
      </div>
    </div>
  )

  const handleType = (data) => {
    const { name, checked } = data.target
    let filteredStatus = isType.filter((item) => item.enum === name)
    let newListStatus = {
      label: filteredStatus[0].label,
      enum: filteredStatus[0].enum,
      checked: checked
    }
    let newStatus = isType.map((el) => (el.enum === newListStatus.enum ? { ...newListStatus } : el))
    setIsType(newStatus)
    if (checked) {
      setTypeValue([...typeValue, name])
    } else {
      let filteredStatus = typeValue.filter((item) => item !== name)
      setTypeValue(filteredStatus)
    }
  }

  const filterComponent = (
    <>
      <Card className="card-custom card-shadowless">
        <CardHeader title="Jenis Aktivitas" className="px-0" />
        <CardBody className="px-0">
          <CheckboxLabelButton
            formInitialValues={typeValue}
            isStatus={isType}
            name={"activity_type_enum"}
            innerRef={formRef}
            handleChange={handleType}
          />
        </CardBody>
      </Card>
      <Card className="card-custom card-shadowless">
        <CardHeader title="Tanggal Aktivitas" className="px-0" />
        <CardBody className="px-0">
          {selectDate}
          {openDate && dateRangePicker}
        </CardBody>
      </Card>
    </>
  )

  const filterOnSubmitForm = (e) => {
    const valueArray = 0
    const { submited } = e
    if (submited === true) {
      let param
      if (filterDate) {
        param = {
          type: typeValue,
          start_date: format(state[valueArray].startDate, "yyyy-MM-dd"),
          end_date: format(state[valueArray].endDate, "yyyy-MM-dd")
        }
      } else {
        param = {
          type: typeValue
        }
      }
      if (param) {
        setInitialValue(param)
        dispatch(getListActivity(id, param))
        setShowFilter(false)
        setFilterOn(true)
      }
    }
  }
  const onReset = () => {
    setShowFilter(false)
    setFilterOn(false)
    setInitialValue({
      type: "",
      start_date: "",
      end_date: ""
    })
    resetDate()
    let newStatus = isType.map((el) => ({
      label: el.label,
      enum: el.enum,
      checked: false
    }))
    setIsType(newStatus)
    setTypeValue([])
    let param = {
      type: "",
      start_date: "",
      end_date: ""
    }
    dispatch(getListActivity(id, param))
  }
  const handleOpenCloseFilter = (value) => {
    setShowFilter(value)
  }

  const filter = (
    <div className="filter-container">
      <div className="position-relative d-flex flex-wrap justify-content-end align-items-center mb-10">
        <Filter
          filterInnerRef={formRef}
          filterHandleSubmit={filterOnSubmitForm}
          filterFormInitialValues={initialValue}
          filterComponent={filterComponent}
          filterOnReset={onReset}
          showFilter={showFilter}
          handleOpenCloseFilter={handleOpenCloseFilter}
          filterOn={filterOn}
          noneOverflow={true}
        />
      </div>
    </div>
  )

  return (
    <>
      {filter}
      {!General.loading && Area?.activityList?.length === 0 ? (
        <EmptyCard bodyCustom={<h6 className={"mt-12"}>Belum ada aktivitas lahan.</h6>} />
      ) : (
        Area?.activityList?.map((item, index) => {
          return (
            <RowModule key={index}>
              <CardDetail
                titleClassName={"w-100"}
                titleCardDetail={item?.name}
                descriptionLeft={descriptionLeft(item)}
                flatIconAndText2Rows={Text2Rows(item)}
                svgImage={titleImage(item)}
              />
            </RowModule>
          )
        })
      )}
    </>
  )
}