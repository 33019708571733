import React, { useEffect, useState } from "react"
import { TextInput, UploadComponent } from "../../../../../component/index.jsx"
import { Card, CardBody, CardHeader } from "../../../../../_metronic/_partials/controls/index.js"
import { RowModule } from "../../../../../component/atoms/RowModule/RowModule.jsx"
import { AddressMolecule, SelectInput } from "../../../../../component/index.jsx"
import { resetUrlResponse, uploadAction } from "../../../../../redux/actions/MasterDataAction.jsx"
import { useDispatch, useSelector } from "react-redux"
import { UploadIcon } from "../../../../../component/atoms/Icons/SVG/upload.jsx"
import { useFormikContext } from "formik"

export const DataFo = ({ isReadOnly, onKeyDown }) => {
  const { values: formValues } = useFormikContext()
  const { MasterData, FieldOfficer } = useSelector((state) => state)
  const dispatch = useDispatch()
  const [avatar, setAvatar] = useState("")
  const [listRole, setListRole] = useState([])
  const [selectedRole, setSelectedRole] = useState(formValues["roleFO"])

  function onChangeAvatar(e) {
    dispatch(uploadAction({ data: e, type: "AVATAR" }))
  }

  useEffect(() => {
    if (MasterData.urlResponseUpload) {
      if (MasterData.uploadType === "AVATAR") {
        setAvatar(MasterData.urlResponseUpload)
      }
    }
    return () => {
      dispatch(resetUrlResponse())
    }
  }, [MasterData.urlResponseUpload])

  useEffect(() => {
    if (formValues.avatar) {
      if (MasterData.urlResponseUpload === "") {
        formValues.avatar = ""
      }
    }
  }, [MasterData.uploadType, MasterData.urlResponseUpload])

  useEffect(() => {
    if (FieldOfficer?.role && FieldOfficer?.role?.length > 0) {
      setListRole(
        FieldOfficer?.role?.map((value) => ({
          label: value?.display_name,
          value: value?.name
        }))
      )
    }
  }, [FieldOfficer.role])

  useEffect(() => {
    if (formValues.hasOwnProperty("roleFO")) {
      setSelectedRole(formValues["roleFO"])
    }
  }, [formValues["roleFO"]])

  const placeholder = <UploadIcon />

  return (
    <>
      <Card className="card-custom card-shadowless">
        <CardHeader title="Detail Field Officer" />
        <CardBody>
          <RowModule>
            <UploadComponent
              urlParsing={avatar}
              name="avatar"
              label="Avatar"
              editable={!isReadOnly}
              onChange={onChangeAvatar}
              placeholder={placeholder}
            />
          </RowModule>
          <RowModule>
            <TextInput
              onKeyDown={onKeyDown}
              editable={!isReadOnly}
              label="Nama"
              name="name"
              placeholder="Input Nama Field Officer"
            />
          </RowModule>
          <RowModule>
            <TextInput
              editable={!isReadOnly}
              type="number"
              label="No. Handphone"
              name="phone"
              placeholder="Input No. Handphone Field Officer"
            />
          </RowModule>
          <RowModule>
            <TextInput
              editable={!isReadOnly}
              label="Email"
              name="email"
              placeholder="Input Email Field Officer"
            />
          </RowModule>
          <RowModule>
            <SelectInput
              name="roleFO"
              editable={!isReadOnly}
              label="Role FO"
              placeholder="Pilih Role FO"
              options={listRole}
              chooseOptionWithLabel={false}
              value={selectedRole}
            />
          </RowModule>
        </CardBody>
      </Card>
      <AddressMolecule name="fo" isReadOnly={isReadOnly} addressTitle="Alamat Field Officer" />
    </>
  )
}