import { RowModule } from "../../../../../../component/atoms/RowModule/RowModule.jsx"
import { CardDetailHeader } from "../../../../../../component/atoms/CardDetail/CardDetailHeader.jsx"
import { ICON_CONSTANS } from "../../../../../../constants/ImageConstants.js"
import { Card } from "react-bootstrap"
import { CardBody } from "../../../../../../_metronic/_partials/controls/index.js"
import { RowText } from "../../../../../../component/index.jsx"
import React from "react"
import { Skeleton } from "@material-ui/lab"
import { useSelector } from "react-redux"

export const Detail = ({ data, descriptionLeft, informasiProfile }) => {
  const { General } = useSelector((state) => state)
  return (
    <>
      <RowModule>
        <CardDetailHeader
          titleCardDetail={data?.user_merchant_name != null ? data?.user_merchant_name : ""}
          descriptionLeft={descriptionLeft}
          svgImage={ICON_CONSTANS.IC_DETAIL_PROFILE_MAC}
        />
      </RowModule>
      <Card>
        <CardBody>
          <h4 className={"mb-8 text-capitalize font-weight-bolder"}>
            {General.loading ? (
              <Skeleton animation="wave" variant={"text"} height={32} width={200} />
            ) : (
              informasiProfile?.title
            )}
          </h4>
          <RowModule customColumnCss={"col-md-6 mb-2"}>
            {informasiProfile?.data?.map((item, index) => {
              return (
                <RowModule key={index}>
                  <RowText label={item?.label} value={item?.value} />
                </RowModule>
              )
            })}
          </RowModule>
        </CardBody>
      </Card>
    </>
  )
}