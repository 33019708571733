import { createSlice } from "@reduxjs/toolkit"

export const initState = {
  pageString: null,
  data: null,
  search: null,
  dataValue: null,
  dataMultipleTab: 0
}

export const UrlParam = createSlice({
  name: "urlParam",
  initialState: initState,
  reducers: {
    setDataValue: (state, action) => {
      state.dataValue = action.payload.dataValue
    },
    setFilter: (state, action) => {
      state.data = action.payload.data
    },

    setSearch: (state, action) => {
      state.search = action.payload.search
    },

    setPageString: (state, action) => {
      state.pageString = action.payload.pageString
    },
    setDataMultiple: (state, action) => {
      state.dataMultipleTab = action.payload.dataMultipleTab
    },
    setResetValue: (state, action) => {
      const { dataValue, pageString, data } = action.payload
      state.dataValue = dataValue
      state.pageString = pageString
      state.data = data
      state.search = null
      state.dataMultipleTab = 0
    },
    setResetFilter: state => {
      state.dataValue = null
      state.data = null
    }
  }
})
