import { createSlice } from "@reduxjs/toolkit"
import { initialFilterTable } from "../../component/atoms/Tabel"

const initState = {
  pageData: [],
  page: initialFilterTable,
  combobox: [],
  data: undefined,
  actions: false,
  canDeactivate: null,
  type: [],
  hasFarmer: false,
  list: [],
  messages: null
}

export const MitraSlice = createSlice({
  name: "mitra",
  initialState: initState,
  reducers: {
    getPage: (state, action) => {
      state.data = action.payload
    },
    setCombobox: (state, action) => {
      state.combobox = action.payload
    },
    setPageData: (state, action) => {
      const { pageData, page } = action.payload
      state.pageData = pageData
      state.page = page
    },
    setActions: (state, action) => {
      state.actions = action.payload
    },
    setMessage: (state, action) => {
      state.messages = action.payload
    },
    getCanDeactivate: (state, action) => {
      state.canDeactivate = action.payload
    },
    setType: (state, action) => {
      state.type = action.payload
    },
    getCheckHasFarmer: (state, action) => {
      state.hasFarmer = action.payload
    },
    getList: (state, action) => {
      state.list = action.payload
    }
  }
})

export const MitraListSlice = createSlice({
  name: "mitralist",
  initialState: initState,
  reducers: {
    getPage: (state, action) => {
      state.data = action.payload
    },
    setCombobox: (state, action) => {
      state.combobox = action.payload
    },
    setPageData: (state, action) => {
      const { pageData, page } = action.payload
      state.pageData = pageData
      state.page = page
    },
    setActions: (state, action) => {
      state.actions = action.payload
    }
  }
})
