import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import { put, takeLatest } from "redux-saga/effects"

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request user] Action",
  UserLoaded: "[Load user] Auth API",
  SetUser: "[Set user] Action"
}

const initialAuthState = {
  user: undefined,
  authToken: undefined
}

export const reducer = persistReducer(
  { storage, key: "v713-demo1-auth", whitelist: ["user", "authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken } = action.payload
        localStorage.setItem("dataLogin", authToken.data)
        localStorage.setItem("userLogin", JSON.stringify(authToken.data))
        localStorage.setItem("jwt_token", authToken.data.jwt_token)
        localStorage.setItem("province_id", authToken.data.province_id)
        localStorage.setItem("regional_id", authToken.data.regional_id)
        return { authToken: authToken.data.jwt_token, user: authToken.data.email }
      }

      case actionTypes.Register: {
        const { authToken } = action.payload

        return { authToken, user: undefined }
      }

      case actionTypes.Logout: {
        return initialAuthState
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload
        return { ...state, user }
      }

      case actionTypes.SetUser: {
        const { user } = action.payload
        return { ...state, user }
      }

      default:
        return state
    }
  }
)

export const actions = {
  login: (authToken) => ({ type: actionTypes.Login, payload: { authToken } }),
  register: (authToken) => ({
    type: actionTypes.Register,
    payload: { authToken }
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user }
  }),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  setUser: (user) => ({ type: actionTypes.SetUser, payload: { user } })
}

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {})
}