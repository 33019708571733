import React, { useEffect } from "react"
import { Badge, Card, OverlayTrigger, Tooltip } from "react-bootstrap"
import { CardBody } from "../../../../../_metronic/_partials/controls"
import { EmptyCard, TableSkeleton, TableView } from "../../../../../component"
import { formatterRowComponent } from "../../../../../component/atoms/Tabel"
import { sortCaret } from "../../../../../_metronic/_helpers"
import { DetailSvgIcon, EditSvgIcon } from "../../../../../component/atoms/Icons/SVG"
import { loadUserLogin } from "../../../../service/userManagementAction"
import { timeStampToDate } from "../../../../helpers/DateHelper"
import { useSubheader } from "../../../../../_metronic/layout"
import { linkWithCtrl } from "../../../../helpers/Utils"
import { RoutesConstants } from "../../../../../constants/RoutesConstants"
import { getUrlParsingPageFilter } from "../../../../../config/EndpointCollection.js"
import { useLocation } from "react-router"
import { FIELD_OFFICER_ROLE_ENUM } from "../../../../../constants/StringConstant"
import { useTranslation } from "react-i18next"
import { PRIVILEGE_ENUM } from "../../../../../constants/PrivilegeConstants.js"

export const ListFoAgro = ({
  data,
  changePage,
  history,
  general,
  initialFilterTable,
  isActiveSearch
}) => {
  const privileges = loadUserLogin().privilege
  const location = useLocation()
  const subheader = useSubheader()
  const { t } = useTranslation()

  useEffect(() => {
    subheader.setButton(undefined)
  }, [])

  const UiId = (e) => {
    return (
      <div>
        <div className={"font-weight-bolder font-size-lg"}>{e.code ?? "-"}</div>
      </div>
    )
  }

  const UiName = (e) => {
    return (
      <div>
        <div className={"font-weight-bolder font-size-lg"}>{e.name ?? "-"}</div>
        <div>{e.phone_number ?? "-"}</div>
      </div>
    )
  }

  const UiDate = (e) => {
    return (
      <>
        <div className="mb-1">{timeStampToDate(e.created_date, "dd-MM-yyyy")}</div>
        <div>{timeStampToDate(e.created_date, "HH:mm:ss")}</div>
      </>
    )
  }
  const uiStatus = (e) => {
    return (
      <Badge variant={e?.status_enum === "ACTIVE" ? "primary" : "secondary"}>
        {e.status_string ? e.status_string : "-"}
      </Badge>
    )
  }

  const uiAction = (e) => {
    return (
      <div>
        {privileges.indexOf(PRIVILEGE_ENUM.DETAIL_FIELD_OFFICER_AGRONOMIST) > -1 && (
          <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Detail</Tooltip>}>
            <div
              onClick={() => {
                history.push(
                  RoutesConstants.FIELD_OFFICER.DETAIL_FO_AGRONOMIS(
                    FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER_AGRONOMIST,
                    e?.id
                  )
                )
              }}
              className="btn btn-icon btn-detail btn-hover-secondary btn-sm mx-3">
              <DetailSvgIcon />
            </div>
          </OverlayTrigger>
        )}
        {privileges.indexOf(PRIVILEGE_ENUM.EDIT_FIELD_OFFICER) > -1 && (
          <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Edit</Tooltip>}>
            <div
              onClick={() => {
                history.push("/field-officer/edit/" + e?.id)
              }}
              className="btn btn-icon btn-detail btn-hover-secondary btn-sm mx-3">
              <EditSvgIcon />
            </div>
          </OverlayTrigger>
        )}
      </div>
    )
  }
  const colums = [
    {
      dataField: "code",
      text: "ID FO",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: UiId
      }
    },
    {
      dataField: "name",
      text: "NAMA",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: UiName
      },
      sortCaret: sortCaret,
      sort: true,
      headerStyle: () => {
        return { width: "15%" }
      },
      onSort: (field, order) => {
        let param = getUrlParsingPageFilter({
          ...initialFilterTable,
          sort: field + "," + order
        })
        history.push({ pathname: location.pathname, search: param })
      }
    },
    {
      dataField: "full_address",
      text: "ALAMAT",
      formatter: formatterRowComponent,
      headerStyle: () => {
        return { width: "45%" }
      }
    },
    {
      dataField: "status",
      text: "Status",
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiStatus
      }
    },
    {
      dataField: "createdDate",
      text: "TANGGAL DIBUAT",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: UiDate
      },
      onSort: (field, order) => {
        let param = getUrlParsingPageFilter({
          ...initialFilterTable,
          sort: field + "," + order
        })
        history.push({ pathname: location.pathname, search: param })
      },
      sortCaret: sortCaret,
      sort: true,
      headerStyle: () => {
        return { width: "15%" }
      }
    },
    {
      dataField: "action",
      text: "ACTION",
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiAction
      },
      headerStyle: () => {
        return { width: "12%" }
      }
    }
  ]

  return (
    <>
      <Card>
        <CardBody>
          {general.loading ? (
            <TableSkeleton column={6} row={10} />
          ) : data?.pageData?.length == 0 ? (
            <EmptyCard
              body={
                isActiveSearch
                  ? t("messages.response.data_not_found")
                  : t("messages.response.empty_state")
              }
            />
          ) : (
            ""
          )}
          <TableView
            callbackAfterPageChange={changePage}
            columnProperties={colums}
            dataTable={data?.pageData ?? []}
            currentPage={data?.page?.currentPage ?? 1}
            currentElement={data?.page?.currentElement ?? 10}
            totalCount={data?.page?.totalCount ?? 0}
            loadingGetData={general.loading}
            ignoreSinglePage
            rowClasses={() => {
              return "cursor-pointer"
            }}
            rowEvents={{
              onClick: (e, row) => {
                if (privileges.indexOf(PRIVILEGE_ENUM.DETAIL_FIELD_OFFICER_AGRONOMIST) > 1) {
                  if (e.target.cellIndex < 5) {
                    const url = RoutesConstants.FIELD_OFFICER.DETAIL_FO_AGRONOMIS(
                      FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER_AGRONOMIST,
                      row?.id
                    )

                    if (e.ctrlKey) {
                      linkWithCtrl(url)
                    } else {
                      history.push(url)
                    }
                  }
                }
              }
            }}
          />
        </CardBody>
      </Card>
    </>
  )
}