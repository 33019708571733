import React, { useEffect, useRef, useState } from "react"
import {
  ButtonDropdown,
  FlatIconAndText,
  FlatIconAndText2Row,
  SwitchToggle,
  TabLog,
  TabsComponent,
  TextAreaInput
} from "../../../../component/index.jsx"
import { CardDetailUsaha } from "./tab/BusinessTab.jsx"
import { CardDetailFarmer } from "./tab/FarmerTab.jsx"
import { CardDetailMitra } from "./tab/MitraTab.jsx"
import { useDispatch, useSelector } from "react-redux"
import {
  clearDataDetail,
  clearSyncErp,
  deactivateStatusMitra,
  getCheckCanDeactivate,
  getDetail,
  getPageDataVerifiedCombobox,
  resetCheckCanDeactivate,
  syncErp,
  updateImage,
  updateStatus
} from "../../../../redux/actions/MitraAction.jsx"
import { Button } from "react-bootstrap"
import { useSubheader } from "../../../../_metronic/layout/index.js"
import { RowModule } from "../../../../component/atoms/RowModule/RowModule.jsx"
import { Popup, PopupQuestion } from "../../../../component/atoms/Popup/index.jsx"
import { clearUploadAction } from "../../../../redux/actions/MasterDataAction.jsx"
import { Form, Formik } from "formik"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers/index.js"
import Select from "react-select"
import swal from "sweetalert"
import { ENUM_LOG, MITRA } from "../../../../constants/InitTypeConstants.js"
import { loadUserLogin } from "../../../service/userManagementAction.js"
import { ICON_CONSTANS, ILLUSTRATION_ASSETS_V2 } from "../../../../constants/ImageConstants.js"
import { RoutesConstants } from "../../../../constants/RoutesConstants.js"
import { toRupiah } from "../../../helpers/TextHelper.js"
import { ResetIsFinish } from "../../../../redux/actions/GeneraActions.jsx"
import { Skeleton } from "@material-ui/lab"
import { ROUTING_STATUS } from "../../../../constants/StringConstant.js"
import { PRIVILEGE_ENUM } from "../../../../constants/PrivilegeConstants.js"

export const DetailMitra = ({
  history,
  match: {
    params: { status, id }
  }
}) => {
  const privileges = loadUserLogin().privilege
  const subHeader = useSubheader()
  const dispatch = useDispatch()
  const { General, Mitra, MasterData } = useSelector((state) => state)
  const [state, setState] = useState({
    tabsChildren: false
  })

  // notification
  const [rejectReasons, setRejectReasons] = useState({
    reasons: ""
  })
  const [showPopupReject, setShowPopupReject] = useState(false)
  const [showPopupApprove, setShowPopupApprove] = useState(false)
  const [showPopup, setShowPopup] = useState({
    show: false,
    body: ""
  })
  const [swalIcon, setSwallIcon] = useState("")
  const [swalRedirect, setSwalRedirect] = useState("")
  const [activeUser, setActiveUser] = useState(true)
  const [showPopupChangeStatus, setShowPopupChangeStatus] = useState(false)
  const [showPopupSetMitra, setShowPopupSetMitra] = useState(false)
  const [reasonChangeStatus, setReasonChangeStatus] = useState(null)
  const [newMitraId, setNewMitraId] = useState(null)
  const [listMitra, setListMitra] = useState(null)
  const [statusPopup, setStatusPopup] = useState(null)
  const formRef = useRef()

  useEffect(() => {
    if (General.isFinishUpdateOrDelete) {
      if (
        General.actionInitType !== "DEACTIVATE_MITRA" &&
        General.actionInitType !== "UPLOAD" &&
        General.actionInitType !== "UPDATE_IMAGE" &&
        General.actionInitType !== "SYNC_ERP"
      ) {
        history.push(swalRedirect)

        dispatch(ResetIsFinish())
        swal({
          text: Mitra.messages,
          icon: swalIcon,
          buttons: {
            okey: { text: "Ok", className: "sweet-success" }
          }
        }).then((value) => {
          switch (value) {
            case "detail":
              return history.push(swalRedirect)
            default:
              return
          }
        })
      } else if (General.actionInitType === MITRA.DEACTIVATE_MITRA) {
        history.push(RoutesConstants.MITRA.DETAIL_MITRA("inactive", id))
        if (Mitra.messages) {
          dispatch(resetCheckCanDeactivate())
          setShowPopupChangeStatus(false)
          setReasonChangeStatus(null)
          setNewMitraId(null)
          setShowPopupSetMitra(false)
          dispatch(getDetail(id))

          subHeader.setButton(
            <Button className="btn btn-dark" disabled={true}>
              Edit
            </Button>
          )
          swal({
            text: Mitra.messages,
            icon: toAbsoluteUrl(ILLUSTRATION_ASSETS_V2.mitra.IL_SUCCES_MITRA_NONACTIVE)
          })
        } else if (Mitra.hasFarmer === true) {
          setShowPopupChangeStatus(false)
          setShowPopupSetMitra(true)
          dispatch(getPageDataVerifiedCombobox())
        }
      } else if (General.actionInitType === "UPLOAD") {
        const data = {
          upload_enum: statusPopup,
          url: MasterData.urlResponseUpload,
          mitra_type_enum: Mitra?.data?.mitra_type
        }
        dispatch(updateImage({ id, data }))
      } else if (General.actionInitType === "UPDATE_IMAGE") {
        dispatch(getDetail(id))
      }
    }
  }, [General.isFinishUpdateOrDelete, Mitra])

  useEffect(() => {
    if (General.isFinishSyncErp) {
      swal({
        text: Mitra.messages,
        icon: toAbsoluteUrl(ILLUSTRATION_ASSETS_V2.mitra.IL_SUCCESS_SEND_ERP)
      })
      dispatch(clearSyncErp())
    }
  }, [General.isFinishSyncErp, Mitra])

  useEffect(() => {
    dispatch(resetCheckCanDeactivate())
    dispatch(getDetail(id))
    dispatch(clearUploadAction())
    return () => {
      dispatch(clearDataDetail())
    }
  }, [])

  useEffect(() => {
    const mitraStatus =
      (status.toUpperCase() === "ACTIVE" && "Aktif") ||
      (status.toUpperCase() === "PENDING" && "Sedang Menunggu") ||
      ((status.toUpperCase() === "REJECTED" || status.toUpperCase() === "REJECT") && "Ditolak") ||
      (status.toUpperCase() === "INACTIVE" && "Tidak Aktif")
    subHeader.setBreadcrumbs([
      {
        pathname: RoutesConstants.MITRA.LIST_MITRA(ROUTING_STATUS.STATUS_ENUM.PENDING),
        title: "Mitra"
      },
      {
        pathname: RoutesConstants.MITRA.LIST_MITRA(status.toUpperCase()),
        title: mitraStatus
      },
      {
        title: Mitra?.data?.name
      }
    ])
    subHeader.setTitle("Mitra Detail")
    if (status === "pending") {
      if (privileges.indexOf(PRIVILEGE_ENUM.APPROVAL_MITRA_REGISTRATION) > -1) {
        subHeader.setButton(
          <RowModule>
            <>
              <Button
                variant="danger"
                onClick={() => {
                  setShowPopupReject(true)
                }}>
                Reject
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  setShowPopupApprove(true)
                }}
                className="ml-2">
                Approve
              </Button>
            </>
          </RowModule>
        )
      }
    } else if (status === "rejected") {
      if (privileges.indexOf(PRIVILEGE_ENUM.UPDATE_MITRA) > -1) {
        subHeader.setButton(
          <Button
            className={"btn btn-outline-primary"}
            onClick={() => history.push("/mitras/action/" + status + "/edit/" + id)}>
            Edit
          </Button>
        )
      }
    } else {
      if (privileges.indexOf(PRIVILEGE_ENUM.UPDATE_MITRA) > -1) {
        subHeader.setButton(<ButtonDropdown title={"Action"} dropdownList={listDropdown} />)
      }
    }
  }, [Mitra.data])

  useEffect(() => {
    if (id) {
      dispatch(getDetail(id))
    }
  }, [dispatch])

  useEffect(() => {
    if (Mitra?.combobox) {
      setListMitra(
        Mitra?.combobox.map((e) => ({
          label: e?.name + ", " + e?.id,
          value: e?.id
        }))
      )
    }
  }, [Mitra?.combobox])

  useEffect(() => {
    if (Mitra?.data) {
      if (Mitra?.canDeactivate?.status === true) {
        setShowPopupChangeStatus(true)
      } else if (Mitra?.canDeactivate?.status === false) {
        swal({
          text: Mitra?.canDeactivate?.message,
          icon: toAbsoluteUrl(ILLUSTRATION_ASSETS_V2.mitra.IL_CANNOT_CHANGE)
        })
      }
    }
  }, [Mitra.canDeactivate])

  const handleApproval = (status, reasons) => {
    dispatch(updateStatus(id, status, reasons))
  }

  const descriptionLeft = (data) => {
    return (
      <>
        <div className="d-flex flex-row">
          <div className="d-flex flex-wrap mb-4">
            <FlatIconAndText text={data?.code ?? "-"} svg={ICON_CONSTANS.IC_MITRA_SMALL} />
            <FlatIconAndText text={data?.regional ?? "-"} svg={ICON_CONSTANS.IC_PIN_LOCATION_2} />
            <FlatIconAndText text={data?.email ?? "-"} svg={ICON_CONSTANS.IC_MAIL} />
            {data?.mitra_type === "INDIVIDUAL" && (
              <FlatIconAndText text={data?.gender ?? "-"} svg={ICON_CONSTANS.IC_GENDER} />
            )}
            {data?.mitra_type === "INDIVIDUAL" && (
              <FlatIconAndText text={data?.dob ?? "-"} svg={ICON_CONSTANS.IC_CALENDAR} />
            )}
            {privileges.indexOf(PRIVILEGE_ENUM.MITRA_PHONE_NUMBER) > -1 && (
              <FlatIconAndText text={data?.phone_number ?? "-"} svg={ICON_CONSTANS.IC_PHONE} />
            )}
          </div>
        </div>
        {General.loading ? (
          <>
            <Skeleton animation="wave" variant={"text"} width={250} />
            <Skeleton animation="wave" variant={"text"} width={200} />
          </>
        ) : (
          <>
            <span className="font-weight-bold text-dark-50">
              {data?.address + ", " + data?.sub_district + ", " + data?.district}
            </span>
            <span className="font-weight-bold text-dark-50">
              {data?.regency + ", " + data?.province}
            </span>
          </>
        )}
      </>
    )
  }

  const descriptionRight = () => {
    return (
      <>
        {(status === "active" || status === "inactive") && (
          <div className="d-flex flex-column">
            <SwitchToggle
              checked={activeUser}
              label="Status User"
              onChange={inactiveUserMitra}
              withLabel={true}
              withStatusText={true}
              statusText={activeUser ? "Active" : "Inactive"}
              disabled={!activeUser ? true : false}
            />
          </div>
        )}
      </>
    )
  }

  const flatIconAndText2Rows = (data) => {
    return (
      <>
        <FlatIconAndText2Row
          title="Jumlah Petani"
          description={data?.total_farmer ? data?.total_farmer + " Petani" : "-"}
          svg={ICON_CONSTANS.IC_FARMER_BIG}
        />
        <FlatIconAndText2Row
          title="Total Plafon KUR BNI"
          description={data?.kur_plafond ? toRupiah(data?.kur_plafond) : "-"}
          svg={ICON_CONSTANS.IC_INCOME}
        />
        <FlatIconAndText2Row
          title="Nama Bank"
          description={data?.bank_name ?? "-"}
          svg={ICON_CONSTANS.IC_BANK}
        />
        <FlatIconAndText2Row
          title="Pemilik Rekening"
          description={data?.bank_account_name ?? "-"}
          svg={ICON_CONSTANS.IC_REKENING}
        />
        {privileges.indexOf(PRIVILEGE_ENUM.MITRA_BANK_ACCOUNT_NO) > -1 && (
          <FlatIconAndText2Row
            title="No. Rekening"
            description={data?.bank_account_number ?? "-"}
            svg={ICON_CONSTANS.IC_BANK_ACCOUNT}
          />
        )}
      </>
    )
  }

  const mitraFullAddress = (data) => {
    return (
      <>
        {data?.shop_address +
          " " +
          data?.shop_sub_district_name +
          ", " +
          " " +
          data?.shop_district_name +
          ", " +
          data?.shop_regency_name +
          ", " +
          data?.shop_province_name}
      </>
    )
  }

  useEffect(() => {
    if (Mitra.data) {
      setActiveUser(Mitra?.data?.mitra_activation?.toUpperCase() === "ACTIVE" ? true : false)
      setState({
        ...state,
        tabsChildren: [
          {
            id: "detail",
            title: "DETAIL MITRA",
            view: (
              <CardDetailMitra
                titleCardDetail={Mitra.data?.name + " (" + Mitra.data?.mitra_type_name + ")"}
                descriptionLeft={descriptionLeft(Mitra.data)}
                descriptionRight={descriptionRight(Mitra.data)}
                flatIconAndText2Rows={flatIconAndText2Rows(Mitra.data)}
                ktpCardDetail={Mitra.data?.ktp_pic_url}
                swafotoCardDetail={Mitra.data?.ktp_selfie_pic_url}
                data={Mitra.data}
                mitraType={Mitra.data?.mitra_type}
                status={status}
              />
            )
          },
          {
            id: "usaha",
            title: "USAHA MITRA",
            view: (
              <CardDetailUsaha
                mitraDocUrl={Mitra.data?.business_document_pic_url}
                documentType={Mitra.data?.business_document_type}
                mitraFullAddress={mitraFullAddress(Mitra.data)}
                setStatusPopup={setStatusPopup}
              />
            )
          },
          {
            id: "petani",
            title: "PETANI",
            view: <CardDetailFarmer id={Mitra.data?.id} />
          }
        ]
      })
    }
  }, [Mitra.data])

  const getTabContents = () => {
    const labelList = ["DETAIL MITRA", "DOKUMEN"]

    const componentList = [
      <CardDetailMitra
        key={0}
        titleImage={Mitra.data?.mitra_pic_url}
        titleCardDetail={Mitra.data?.name + " (" + Mitra.data?.mitra_type_name + ")"}
        descriptionLeft={descriptionLeft(Mitra.data)}
        descriptionRight={descriptionRight(Mitra.data)}
        flatIconAndText2Rows={flatIconAndText2Rows(Mitra.data)}
        data={Mitra.data}
        mitraType={Mitra.data?.mitra_type}
        history={history}
        status={status}
      />,
      <CardDetailUsaha
        key={1}
        mitraType={Mitra.data?.mitra_type}
        npwp={Mitra.data?.npwp_no}
        npwpImage={Mitra.data?.other_image}
        ktpImage={Mitra.data?.ktp_pic_url}
        swafoto={Mitra.data?.ktp_selfie_pic_url}
        suratTugas={Mitra.data?.ktp_selfie_pic_url}
        kkImage={Mitra.data?.business_document_pic_url}
        documentPerusahaan={Mitra.data?.business_document_pic_url}
        structurOrganisasi={Mitra.data?.business_document_pic_url}
        setStatusPopup={setStatusPopup}
        ktp={Mitra.data?.ktp_no}
        nama={Mitra.data?.name}
      />
    ]

    if (status === "active" || status === "inactive") {
      labelList.push("PETANI")
      componentList.push(<CardDetailFarmer id={Mitra.data?.id} />)
    }

    if (status) {
      labelList.push("LOG")
      componentList.push(<TabLog historyTabEnum={ENUM_LOG.MITRA} id={id} />)
    }

    return {
      labels: labelList,
      contents: componentList
    }
  }

  const handleReason = (e) => {
    setRejectReasons({
      ...rejectReasons,
      reasons: e.target.value
    })
  }

  const formReason = (
    <>
      <Formik enableReinitialize innerRef={formRef} initialValues={rejectReasons}>
        <Form>
          <TextAreaInput
            name={"rejectReason"}
            placeholder={"Alasan penolakan"}
            label={"Silahkan tambah alasan menolak mitra"}
            editable={false}
            onKeyUp={handleReason}
            withoutFeedback={true}
          />
        </Form>
      </Formik>
    </>
  )

  const handleSyncERP = () => {
    const code = Mitra.data.code
    const urlName = "mitra"
    dispatch(syncErp(urlName, code))
  }
  const listDropdown = [
    {
      title: "Edit mitra",
      icon: ICON_CONSTANS.IC_EDIT,
      action: () => history.push("/mitras/action/" + status + "/edit/" + id),
      showed: Mitra?.data?.mitra_activation?.toUpperCase() === "ACTIVE" ? true : false
    },
    {
      title: "Sync data ERP",
      icon: ICON_CONSTANS.IC_SYNC,
      action: handleSyncERP,
      showed: privileges.indexOf(PRIVILEGE_ENUM.CREATE_MITRA) > -1
    }
  ]

  function inactiveUserMitra() {
    dispatch(getCheckCanDeactivate(id))
  }

  function handleSubmitChangeStatusMitra() {
    if (showPopupChangeStatus && !showPopupSetMitra) {
      dispatch(deactivateStatusMitra(id, { reason: reasonChangeStatus }))
      setSwalRedirect(RoutesConstants.MITRA.DETAIL_MITRA("inactive", id))
    } else if (!showPopupChangeStatus && showPopupSetMitra) {
      setSwalRedirect(RoutesConstants.MITRA.DETAIL_MITRA("inactive", id))

      dispatch(
        deactivateStatusMitra(id, {
          reason: reasonChangeStatus,
          new_mitra_id: newMitraId
        })
      )
    }
  }

  return (
    <>
      {/* Popup Success */}
      <Popup
        show={showPopup.show}
        body={showPopup.body}
        onClick={() => {
          setShowPopup({
            show: false,
            body: ""
          })
        }}
      />

      {/* Popup for reject */}
      <PopupQuestion
        show={showPopupReject}
        title="MITRA TIDAK SESUAI KRITERIA"
        bodyCustom={formReason}
        textOk="Submit"
        onCancel={() => {
          setShowPopupReject(false)
          setRejectReasons({
            reasons: ""
          })
        }}
        disable={rejectReasons.reasons.length > 0 ? false : true}
        onOk={() => {
          handleApproval("REJECTED", rejectReasons.reasons)
          setShowPopupReject(false)
          setSwallIcon(toAbsoluteUrl(ILLUSTRATION_ASSETS_V2.mitra.IL_SUCCESS_REJECT))
          setSwalRedirect(RoutesConstants.MITRA.LIST_MITRA("REJECTED"))
        }}
      />

      <PopupQuestion
        show={showPopupApprove}
        title="MITRA SESUAI KRITERIA"
        bodyCustom={
          <span style={{ textAlign: "center" }}>
            Data Mitra yang disetujui akan berubah menjadi{" "}
            <span className="font-weight-bold">terverifikasi</span>. Pastikan semua data telah
            lengkap dan benar.
          </span>
        }
        textOk="Approve"
        onCancel={() => {
          setShowPopupApprove(false)
        }}
        onOk={() => {
          handleApproval("ACTIVE", "")
          setShowPopupApprove(false)
          setSwallIcon(toAbsoluteUrl(ILLUSTRATION_ASSETS_V2.mitra.IL_SUCCESS_APPROVE))
          setSwalRedirect(RoutesConstants.MITRA.LIST_MITRA("PENDING"))
        }}
      />

      {/* Popup for change status */}
      <PopupQuestion
        show={showPopupChangeStatus}
        title="UBAH STATUS MITRA"
        bodyCustom={
          <div className="mb-3">
            <label>Silahkan tambah alasan ubah status Mitra.</label>
            <textarea
              rows={3}
              className="form-control"
              value={reasonChangeStatus}
              onChange={(e) => {
                setReasonChangeStatus(e.target.value)
              }}
              placeholder="Input alasan ubah status Mitra."
            />
            <div className="p-5 mt-3 rounded" style={{ background: "#F8F8F8" }}>
              <i className="fa fa-info-circle mr-2" style={{ color: "#808080" }}></i>
              Setiap action akan terekam dalam log history.
            </div>
          </div>
        }
        variantOkButton={reasonChangeStatus ? "primary" : "secondary"}
        disable={reasonChangeStatus === null || reasonChangeStatus === ""}
        textOk="Lanjut"
        onCancel={() => {
          setShowPopupChangeStatus(false)
          setReasonChangeStatus(null)
        }}
        onOk={handleSubmitChangeStatusMitra}
      />

      {/* Popup for choose mitra changed */}
      <PopupQuestion
        show={showPopupSetMitra}
        title="PILIH MITRA PENGGANTI"
        bodyCustom={
          <div className="mb-3">
            <label>Silahkan pilih mitra yang akan di assign ke Petani.</label>
            <Select
              name="mitraId"
              onChange={(e) => setNewMitraId(e.value)}
              label={null}
              placeholder="Pilih Salah Satu"
              options={listMitra}
              withoutFeedback={true}
              clearNow={true}
            />
          </div>
        }
        variantCancelButton="outline-secondary"
        variantOkButton={newMitraId ? "primary" : "secondary"}
        disable={newMitraId === null || !newMitraId}
        textOk="Lanjut"
        onCancel={() => {
          setShowPopupChangeStatus(false)
          setReasonChangeStatus(null)
          setShowPopupSetMitra(false)
          setNewMitraId(null)
        }}
        onOk={handleSubmitChangeStatusMitra}
      />

      <TabsComponent labelTab={getTabContents().labels} componentTab={getTabContents().contents} />
    </>
  )
}