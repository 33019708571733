import { toAbsoluteUrl } from "../../../_metronic/_helpers/index.js"
import SVG from "react-inlinesvg"
import React from "react"
import { NavLink } from "react-router-dom"

export const AddMenuItem = ({ data, key }) => {
  return (
    <NavLink to={data?.navigateLink ?? "/"}>
      <div className={"menu-item d-flex align-items-center p-3"} key={key}>
        <SVG src={toAbsoluteUrl(data?.iconMenu)} />
        <div className={"ml-4"}>
          <div className={"title text-dark font-weight-bolder mb-1 font-size-lg"}>{data.title}</div>
          <span className={"color-gray"}>{data?.subTitle}</span>
        </div>
      </div>
    </NavLink>
  )
}
