import React, { useEffect, useState } from "react"
import { timeStampToDate } from "../../../../../helpers/DateHelper"
import { useTranslation } from "react-i18next"
import { toMeter } from "../../../../../helpers/TextHelper"
import { AREA_ACTIVITY_TYPE } from "../../../../../../constants/InitTypeConstants"

export const AccordionContent = ({ data }) => {
  const { t } = useTranslation()
  const panen = "Panen"
  const [dataDescription, setDataDescription] = useState([
    {
      label: t("label.activity_date"),
      value:
        data?.activity_name !== panen
          ? timeStampToDate(data?.activity_date, "dd MMMM yyyy")
          : timeStampToDate(data?.activity_date, "dd MMMM yyyy") +
            " s/d " +
            timeStampToDate(data?.activity_end_date, "dd MMMM yyyy")
    },
    {
      label: t("label.land_area_worked"),
      value: data?.worked_area ? toMeter(data?.worked_area) : "-"
    },
    {
      label: t("label.farmer_name"),
      value: data?.farmer_name ? data?.farmer_name + " (" + data?.farmer_code + ")" : ""
    },
    {
      label: t("label.mitra_name"),
      value: data?.mitra_name ? data?.mitra_name + " (" + data?.mitra_code + ")" : ""
    },
    { label: t("label.area_address"), value: data?.area_full_address ?? "" }
  ])

  useEffect(() => {
    if (
      data?.activity_type_enum === AREA_ACTIVITY_TYPE.SPRAYING ||
      data?.activity_type_enum === AREA_ACTIVITY_TYPE.PRE_PLANTING
    ) {
      setDataDescription([
        ...dataDescription,
        {
          label: t("label.warehouse"),
          value: data?.warehouse ? data?.warehouse : "-"
        }
      ])
    }
  }, [data])

  return (
    <div className="overview pb-8 mb-8">
      <h4 className="mb-6">{t("label.overview_activity")}</h4>

      {dataDescription &&
        dataDescription.map((item, i) => (
          <div key={i} className="row mb-3">
            <div className="col-3 color-gray">{item?.label}</div>
            <div className="col-9">
              <div className="d-flex">
                <span className={"mr-2"}>:</span>
                <span>{item.value}</span>
              </div>
            </div>
          </div>
        ))}
    </div>
  )
}
