import React, { useEffect, useState } from "react"
import { ActivityDetailHeader } from "../../../../../component/molecule/ActivityDetailHeader/ActivityDetailHeader.jsx"
import { useDispatch, useSelector } from "react-redux"
import { ACTIVITY_ICONS } from "../../../../../constants/ImageConstants.js"
import { RowText, Status } from "../../../../../component/index.jsx"
import { useHtmlClassService, useSubheader } from "../../../../../_metronic/layout/index.js"
import { RoutesConstants } from "../../../../../constants/RoutesConstants.js"
import { DATE_TYPE_ENUM, ROUTING_STATUS } from "../../../../../constants/StringConstant.js"
import { useTranslation } from "react-i18next"
import { checkValue, toHectare, toMeter } from "../../../../helpers/TextHelper.js"
import { getDetailCertification } from "../../../../../redux/actions/SubmissionAction.jsx"
import { Card } from "react-bootstrap"
import { CardBody } from "../../../../../_metronic/_partials/controls/index.js"
import { RowModule } from "../../../../../component/atoms/RowModule/RowModule.jsx"
import { timeStampToDate } from "../../../../helpers/DateHelper.js"
import { Skeleton } from "@material-ui/lab"

export const DetailCertificationPending = ({
  match: {
    params: { id }
  }
}) => {
  const { t } = useTranslation()
  const subHeader = useSubheader()
  const dispatch = useDispatch()
  const uiService = useHtmlClassService()
  const { Submission, General } = useSelector((state) => state)
  const [detail, setDetail] = useState({})

  useEffect(() => {
    dispatch(getDetailCertification(id))
    uiService.setExitFullPage()
  }, [])
  useEffect(() => {
    subHeader.setButton(null)
    subHeader.setBreadcrumbs([
      {
        pathname: RoutesConstants.SUBMISSION.LIST_SUBMISSION_BY_STATUS(
          ROUTING_STATUS.CERTIFICATION_STATUS_STRING.PENDING
        ),
        title: t("menu.submission")
      },
      {
        pathname: RoutesConstants.SUBMISSION.LIST_SUBMISSION_BY_STATUS(
          ROUTING_STATUS.CERTIFICATION_STATUS_STRING.PENDING
        ),
        title: t("menu_aside.sertification_and_block")
      },
      {
        pathname: RoutesConstants.SUBMISSION.LIST_SUBMISSION_BY_STATUS(
          ROUTING_STATUS.CERTIFICATION_STATUS_STRING.PENDING
        ),
        title: t("menu_aside.area_pending")
      },
      {
        title: checkValue(detail?.header?.area_code)
      }
    ])
  }, [detail])

  useEffect(() => {
    if (Submission?.data) setDetail(Submission?.data)
  }, [Submission?.data])

  const customBottom = (
    <Card className={"mt-6"} bg={"secondary"}>
      <CardBody className={"px-12 py-4"}>
        <RowModule customColumnCss={"col-md-6"} customSeparator={"my-0"}>
          <RowText
            label={t("label.submitted_by")}
            value={checkValue(detail?.header?.submitted_by)}
          />
          <RowText
            label={t("table_header.submission_date")}
            value={checkValue(
              timeStampToDate(detail?.header?.submitted_date, DATE_TYPE_ENUM.ISO_DATETIME_STRAP)
            )}
          />
        </RowModule>
      </CardBody>
    </Card>
  )

  const titleActivity = (
    <ActivityDetailHeader
      title={detail?.header?.area_code}
      icon={ACTIVITY_ICONS.IC_LAHAN_PEMBENIHAN}
      rightContent={<Status variant={detail?.header?.status_enum} text={t("label.pending")} />}
      cardBottom={customBottom}
    />
  )

  const landArea = (
    <div>
      {toMeter(detail?.area_information?.land_area_meter)}&nbsp; (
      {toHectare(detail?.area_information?.land_area_ha)})
    </div>
  )

  const areaInformation = (
    <Card className={"mb-4"}>
      <CardBody>
        <h4 className={"mb-6 text-uppercase"}>
          {General.loading ? (
            <Skeleton animation="wave" variant={"text"} height={32} width={200} />
          ) : (
            t("label.area_information")
          )}
        </h4>
        <RowModule customColumnCss={"col-md-6"} customSeparator={"my-0"}>
          <RowText
            label={t("label.farmer")}
            value={checkValue(detail?.area_information?.farmer_name)}
          />
          <RowText
            label={t("label.area_location")}
            value={checkValue(detail?.area_information?.location)}
          />
          <RowText
            label={t("label.mitra")}
            value={checkValue(detail?.area_information?.mitra_name)}
          />
          <RowText label={t("label.land_area")} value={landArea} />
        </RowModule>
      </CardBody>
    </Card>
  )

  const plantInformation = (
    <Card className={"mb-4"}>
      <CardBody>
        <h4 className={"mb-6 text-uppercase"}>
          {General.loading ? (
            <Skeleton animation="wave" variant={"text"} height={32} width={200} />
          ) : (
            t("label.plant_data")
          )}
        </h4>
        <RowModule customColumnCss={"col-md-6"} customSeparator={"my-0"}>
          <RowText
            label={t("label.plant_type")}
            value={checkValue(detail?.plant_data?.plant_type)}
          />
          <RowText
            label={t("label.female_planting_date")}
            value={checkValue(
              timeStampToDate(detail?.plant_data?.female_planting_date, DATE_TYPE_ENUM.ISO_DATE_ID)
            )}
          />
          <RowText
            label={t("label.varietas")}
            value={checkValue(detail?.plant_data?.variety_name)}
          />
        </RowModule>
      </CardBody>
    </Card>
  )

  return (
    <>
      {titleActivity}
      {areaInformation}
      {plantInformation}
    </>
  )
}
