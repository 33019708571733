import { useDispatch, useSelector } from "react-redux"
import React, { useCallback, useEffect, useRef, useState } from "react"
import {
  getListAssignMitra,
  clearDataFO,
  deleteListAssignMitra
} from "../../../../../redux/actions/FieldOfficerAction.jsx"
import TableView, {
  formatterRowComponent,
  initialFilterTable
} from "../../../../../component/atoms/Tabel/index.jsx"
import { sortCaret } from "../../../../../_metronic/_helpers/index.js"
import { Card, CardBody } from "../../../../../_metronic/_partials/controls/index.js"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { DeleteSvgIcon } from "../../../../../component/atoms/Icons/SVG/index.jsx"
import { EmptyCard, Search, TableSkeleton } from "../../../../../component/index.jsx"
import { Popup, PopupQuestion } from "../../../../../component/atoms/Popup/index.jsx"
import { FIELD_OFFICER } from "../../../../../constants/InitTypeConstants.js"
import { useTranslation } from "react-i18next"

export const AssignListTab = () => {
  const { t } = useTranslation()
  const { FieldOfficer, General } = useSelector((state) => state)

  const dispatch = useDispatch()
  const [showPopupConfirm, setShowPopupConfirm] = useState(false)
  const [assignmentId, setAssignmentId] = useState(null)
  const [foName, setFoName] = useState("")
  const [mitraName, setMitraName] = useState("")
  const [isActiveSearch, setIsActiveSearch] = useState(false)
  const formRef = useRef()
  const [search, setSearch] = useState({
    string_filter: ""
  })
  const [showPopupSuccess, setShowPopupSuccess] = useState(false)
  const [showPopupError, setShowPopupError] = useState(false)

  useEffect(() => {
    return () => {
      dispatch(clearDataFO())
    }
  }, [])

  useEffect(() => {
    if (search.string_filter) {
      setIsActiveSearch(true)
    } else {
      setIsActiveSearch(false)
    }
  }, [search])

  useEffect(() => {
    dispatch(getListAssignMitra({ pageReq: initialFilterTable }))
  }, [dispatch])

  const changePage = useCallback(
    (param) => {
      let newParam = {
        ...search,
        ...param
      }
      dispatch(getListAssignMitra({ pageReq: newParam }))
    },
    [dispatch, search]
  )

  const seacrhFunc = (values) => {
    let param = {
      ...initialFilterTable,
      string_filter: values.string_filter
    }
    setSearch({
      string_filter: values.string_filter
    })
    dispatch(getListAssignMitra({ pageReq: param }))
  }

  const handleResetSearch = () => {
    setSearch({
      string_filter: ""
    })
    dispatch(getListAssignMitra({ pageReq: initialFilterTable }))
  }

  const uiFo = (e) => {
    return (
      <>
        <p className="text-dark font-weight-bolder mb-1 font-size-lg">{e.fo_name}</p>
        <span className="text-muted font-weight-bold d-block">{e.fo_phone}</span>
      </>
    )
  }

  const uiMitra = (e) => {
    return (
      <>
        <p className="text-dark font-weight-bolder mb-1 font-size-lg">{e.mitra_name}</p>
        <span className="text-muted font-weight-bold d-block">{e.mitra_phone}</span>
      </>
    )
  }

  const uiAction = (e) => {
    return (
      <div>
        <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Hapus</Tooltip>}>
          <div
            className="btn btn-icon btn-detail btn-hover-secondary btn-sm mx-3"
            onClick={() => {
              setMitraName(e?.mitra_name)
              setFoName(e?.fo_name)
              setAssignmentId(e?.assignment_id)
              setShowPopupConfirm(true)
            }}>
            <DeleteSvgIcon />
          </div>
        </OverlayTrigger>
      </div>
    )
  }

  useEffect(() => {
    if (General.actionInitType === FIELD_OFFICER.DELETE_LIST_ASSIGN_MITRA) {
      if (General.isFinishUpdateOrDelete) {
        setShowPopupConfirm(false)
        setShowPopupSuccess(true)
      } else if (General.isError) {
        setShowPopupConfirm(false)
        setShowPopupError(true)
      }
    }
  }, [General])
  const submitAssign = () => {
    if (assignmentId) {
      dispatch(deleteListAssignMitra(assignmentId))
    } else {
      setTitleAlert("Oh snap! You got an error!")
      setAlertType("danger")
    }
  }

  const columns = [
    {
      dataField: "fo_name",
      text: "FIELD OFFICER",
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiFo
      },
      sortCaret: sortCaret
    },
    {
      dataField: "mitra_name",
      text: "MITRA",
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiMitra
      },
      sortCaret: sortCaret
    },
    {
      dataField: "action",
      text: "ACTION",
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiAction
      }
    }
  ]
  return (
    <div>
      {/* Popup Error */}
      <Popup
        type="error"
        body={<span>Assign Mitra gagal dihapus</span>}
        centered={true}
        persistent={true}
        show={showPopupError}
        onClick={() => {
          setShowPopupError(false)
        }}
      />
      <Popup
        type="success"
        body={<span>Assign Mitra berhasil dihapus.</span>}
        centered={true}
        persistent={true}
        show={showPopupSuccess}
        onClick={() => {
          let param = {
            ...initialFilterTable,
            ...search
          }
          dispatch(getListAssignMitra({ pageReq: param }))
          setShowPopupSuccess(false)
        }}
      />
      {/* Popup Confirm */}
      <PopupQuestion
        show={showPopupConfirm}
        onOk={submitAssign}
        textOk="Submit"
        onCancel={() => {
          setShowPopupConfirm(false)
        }}
        title="DELETE"
        bodyCustom={
          <>
            <div>Apakah Anda yakin ingin menghapus data ini ?</div>
            <div className="mt-3">
              <table>
                <thead>
                  <tr>
                    <th>Field Officer</th>
                    <th className="px-5"> ---&gt; </th>
                    <th>Nama Mitra</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{foName}</td>
                    <td></td>
                    <td>{mitraName}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        }
      />
      <Card>
        <CardBody>
          <div className="filter-container">
            <div className="d-flex flex-wrap justify-content-between align-items-center mb-10">
              <Search
                // seacrh
                isActiveSearch={isActiveSearch}
                placeholder={"Masukkan Nama"}
                onSubmitForm={seacrhFunc}
                initialValues={search}
                name={"string_filter"}
                innerRef={formRef}
                trigerReset={handleResetSearch}
                // filter
                showFilter={false}
              />
            </div>
          </div>
          {General.loading ? (
            <TableSkeleton column={3} row={10} />
          ) : FieldOfficer?.pageData?.length > 0 ? (
            <TableView
              callbackAfterPageChange={changePage}
              columnProperties={columns}
              dataTable={FieldOfficer?.pageData ?? []}
              currentPage={FieldOfficer?.page?.currentPage ?? 0}
              currentElement={FieldOfficer?.page?.currentElement ?? 10}
              totalCount={FieldOfficer?.page?.totalCount ?? 0}
              loadingGetData={General.loading}
            />
          ) : (
            <EmptyCard
              body={
                isActiveSearch
                  ? t("messages.response.data_not_found")
                  : t("messages.response.empty_state")
              }
            />
          )}
        </CardBody>
      </Card>
    </div>
  )
}