import React, { useEffect, useState } from "react"
import { Button, Form } from "react-bootstrap"
import { useSubheader } from "../../../../../_metronic/layout/index.js"
import { useDispatch, useSelector } from "react-redux"
import { TabsComponent } from "../../../../../component/atoms/Tab/index.jsx"
import { DetailUser } from "./component/DetailUser.jsx"
import {
  deactivateStatusUser,
  getUserDetail,
  resetPassword
} from "../../../../../redux/actions/AccountAction.jsx"
import { FlatIconAndText, Submited, SwitchToggle } from "../../../../../component/index.jsx"
import { Popup, PopupQuestion } from "../../../../../component/atoms/Popup/index.jsx"
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers/index.js"
import { ROLE_FO } from "../../../../../constants/InitTypeConstants.js"
import { loadUserLogin } from "../../../../service/userManagementAction.js"
import { Skeleton } from "@material-ui/lab"
import { RoutesConstants } from "../../../../../constants/RoutesConstants.js"
import { PRIVILEGE_ENUM } from "../../../../../constants/PrivilegeConstants.js"
import { ICON_CONSTANS, ILUSTRATION_CONSTANS } from "../../../../../constants/ImageConstants.js"

export const AccountDetail = ({
  history,
  match: {
    params: { id }
  }
}) => {
  const privileges = loadUserLogin().privilege
  const subHeader = useSubheader()
  const dispatch = useDispatch()
  const { Account, General } = useSelector((state) => state)
  const [data, setData] = useState("")
  const [showPopupReset, setShowPopupReset] = useState(false)
  const [showPopupSuccessReset, setShowPopupSuccessReset] = useState(false)
  const [showPopupChangeStatus, setShowPopupChangeStatus] = useState(false)
  const [reasonChangeStatus, setReasonChangeStatus] = useState(null)

  useEffect(() => {
    dispatch(getUserDetail(id))
  }, [])

  useEffect(() => {
    subHeader.setBreadcrumbs([
      {
        pathname: "/role/list",
        title: "Admin Setting"
      },
      {
        pathname: "/account/list",
        title: "user CMS"
      },
      {
        title: data?.account_name
      }
    ])
  }, [data])

  useEffect(() => {
    if (
      data?.account_role === ROLE_FO.FIELD_OFFICER ||
      data?.account_role === ROLE_FO.FIELD_OFFICER_AGRONOMIST ||
      data?.account_role === ROLE_FO.FIELD_OFFICER_COORDINATOR
    ) {
      subHeader.setButton(null)
    } else {
      if (privileges.indexOf(PRIVILEGE_ENUM.EDIT_USER_CMS) > -1)
        subHeader.setButton(
          <Button
            variant="secondary"
            onClick={() => history.push(RoutesConstants.SETTING.EDIT_USER(id))}>
            Edit
          </Button>
        )
    }
  }, [data])

  useEffect(() => {
    if (Account.data) {
      setData(Account.data)
    }
  }, [Account.data])

  useEffect(() => {
    if (privileges.indexOf(PRIVILEGE_ENUM.EDIT_USER_CMS) > -1) {
      subHeader.setButton(
        <Button
          className={
            Account.data?.status?.toUpperCase() === "ACTIVE"
              ? "btn btn-outline-primary"
              : "btn btn-dark"
          }
          disabled={Account.data?.status?.toUpperCase() === "INACTIVE" ? true : false}
          onClick={() => {
            if (Account.data?.status?.toUpperCase() === "ACTIVE") {
              history.push("/account/list/edit/" + id)
            }
          }}>
          Edit
        </Button>
      )
    }
  }, [Account.data?.status])

  useEffect(() => {
    if (General.isFinishUpdateOrDelete && General.actionInitType !== "DEACTIVATE_ACCOUNT") {
      if (Account.passwordReset) {
        setShowPopupReset(false)
        setShowPopupSuccessReset(true)
      }
    } else if (General.isFinishUpdateOrDelete && General.actionInitType === "DEACTIVATE_ACCOUNT") {
      setShowPopupChangeStatus(false)
      setReasonChangeStatus(null)
      dispatch(getUserDetail(id))
      subHeader.setButton(
        <Button className="btn btn-dark" disabled={true}>
          Edit
        </Button>
      )
      swal({
        text: "user CMS berhasil dinonaktifkan.",
        icon: toAbsoluteUrl(ILUSTRATION_CONSTANS.ILLUSTRATION_SUCCESS)
      })
    }
  }, [General.isFinishUpdateOrDelete])

  const descriptionLeft = (
    <>
      <div className="d-flex flex-row">
        <div className="d-flex flex-column">
          <div className="d-flex flex-wrap mb-4">
            <FlatIconAndText
              text={data?.account_role ? data?.account_role : "-"}
              svg={ICON_CONSTANS.IC_ROLE_ACCESS}
            />
            <FlatIconAndText
              text={data?.regional_name ? data?.regional_name : "All"}
              svg={ICON_CONSTANS.IC_PIN_LOCATION_2}
            />
            <FlatIconAndText
              text={data?.account_email ? data?.account_email : "-"}
              svg={ICON_CONSTANS.IC_MAIL}
            />
            <FlatIconAndText text={data?.phone ? data?.phone : "-"} svg={ICON_CONSTANS.IC_PHONE} />
          </div>
          {privileges.indexOf(PRIVILEGE_ENUM.RESET_USER_PASSWORD) > -1 &&
            data?.status?.toUpperCase() === "ACTIVE" && (
              <a
                className="font-weight-bold font-size-lg link"
                onClick={() => {
                  setShowPopupReset(true)
                }}>
                Reset Password
              </a>
            )}
        </div>
      </div>
    </>
  )

  const descriptionRight = (
    <>
      <div className="d-flex flex-column ">
        <SwitchToggle
          checked={data?.status?.toUpperCase() === "ACTIVE"}
          label="Status User"
          onChange={inactiveUser}
          withLabel={true}
          withStatusText={true}
          statusText={data?.status?.toUpperCase() === "ACTIVE" ? "Active" : "Inactive"}
          disabled={data?.status?.toUpperCase() === "INACTIVE" ? true : false}
        />
      </div>
    </>
  )

  const flatIconAndText2Rows = (
    <>
      {General?.loading ? (
        <div className={"d-flex "} style={{ gap: 60 }}>
          <div>
            <Skeleton width={120} height={30} />
            <Skeleton width={160} height={30} />
          </div>
          <div>
            <Skeleton width={160} height={30} />
            <Skeleton width={230} height={30} />
          </div>
        </div>
      ) : (
        <Submited
          name={data.created_by ? data.created_by : "-"}
          time={data.created_date ? data.created_date + " - " + data.created_time : "-"}
        />
      )}
    </>
  )

  const renderContents = () => {
    const labels = ["DETAIL USER"]
    const components = [
      <DetailUser
        key={0}
        data={Account.data}
        descriptionLeft={descriptionLeft}
        descriptionRight={descriptionRight}
        flatIconAndText2Rows={flatIconAndText2Rows}
      />
    ]

    return {
      labels,
      components
    }
  }

  function inactiveUser() {
    setShowPopupChangeStatus(true)
  }

  function handleSubmitChangeStatusUser() {
    dispatch(deactivateStatusUser(id, { reason: reasonChangeStatus }))
  }

  return (
    <>
      <PopupQuestion
        title={"Reset Password"}
        body={
          <span>
            Apakah Anda yakin ingin melakukan Reset Password User <br />{" "}
            <b> {data.account_email} </b> ?
          </span>
        }
        centered={true}
        persistent={true}
        show={showPopupReset}
        textOk="RESET"
        onCancel={() => {
          setShowPopupReset(false)
        }}
        onOk={() => {
          dispatch(resetPassword({ account_id: data.account_id }))
        }}
      />
      <Popup
        type={"berhasil"}
        body={
          <span>
            Password Baru dari User <br />
            <b>{data?.account_email} </b> :
            <br /> <br />
            {Account?.passwordReset && (
              <h2>{Account?.passwordReset.password ? Account?.passwordReset.password : ""}</h2>
            )}
          </span>
        }
        centered={true}
        persistent={true}
        show={showPopupSuccessReset}
        onClick={() => {
          setShowPopupSuccessReset(false)
        }}
      />
      <PopupQuestion
        show={showPopupChangeStatus}
        title="DEACTIVATE USER"
        bodyCustom={
          <Form.Group className="mb-3">
            <Form.Label>Silahkan tambah alasan deactivate User CMS.</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={reasonChangeStatus}
              onChange={(e) => {
                setReasonChangeStatus(e.target.value)
              }}
              placeholder="Input alasan ubah status User CMS."
            />
          </Form.Group>
        }
        variantCancelButton="outline-secondary"
        variantOkButton={reasonChangeStatus ? "primary" : "secondary"}
        disable={reasonChangeStatus === null || reasonChangeStatus === ""}
        textOk="Lanjut"
        onCancel={() => {
          setShowPopupChangeStatus(false)
          setReasonChangeStatus(null)
        }}
        onOk={handleSubmitChangeStatusUser}
      />
      <TabsComponent
        labelTab={renderContents().labels}
        componentTab={renderContents().components}
      />
    </>
  )
}