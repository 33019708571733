import React, { Fragment, useEffect, useState } from "react"
import { BigCalendar } from "../../../../../component"
import { Popup } from "../../../../../component/atoms/Popup"
import { Accordion } from "../../../../../component/atoms/Expands"
import { TableTeamSchedule } from "../TableTeamSchedule"
import { toStringDateLocale } from "../../../../helpers/DateHelper"
import { useDispatch, useSelector } from "react-redux"
import {
  clearCalendarActions,
  GetDetailPopCalendarTeamSchedule,
  GetTeamScheduleCalendar
} from "../../../../../redux/actions/FieldOfficerAction"
import { useUrlParam } from "../../../../hooks/useUrlParams"
import { useLocation } from "react-router"
import { useHistory } from "react-router-dom"
import { format } from "date-fns"
import { Skeleton } from "@material-ui/lab"

export const ListTeamSchedule = () => {
  const [showModalPopup, setShowModalPopup] = useState(false)
  const [titleDate, setTitleDate] = useState(undefined)
  const [dataPopupJadwalTeam, setDataPopupJadwalTeam] = useState(undefined)
  const [currentDateCalendar, setCurrentDateCalendar] = useState(new Date())
  const [dataCalendar, setDataCalendar] = useState(undefined)
  const [dateCalendar, setDateCalendar] = useState(undefined)
  const [loadingDetailPopup, setLoadingDetailPopup] = useState(true)

  const urlParams = useUrlParam()
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const { FieldOfficer } = useSelector((state) => state)

  useEffect(() => {
    const paramList = urlParams.stringifyObj({
      start_date: format(currentDateCalendar, "yyyy-MM-dd")
    })
    history.push({ pathname: location.pathname, search: paramList })
    dispatch(GetTeamScheduleCalendar(paramList))
  }, [currentDateCalendar])

  useEffect(() => {
    dispatch(clearCalendarActions())
  }, [])

  useEffect(() => {
    if (FieldOfficer?.calendar) {
      setDataCalendar(undefined)
      const result = FieldOfficer.calendar
      setDataCalendar(result)
    } else {
      setDataCalendar(undefined)
    }
    if (FieldOfficer?.detailCalendarLoading) {
      setLoadingDetailPopup(true)
    } else {
      setLoadingDetailPopup(false)
    }
    if (FieldOfficer?.calendarList) {
      setDataPopupJadwalTeam(FieldOfficer?.calendarList)
    }
  }, [FieldOfficer])

  useEffect(() => {
    if (dateCalendar) {
      const paramDetail = urlParams.stringifyObj({
        start_date: format(dateCalendar, "yyyy-MM-dd")
      })
      dispatch(GetDetailPopCalendarTeamSchedule(paramDetail))
    }
  }, [dateCalendar])

  const onClickCalendar = (e) => {
    setTitleDate(toStringDateLocale(e))
    setDateCalendar(e)
    setShowModalPopup(true)
  }

  const onChangeCalendar = (e) => {
    setCurrentDateCalendar(e)
  }

  const BodyModal = (
    <div>
      {dataPopupJadwalTeam && dataPopupJadwalTeam.length && !loadingDetailPopup ? (
        <Accordion
          items={dataPopupJadwalTeam.map((e, index) => ({
            header: <h4 className={"font-weight-bolder"}>{e.name}</h4>,
            content: (
              <Fragment key={index}>
                <TableTeamSchedule data={e?.list_area} />
              </Fragment>
            )
          }))}
        />
      ) : (
        <div className={"d-flex w-100"} style={{ gap: 24 }}>
          <Skeleton
            style={{ marginBottom: 24 }}
            height={100}
            count={3}
            animation={"wave"}
            width={900}
          />
          <Skeleton
            style={{ marginBottom: 24 }}
            height={100}
            count={3}
            width={100}
            animation={"wave"}
          />
        </div>
      )}
    </div>
  )
  const ModalPopup = (
    <>
      <Popup
        size={"xl"}
        contentClassName={"modal-main modal_detail_team_customs_detail_schedule "}
        show={showModalPopup}
        titleCustom={<h3 className={"font-weight-bolder"}>{titleDate}</h3>}
        closeButton={true}
        showButton={false}
        body={BodyModal}
        onCancel={() => {
          setShowModalPopup(false)
        }}
      />
    </>
  )

  return (
    <div className={"calendar_team_schedule"}>
      {ModalPopup}

      <BigCalendar
        disablePopup={true}
        dataCalendar={dataCalendar}
        onChange={onChangeCalendar}
        onClick={onClickCalendar}
      />
    </div>
  )
}
