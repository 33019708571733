import React, { useState } from "react"
import { RowModule } from "../RowModule/RowModule.jsx"
import { toAbsoluteUrl } from "../../../_metronic/_helpers"
import { Card, Image } from "react-bootstrap"
import { CardHeader } from "../../../_metronic/_partials/controls"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import { PopupImage } from "../Popup"
import { useSelector } from "react-redux"
import { Skeleton } from "@material-ui/lab"

export function DetailText({
  title,
  value,
  type,
  withoutSeparator,
  responsiveCarousel = null,
  menu = null,
  setIdDocument,
  indexKey = null,
  setPrefixMenu
}) {
  const { General } = useSelector((state) => state)
  const [isShown, setIsShown] = useState(false)
  const [showPopup, setShowPopup] = useState(false)
  const [currentIndexSlide, setCurrentIndexSlide] = useState(0)
  const [firstPopup, setFirstPopup] = useState(false)

  const CustomRightArrow = ({ onClick }) => {
    return (
      <div
        onClick={() => {
          onClick()
          if (showPopup) {
            if (currentIndexSlide === value.length - 1) {
              setCurrentIndexSlide(0)
              setIdDocument(0)
            } else {
              setCurrentIndexSlide(currentIndexSlide + 1)
              setIdDocument(currentIndexSlide + 1)
            }
          }
        }}
        aria-label="Go to next slide"
        className="react-multi-carousel-arrows react-multi-carousel-arrows-right">
        <div className="btn-react-multi-carousel-arrows-right"></div>
      </div>
    )
  }

  const CustomLeftArrow = ({ onClick }) => {
    return (
      <div
        onClick={() => {
          onClick()
          if (showPopup) {
            if (currentIndexSlide === 0) {
              setCurrentIndexSlide(value.length - 1)
              setIdDocument(value.length - 1)
            } else {
              setCurrentIndexSlide(currentIndexSlide - 1)
              setIdDocument(currentIndexSlide - 1)
            }
          }
        }}
        aria-label="Go to next slide"
        className="react-multi-carousel-arrows react-multi-carousel-arrows-left">
        <div className="btn-react-multi-carousel-arrows-left"></div>
      </div>
    )
  }

  const CustomDot = ({ onClick, ...rest }) => {
    const { index, active } = rest
    if (!showPopup && !firstPopup) {
      if (active) {
        setCurrentIndexSlide(index)
        setIdDocument(index)
      }
    }
    return (
      <div className="mb-4">
        <div
          className={
            showPopup
              ? currentIndexSlide === index
                ? "custom-dot-multi-carousel-active mr-2 ml-2"
                : "custom-dot-multi-carousel-inactive mr-2 ml-2"
              : active
              ? "custom-dot-multi-carousel-active mr-2 ml-2"
              : "custom-dot-multi-carousel-inactive mr-2 ml-2"
          }
          onClick={() => onClick()}></div>
      </div>
    )
  }

  function PopupImageModal() {
    return (
      <PopupImage
        key={title}
        show={showPopup}
        type={type}
        closeButton={true}
        images={value}
        autoFocus={false}
        title={title}
        indexCurrentCarousel={currentIndexSlide}
        responsiveCarousel={responsiveCarousel}
        customRightArrow={type === "carousel" ? <CustomRightArrow /> : null}
        customLeftArrow={type === "carousel" ? <CustomLeftArrow /> : null}
        customDot={type === "carousel" ? <CustomDot /> : null}
        onCancel={() => {
          setShowPopup(false)
          setFirstPopup(false)
        }}
        onChangeSlide={() => {
          setFirstPopup(false)
        }}
        menu={menu}
      />
    )
  }

  if (type === "image") {
    return (
      <>
        {showPopup && PopupImageModal()}
        <RowModule withoutSeparator={withoutSeparator}>
          <Card className="card-custom border">
            {General.loading ? (
              <Skeleton animation="wave" variant={"text"} width={80} className={"mx-4 my-4"} />
            ) : (
              title && <CardHeader title={title}> </CardHeader>
            )}
            {General.loading ? (
              <div className={"d-flex justify-content-center w-full p-4"}>
                <Skeleton animation="wave" variant={"rect"} width={240} height={160} />
              </div>
            ) : (
              //check if img url null image will hidden
              <Image
                src={
                  (value && value.length > 0) || value !== null
                    ? value
                    : toAbsoluteUrl("/media/users/blank.png")
                }
                // rounded
                onClick={() => {
                  setShowPopup(true)
                  setIdDocument(indexKey)
                  setPrefixMenu(menu)
                }}
                className={"images-card cursor-pointer py-5"}
              />
            )}
          </Card>
        </RowModule>
      </>
    )
  }

  if (type === "image-dashed") {
    return (
      <>
        {PopupImageModal()}
        <RowModule withoutSeparator={withoutSeparator}>
          <Card className="card-custom border-dashed py-4 ">
            {General.loading ? (
              <Skeleton animation="wave" variant={"text"} width={80} className={"mx-4 my-4"} />
            ) : (
              title && <CardHeader title={title}> </CardHeader>
            )}
            {General.loading ? (
              <div className={"d-flex justify-content-center w-full p-4"}>
                <Skeleton animation="wave" variant={"rect"} width={240} height={160} />
              </div>
            ) : (
              <Image
                src={value && value.length > 0 ? value : toAbsoluteUrl("/media/users/blank.png")}
                rounded
                onClick={() => {
                  setShowPopup(true)
                }}
                className={"images-card cursor-pointer"}
              />
            )}
          </Card>
        </RowModule>
      </>
    )
  }

  if (type === "carousel") {
    return (
      <>
        {showPopup && PopupImageModal()}
        <RowModule withoutSeparator={withoutSeparator}>
          <Card className="card-custom">
            {General.loading ? (
              <Skeleton animation="wave" variant={"text"} width={80} className={"mx-4 my-4"} />
            ) : (
              title && <CardHeader title={title}> </CardHeader>
            )}
            {General.loading ? (
              <div className={"d-flex justify-content-center w-full p-4"}>
                <Skeleton animation="wave" variant={"rect"} width={240} height={160} />
              </div>
            ) : (
              <div className="images-carousel-card" onMouseLeave={() => setIsShown(false)}>
                {value && value.length > 0 ? (
                  <Carousel
                    swipeable={true}
                    draggable={false}
                    arrows={value.length > 1 && isShown}
                    showDots={value.length > 1}
                    responsive={responsiveCarousel}
                    infinite={true}
                    autoPlaySpeed={1000}
                    keyBoardControl={true}
                    customTransition="all .5"
                    transitionDuration={500}
                    partialVisible={true}
                    renderArrowsWhenDisabled={true}
                    focusOnSelect={true}
                    customRightArrow={<CustomRightArrow />}
                    customLeftArrow={<CustomLeftArrow />}
                    customDot={<CustomDot />}
                    autoPlay={false}>
                    {value.map((item, idx) => {
                      return item?.urlDocument ? (
                        <Image
                          key={idx}
                          src={item?.urlDocument}
                          width="100%"
                          height="380px"
                          onMouseEnter={() => setIsShown(true)}
                          className="cursor-pointer"
                          onClick={() => {
                            if (item?.urlDocument) {
                              setShowPopup(true)
                              setFirstPopup(true)
                            }
                          }}
                          // resizeMode="contain"
                          style={{
                            objectFit: "contain"
                          }}
                        />
                      ) : (
                        <Image
                          src={toAbsoluteUrl("/media/users/blank.png")}
                          width="100%"
                          height="380px"
                          onMouseEnter={() => setIsShown(true)}
                          className={"cursor-pointer"}
                          onClick={() => {
                            setShowPopup(true)
                            setFirstPopup(true)
                          }}
                          alt={"no-image" + idx}
                          style={{
                            objectFit: "contain"
                          }}
                        />
                      )
                    })}
                  </Carousel>
                ) : (
                  <Image
                    src={toAbsoluteUrl("/media/users/blank.png")}
                    height="380px"
                    width="100%"
                    className={"mx-auto d-block"}
                    style={{
                      objectFit: "contain"
                    }}
                    // onClick={() => {
                    //   setShowPopup(true);
                    // }}
                  />
                )}
              </div>
            )}
          </Card>
        </RowModule>
      </>
    )
  }

  if (type === "address") {
    return (
      <>
        {General.loading ? (
          <>
            <RowModule withoutSeparator={withoutSeparator}>
              <Skeleton animation="wave" variant={"text"} width={80} className={"mb-4"} />
              <Skeleton animation="wave" variant={"text"} width={80} />
            </RowModule>
          </>
        ) : (
          <>
            <RowModule withoutSeparator={withoutSeparator}>
              <b>{title}</b>
            </RowModule>
            <RowModule>{value}</RowModule>
          </>
        )}
      </>
    )
  }

  return (
    <>
      {General.loading ? (
        <>
          <Skeleton animation="wave" variant={"text"} width={100} className={"mx-2"} />
          <span>:</span>
          <Skeleton animation="wave" variant={"text"} width={100} className={"mx-2"} />
        </>
      ) : (
        <RowModule>
          <>
            {title} : <b>{value}</b>
          </>
        </RowModule>
      )}
    </>
  )
}
