import React, { useEffect, useRef, useState } from "react"
import { useSubheader } from "../../../../../_metronic/layout/index.js"
import { useDispatch, useSelector } from "react-redux"
import {
  activitySendToErp,
  getDetailFertilization,
  updateAreaActivityImage
} from "../../../../../redux/actions/AreaAction.jsx"
import { Button, Card } from "react-bootstrap"
import { CardBody } from "../../../../../_metronic/_partials/controls/index.js"
import { RowModule } from "../../../../../component/atoms/RowModule/RowModule.jsx"
import { DetailText, RegionSelect, RowText, Status } from "../../../../../component/index.jsx"
import { timeStampToDate } from "../../../../helpers/DateHelper.js"
import { toMeter } from "../../../../helpers/TextHelper.js"
import { AREA } from "../../../../../constants/InitTypeConstants.js"
import { RoutesConstants } from "../../../../../constants/RoutesConstants.js"
import { ACTIVITY_ICONS, ILUSTRATION_CONSTANS } from "../../../../../constants/ImageConstants.js"
import { ActivityDetailHeader } from "../../../../../component/molecule/ActivityDetailHeader/ActivityDetailHeader.jsx"
import { useTranslation } from "react-i18next"
import swal from "sweetalert"
import { PopupQuestion } from "../../../../../component/atoms/Popup/index.jsx"
import { Form, Formik } from "formik"
import * as Yup from "yup"
import { FORM_NEED, getStringErrorMassage } from "../../../../helpers/StringCollection.js"
import {
  ACTIVITY_METHODE_ENUM,
  ACTIVITY_STATUS_ENUM
} from "../../../../../constants/StringConstant.js"
import { ErpNumber } from "../../../../../component/atoms/ErpNumber/ErpNumber.jsx"

export const FertilizationDetail = ({
  match: {
    params: { status, id, activity_id }
  }
}) => {
  const subHeader = useSubheader()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const formRef = useRef()

  const { MasterData, General, Area } = useSelector((state) => state)
  const [detail, setDetail] = useState({})
  const [idDocument, setIdDocument] = useState(null)
  const [showModalDialog, setShowModalDialog] = useState(false)
  const [showModalDialogRegion, setShowModalDialogRegion] = useState(false)
  const [initialValue] = useState({
    regional_id: ""
  })
  const [isValid, setIsValid] = useState(false)
  const [regionalValue, setRegionalValue] = useState("")

  const validationSchema = Yup.object().shape({
    regional_id: Yup.string().required(getStringErrorMassage("Regional ", FORM_NEED.harusDiIsi))
  })

  useEffect(() => {
    dispatch(getDetailFertilization(activity_id))
  }, [])

  useEffect(() => {
    if (Area.data) {
      setDetail(Area.data)
    }
  }, [Area])

  useEffect(() => {
    if (detail) {
      let newStatus
      if (status === "active") {
        newStatus = "teregistrasi"
      }
      if (
        detail?.activity_detail_response?.activity_methode_enum !== ACTIVITY_METHODE_ENUM.MANUAL &&
        detail?.activity_status_enum === "WAITING"
      ) {
        subHeader.setButton(btnApprove)
      } else {
        subHeader.setButton(null)
      }
      subHeader.setBreadcrumbs([
        {
          pathname: RoutesConstants.AREA.LIST_AREA_TEREGISTRASI("all"),
          title: "Lahan"
        },
        {
          pathname: RoutesConstants.AREA.LIST_AREA_TEREGISTRASI("all"),
          title: newStatus
        },
        {
          pathname: "/lahan/list/" + status + "/" + id,
          title: detail?.area_information?.area_name
        },
        {
          title: "Pemupukan ke " + detail?.activity_detail_response?.seq
        }
      ])
    }
  }, [detail])

  useEffect(() => {
    if (General.isFinishUpdateOrDelete) {
      if (General.actionInitType === "UPLOAD") {
        const data = {
          id: detail?.activity_detail_response?.image_and_id_activity[idDocument]?.id,
          url: MasterData.urlResponseUpload
        }

        dispatch(updateAreaActivityImage(data))
      } else if (General.actionInitType === AREA.UPDATE_ACTIVITY_IMAGE) {
        dispatch(getDetailFertilization(activity_id))
      } else if (
        General.loading === false &&
        General.actionInitType === AREA.APPROVE_ACTIVITY_TO_ERP
      ) {
        setShowModalDialog(false)
        swal({
          icon: ILUSTRATION_CONSTANS.SUCCESS_FORM,
          text: t("messages.response.activity_approve_to_erp_success")
        })
        dispatch(getDetailFertilization(activity_id))
      }
    }
  }, [General])

  const labelActivity = (
    <ActivityDetailHeader
      title={`AKTIVITAS PEMUPUKAN KE-${detail?.activity_detail_response?.seq}`}
      icon={ACTIVITY_ICONS.IC_PEMUPUKAN}
      rightContent={
        detail?.activity_detail_response?.activity_methode_enum !==
          ACTIVITY_METHODE_ENUM.MANUAL && (
          <Status
            text={
              detail?.activity_status_string === "Pending to ERP"
                ? "Pending To Finance"
                : detail?.activity_status_string
            }
            variant={
              detail?.activity_status_enum === ACTIVITY_STATUS_ENUM.APPROVED
                ? "approved"
                : "warning"
            }
          />
        )
      }
      bottomContent={
        detail?.activity_status_enum === ACTIVITY_STATUS_ENUM.APPROVED && (
          <ErpNumber
            noFaktur={detail?.no_faktur}
            noOrderSj={detail?.no_order_sj}
            noOrderSpk={detail?.no_order_spk}
            noOrderErp={detail?.no_order_erp}
            status={detail?.activity_type_enum}
          />
        )
      }
    />
  )

  const btnApprove = (
    <Button className={"btn btn-primary"} onClick={() => setShowModalDialogRegion(true)}>
      {t("button.send_to_finance")}
    </Button>
  )

  const unit = (type) => {
    return type === "LIQUID" ? " Liter/Ha" : " Kg/Ha"
  }

  const areaInformation = (
    <Card className={"mb-4"}>
      <CardBody>
        <h4 className={"mb-6 text-uppercase"}>INFORMASI LAHAN</h4>
        <RowModule customColumnCss={"col-md-6 mb-2"}>
          <RowModule>
            <RowText label={"Lahan"} value={detail?.area_information?.area_name ?? "-"} />
          </RowModule>
          <RowModule>
            <RowText
              label={"Luas Lahan (Manual)"}
              value={
                detail?.area_information?.area_land_manual
                  ? toMeter(detail?.area_information?.area_land_manual)
                  : "-"
              }
            />
          </RowModule>
          <RowModule>
            <RowText
              hasIcon={detail?.area_information?.has_kur}
              icon={detail?.area_information?.bank_image}
              label={"Petani"}
              value={
                detail?.area_information?.farmer_name
                  ? `${detail?.area_information?.farmer_name} (${detail?.area_information?.farmer_code})`
                  : "-"
              }
            />
          </RowModule>
          <RowModule>
            <RowText
              label={"Luas Lahan (Polygon)"}
              value={
                detail?.area_information?.area_land_polygon
                  ? toMeter(detail?.area_information?.area_land_polygon)
                  : "-"
              }
            />
          </RowModule>
          <RowModule>
            <RowText
              label={"Mitra"}
              value={
                detail?.area_information?.mitra_name
                  ? `${detail?.area_information?.mitra_name} (${detail?.area_information?.mitra_code})`
                  : "-"
              }
            />
          </RowModule>
          <RowModule>
            <RowText
              label={"Jenis Tanaman"}
              value={detail?.area_information?.plant_type_name ?? "-"}
            />
          </RowModule>
          <RowModule>
            <RowText label={"Lokasi Lahan"} value={detail?.area_information?.full_address ?? "-"} />
          </RowModule>
          <RowModule>
            <RowText
              label={"Varietas Tanaman"}
              value={detail?.area_information?.variety_name ?? "-"}
            />
          </RowModule>
        </RowModule>
      </CardBody>
    </Card>
  )

  const detailActivitas = (
    <Card>
      <CardBody>
        <h4 className={"mb-6 mt-6 text-uppercase"}>Detail Aktivitas</h4>
        <RowModule customColumnCss={"col-md-6 mb-2"}>
          <RowModule>
            <RowText
              label={"Submitted by"}
              value={detail?.activity_detail_response?.submitted_by ?? "-"}
            />
          </RowModule>
          <RowModule>
            <RowText label={"Sumber Pupuk"} value={detail?.fertilization_source_string ?? "-"} />
          </RowModule>
          <RowModule>
            <RowText
              label={"Submitted time"}
              value={
                detail?.activity_detail_response?.submitted_date
                  ? timeStampToDate(
                      detail?.activity_detail_response?.submitted_date,
                      "dd-MM-yyyy HH:mm:ss"
                    )
                  : "-"
              }
            />
          </RowModule>
          <RowModule>
            <RowText label={"Bentuk Pupuk"} value={detail?.fertilization_form_string ?? "-"} />
          </RowModule>
          <RowModule>
            <RowText label={"Pemupukan ke"} value={detail.activity_detail_response?.seq ?? "-"} />
          </RowModule>
          <RowModule>
            <RowText label={"Jenis Pupuk"} value={detail?.fertilization_type ?? "-"} />
          </RowModule>
          <RowModule>
            <RowText
              label={"Requested by"}
              value={detail?.activity_detail_response?.request_by_string ?? ""}
            />
          </RowModule>
          <RowModule>
            <RowText
              label={"Dosis Pupuk"}
              value={
                detail?.fertilization_doze
                  ? detail?.fertilization_doze + unit(detail?.fertilization_form)
                  : "-"
              }
            />
          </RowModule>
          <RowModule>
            <RowText
              label={"Tanggal Aktivitas"}
              value={
                detail?.activity_detail_response?.activity_date
                  ? timeStampToDate(detail?.activity_detail_response?.activity_date, "dd-MM-yyyy")
                  : "-"
              }
            />
          </RowModule>
          <RowModule>
            {/*<RowText*/}
            {/*  label={"Pemakaian Pupuk"}*/}
            {/*  value={*/}
            {/*    list?.fertilization_usage*/}
            {/*      ? list?.fertilization_usage +*/}
            {/*        unitUsed(list?.fertilization_form)*/}
            {/*      : "-"*/}
            {/*  }*/}
            {/*/>*/}
            <RowText
              label={"Pemakaian Pupuk"}
              value={detail?.fertilization_usage ? detail?.fertilization_usage + " Liter" : "-"}
            />
          </RowModule>
          <RowModule>
            <RowText label={"HST"} value={detail?.activity_detail_response?.hst + " HST" ?? "-"} />
          </RowModule>
          {detail?.fertilization_method !== "MANUAL" && (
            <RowModule>
              <RowText
                label={"Luas Lahan Dikerjakan (Alsintan)"}
                value={
                  detail?.activity_detail_response?.worked_area
                    ? toMeter(detail?.activity_detail_response?.worked_area)
                    : "-"
                }
              />
            </RowModule>
          )}
          <RowModule>
            <RowText
              label={"Metode Pemupukan"}
              value={detail?.fertilization_method_string ?? "-"}
            />
          </RowModule>
          {detail?.operators && detail?.operators.length > 0 && (
            <RowModule>
              <RowText
                label={t("label.operator")}
                value={detail?.operators ? detail?.operators.join(", ") : "-"}
              />
            </RowModule>
          )}
        </RowModule>
        {(detail?.activity_detail_response?.image_and_id_activity.length > 0 ||
          detail?.activity_detail_response?.note) && (
          <div className={"my-10"}>
            <h4 className={"mb-6"}>CATATAN</h4>
            <div className={"row mb-4"}>
              {detail?.activity_detail_response?.image_and_id_activity.map((item, index) => {
                return (
                  <div className={"col-md-3"} key={index}>
                    <DetailText
                      type="image"
                      value={item.url}
                      indexKey={index}
                      menu={AREA.ACTIVITY_FERTILIZATION}
                      setIdDocument={setIdDocument}
                    />
                  </div>
                )
              })}
            </div>
            <span className="font-weight-bold text-dark-50 font-size-sm">
              {detail?.activity_detail_response?.note ?? "-"}
            </span>
          </div>
        )}
      </CardBody>
    </Card>
  )

  return (
    <>
      <PopupQuestion
        onCancel={() => setShowModalDialogRegion(false)}
        textCancel={t("button.cancel")}
        textOk={t("button.continue")}
        title={t("messages.title.activity_select_regional")}
        onOk={() => {
          setShowModalDialogRegion(false)
          setShowModalDialog(true)
        }}
        disable={!isValid}
        show={showModalDialogRegion}
        bodyCustom={
          <Formik
            enableReinitialize
            initialValues={initialValue}
            innerRef={formRef}
            validationSchema={validationSchema}>
            {() => (
              <Form id={"formRegion"} className={"form"}>
                <div className={"mt-4"}>
                  <RegionSelect setIsValid={setIsValid} setRegionalValue={setRegionalValue} />
                </div>
              </Form>
            )}
          </Formik>
        }
      />
      <PopupQuestion
        onCancel={() => setShowModalDialog(false)}
        textCancel={t("button.cancel")}
        textOk={t("button.continue")}
        title={t("messages.title.activity_send_to_erp")}
        onOk={() => {
          dispatch(
            activitySendToErp(activity_id, {
              regional_id: regionalValue
            })
          )
          setShowModalDialog(false)
        }}
        show={showModalDialog}
        bodyCustom={
          <RowModule>
            <p className={"swal-text p-4"}>
              {t("messages.confirmation.activity_approve_to_erp_confirmation")}
            </p>
          </RowModule>
        }
      />
      {labelActivity}
      {areaInformation}
      {detailActivitas}
    </>
  )
}