import React, { useEffect, useState } from "react"
import { AppBar, Paper, Tab, Tabs } from "@material-ui/core"
// eslint-disable-next-line no-restricted-imports
import Tooltip from "@material-ui/core/Tooltip"
import { toAbsoluteUrl } from "../../../_metronic/_helpers"
import SVG from "react-inlinesvg"

export function TabsWithIcon({ labelTab, componentTab, header, initValue, changeTab }) {
  const [value, setValue] = useState(initValue)

  useEffect(() => {
    setValue(initValue)
  }, [initValue])

  function handleChange(event, newValue) {
    setValue(newValue)
    changeTab(newValue)
  }

  function getTab(value) {
    return componentTab[value]
  }

  return (
    <>
      {header ?? <></>}
      <Paper square className={"tabIcon px-16"}>
        <AppBar position="static" color="transparent">
          <Tabs
            value={value}
            indicatorColor={"primary"}
            textColor="primary"
            onChange={handleChange}>
            {labelTab.map((item, i) => {
              if (item?.labelTooltip) {
                return (
                  <Tooltip key={i} title={item?.labelTooltip} placement={"bottom"}>
                    <Tab
                      label={item?.label}
                      icon={<SVG className={"ml-2 mb-0"} src={toAbsoluteUrl(item?.icon)} />}
                    />
                  </Tooltip>
                )
              } else if (item?.icon) {
                return (
                  <Tab
                    key={i}
                    label={item?.label}
                    icon={<SVG className={"ml-2 mb-0"} src={toAbsoluteUrl(item?.icon)} />}
                  />
                )
              } else {
                return <Tab key={i} label={item?.label} />
              }
            })}
          </Tabs>
        </AppBar>
      </Paper>
      {getTab(value)}
    </>
  )
}
