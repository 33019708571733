import React from "react"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers"
import { ICON_CONSTANS } from "../../../../constants/ImageConstants.js"

export const CloseSvgIcon = () => {
  return (
    <span className="svg-icon svg-icon-lg svg-icon-primary">
      <SVG src={toAbsoluteUrl(ICON_CONSTANS.IC_BULLET_CLOSE)} />
    </span>
  )
}