import React, { useEffect, useRef, useState } from "react"
import { SelectInput, TabsInputComponent } from "../../../../component/index.jsx"
import * as Yup from "yup"
import {
  FORM_NEED,
  getMaximumErrorString,
  getMinimumErrorString,
  getStringErrorMassage
} from "../../../helpers/StringCollection.js"
import { useDispatch, useSelector } from "react-redux"
import {
  clearActionsMitra,
  createMitra,
  getDetail,
  getTypeMitra
} from "../../../../redux/actions/MitraAction.jsx"
import { useSubheader } from "../../../../_metronic/layout/index.js"
import { resetZones } from "../../../../redux/actions/MasterDataAction.jsx"
import swal from "sweetalert"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers/index.js"
import { Card, CardBody } from "../../../../_metronic/_partials/controls/index.js"
import { Form, Formik, useFormik } from "formik"
import { RowModule } from "../../../../component/atoms/RowModule/RowModule.jsx"

import { dateSelect, dateSelectReserve } from "../../../helpers/DateHelper.js"
import { ILLUSTRATION_ASSETS_V2 } from "../../../../constants/ImageConstants.js"
import { RoutesConstants } from "../../../../constants/RoutesConstants.js"
import { ROUTING_STATUS } from "../../../../constants/StringConstant.js"
import { titleCase } from "../../../helpers/TextHelper.js"
import { DataMitraEdu } from "./education/DataMitraEdu.jsx"
import { DocumentMitraEdu } from "./education/DocumentMitraEdu.jsx"
import { AccountBankEdu } from "./education/AccountBankEdu.jsx"
import { ReviewEdu } from "./education/ReviewEdu.jsx"
import { DataMitraIndividual } from "./individual/DataMitraIndividual.jsx"
import { DocumentMitraIndividual } from "./individual/DocumentMitraIndividual.jsx"
import { AccountBankIndividual } from "./individual/AccountBankIndividual.jsx"
import { ReviewIndividual } from "./individual/ReviewIndividual.jsx"
import { DataMitraGov } from "./goverment/DataMitraGov.jsx"
import { DocumentInstansiGov } from "./goverment/DocumentInstansiGov.jsx"
import { AccountBankGov } from "./goverment/AccountBankGov.jsx"
import { ReviewGov } from "./goverment/ReviewGov.jsx"
import { DataMitraSwasta } from "./swasta/DataMitraSwasta.jsx"
import { DocumentCompanySwasta } from "./swasta/DocumentCompanySwasta.jsx"
import { AccountBankSwasta } from "./swasta/AccountBankSwasta.jsx"
import { ReviewSwasta } from "./swasta/ReviewSwasta.jsx"

export const CreateEditMitra = ({
  history,
  match: {
    params: { id }
  }
}) => {
  const [isSubmit, setIsSubmit] = useState(true)
  const [stepsTitle] = useState(["Data mitra", "KTP & SWAFOTO", "Usaha mitra"])
  const [stepsTitleIndividu] = useState(["Data mitra", "Dokumen mitra", "Akun Bank"])
  const [stepsTitleGoverment] = useState(["Data mitra", "Dokumen Instansi", "Akun Bank"])
  const [stepsTitleEducation] = useState(["Data mitra", "Dokumen Instansi", "Akun Bank"])
  const [stepsTitleSwasta] = useState(["Data mitra", "Dokumen Perusahaan", "Akun Bank"])

  const { Mitra, General } = useSelector((state) => state)
  const subHeader = useSubheader()
  const dispatch = useDispatch()
  const formRef = useRef()
  const formSelectTypeRef = useRef()
  const formType = useFormik({
    innerRef: formSelectTypeRef,
    enableReinitialize: true,
    initialValues: {
      mitra_type: ""
    }
  })
  const [formData, setFormData] = useState({})
  const [initialValue, setInitialValue] = useState({
    regionalId: "",
    mitra_type: "",
    regional: "",
    id: "",
    name: "",
    phone: "",
    email: "",
    province_name: "",
    regency_name: "",
    district_name: "",
    mitraAddress: "",
    postal: "",
    ktp_no: "",
    business_document_type: "",
    mitraProvince: "",
    mitraRegency: "",
    mitraDistrict: "",
    mitraSubDistrict: "",
    ktp_pic_url: "",
    business_document_pic_url: "",
    mitra_pic_url: "",
    ktp_selfie_pic_url: "",
    job: "",
    other_image: "",
    dob: "",
    gender: "",
    npwp_no: "",
    bank_id: "",
    bank_account_number: "",
    bank_account_name: "",
    coordinator: ""
  })

  const [swalIcon, setSwallIcon] = useState("")
  const [mitraType, setMitraType] = useState([])
  const [isReadOnly, setIsReadOnly] = useState(false)

  useEffect(() => {
    if (id) {
      setIsReadOnly(true)
    }
  }, [])

  useEffect(() => {
    dispatch(getTypeMitra())
  }, [])

  useEffect(() => {
    if (Mitra.type) {
      setMitraType(
        Mitra.type.map((value) => ({
          label: value.title,
          value: value.mitra_type_enum
        }))
      )
    }

    if (id) {
      subHeader.setBreadcrumbs([
        {
          pathname: RoutesConstants.MITRA.LIST_MITRA(ROUTING_STATUS.STATUS_ENUM.PENDING),
          title: "Mitra"
        },
        {
          pathname: RoutesConstants.MITRA.LIST_MITRA(Mitra?.data?.status?.toUpperCase()),
          title: titleCase(
            Mitra?.data?.status?.toLowerCase() === "active"
              ? "aktif"
              : Mitra?.data?.status?.toLowerCase() === "inactive"
              ? "tidak aktif"
              : "ditolak"
          )
        },
        {
          pathname: RoutesConstants.MITRA.DETAIL_MITRA(
            Mitra?.data?.status?.toLowerCase(),
            Mitra?.data?.id
          ),
          title: Mitra?.data?.name
        },
        {
          title: "Edit"
        }
      ])
    } else {
      subHeader.setBreadcrumbs(null)
    }
    if (!id) {
      subHeader.setTitle("Tambah mitra Baru")
    }
  }, [Mitra, id])

  useEffect(() => {
    // Will did unmount remove button submit
    return () => {
      dispatch(clearActionsMitra())
      // subHeader.setButton(null);
    }
  }, [])

  useEffect(() => {
    if (General.isFinishUpdateOrDelete && General.actionInitType !== "UPLOAD") {
      swal({
        text: Mitra.messages,
        icon: swalIcon
      })
      history.push(RoutesConstants.MITRA.LIST_MITRA(ROUTING_STATUS.STATUS_ENUM.PENDING))
    }
  }, [General.isFinishUpdateOrDelete, Mitra])

  useEffect(() => {
    dispatch(getDetail(id))
  }, [dispatch])

  useEffect(() => {
    if (!id) {
      stepsTitleIndividu.push("Preview")
      stepsTitleEducation.push("Preview")
      stepsTitleGoverment.push("Preview")
      stepsTitleSwasta.push("Preview")
    }
    subHeader.setButton(buttonSubmited)
  }, [])

  useEffect(() => {
    subHeader.setButton(buttonSubmited)
  }, [isSubmit])

  const stepsDescriptionIndividu = [
    "Informasi user",
    "Foto Dokumen mitra ",
    "Informasi Rekening",
    "ReviewIndividual & Submit"
  ]

  const stepsDescriptionGoverment = [
    "Informasi user",
    "Foto Dokumen Instansi ",
    "Informasi Rekening",
    "ReviewIndividual & Submit"
  ]

  const stepsDescriptionEducation = [
    "Informasi user",
    "Foto Dokumen Instansi ",
    "Informasi Rekening",
    "ReviewIndividual & Submit"
  ]

  const stepsDescriptionSwasta = [
    "Informasi user",
    "Foto Dokumen Perusahaan ",
    "Informasi Rekening",
    "ReviewIndividual & Submit"
  ]

  const buttonSubmited = (
    <button
      type="button"
      disabled={isSubmit}
      className="btn btn-primary"
      onClick={() => {
        if (formRef.current) {
          formRef.current.handleSubmit()
        }
      }}>
      Submit
    </button>
  )

  useEffect(() => {
    if (!id) {
      if (formType.values.mitra_type) {
        setInitialValue({
          mitra_type: formType.values.mitra_type,
          regionalId: "",
          id: "",
          name: "",
          phone: "",
          email: "",
          province_name: "",
          regency_name: "",
          district_name: "",
          mitraAddress: "",
          postal: "",
          ktp_no: "",
          business_document_type: "",
          mitraProvince: "",
          mitraRegency: "",
          mitraDistrict: "",
          mitraSubDistrict: "",
          ktp_pic_url: "",
          business_document_pic_url: "",
          mitra_pic_url: "",
          ktp_selfie_pic_url: "",
          job: "",
          other_image: "",
          dob: "",
          gender: "",
          npwp_no: "",
          bank_id: "",
          bank_account_number: "",
          bank_account_name: "",
          coordinator: ""
        })
      }
    }
  }, [formType.values.mitra_type])

  useEffect(() => {
    if (Mitra.data && id) {
      const mitra = Mitra.data
      let newDate
      if (mitra) {
        if (mitra?.dob) newDate = dateSelectReserve(mitra?.dob)
      }

      formType.setFieldValue("mitra_type", mitra.mitra_type)
      setInitialValue({
        mitra_type: mitra.mitra_type,
        regionalId: mitra.regional_id,
        name: mitra.name,
        phone: mitra.phone_number,
        email: mitra.email,
        province_name: mitra.province,
        regency_name: mitra.regency,
        district_name: mitra.district,
        mitraAddress: mitra.address,
        mitraPostalCode: mitra.postal_code,
        ktp_no: mitra.ktp_no,
        business_document_type: mitra.business_document_type
          ? mitra.business_document_type
          : "SIUP",
        mitraProvince: mitra.province_id,
        mitraRegency: mitra.regency_id,
        mitraDistrict: mitra.district_id,
        mitraSubDistrict: mitra.sub_district_id,
        ktp_pic_url: mitra.ktp_pic_url,
        business_document_pic_url: mitra.business_document_pic_url,
        mitra_pic_url: mitra.mitra_pic_url,
        ktp_selfie_pic_url: mitra.ktp_selfie_pic_url,
        job: mitra.job,
        other_image: mitra.other_image,
        dob: newDate,
        gender: mitra.gender ? mitra.gender : "LAKI_LAKI",
        npwp_no: mitra.npwp_no,
        bank_id: mitra.bank_id,
        bank_account_number: mitra.bank_account_number,
        bank_account_name: mitra.bank_account_name,
        coordinator: mitra.coordinator
      })
    }
  }, [Mitra.data, id])

  const MitraEditSchema = [
    Yup.object().shape({
      regionalId: Yup.string().required(getStringErrorMassage("Regional ", FORM_NEED.harusDiIsi)),
      mitra_pic_url: Yup.string().required(
        getStringErrorMassage("profile Picture", FORM_NEED.harusDiIsi)
      ),
      name: Yup.string()
        .min(3, getMinimumErrorString(3))
        .max(100, getMaximumErrorString(100))
        .required(getStringErrorMassage("Nama", FORM_NEED.harusDiIsi)),
      email: Yup.string()
        .email("Invalid Email")
        .required(getStringErrorMassage("Email ", FORM_NEED.harusDiIsi)),
      phone: Yup.string()
        .required(getStringErrorMassage("Phone Number ", FORM_NEED.harusDiIsi))
        .matches(/^[0-9]+$/, "Harus Angka")
        .min(6, "Minimal 6 Digit")
        .max(15, "Maximal 15 Digit"),
      job: Yup.string(),
      dob: Yup.string().required(getStringErrorMassage("Tanggal Lahir", FORM_NEED.harusDiIsi)),
      gender: Yup.string().required(getStringErrorMassage("Jenis Kelamin", FORM_NEED.harusDiIsi)),
      mitraProvince: Yup.string().required(
        getStringErrorMassage("Provinsi ", FORM_NEED.harusDiIsi)
      ),
      mitraRegency: Yup.string().required(getStringErrorMassage("Kota ", FORM_NEED.harusDiIsi)),
      mitraDistrict: Yup.string().required(
        getStringErrorMassage("Kecamatan ", FORM_NEED.harusDiIsi)
      ),
      mitraSubDistrict: Yup.string().required(
        getStringErrorMassage("Kelurahan ", FORM_NEED.harusDiIsi)
      ),
      mitraPostalCode: Yup.string(),
      mitraAddress: Yup.string().required(
        getStringErrorMassage("Alamat lengkap ", FORM_NEED.harusDiIsi)
      )
    }),
    Yup.object().shape({
      ktp_no: Yup.string()
        .required(getStringErrorMassage("Nomor KTP ", FORM_NEED.harusDiIsi))
        .matches(/^[0-9]+$/, "Harus Angka")
        .min(6, "Minimal 6 Digit")
        .max(16, "Maximal 16 Digit"),
      ktp_pic_url: Yup.string().required(getStringErrorMassage("Foto KTP ", FORM_NEED.harusDiIsi)),
      ktp_selfie_pic_url: Yup.string().required(
        getStringErrorMassage("Swafoto ", FORM_NEED.harusDiIsi)
      ),
      business_document_pic_url: Yup.string().required(
        getStringErrorMassage("KK ", FORM_NEED.harusDiIsi)
      ),
      npwp_no: Yup.string()
        .matches(/^[0-9]+$/, "Harus Angka")
        .min(6, "Minimal 6 Digit")
        .max(16, "Maximal 16 Digit")
    }),
    Yup.object().shape({
      bank_id: Yup.string()
        .required(getStringErrorMassage("Nama Bank ", FORM_NEED.harusDiIsi))
        .nullable(),
      bank_account_number: Yup.string()
        .required(getStringErrorMassage("Nomor Rekening ", FORM_NEED.harusDiIsi))
        .nullable()
        .matches(/^[0-9]+$/, "Harus Angka")
        .max(15, "Nomor Rekening Maksimal 15 Karakter"),
      bank_account_name: Yup.string()
        .required(getStringErrorMassage("Nama Pemilik Rekening ", FORM_NEED.harusDiIsi))
        .min(3, getMinimumErrorString(3))
        .max(100, getMaximumErrorString(100))
        .nullable()
    })
  ]

  const SwastaSchema = [
    Yup.object().shape({
      regionalId: Yup.string().required(getStringErrorMassage("Regional ", FORM_NEED.harusDiIsi)),
      mitra_pic_url: Yup.string().required(getStringErrorMassage("Profile", FORM_NEED.harusDiIsi)),
      name: Yup.string()
        .min(3, getMinimumErrorString(3))
        .max(100, getMaximumErrorString(100))
        .required(getStringErrorMassage("Nama", FORM_NEED.harusDiIsi)),
      email: Yup.string()
        .email("Invalid Email")
        .required(getStringErrorMassage("Email ", FORM_NEED.harusDiIsi)),
      phone: Yup.string()
        .required(getStringErrorMassage("Phone Number ", FORM_NEED.harusDiIsi))
        .matches(/^[0-9]+$/, "Harus Angka")
        .min(6, "Minimal 6 Digit")
        .max(15, "Maximal 15 Digit"),
      coordinator: Yup.string()
        .min(3, getMinimumErrorString(3))
        .max(100, getMaximumErrorString(100))
        .required(getStringErrorMassage("Nama", FORM_NEED.harusDiIsi)),
      ktp_no: Yup.string()
        .required(getStringErrorMassage("Nomor KTP ", FORM_NEED.harusDiIsi))
        .matches(/^[0-9]+$/, "Harus Angka")
        .min(6, "Minimal 6 Digit")
        .max(16, "Maximal 16 Digit"),
      ktp_pic_url: Yup.string().required(getStringErrorMassage("Foto KTP ", FORM_NEED.harusDiIsi)),
      mitraProvince: Yup.string().required(
        getStringErrorMassage("Provinsi ", FORM_NEED.harusDiIsi)
      ),
      mitraRegency: Yup.string().required(getStringErrorMassage("Kota ", FORM_NEED.harusDiIsi)),
      mitraDistrict: Yup.string().required(
        getStringErrorMassage("Kecamatan ", FORM_NEED.harusDiIsi)
      ),
      mitraSubDistrict: Yup.string().required(
        getStringErrorMassage("Kelurahan ", FORM_NEED.harusDiIsi)
      ),
      mitraAddress: Yup.string().required(
        getStringErrorMassage("Alamat lengkap ", FORM_NEED.harusDiIsi)
      ),
      mitraPostalCode: Yup.string()
    }),
    Yup.object().shape({
      business_document_pic_url: Yup.string().required(
        getStringErrorMassage("Akta Pendirian Perusahaan ", FORM_NEED.harusDiIsi)
      ),
      npwp_no: Yup.string()
        .matches(/^[0-9]+$/, "Harus Angka")
        .min(6, "Minimal 6 Digit")
        .max(16, "Maximal 16 Digit")
    }),
    Yup.object().shape({
      bank_id: Yup.string()
        .required(getStringErrorMassage("Nama Bank ", FORM_NEED.harusDiIsi))
        .nullable(),
      bank_account_number: Yup.string()
        .required(getStringErrorMassage("Nomor Rekening ", FORM_NEED.harusDiIsi))
        .nullable()
        .matches(/^[0-9]+$/, "Harus Angka")
        .max(15, "Nomor Rekening Maksimal 15 Karakter"),
      bank_account_name: Yup.string()
        .min(3, getMinimumErrorString(3))
        .max(100, getMaximumErrorString(100))
        .required(getStringErrorMassage("Nama Pemilik Rekening ", FORM_NEED.harusDiIsi))
        .nullable()
    })
  ]

  const GovermentSchema = [
    Yup.object().shape({
      regionalId: Yup.string().required(getStringErrorMassage("Regional ", FORM_NEED.harusDiIsi)),
      mitra_pic_url: Yup.string().required(getStringErrorMassage("Profile", FORM_NEED.harusDiIsi)),
      name: Yup.string()
        .min(3, getMinimumErrorString(3))
        .max(100, getMaximumErrorString(100))
        .required(getStringErrorMassage("Nama", FORM_NEED.harusDiIsi)),
      email: Yup.string()
        .email("Invalid Email")
        .required(getStringErrorMassage("Email ", FORM_NEED.harusDiIsi)),
      phone: Yup.string()
        .required(getStringErrorMassage("Phone Number ", FORM_NEED.harusDiIsi))
        .matches(/^[0-9]+$/, "Harus Angka")
        .min(6, "Minimal 6 Digit")
        .max(15, "Maximal 15 Digit"),
      coordinator: Yup.string()
        .min(3, getMinimumErrorString(3))
        .max(100, getMaximumErrorString(100))
        .required(getStringErrorMassage("Nama", FORM_NEED.harusDiIsi)),
      ktp_no: Yup.string()
        .required(getStringErrorMassage("Nomor KTP ", FORM_NEED.harusDiIsi))
        .matches(/^[0-9]+$/, "Harus Angka")
        .min(6, "Minimal 6 Digit")
        .max(16, "Maximal 16 Digit"),
      ktp_pic_url: Yup.string().required(getStringErrorMassage("Foto KTP ", FORM_NEED.harusDiIsi)),
      mitraProvince: Yup.string().required(
        getStringErrorMassage("Provinsi ", FORM_NEED.harusDiIsi)
      ),
      mitraRegency: Yup.string().required(getStringErrorMassage("Kota ", FORM_NEED.harusDiIsi)),
      mitraDistrict: Yup.string().required(
        getStringErrorMassage("Kecamatan ", FORM_NEED.harusDiIsi)
      ),
      mitraSubDistrict: Yup.string().required(
        getStringErrorMassage("Kelurahan ", FORM_NEED.harusDiIsi)
      ),
      mitraAddress: Yup.string().required(
        getStringErrorMassage("Alamat lengkap ", FORM_NEED.harusDiIsi)
      ),
      mitraPostalCode: Yup.string()
    }),
    Yup.object().shape({
      ktp_selfie_pic_url: Yup.string().required(
        getStringErrorMassage("Surat Tugas ", FORM_NEED.harusDiIsi)
      ),
      npwp_no: Yup.string()
        .matches(/^[0-9]+$/, "Harus Angka")
        .min(6, "Minimal 6 Digit")
        .max(16, "Maximal 16 Digit")
    }),
    Yup.object().shape({
      bank_id: Yup.string()
        .required(getStringErrorMassage("Nama Bank ", FORM_NEED.harusDiIsi))
        .nullable(),
      bank_account_number: Yup.string()
        .required(getStringErrorMassage("Nomor Rekening ", FORM_NEED.harusDiIsi))
        .nullable()
        .matches(/^[0-9]+$/, "Harus Angka")
        .max(15, "Nomor Rekening Maksimal 15 Karakter"),
      bank_account_name: Yup.string()
        .min(3, getMinimumErrorString(3))
        .max(100, getMaximumErrorString(100))
        .required(getStringErrorMassage("Nama Pemilik Rekening ", FORM_NEED.harusDiIsi))
        .nullable()
    })
  ]

  const EducationSchema = [
    Yup.object().shape({
      regionalId: Yup.string().required(getStringErrorMassage("Regional ", FORM_NEED.harusDiIsi)),
      mitra_pic_url: Yup.string().required(getStringErrorMassage("Profile", FORM_NEED.harusDiIsi)),
      name: Yup.string()
        .min(3, getMinimumErrorString(3))
        .max(100, getMaximumErrorString(100))
        .required(getStringErrorMassage("Nama", FORM_NEED.harusDiIsi)),
      email: Yup.string()
        .email("Invalid Email")
        .required(getStringErrorMassage("Email ", FORM_NEED.harusDiIsi)),
      phone: Yup.string()
        .required(getStringErrorMassage("Phone Number ", FORM_NEED.harusDiIsi))
        .matches(/^[0-9]+$/, "Harus Angka")
        .min(6, "Minimal 6 Digit")
        .max(15, "Maximal 15 Digit"),
      coordinator: Yup.string()
        .min(3, getMinimumErrorString(3))
        .max(100, getMaximumErrorString(100))
        .required(getStringErrorMassage("Nama Koodinator", FORM_NEED.harusDiIsi)),
      ktp_no: Yup.string()
        .required(getStringErrorMassage("Nomor KTP ", FORM_NEED.harusDiIsi))
        .matches(/^[0-9]+$/, "Harus Angka")
        .min(6, "Minimal 6 Digit")
        .max(16, "Maximal 16 Digit"),
      ktp_pic_url: Yup.string().required(getStringErrorMassage("Foto KTP ", FORM_NEED.harusDiIsi)),
      mitraProvince: Yup.string().required(
        getStringErrorMassage("Provinsi ", FORM_NEED.harusDiIsi)
      ),
      mitraRegency: Yup.string().required(getStringErrorMassage("Kota ", FORM_NEED.harusDiIsi)),
      mitraDistrict: Yup.string().required(
        getStringErrorMassage("Kecamatan ", FORM_NEED.harusDiIsi)
      ),
      mitraSubDistrict: Yup.string().required(
        getStringErrorMassage("Kelurahan ", FORM_NEED.harusDiIsi)
      ),
      mitraAddress: Yup.string().required(
        getStringErrorMassage("Alamat lengkap ", FORM_NEED.harusDiIsi)
      ),
      mitraPostalCode: Yup.string()
    }),
    Yup.object().shape({
      npwp_no: Yup.string()
        .matches(/^[0-9]+$/, "Harus Angka")
        .min(6, "Minimal 6 Digit")
        .max(16, "Maximal 16 Digit")
    }),
    Yup.object().shape({
      bank_id: Yup.string()
        .required(getStringErrorMassage("Nama Bank ", FORM_NEED.harusDiIsi))
        .nullable(),
      bank_account_number: Yup.string()
        .required(getStringErrorMassage("Nomor Rekening ", FORM_NEED.harusDiIsi))
        .nullable()
        .matches(/^[0-9]+$/, "Harus Angka")
        .max(15, "Nomor Rekening Maksimal 15 Karakter"),
      bank_account_name: Yup.string()
        .min(3, getMinimumErrorString(3))
        .max(100, getMaximumErrorString(100))
        .required(getStringErrorMassage("Nama Pemilik Rekening ", FORM_NEED.harusDiIsi))
        .nullable()
    })
  ]

  function onSubmitForm(e) {
    const { values, submited } = e
    setFormData(values)
    let newDob = ""
    if (values?.dob) {
      newDob = dateSelect(values.dob)
    }
    if (submited) {
      let param = {
        id: id,
        mitra_type: values.mitra_type,
        name: values.name,
        phone: values.phone,
        email: values.email,
        province_name: values.mitraProvince.name,
        regency_name: values.mitraRegency.name,
        district_name: values.mitraDistrict.name,
        address: values.mitraAddress,
        postal: values.mitraPostalCode,
        ktp_no: values.ktp_no,
        business_document_type: values.business_document_type
          ? values.business_document_type
          : "SIUP",
        province_id: values.mitraProvince,
        regency_id: values.mitraRegency,
        district_id: values.mitraDistrict,
        sub_district_id: values.mitraSubDistrict,
        ktp_pic_url: values.ktp_pic_url,
        business_document_pic_url: values.business_document_pic_url,
        mitra_pic_url: values.mitra_pic_url,
        ktp_selfie_pic_url: values.ktp_selfie_pic_url,
        job: values.job,
        other_image: values.other_image,
        dob: values.dob ? newDob : "",
        gender: values.gender ? values.gender : "LAKI_LAKI",
        npwp_no: values.npwp_no,
        bank_id: values.bank_id,
        bank_account_number: values.bank_account_number,
        bank_account_name: values.bank_account_name,
        coordinator: values.coordinator
      }
      if (!id) {
        dispatch(createMitra(param, "create"))
        setSwallIcon(toAbsoluteUrl(ILLUSTRATION_ASSETS_V2.mitra.IL_SUCCESS_CREATED))
      } else {
        param.id = id
        dispatch(createMitra(param, "update"))
        setSwallIcon(toAbsoluteUrl(ILLUSTRATION_ASSETS_V2.mitra.IL_SUCCESS_UPDATED))
      }
    }
  }

  const handleChangeType = (val) => {
    formType.setFieldValue("mitra_type", val.mitra_type)
  }

  const [editStep, setEditStep] = useState("")
  const handleEditStep = (val) => {
    setEditStep(val)
  }

  const addMitraFlowIndividual = [
    <DataMitraIndividual
      key={0}
      mitraTypeEdit={isReadOnly}
      isReadOnly={false}
      mitraType={mitraType}
      changeType={handleChangeType}
    />,
    <DocumentMitraIndividual key={1} isReadOnly={false} />,
    <AccountBankIndividual key={2} isReadOnly={false} />,
    <ReviewIndividual key={3} formData={formData} editStep={handleEditStep} />
  ]

  const addMitraGoverment = [
    <DataMitraGov
      key={0}
      mitraTypeEdit={isReadOnly}
      isReadOnly={false}
      mitraType={mitraType}
      changeType={handleChangeType}
    />,
    <DocumentInstansiGov key={1} isReadOnly={false} />,
    <AccountBankGov key={2} isReadOnly={false} />,
    <ReviewGov key={3} formData={formData} editStep={handleEditStep} />
  ]

  const addMitraEducation = [
    <DataMitraEdu
      key={0}
      mitraTypeEdit={isReadOnly}
      isReadOnly={false}
      mitraType={mitraType}
      changeType={handleChangeType}
    />,
    <DocumentMitraEdu key={1} isReadOnly={false} />,
    <AccountBankEdu key={2} isReadOnly={false} />,
    <ReviewEdu key={3} formData={formData} editStep={handleEditStep} />
  ]

  const addMitraSwasta = [
    <DataMitraSwasta
      key={1}
      mitraTypeEdit={isReadOnly}
      isReadOnly={false}
      mitraType={mitraType}
      changeType={handleChangeType}
    />,
    <DocumentCompanySwasta key={2} isReadOnly={false} />,
    <AccountBankSwasta key={3} isReadOnly={false} />,
    <ReviewSwasta key={4} formData={formData} editStep={handleEditStep} />
  ]

  return (
    <>
      {/*for select type mitra first*/}
      {!formType.values.mitra_type && (
        <Formik initialValues={formType.initialValues} onSubmit={formType.handleSubmit}>
          <Form>
            <Card>
              <CardBody>
                <div className={"row justify-content-center py-15"}>
                  <div className={"col-xl-9"}>
                    <h4 className={"mb-10"}>{"list mitra".toUpperCase()}</h4>
                    <RowModule>
                      <SelectInput
                        required={true}
                        name="mitra_type"
                        editable={!isReadOnly}
                        label="Pilih Jenis Mitra"
                        placeholder="Pilih Salah Satu"
                        options={mitraType}
                        withoutFeedback={true}
                        clearNow={true}
                        onChange={(value) => {
                          if (!value) {
                            formType.setFieldValue("mitra_type", null)
                          } else {
                            formType.setFieldValue("mitra_type", value)
                          }
                        }}
                      />
                    </RowModule>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Form>
        </Formik>
      )}
      {/*create-user after select type mitra*/}
      {formType.values.mitra_type && (
        <TabsInputComponent
          isEditTabs={id ? true : false}
          steps={
            formType.values.mitra_type === "INDIVIDUAL"
              ? stepsTitleIndividu
              : formType.values.mitra_type === "GOVERNMENT_AGENCIES"
              ? stepsTitleGoverment
              : formType.values.mitra_type === "EDUCATIONAL_INSTITUTIONS"
              ? stepsTitleEducation
              : formType.values.mitra_type === "PRIVATE_COMPANIES"
              ? stepsTitleSwasta
              : ""
          }
          stepDescription={
            formType.values.mitra_type === "INDIVIDUAL"
              ? stepsDescriptionIndividu
              : formType.values.mitra_type === "GOVERNMENT_AGENCIES"
              ? stepsDescriptionGoverment
              : formType.values.mitra_type === "EDUCATIONAL_INSTITUTIONS"
              ? stepsDescriptionEducation
              : formType.values.mitra_type === "PRIVATE_COMPANIES"
              ? stepsDescriptionSwasta
              : ""
          }
          formInitialValues={initialValue}
          formId="mitraInput"
          innerRef={formRef}
          componentTab={
            formType.values.mitra_type === "INDIVIDUAL"
              ? addMitraFlowIndividual
              : formType.values.mitra_type === "GOVERNMENT_AGENCIES"
              ? addMitraGoverment
              : formType.values.mitra_type === "EDUCATIONAL_INSTITUTIONS"
              ? addMitraEducation
              : formType.values.mitra_type === "PRIVATE_COMPANIES"
              ? addMitraSwasta
              : ""
          }
          validationSchema={
            formType.values.mitra_type === "INDIVIDUAL"
              ? MitraEditSchema
              : formType.values.mitra_type === "GOVERNMENT_AGENCIES"
              ? GovermentSchema
              : formType.values.mitra_type === "EDUCATIONAL_INSTITUTIONS"
              ? EducationSchema
              : formType.values.mitra_type === "PRIVATE_COMPANIES"
              ? SwastaSchema
              : ""
          }
          onSubmitForm={onSubmitForm}
          onChangeStep={(activeStep) => {
            dispatch(resetZones())

            function checkDisableButton() {
              if (formRef.current.isValid) {
                if (!id) {
                  return activeStep < stepsTitle.length ? true : false
                } else {
                  return activeStep < stepsTitle.length - 1 ? true : false
                }
              } else {
                return true
              }
            }
            setIsSubmit(checkDisableButton())
          }}
          backToStep={editStep}
        />
      )}
    </>
  )
}