import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useFormikContext } from "formik"
import {
  Card,
  CardBody,
  CardHeader
} from "../../../../../../../../_metronic/_partials/controls/index.js"
import { RowModule } from "../../../../../../../../component/atoms/RowModule/RowModule.jsx"
import { SelectInput, TextInput } from "../../../../../../../../component/index.jsx"
import { getPlantType } from "../../../../../../../../redux/actions/MasterDataAction.jsx"
import { handleSpace } from "../../../../../../../helpers/TextHelper.js"

export const FormBenih = ({ isReadOnly, setIsValid }) => {
  const dispatch = useDispatch()
  const { values: formValues } = useFormikContext()
  const [statePlantType, setStatePlantType] = useState()
  const { MasterData } = useSelector((state) => state)
  const [plantType, setPlantType] = useState(formValues["plant_type_id"])

  const { values } = useFormikContext()

  useEffect(() => {
    if (values?.plant_type_id && values?.category_name) {
      setIsValid(false)
    } else {
      setIsValid(true)
    }
  }, [values])

  useEffect(() => {
    dispatch(getPlantType())
  }, [])

  useEffect(() => {
    if (MasterData.plantType) {
      setStatePlantType(
        MasterData.plantType.map((value) => ({ label: value.name, value: value.id }))
      )
    }
  }, [MasterData.plantType])

  useEffect(() => {
    if (formValues.hasOwnProperty("plant_type_id")) {
      setPlantType(formValues["plant_type_id"])
    }
  }, [formValues["plant_type_id"]])

  const onChangePlantType = (e) => {
    setPlantType(e)
  }

  return (
    <>
      <Card className="card-custom card-shadowless">
        <CardHeader title="INFORMASI BENIH" />
        <CardBody>
          <RowModule>
            <SelectInput
              name="plant_type_id"
              onChange={onChangePlantType}
              editable={!isReadOnly}
              label="Tipe Tanaman"
              placeholder="Silahkan pilih satu"
              options={statePlantType}
              withoutFeedback={false}
              clearNow={true}
              value={plantType}
            />
          </RowModule>
          <RowModule>
            <TextInput
              editable={!isReadOnly}
              label="Nama Variant"
              name="category_name"
              placeholder="Tambahkan nama variant"
              type={"text"}
              onKeyDown={handleSpace}
            />
          </RowModule>
        </CardBody>
      </Card>
    </>
  )
}