import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useSubheader } from "../../../../../_metronic/layout/index.js"
import { Card } from "../../../../../_metronic/_partials/controls/index.js"
import { RowModule } from "../../../../../component/atoms/RowModule/RowModule.jsx"
import { Button, Form } from "react-bootstrap"
import { EmptyCard } from "../../../../../component/atoms/index.jsx"
import { TableDetail } from "./table/TableDetail.jsx"
import { PopupQuestion } from "../../../../../component/atoms/Popup/index.jsx"
import { createReason, getDetailReason } from "../../../../../redux/actions/MasterDataAction.jsx"
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers/index.js"
import { loadUserLogin } from "../../../../../config/index.jsx"
import { useFormik } from "formik"
import {
  FORM_NEED,
  getMaximumErrorString,
  getStringErrorMassage
} from "../../../../helpers/StringCollection.js"
import * as Yup from "yup"
import { PRIVILEGE_ENUM } from "../../../../../constants/PrivilegeConstants.js"
import { ILUSTRATION_CONSTANS } from "../../../../../constants/ImageConstants.js"

export const ManageReasonDetail = ({
  history,
  match: {
    params: { id }
  }
}) => {
  const subHeader = useSubheader()
  const dispatch = useDispatch()
  const [showNewReason, setShowNewReason] = useState(false)
  const [swalMessage, setSwalMessage] = useState("")
  const [swalIcon, setSwalIcon] = useState("")
  const [detailReason, setDetailReason] = useState("")
  const { MasterData, General } = useSelector((state) => state)
  const privileges = loadUserLogin().privilege

  useEffect(() => {
    dispatch(getDetailReason())
  }, [])

  const formik = useFormik({
    initialValues: {
      newReason: ""
    },
    validationSchema: Yup.object({
      newReason: Yup.string()
        .required(getStringErrorMassage("Reason", FORM_NEED.harusDiIsi))
        .max(100, getMaximumErrorString(100))
        .matches("^.*$", "Tidak Boleh di Enter")
    })
  })

  useEffect(() => {
    if (MasterData.detailReason) {
      setDetailReason(MasterData.detailReason)
    }
  }, [MasterData])

  const ActionButton = () => {
    return (
      <Button variant="primary" onClick={() => setShowNewReason(true)}>
        Tambah Reason
      </Button>
    )
  }

  useEffect(() => {
    if (privileges.indexOf(PRIVILEGE_ENUM.MODIFIED_MANAGE_REASON) > -1) {
      subHeader.setButton(ActionButton)
    } else {
      subHeader.setButton(null)
    }
    subHeader.setBreadcrumbs([
      {
        pathname: "/master/manage-reason",
        title: "Manage Reason"
      },
      {
        title: handleUnderscore(id)
      }
    ])
  }, [])

  const handleUnderscore = (value) => {
    return value.replace("_", " ")
  }

  useEffect(() => {
    if (General.isFinishUpdateOrDelete) {
      if (swalMessage) {
        swal({
          icon: swalIcon,
          text: swalMessage
        })
      }
      history.push("/master/manage-reason/unpackage_reason")

      formik.setFieldValue("newReason", "")
      setShowNewReason(false)
      dispatch(getDetailReason())
    }
  }, [General.isFinishUpdateOrDelete])

  const handleSubmitCreate = () => {
    setSwalMessage("Reason berhasil di tambahkan")
    setSwalIcon(toAbsoluteUrl(ILUSTRATION_CONSTANS.SUCCESS_PLACEHOLDER))
    const formData = {
      reason_name: formik.values.newReason
    }
    dispatch(createReason(formData))
  }

  return (
    <>
      <PopupQuestion
        disable={(formik.errors.newReason && true) || !formik.values.newReason}
        onCancel={() => setShowNewReason(false)}
        title={"TAMBAH REASON"}
        onOk={handleSubmitCreate}
        show={showNewReason}
        bodyCustom={
          <Form.Group className="mb-3">
            <Form.Label>Silahkan tambah reason</Form.Label>
            <Form.Control
              as="textarea"
              onKeyPress={(e) => {
                e.key === "Enter" && e.preventDefault()
              }}
              rows={3}
              name="newReason"
              className={formik.errors.newReason && "is-invalid"}
              value={formik.values.newReason}
              onChange={formik.handleChange}
              placeholder="Input reason"
            />
            {formik.errors.newReason && (
              <div className="text-danger">{formik.errors.newReason}</div>
            )}
          </Form.Group>
        }
      />

      {General.loading === false && detailReason.length < 1 ? (
        <EmptyCard body={<h6>Belum ada Item.</h6>} />
      ) : (
        <Card>
          <RowModule>
            <TableDetail
              title={handleUnderscore(id)}
              data={detailReason}
              general={{ loading: false }}
              history={history}
              swalMessage={swalMessage}
              setSwalMessage={setSwalMessage}
              swalIcon={swalIcon}
              setSwalIcon={setSwalIcon}
            />
          </RowModule>
        </Card>
      )}
    </>
  )
}