import React from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import TableView, { formatterRowComponent } from "../../../../../../component/atoms/Tabel/index.jsx"
import { Card, CardBody } from "../../../../../../_metronic/_partials/controls/index.js"
import { DetailSvgIcon } from "../../../../../../component/atoms/Icons/SVG/index.jsx"
import { loadUserLogin } from "../../../../../../config/index.jsx"
import { linkWithCtrl } from "../../../../../helpers/Utils.js"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { EmptyCard, TableSkeleton } from "../../../../../../component/index.jsx"
import { PRIVILEGE_ENUM } from "../../../../../../constants/PrivilegeConstants.js"

export const TableList = ({ data, history }) => {
  const privileges = loadUserLogin().privilege
  const { t } = useTranslation()
  const { General } = useSelector((state) => state)
  const uiName = (e) => {
    return (
      <>
        <p className="text-dark font-weight-bolder mb-1 font-size-lg">{e?.enum_value ?? "-"}</p>
      </>
    )
  }

  const uiTotal = (e) => {
    return (
      <>
        <p className="text-dark  mb-1  font-size-lg">{e?.total_reason ?? "-"}</p>
      </>
    )
  }

  const uiAction = (e) => {
    return (
      <>
        {privileges.indexOf(PRIVILEGE_ENUM.MANAGE_REASON_DETAIL) > -1 && (
          <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Detail</Tooltip>}>
            <a
              onClick={() => {
                history.push("/master/manage-reason/" + e?.enum_name.toLowerCase())
              }}
              className="btn btn-icon btn-hover-primary btn-sm mx-3">
              <DetailSvgIcon></DetailSvgIcon>
            </a>
          </OverlayTrigger>
        )}
      </>
    )
  }

  const columns = [
    {
      dataField: "manageReason_name",
      text: "NAMA KATEGORI",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiName
      }
    },
    {
      dataField: "manageReason_size",
      text: "Jumlah Reason",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiTotal
      }
    },
    {
      dataField: "action",
      text: "ACTION",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiAction
      }
    }
  ]

  return (
    <>
      {General.loading ? (
        <TableSkeleton column={3} row={10} />
      ) : (
        <Card className="card-shadowless">
          <CardBody>
            {data?.length > 0 ? (
              <TableView
                columnProperties={columns}
                dataTable={data}
                currentPage={null}
                currentElement={null}
                totalCount={null}
                showingTotalPage={false}
                rowClasses={() => {
                  if (privileges.indexOf(PRIVILEGE_ENUM.MANAGE_REASON_DETAIL) > -1) {
                    return "cursor-pointer"
                  } else {
                    return "cursor-default"
                  }
                }}
                rowEvents={{
                  onClick: (e, row) => {
                    if (privileges.indexOf(PRIVILEGE_ENUM.MANAGE_REASON_DETAIL) > -1) {
                      const url = "/master/manage-reason/" + row?.enum_name.toLowerCase()
                      if (e.ctrlKey) {
                        linkWithCtrl(url)
                      } else {
                        history.push(url)
                      }
                    }
                  }
                }}
              />
            ) : (
              <EmptyCard body={t("messages.response.empty_state")} />
            )}
          </CardBody>
        </Card>
      )}
    </>
  )
}