import React, { useEffect, useState } from "react"
import { ActivityDetailHeader } from "../../../../../component/molecule/ActivityDetailHeader/ActivityDetailHeader.jsx"
import { ACTIVITY_ICONS } from "../../../../../constants/ImageConstants.js"
import { CardBody } from "../../../../../_metronic/_partials/controls/index.js"
import { Card } from "react-bootstrap"
import { RowModule } from "../../../../../component/atoms/RowModule/RowModule.jsx"
import { DetailText, FileList, RowText } from "../../../../../component/index.jsx"
import { fileTypeSplit, toMeter } from "../../../../helpers/TextHelper.js"
import { useSubheader } from "../../../../../_metronic/layout/index.js"
import { RoutesConstants } from "../../../../../constants/RoutesConstants.js"
import { timeStampToDate } from "../../../../helpers/DateHelper.js"
import { getFailureHarvest } from "../../../../../redux/actions/AreaAction.jsx"
import { useDispatch, useSelector } from "react-redux"

export function HarvestFailureDetail({
  match: {
    params: { status, id, activity_id }
  }
}) {
  const [detail, setDetail] = useState()

  const subHeader = useSubheader()
  const dispatch = useDispatch()
  const { Area } = useSelector((state) => state)

  useEffect(() => {
    if (Area?.data) {
      setDetail(Area?.data)
    }
  }, [Area?.data])

  useEffect(() => {
    dispatch(getFailureHarvest(activity_id))
  }, [activity_id])

  useEffect(() => {
    if (detail) {
      let newStatus
      if (status === "active") {
        newStatus = "teregistrasi"
      }
      subHeader.setButton(null)
      subHeader.setBreadcrumbs([
        {
          pathname: RoutesConstants.AREA.LIST_AREA_TEREGISTRASI("all"),
          title: "Lahan"
        },
        {
          pathname: RoutesConstants.AREA.LIST_AREA_TEREGISTRASI("all"),
          title: newStatus
        },
        {
          pathname: "/lahan/list/" + status + "/" + id,
          title: detail?.area_information_response?.area_name
        },
        {
          title: "Gagal Panen"
        }
      ])
    }
  }, [detail])

  function checkDataReason(reason) {
    if (reason?.reason_failure_enum === "OTHER") {
      return reason?.other_reason
    } else if (reason?.reason_failure_enum === "FLOODING") {
      return reason?.reason_failure_string
    } else if (reason?.reason_failure_enum === "PEST_ATTACK") {
      return reason?.reason_failure_string + " " + reason?.pest_type?.join(", ")
    }
  }

  function detailActivity() {
    return (
      <>
        <Card>
          <CardBody>
            <h4 className={"mb-6 text-uppercase"}>INFORMASI LAHAN</h4>
            <RowModule customColumnCss={"col-md-6 mb-2"}>
              <RowModule>
                <RowText
                  label={"Lahan"}
                  value={
                    detail?.area_information_response?.area_name
                      ? detail?.area_information_response?.area_name
                      : "-"
                  }
                />
              </RowModule>
              <RowModule>
                <RowText
                  label={"Luas Lahan (Manual)"}
                  value={
                    detail?.area_information_response?.area_land_manual
                      ? toMeter(detail?.area_information_response?.area_land_manual)
                      : "-"
                  }
                />
              </RowModule>
              <RowModule>
                <RowText
                  hasIcon={detail?.area_information_response?.has_kur}
                  icon={detail?.area_information_response?.bank_image}
                  label={"Petani"}
                  value={
                    detail?.area_information_response?.farmer_name
                      ? `${detail?.area_information_response?.farmer_name} (${detail?.area_information_response?.farmer_code})`
                      : "-"
                  }
                />
              </RowModule>
              <RowModule>
                <RowText
                  label={"Luas Lahan (Polygon)"}
                  value={
                    detail?.area_information_response?.area_land_polygon
                      ? toMeter(detail?.area_information_response?.area_land_polygon)
                      : "-"
                  }
                />
              </RowModule>
              <RowModule>
                <RowText
                  label={"Mitra"}
                  value={
                    detail?.area_information_response?.mitra_name
                      ? `${detail?.area_information_response?.mitra_name} (${detail?.area_information_response?.mitra_code})`
                      : "-"
                  }
                />
              </RowModule>
              <RowModule>
                <RowText
                  label={"Jenis Tanaman"}
                  value={
                    detail?.area_information_response?.plant_type_name
                      ? detail?.area_information_response?.plant_type_name
                      : "-"
                  }
                />
              </RowModule>
              <RowModule>
                <RowText
                  label={"Lokasi Lahan"}
                  value={
                    detail?.area_information_response?.full_address
                      ? detail?.area_information_response?.full_address
                      : "-"
                  }
                />
              </RowModule>
              <RowModule>
                <RowText
                  label={"Varietas Tanaman"}
                  value={
                    detail?.area_information_response?.variety_name
                      ? detail?.area_information_response?.variety_name
                      : "-"
                  }
                />
              </RowModule>
            </RowModule>
          </CardBody>
        </Card>
      </>
    )
  }

  function harvestFailureData() {
    const data = detail?.harvest_failure
    return (
      <>
        <Card>
          <CardBody>
            <h4 className={"mb-6 text-uppercase"}>DATA GAGAL PANEN</h4>
            <RowModule customColumnCss={"col-md-6 mb-2"}>
              <RowModule>
                <RowText
                  label={"Submitted by"}
                  value={data?.submitted_by ? data?.submitted_by : "-"}
                />
              </RowModule>
              <RowModule>
                <RowText
                  label={"HST"}
                  value={data?.hst >= 0 && typeof data?.hst === "number" ? data?.hst + " HST" : "-"}
                />
              </RowModule>
              <RowModule>
                <RowText
                  label={"Submitted time"}
                  value={
                    data?.submitted_date
                      ? timeStampToDate(data?.submitted_date, "dd-MM-yyyy - HH:mm:ss")
                      : "-"
                  }
                />
              </RowModule>
              <RowModule>
                <RowText label={"Alasan Gagal Panen"} value={checkDataReason(data?.reason)} />
              </RowModule>
              <RowModule>
                <RowText
                  label={"Tanggal Aktivitas"}
                  value={
                    data?.activity_date
                      ? timeStampToDate(data?.activity_date, "dd-MM-yyyy - HH:mm:ss")
                      : "-"
                  }
                />
              </RowModule>
            </RowModule>
            <div className={"my-10"}>
              <h4 className={"mb-6 text-uppercase"}>Bukti Foto</h4>

              <div className={"row mb-4"}>
                {data?.image_url &&
                  data?.image_url.map((item, index) => {
                    return (
                      <div className={"col-md-3"} key={index}>
                        <DetailText type="image" value={item} indexKey={index} />
                      </div>
                    )
                  })}
              </div>
            </div>
            <div className={"my-10"}>
              <h4 className={"mb-6 text-uppercase"}>Berita Acara</h4>
              <div>
                {data && (
                  <FileList
                    view={data?.document_url}
                    className={"mb-5"}
                    name={data?.document_name}
                    size={data?.document_size}
                    type={fileTypeSplit(data?.document_name)}
                  />
                )}
              </div>
            </div>
          </CardBody>
        </Card>
      </>
    )
  }

  return (
    <>
      <div className={"row justify-content-center "}>
        <div className={" d-flex flex-column"} style={{ gap: 14 }}>
          <ActivityDetailHeader
            icon={ACTIVITY_ICONS.IC_HARVEST_FAILURE}
            title={` AKTIVITAS GAGAL PANEN`}
          />
          {detailActivity()}
          {harvestFailureData()}
        </div>
      </div>
    </>
  )
}