import React from "react"
import { Card, Image } from "react-bootstrap"
import { toAbsoluteUrl } from "../../../_metronic/_helpers"
import SVG from "react-inlinesvg"
import { FlatIconAndText } from "../FlaticonAndText"
import { dateFormat } from "../../../app/helpers/TextHelper"
import { useSelector } from "react-redux"
import { Skeleton } from "@material-ui/lab"
import { ICON_CONSTANS } from "../../../constants/ImageConstants.js"

/**
 *
 * @param titleImage di isi klo ada image nya klo g ada akan di ambil dari inisial title card
 * @param titleCardDetail
 * @param toolbarComponent
 * @param descriptionLeft
 * Example
 * <div className="d-flex flex-wrap mb-4">
 *     <a href="#"
 *     className="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
 *     <i className="flaticon2-new-email mr-2 font-size-lg"/>jason@siastudio.com</a>
 *     <a href="#"
 *     className="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
 *     <i className="flaticon2-calendar-3 mr-2 font-size-lg"/>PR Manager</a>
 *     <a href="#" className="text-dark-50 text-hover-primary font-weight-bold">
 *         <i className="flaticon2-placeholder mr-2 font-size-lg"/>Melbourne</a>
 * </div>
 * <span className="font-weight-bold text-dark-50">I distinguish three main text objectives could be merely to inform people.</span>
 * <span className="font-weight-bold text-dark-50">A second could be persuade people.You want people to bay objective</span>
 * @param descriptionRight
 * @param flatIconAndText2Rows flat icon text row 2 untuk di  isikan di samping bisa lebih dari satu
 * @returns {JSX.Element}
 * @constructor
 */
export function CardDetail({
  header,
  titleImage,
  titleCardDetail,
  toolbarComponent,
  descriptionLeft,
  descriptionRight,
  flatIconAndText2Rows,
  wrap,
  svgImage,
  headerWithoutLine,
  titleClassName,
  joinDate,
  kurBNI,
  kurBNIImageurl
}) {
  const { General } = useSelector((state) => state)
  const getInitials = (name) => {
    if (name) {
      let initials = name.split(" ")

      if (initials.length > 1) {
        initials = initials.shift().charAt(0) + initials.pop().charAt(0)
      } else {
        initials = name.substring(0, 2)
      }

      return initials.toUpperCase()
    }
    return "MT"
  }
  const imageData = (imageUrl) => {
    if (imageUrl) {
      return <Image src={imageUrl} alt="image" />
    }
    return (
      <span className="font-size-h3 symbol-label font-weight-boldest">
        {getInitials(titleCardDetail)}
      </span>
    )
  }
  const imageSvg = (imageUrl) => {
    if (imageUrl) {
      return <SVG className="mr-2 mb-1" src={toAbsoluteUrl(imageUrl)} />
    }
  }
  return (
    <Card>
      {header && <Card.Header>{header}</Card.Header>}
      {headerWithoutLine ? (
        General.loading ? (
          <Skeleton
            animation="wave"
            variant={"rect"}
            width={100}
            height={24}
            className={"mx-4 my-4"}
          />
        ) : (
          headerWithoutLine && (
            <b>
              <h2 className="mb-0 ml-5 mt-5 px-4 py-4">{headerWithoutLine}</h2>
            </b>
          )
        )
      ) : (
        ""
      )}
      <Card.Body>
        {/*begin::Details*/}
        <div className="d-flex justify-content-between mb-8">
          {/*begin::Pic*/}
          <div className="flex-shrink-0 mr-7 mt-lg-0 mt-3">
            <div className="symbol symbol-50 symbol-lg-120">
              {General.loading ? (
                <Skeleton animation="wave" variant={"rect"} width={120} height={120} />
              ) : svgImage ? (
                imageSvg(svgImage)
              ) : (
                imageData(titleImage)
              )}
            </div>
            <div className="symbol symbol-50 symbol-lg-120 symbol-primary d-none">
              <span className="font-size-h3 symbol-label font-weight-boldest">JM</span>
            </div>
          </div>
          {/*end::Pic*/}
          {/*begin::Info*/}
          <div className="flex-grow-1">
            {/*begin::Title*/}
            <div className="">
              <div className="d-flex w-100 align-items-center">
                <div
                  className={`d-flex text-dark-75 ${
                    titleClassName ? titleClassName : ""
                  } font-size-h5 font-weight-bold `}>
                  {General.loading ? (
                    <Skeleton animation="wave" variant={"text"} width={120} />
                  ) : (
                    titleCardDetail
                  )}
                </div>
                {joinDate && (
                  <div className={"ml-2"}>
                    <FlatIconAndText
                      text={`Bergabung Sejak ${dateFormat(joinDate, "DD MMMM YYYY")}`}
                      svg={ICON_CONSTANS.IC_BULLET_TICK}
                    />
                  </div>
                )}
                {kurBNI && <div className={"kur-bni detail"}>{imageData(kurBNIImageurl)}</div>}
              </div>
              <div className="my-lg-0 my-3">{toolbarComponent}</div>
            </div>
            {/*end::Title*/}
            {/*begin::Content*/}
            <div
              className={
                wrap
                  ? "d-flex flex-wrap justify-content-between mt-1"
                  : "d-flex flex-nowrap justify-content-between mt-1"
              }>
              <div className="d-flex flex-column flex-grow-1 pr-8">{descriptionLeft}</div>
              <div className="d-flex justify-content-end align-items-center w-25 flex-fill float-right">
                {descriptionRight}
              </div>
            </div>
            {/*end::Content*/}
          </div>
          {/*end::Info*/}
        </div>

        {/*end::Details*/}
        {/*begin::Items*/}
        {flatIconAndText2Rows && (
          <>
            <div className="separator separator-solid" />
            <div className="d-flex align-items-center flex-wrap mt-8">{flatIconAndText2Rows}</div>
          </>
        )}
        {/*begin::Items*/}
      </Card.Body>
    </Card>
  )
}