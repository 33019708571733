import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Button } from "@material-ui/core"
import { useSelector } from "react-redux"

const ButtonAction = ({ variant, text, onClick, disabled, ...prop }) => {
  const { General } = useSelector(state => state)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(General.loading)
  }, [General])
  const disabledInput = () => {
    if (!disabled) {
      return loading
    }
    return disabled
  }
  if (disabledInput()) {
    return (
      <Button variant="outlined" color={variant} onClick={onClick} {...prop} disabled>
        {text}
      </Button>
    )
  } else {
    return (
      <Button variant="outlined" color={variant} onClick={onClick} {...prop}>
        {text}
      </Button>
    )
  }
}

export default ButtonAction

ButtonAction.propTypes = {
  variant: PropTypes.oneOf([
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "light",
    "dark"
  ]),
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
}
