import React, { useEffect, useState } from "react"
import { Form, useFormikContext } from "formik"
import { Col } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { getAppList } from "../../../../../../redux/actions/BroadcastAction.jsx"
import { CkEditorCustom, TextInput, UploadComponent } from "../../../../../../component/index.jsx"
import { UploadIcon } from "../../../../../../component/atoms/Icons/SVG/upload.jsx"
import {
  resetUrlResponse,
  uploadAction
} from "../../../../../../redux/actions/MasterDataAction.jsx"
import { VARIABLE_CONSTANT } from "../../../../../../constants/VariableConstant.js"
import { parse } from "node-html-parser"
import { useTranslation } from "react-i18next"

const FormViewCreate = ({
  setFileListForm,
  counterFile,
  setFileSize,
  setDataBodySend,
  setValidBtn
}) => {
  const { t } = useTranslation()
  const [banner, setBanner] = useState("")
  const [dataBody, setDataBody] = useState("")
  const [fileAttachments, setFileAttachments] = useState([])
  const [removeCounter, setRemoveCounter] = useState(false)
  const [attachmentList] = useState([
    t("content.attachment_maximum_size"),
    t("content.attachment_maximum_file"),
    t("content.attachment_format_file")
  ])

  const { MasterData } = useSelector((state) => state)
  const dispatch = useDispatch()
  const valuesData = useFormikContext()

  useEffect(() => {
    const dataParse = parse(dataBody).text

    if (
      valuesData?.values?.title &&
      dataBody &&
      dataParse.length < VARIABLE_CONSTANT.MAX_CARACTER
    ) {
      setValidBtn(true)
    } else {
      setValidBtn(false)
    }
  }, [valuesData?.values, dataBody])

  useEffect(() => {
    setFileListForm(fileAttachments)
    counterFile(removeCounter)
  }, [fileAttachments, removeCounter])

  useEffect(() => {
    if (MasterData.urlResponseUpload) {
      if (MasterData.uploadType === "AVATAR") {
        setBanner(MasterData.urlResponseUpload)
      }
    }
    return () => {
      dispatch(resetUrlResponse())
    }
  }, [MasterData.urlResponseUpload])

  useEffect(() => {
    dispatch(getAppList())
    setBanner("")
  }, [])

  useEffect(() => {
    setDataBodySend(dataBody)
  }, [dataBody])

  const placeholderUpload = <UploadIcon />

  const onRemove = () => {
    setRemoveCounter(!removeCounter)
  }

  const onChangeBanner = (e) => {
    dispatch(uploadAction({ data: e, type: "AVATAR" }))
  }

  const onChangeDataBody = (event, editor) => {
    const data = editor.getData()
    setDataBody(data)
  }

  const onChangeFileUpload = (e, size) => {
    setFileAttachments(e)
    setFileSize(size)
  }

  const ckEditorTools = [
    "heading",
    "|",
    "bold",
    "italic",
    "link",
    "bulletedList",
    "numberedList",
    "|",
    "undo",
    "redo"
  ]

  const AttachmentDescription = () => {
    return (
      <div className={"mb-5"}>
        <h5>Lampiran File</h5>
        {attachmentList.map((items, i) => (
          <li key={i}>{items}</li>
        ))}
      </div>
    )
  }

  return (
    <Form style={{ display: "flex", flexDirection: "column", gap: 50 }}>
      <Col>
        <h2>DETAIL INFORMASI</h2>
      </Col>

      {/*<Col>*/}
      {/*    <p>Send To *</p>*/}
      {/*    {*/}
      {/*        Broadcast.pageData?.map((item, i) => (*/}

      {/*            <CheckboxCustomGroup*/}
      {/*                key={i}*/}
      {/*                label={item?.enum_value}*/}
      {/*                name={"send_to"}*/}
      {/*                value={item?.enum_name}*/}
      {/*            />*/}
      {/*        ))*/}
      {/*    }*/}
      {/*</Col>*/}
      <Col>
        <UploadComponent
          placeholder={placeholderUpload}
          urlParsing={banner}
          name={"banner_image"}
          laber={"banner"}
          onChange={onChangeBanner}
        />
        <p className={"text-black-50"}>Ukuran banner harus 1660x350px, ukuran maksimal file 2Mb.</p>
      </Col>

      <Col>
        <TextInput
          counter={130}
          editable={true}
          label={"Headline *"}
          name={"title"}
          placeholder={"Tambahkan Headline"}
        />
      </Col>
      <Col>
        <CkEditorCustom
          counter={VARIABLE_CONSTANT.MAX_CARACTER}
          onChange={onChangeDataBody}
          tool={ckEditorTools}
          data={dataBody}
          label={"Body Text *"}
          setData={setDataBody}
        />
      </Col>

      <Col>
        <AttachmentDescription />
        <UploadComponent
          onDeleteFileList={onRemove}
          fileList={fileAttachments}
          typeUpload={"files"}
          editable={true}
          onChange={onChangeFileUpload}
          placeholder={placeholderUpload}
          maxSize={VARIABLE_CONSTANT.MAX_SIZE_UPLOAD}
          maxSizeText={VARIABLE_CONSTANT.MAX_SIZE_TEXT}
        />
      </Col>
    </Form>
  )
}

export default FormViewCreate