import { initialFilterTable } from "../../component/atoms/Tabel"
import { createSlice } from "@reduxjs/toolkit"

const initState = {
  pageData: [],
  page: initialFilterTable,
  combobox: [],
  data: undefined
}

export const VideoSlice = createSlice({
  name: "video",
  initialState: initState,
  reducers: {
    getPage: (state, action) => {
      state.data = action.payload
    },
    setPageData: (state, action) => {
      const { pageData, page } = action.payload
      state.pageData = pageData
      state.page = page
    }
  }
})
