import React, { useEffect } from "react"
import { useSubheader } from "../../../_metronic/layout/index.js"

export function DashboardPage() {
  const subheader = useSubheader()

  useEffect(() => {
    subheader.setButton(undefined)
  }, [])

  return (
    <>
      <iframe
        src="https://metabase.maxxitani.id/public/dashboard/5c3d710a-e3b3-4714-8ee9-5fb99974c7d9"
        frameBorder="0"
        width="100%"
        height="100%"
      />
    </>
  )
}