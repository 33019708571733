import React, { useEffect, useState } from "react"
import { Chip } from "@material-ui/core"
import Checkbox from "@material-ui/core/Checkbox"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank.js"
import CheckBoxIcon from "@material-ui/icons/CheckBox.js"
import CloseIcon from "@material-ui/icons/Close"

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />
export function ChipsMultipleInput({
  placeholder,
  label,
  options = [],
  onChange,
  value,
  disable,
  required
}) {
  const [selectedValue, setSelectedValue] = useState(value || [])

  function onChanges(_, v) {
    onChange(v)
  }

  function handleDeleteData(chipToDelete) {
    onChange((chips) => chips.filter((chip) => chip.value !== chipToDelete.value))
  }

  useEffect(() => {
    if (value) {
      setSelectedValue(value)
    }
  }, [value])

  function checkCheckedValue(e) {
    return !!selectedValue.find((v) => v.value === e.value)
  }

  return (
    <div>
      <div>
        {label} {required && <span className={"text-danger"}> *</span>}
      </div>
      <Autocomplete
        multiple
        disabled={disable}
        onChange={onChanges}
        id="checkboxes-tags-demo"
        options={options}
        value={value}
        disableCloseOnSelect
        getOptionLabel={(option) => option.label}
        renderOption={(option) => (
          <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={checkCheckedValue(option)}
            />
            {option.label}
          </React.Fragment>
        )}
        style={{ width: "100%" }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <span key={index} {...getTagProps({ index })}>
              {option.label},
            </span>
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={selectedValue.length > 0 ? "" : placeholder}
          />
        )}
      />

      <div
        className={"d-flex "}
        style={{ display: "flex", flexWrap: "wrap", gap: 4, marginTop: 14 }}>
        {selectedValue.map((item, i) => (
          <Chip
            size={"medium"}
            style={{
              background: "#EFF8ED",
              color: "#6CC049",
              borderWidth: 0.1,
              border: "solid",
              borderColor: "#6CC049"
            }}
            key={i}
            deleteIcon={<CloseIcon style={{ color: "#6CC049" }} />}
            label={item.label}
            onDelete={() => handleDeleteData(item)}
          />
        ))}
      </div>
    </div>
  )
}
