import React, { useEffect, useRef, useState } from "react"
import { withRouter } from "react-router-dom"
import { ProgressBar } from "react-bootstrap"

const AnimateLoading = ({ location }) => {
  const [width, setWidth] = useState(0)
  const [routeChanged, setRouteChanged] = useState(false)

  const animateTimeout = useRef(null)
  const stopAnimateTimeout = useRef(null)

  // Handle route changes and initiate animation
  useEffect(() => {
    if (routeChanged) {
      animate()
      scrollToTop()
      setRouteChanged(false) // Reset routeChanged after handling
    }
  }, [routeChanged])

  // Handle cleanup on unmount
  useEffect(() => {
    return () => {
      if (stopAnimateTimeout.current) {
        clearTimeout(stopAnimateTimeout.current)
      }
      if (animateTimeout.current) {
        clearTimeout(animateTimeout.current)
      }
    }
  }, [])

  // Handle route change detection
  useEffect(() => {
    setRouteChanged(true) // Set to true when location changes
  }, [location.pathname])

  const scrollToTop = () => {
    const scrollToTopBtn = document.getElementById("kt_scrolltop")
    if (scrollToTopBtn) {
      scrollToTopBtn.click()
    }
  }

  const animate = () => {
    animateTimeout.current = setTimeout(() => {
      if (width <= 100) {
        setWidth((prevWidth) => prevWidth + 10)
        animate()
      } else {
        stopAnimate()
      }
    }, 30)
  }

  const stopAnimate = () => {
    clearTimeout(animateTimeout.current)
    stopAnimateTimeout.current = setTimeout(() => {
      setWidth(0)
    }, 300)
  }

  return (
    <div className="header-progress-bar" style={{ height: "3px", width: "100%" }}>
      {width > 0 && width <= 100 && (
        <ProgressBar variant="success" now={width} style={{ height: "3px" }} />
      )}
    </div>
  )
}

export default withRouter(AnimateLoading)