import React from "react"
import { ICON_CONSTANS } from "../../../constants/ImageConstants.js"
import { Button } from "react-bootstrap"

export const FloatingSelectArea = ({
  totalArea,
  totalLandArea,
  buttonlabel,
  handleClick,
  withIcon,
  icon,
  buttonColor,
  frontIcon,
  label
}) => {
  return (
    <div className={`floating-select-area`}>
      <div className={"d-flex justify-content-between align-items-center"}>
        <div className={"d-flex align-items-center"}>
          <img src={ICON_CONSTANS.IC_MAP_WHITE} alt={""} className={"mr-2"} />
          <div className={"color-white d-flex text-lowercase"}>
            {totalArea} {label ? label : "lahan terpilih"}{" "}
            <span className={"font-weight-light ml-1"}>{totalLandArea}</span>
          </div>
        </div>
        <Button
          onClick={handleClick}
          className={"d-flex align-items-center color-white"}
          variant={buttonColor ?? "success"}>
          {frontIcon && frontIcon}
          {buttonlabel}{" "}
          {withIcon && (
            <img src={icon ? icon : ICON_CONSTANS.IC_ARROW_RIGHT_WHITE} className={"ml-2"} />
          )}
        </Button>
      </div>
    </div>
  )
}
