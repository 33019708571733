import React from "react"

function RowCreateColumnCss(children) {
  if (children instanceof Array) {
    if (children.length === 1) {
      return " col-lg-12 col-sm-12 col-md-12"
    }
    if (children.length === 2) {
      return " col-lg-6 col-sm-6 col-md-6"
    }
    if (children.length === 3) {
      return " col-lg-4 col-sm-6 col-md-4"
    }
    if (children.length === 4) {
      return " col-lg-3 col-sm-6 col-md-4"
    }
    if (children.length >= 5) {
      return " col-lg-2 col-sm-4 col-md-4"
    }
  }
  return " col-lg-12 col-sm-12 col-md-12"
}

export function RowModule({
  customColumnCss,
  children,
  withoutSeparator,
  customSeparator,
  ...props
}) {
  let cssColumn = RowCreateColumnCss(children)
  const isArray = children instanceof Array
  if (customColumnCss !== undefined) {
    cssColumn = customColumnCss
  }
  // separator-dashed
  let separatorClass = "separator my-5"
  if (customSeparator !== undefined) {
    separatorClass = customSeparator
  }
  let separator = <div className={separatorClass} />
  if (withoutSeparator !== undefined) {
    if (withoutSeparator) {
      separator = <div className="separator my-2" />
    }
  }
  if (!isArray) {
    return (
      <>
        <div className="row">
          <div className={cssColumn}>{children}</div>
        </div>
        {separator}
      </>
    )
  }
  let renderedOutput = children
  if (isArray) {
    renderedOutput = children.map((item, i) => (
      <div key={i} className={cssColumn}>
        {item}
      </div>
    ))
  }
  return (
    <>
      <div className="row" {...props}>
        {renderedOutput}
      </div>
      {separator}
    </>
  )
}
