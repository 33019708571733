export const PRIVILEGE_ENUM = {
  //farmer
  CREATE_FARMER: "CREATE_FARMER",
  UPDATE_FARMER: "UPDATE_FARMER",
  APPROVAL_FARMER_REGISTRATION: "APPROVAL_FARMER_REGISTRATION",
  LIST_FARMER_PENDING: "LIST_FARMER_PENDING",
  LIST_FARMER_REGISTERED: "LIST_FARMER_REGISTERED",
  LIST_FARMER_REJECTED: "LIST_FARMER_REJECTED",
  DETAIL_FARMER_PENDING: "DETAIL_FARMER_PENDING",
  DETAIL_FARMER_REGISTERED: "DETAIL_FARMER_REGISTERED",
  DETAIL_FARMER_REJECTED: "DETAIL_FARMER_REJECTED",
  FARMER_BULK_INSERT: "FARMER_BULK_INSERT",
  FARMER_LEAD_LIST: "FARMER_LEAD_LIST",
  FARMER_LEAD_DETAIL: "FARMER_LEAD_DETAIL",
  FARMER_LEAD_DELETE: "FARMER_LEAD_DELETE",
  FARMER_LEAD_BULK: "FARMER_LEAD_BULK",
  FARMER_LEAD_CREATE: "FARMER_LEAD_CREATE",
  LIST_FARMER_REGISTERED_TAB_ALL: "LIST_FARMER_REGISTERED_TAB_ALL",
  LIST_FARMER_REGISTERED_TAB_FARMER: "LIST_FARMER_REGISTERED_TAB_FARMER",
  LIST_FARMER_REGISTERED_TAB_FARMER_KUR: "LIST_FARMER_REGISTERED_TAB_FARMER_KUR",

  //mitra
  CREATE_MITRA: "CREATE_MITRA",
  UPDATE_MITRA: "UPDATE_MITRA",
  APPROVAL_MITRA_REGISTRATION: "APPROVAL_MITRA_REGISTRATION",
  LIST_MITRA_PENDING: "LIST_MITRA_PENDING",
  LIST_MITRA_REGISTERED: "LIST_MITRA_REGISTERED",
  LIST_MITRA_REJECTED: "LIST_MITRA_REJECTED",
  DETAIL_MITRA_PENDING: "DETAIL_MITRA_PENDING",
  DETAIL_MITRA_REGISTERED: "DETAIL_MITRA_REGISTERED",
  DETAIL_MITRA_REJECTED: "DETAIL_MITRA_REJECTED",
  LIST_MITRA_REGISTERED_TAB_ALL: "LIST_MITRA_REGISTERED_TAB_ALL",
  LIST_MITRA_REGISTERED_TAB_MITRA: "LIST_MITRA_REGISTERED_TAB_MITRA",
  LIST_MITRA_REGISTERED_TAB_MITRA_KUR: "LIST_MITRA_REGISTERED_TAB_MITRA_KUR",

  //area
  MAP_PREVIEW: "MAP_PREVIEW",
  LIST_AREA_REGISTERED: "LIST_AREA_REGISTERED",
  AREA_DETAIL_REGISTERED: "AREA_DETAIL_REGISTERED",
  DETAIL_AREA_PENDING: "DETAIL_AREA_PENDING",
  DETAIL_AREA_PENDING_UNASSIGNED: "DETAIL_AREA_PENDING_UNASSIGNED",
  DETAIL_AREA_REJECT: "DETAIL_AREA_REJECT",
  LIST_AREA_PENDING: "LIST_AREA_PENDING",
  APPROVAL_AREA_REGISTRATION: "APPROVAL_AREA_REGISTRATION",
  LIST_AREA_REJECT: "LIST_AREA_REJECT",
  LIST_AREA_LEAD: "LIST_AREA_LEAD",
  DETAIL_AREA_LEAD: "DETAIL_AREA_LEAD",
  DETAIL_AREA_UNASSIGNED: "DETAIL_AREA_UNASSIGNED",
  LIST_AREA_UNASSIGNED: "LIST_AREA_UNASSIGNED",
  EDIT_PLANT_EVENT: "EDIT_PLANT_EVENT",
  LIST_AREA_ACTIVITY: "LIST_AREA_ACTIVITY",
  TAB_ACTIVITY: "TAB_ACTIVITY",
  DETAIL_ACTIVITY: "DETAIL_ACTIVITY",
  HARVEST_CONFIRMATION: "HARVEST_CONFIRMATION",
  TAB_ACTIVITY_HISTORY: "TAB_ACTIVITY_HISTORY",
  DETAIL_PLANTING_SEASON: "DETAIL_PLANTING_SEASON",
  HARVEST_TOTAL: "HARVEST_TOTAL",
  TOTAL_INCOME: "TOTAL_INCOME",
  DETAIL_ACTIVITY_SEEDLING: "DETAIL_ACTIVITY_SEEDLING",
  DETAIL_ACTIVITY_TILLAGE: "DETAIL_ACTIVITY_TILLAGE",
  DETAIL_ACTIVITY_PLANTING: "DETAIL_ACTIVITY_PLANTING",
  DETAIL_ACTIVITY_FERTILIZATION: "DETAIL_ACTIVITY_FERTILIZATION",
  DETAIL_ACTIVITY_SPRAYING: "DETAIL_ACTIVITY_SPRAYING",
  DETAIL_ACTIVITY_HARVEST_PREDICTION: "DETAIL_ACTIVITY_HARVEST_PREDICTION",
  DETAIL_ACTIVITY_HARVEST: "DETAIL_ACTIVITY_HARVEST",
  DETAIL_ACTIVITY_SPRAYING_PREPLANTING: "DETAIL_ACTIVITY_SPRAYING_PREPLANTING",
  DETAIL_ACTIVITY_HARVEST_FAILURE: "DETAIL_ACTIVITY_HARVEST_FAILURE",
  LIST_AREA_REGISTERED_TAB_ALL: "LIST_AREA_REGISTERED_TAB_ALL",
  LIST_AREA_REGISTERED_TAB_AREA: "LIST_AREA_REGISTERED_TAB_AREA",
  LIST_AREA_REGISTERED_TAB_AREA_KUR: "LIST_AREA_REGISTERED_TAB_AREA_KUR",
  LIST_AREA_ACTIVITY_TAB_ALL: "LIST_AREA_ACTIVITY_TAB_ALL",
  LIST_AREA_ACTIVITY_TAB_ACTIVITY: "LIST_AREA_ACTIVITY_TAB_ACTIVITY",
  LIST_AREA_ACTIVITY_TAB_ACTIVITY_KUR: "LIST_AREA_ACTIVITY_TAB_ACTIVITY_KUR",

  //fo
  LIST_FIELD_OFFICER: "LIST_FIELD_OFFICER",
  DETAIL_FIELD_OFFICER: "DETAIL_FIELD_OFFICER",
  ASSIGN_FIELD_OFFICER_TO_MITRA: "ASSIGN_FIELD_OFFICER_TO_MITRA",
  CREATE_FIELD_OFFICER: "CREATE_FIELD_OFFICER",
  EDIT_FIELD_OFFICER: "EDIT_FIELD_OFFICER",
  LIST_FIELD_OFFICER_ASSIGN: "LIST_FIELD_OFFICER_ASSIGN",
  // new
  // FO AGRONOMIS
  LIST_FIELD_OFFICER_AGRONOMIST: "LIST_FIELD_OFFICER_AGRONOMIST",
  LIST_FIELD_OFFICER_AGRONOMIST_TAB_LIST_FO: "LIST_FIELD_OFFICER_AGRONOMIST_TAB_LIST_FO",
  LIST_FIELD_OFFICER_AGRONOMIST_TAB_TEAM_DRONE: "LIST_FIELD_OFFICER_AGRONOMIST_TAB_TEAM_DRONE",
  LIST_FIELD_OFFICER_AGRONOMIST_TAB_JADWAL_TEAM: "LIST_FIELD_OFFICER_AGRONOMIST_TAB_JADWAL_TEAM",
  CREATE_AGRONOMIST_TEAM: "CREATE_AGRONOMIST_TEAM",
  EDIT_AGRONOMIST_TEAM: "EDIT_AGRONOMIST_TEAM",
  DETAIL_FIELD_OFFICER_AGRONOMIST: "DETAIL_FIELD_OFFICER_AGRONOMIST",
  DETAIL_TEAM_FIELD_OFFICER_AGRONOMIST: "DETAIL_TEAM_FIELD_OFFICER_AGRONOMIST",
  // FO COORDINATOR
  LIST_FIELD_OFFICER_COORDINATOR: "LIST_FIELD_OFFICER_COORDINATOR",
  DETAIL_FIELD_OFFICER_COORDINATOR: "DETAIL_FIELD_OFFICER_COORDINATOR",
  // FO HARVEST
  LIST_FIELD_OFFICER_HARVEST: "LIST_FIELD_OFFICER", // TODO : CHANGE ENUM PRIVILEGE
  DETAIL_FIELD_OFFICER_HARVEST: "DETAIL_FIELD_OFFICER_AGRONOMIST",
  //FO QUALITY ASSURANCE
  LIST_FIELD_OFFICER_QUALITY_ASSURANCE: "LIST_FIELD_OFFICER_QUALITY_ASSURANCE",
  DETAIL_FIELD_OFFICER_QUALITY_ASSURANCE: "DETAIL_FIELD_OFFICER_QUALITY_ASSURANCE",

  //CONFIDENTIAL_DATA
  FARMER_PHONE_NUMBER: "FARMER_PHONE_NUMBER",
  FARMER_KTP_NUMBER: "FARMER_KTP_NUMBER",
  FARMER_KTP_PICTURE: "FARMER_KTP_PICTURE",
  FARMER_KTP_SELFIE_PICTURE: "FARMER_KTP_SELFIE_PICTURE",
  FARMER_BANK_ACCOUNT_NO: "FARMER_BANK_ACCOUNT_NO",

  MITRA_PHONE_NUMBER: "MITRA_PHONE_NUMBER",
  MITRA_KTP_NUMBER: "MITRA_KTP_NUMBER",
  MITRA_KTP_PICTURE: "MITRA_KTP_PICTURE",
  MITRA_KTP_SELFIE_PICTURE: "MITRA_KTP_SELFIE_PICTURE",
  MITRA_BANK_ACCOUNT_NO: "MITRA_BANK_ACCOUNT_NO",
  AREA_CERTIFICATE: "AREA_CERTIFICATE",

  //setting
  LIST_ROLE: "LIST_ROLE",
  EDIT_ROLE_PRIVILEGE: "EDIT_ROLE_PRIVILEGE",
  RESET_USER_PASSWORD: "RESET_USER_PASSWORD",
  LIST_VIDEO: "LIST_VIDEO",
  INSERT_VIDEO: "INSERT_VIDEO",
  DETAIL_VIDEO: "DETAIL_VIDEO",
  UPDATE_VIDEO: "UPDATE_VIDEO",
  DELETE_VIDEO: "DELETE_VIDEO",
  CREATE_USER_CMS: "CREATE_USER_CMS",
  EDIT_USER_CMS: "EDIT_USER_CMS",
  DETAIL_USER_CMS: "DETAIL_USER_CMS",
  LIST_USER_CMS: "LIST_USER_CMS",
  BROADCAST_MESSAGE: "BROADCAST_MESSAGE",
  BROADCAST_MESSAGE_DETAIL: "BROADCAST_MESSAGE_DETAIL",
  BROADCAST_MESSAGE_REMOVE: "BROADCAST_MESSAGE_REMOVE",
  BROADCAST_MESSAGE_CREATE: "BROADCAST_MESSAGE_CREATE",

  //master
  CATEGORY_ITEM: "CATEGORY_ITEM",
  CATEGORY_ITEM_DETAIL: "CATEGORY_ITEM_DETAIL",
  MODIFIED_CATEGORY_ITEM: "MODIFIED_CATEGORY_ITEM",
  TREATMENT_LIST: "TREATMENT_LIST",
  TREATMENT_DETAIL: "TREATMENT_DETAIL",
  MODIFIED_TREATMENT: "MODIFIED_TREATMENT",
  TREATMENT_NEW: "TREATMENT_NEW",
  PACKAGE_LIST: "PACKAGE_LIST",
  PACKAGE_DETAIL: "PACKAGE_DETAIL",
  PACKAGE_NEW: "PACKAGE_NEW",
  MODIFIED_PACKAGE: "MODIFIED_PACKAGE",
  MANAGE_REASON_LIST: "MANAGE_REASON_LIST",
  MANAGE_REASON_DETAIL: "MANAGE_REASON_DETAIL",
  MODIFIED_MANAGE_REASON: "MODIFIED_MANAGE_REASON",
  LIST_PLANT_TYPE: "MODIFIED_MANAGE_REASON",
  EDIT_PLANT_TYPE: "MODIFIED_MANAGE_REASON",
  DETAIL_PLANT_TYPE: "MODIFIED_MANAGE_REASON",
  SUB_DETAIL_PLANT_TYPE: "MODIFIED_MANAGE_REASON",

  // ORDER
  LIST_ORDER_ALL: "LIST_ORDER_ALL",
  LIST_ORDER_APPROVE: "LIST_ORDER_APPROVE",
  LIST_ORDER_PENDING: "LIST_ORDER_PENDING",
  LIST_ORDER_RESCHEDULED: "LIST_ORDER_RESCHEDULED",
  DETAIL_ORDER: "DETAIL_ORDER",
  APPROVAL_ORDER: "APPROVAL_ORDER",
  EDIT_ORDER: "EDIT_ORDER",
  REJECT_ORDER: "REJECT_ORDER",
  CANCEL_ORDER: "CANCEL_ORDER",

  // SUBMISSION
  CERTIFICATION_LIST_SUBMISSION_ALL: "CERTIFICATION_LIST_SUBMISSION_ALL",
  CERTIFICATION_DETAIL_SUBMISSION: "CERTIFICATION_DETAIL_SUBMISSION",
  CERTIFICATION_LIST_SUBMISSION_PENDING: "CERTIFICATION_LIST_SUBMISSION_PENDING",
  CERTIFICATION_LIST_SUBMISSION_SUBMITTED: "CERTIFICATION_LIST_SUBMISSION_SUBMITTED",
  CERTIFICATION_LIST_SUBMISSION_REJECTED: "CERTIFICATION_LIST_SUBMISSION_REJECTED",
  CERTIFICATION_LIST_SUBMISSION_APPROVED: "CERTIFICATION_LIST_SUBMISSION_APPROVED",
  CERTIFICATION_DOWNLOAD_SUBMISSION: "CERTIFICATION_DOWNLOAD_SUBMISSION",
  CERTIFICATION_CREATE_SUBMISSION: "CERTIFICATION_CREATE_SUBMISSION",
  CERTIFICATION_CANCEL_SUBMISSION: "CERTIFICATION_CANCEL_SUBMISSION",

  // MAC
  DETAIL_ACTIVITY_REPORT_MAC: "CANCEL_ORDER"
}

export const PRIVILEGE_PARENT_ENUM = {
  FARMER: "FARMER",
  MITRA: "MITRA",
  FO: "FO",
  AREA: "AREA",
  ORDER: "ORDER",
  SUBMISSION: "SUBMISSION",
  MAC: "MAC",
  SETTING: "SETTING",
  MASTER: "MASTER"
}