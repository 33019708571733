import { createSlice } from "@reduxjs/toolkit"
import { initialFilterTable } from "../../component/atoms/Tabel"

const initMasterData = {
  bankList: [],
  provinceList: [],
  regencyList: [],
  districtList: [],
  subDistrictList: [],
  statusAccountList: [],
  plantList: [],
  varietyList: [],
  categoryVideoList: [],
  urlResponseUpload: null,
  uploadType: null,
  plantType: [],
  plantForm: [],
  ownerList: [],
  categoryList: [],
  pageData: [],
  page: initialFilterTable,
  data: undefined,
  variantList: [],
  machineType: [],
  categoryReason: [],
  detailReason: [],
  treatmentList: [],
  treatmentDetail: undefined,
  deleteReason: [],
  activityTypeList: [],
  statusActivity: [],
  reasonList: [],
  pestTypeList: [],
  legendMap: [],
  userMerchantList: [],
  plantTypeUser: [],
  listAvailableProvince: [],
  selectedRegionalEdit: undefined,
  listRegionalSelected: [],
  listRegionalAll: [],
  detailRegional: undefined,
  listAvailableRegencyRegional: [],
  messages: null,
  detailSubRegional: undefined
}

export const MasterDataSlice = createSlice({
  name: "masterData",
  initialState: initMasterData,
  reducers: {
    uploadData: (state, action) => {
      state.urlResponseUpload = action.payload.url
      state.uploadType = action.payload.uploadType
    },
    setBankList: (state, action) => {
      state.bankList = action.payload
    },
    setStatusAccountList: (state, action) => {
      state.statusAccountList = action.payload
    },
    setProvinceList: (state, action) => {
      state.provinceList = action.payload
    },
    setRegencyList: (state, action) => {
      state.regencyList = action.payload
    },
    setDistrictList: (state, action) => {
      state.districtList = action.payload
    },
    setSubDistrictList: (state, action) => {
      state.subDistrictList = action.payload
    },
    setPlantList: (state, action) => {
      state.plantList = action.payload
    },
    setVarietyList: (state, action) => {
      state.varietyList = action.payload
    },
    setCategoryVideoList: (state, action) => {
      state.categoryVideoList = action.payload
    },
    resetZones: (state) => {
      state.regencyList = []
      state.districtList = []
      state.subDistrictList = []
    },
    setPlantType: (state, action) => {
      state.plantType = action.payload
    },
    setMachineType: (state, action) => {
      state.machineType = action.payload
    },
    setPlantForm: (state, action) => {
      state.plantForm = action.payload
    },
    setOwnerList: (state, action) => {
      state.ownerList = action.payload
    },
    setCategoryList: (state, action) => {
      state.categoryList = action.payload
    },
    setPageData: (state, action) => {
      const { pageData, page } = action.payload
      state.pageData = pageData
      state.page = page
    },
    getPage: (state, action) => {
      state.data = action.payload
    },
    setVariantList: (state, action) => {
      state.variantList = action.payload
    },
    setCategoryReason: (state, action) => {
      state.categoryReason = action.payload
    },
    setDetailReason: (state, action) => {
      state.detailReason = action.payload
    },
    setDeleteReason: (state, action) => {
      state.deleteReason = action.payload
    },
    setTreatmentList: (state, action) => {
      state.treatmentList = action.payload
    },
    setTreatmentDetail: (state, action) => {
      state.treatmentDetail = action.payload
    },
    setActivityTypeList: (state, action) => {
      state.activityTypeList = action.payload
    },
    setStatusActivity: (state, action) => {
      state.statusActivity = action.payload
    },
    setReasonList: (state, action) => {
      state.reasonList = action.payload
    },
    setPestTypeList: (state, action) => {
      state.pestTypeList = action.payload
    },
    resetUrlResponse: (state) => {
      state.urlResponseUpload = null
      state.uploadType = null
    },
    setLegendMap: (state, action) => {
      state.legendMap = action.payload
    },
    setUserMerchantList: (state, action) => {
      state.userMerchantList = action.payload
    },
    setPlantTypeUser: (state, action) => {
      state.plantTypeUser = action.payload
    },
    setListAvailableProvince: (state, actions) => {
      state.listAvailableProvince = actions.payload
    },
    setSelectedRegionalEdit: (state, actions) => {
      state.selectedRegionalEdit = actions.payload
    },
    setListRegionalSelected: (state, action) => {
      state.listRegionalSelected = action.payload
    },
    setListRegionalAll: (state, action) => {
      state.listRegionalAll = action.payload
    },
    setDataDetailRegional: (state, action) => {
      state.detailRegional = action.payload
    },
    setListAvailableRegency: (state, action) => {
      state.listAvailableRegencyRegional = action.payload
    },
    setMessages: (state, action) => {
      state.messages = action.payload
    },
    setDetailSubRegional: (state, action) => {
      state.detailSubRegional = action.payload
    }
  }
})
