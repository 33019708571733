import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  resetUrlResponse,
  uploadAction
} from "../../../../../../redux/actions/MasterDataAction.jsx"
import {
  Card,
  CardBody,
  CardHeaderTitle
} from "../../../../../../_metronic/_partials/controls/index.js"
import { RowModule } from "../../../../../../component/atoms/RowModule/RowModule.jsx"
import { TextInput, UploadComponent } from "../../../../../../component/index.jsx"
import { UploadIcon } from "../../../../../../component/atoms/Icons/SVG/upload.jsx"

export const KtpSwafotoFarmer = ({ isReadOnly }) => {
  const { MasterData } = useSelector((state) => state)
  const dispatch = useDispatch()

  function onChangeFileKtp(e) {
    dispatch(uploadAction({ data: e, type: "KTP" }))
  }

  function onChangeFileSwafoto(e) {
    dispatch(uploadAction({ data: e, type: "SELFIE" }))
  }

  const [urlKtp, setUrlKtp] = useState("")
  const [swafoto, setSwafoto] = useState("")

  useEffect(() => {
    if (MasterData.urlResponseUpload) {
      if (MasterData.uploadType === "KTP") {
        setUrlKtp(MasterData.urlResponseUpload)
      } else if (MasterData.uploadType === "SELFIE") {
        setSwafoto(MasterData.urlResponseUpload)
      }
    }
    return () => {
      dispatch(resetUrlResponse())
    }
  }, [MasterData.urlResponseUpload, MasterData.uploadType])

  const placeholder = <UploadIcon />

  return (
    <>
      <Card className="card-custom card-shadowless">
        <CardBody>
          <RowModule>
            <CardHeaderTitle className={"my-12"}>
              {"KTP & SWAFOTO DENGAN KTP PETANI".toUpperCase()}
            </CardHeaderTitle>
          </RowModule>

          <RowModule>
            <TextInput
              editable={!isReadOnly}
              label="Nomor KTP"
              required
              type="number"
              name="nomorKtp"
              placeholder="Input Nomor KTP"
            />
          </RowModule>
          <RowModule>
            <div className={"w-100 position-relative"}>
              <UploadComponent
                required
                urlParsing={urlKtp}
                name="fotoKtp"
                label="Foto KTP"
                editable={!isReadOnly}
                onChange={onChangeFileKtp}
                placeholder={placeholder}
              />
            </div>
          </RowModule>
          <RowModule>
            <div className={"w-100 position-relative"}>
              <UploadComponent
                urlParsing={swafoto}
                required
                name="swafoto"
                label="Swafoto Dengan KTP"
                editable={!isReadOnly}
                onChange={onChangeFileSwafoto}
                placeholder={placeholder}
              />
            </div>
          </RowModule>
        </CardBody>
      </Card>
    </>
  )
}