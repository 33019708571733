import { ROLE } from "../../constants/InitTypeConstants"
import { requestGet, requestPost } from "../../config"
import { ROLE_URL } from "../../constants/UrlConstants"
import { GeneralSlice } from "../reducers/General"
import { RoleSlice } from "../reducers/Role"
import { getUrlParsingPageFilter, getUrlParsingRole } from "../../config/EndpointCollection.js"

const { actions: general } = GeneralSlice
const { actions: role } = RoleSlice

export const getListRole = (param) => {
  let urlData = getUrlParsingPageFilter(param.pageReq)
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: ROLE.LIST
      })
    )
    try {
      let res = await requestGet(ROLE_URL.GET_LIST + urlData)
      dispatch(role.getRole(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: ROLE.LIST
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getListRoleFo = (param) => {
  let urlData = getUrlParsingPageFilter(param.pageReq)
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: ROLE.LIST_FO
      })
    )
    try {
      let res = await requestGet(ROLE_URL.GET_LIST + urlData)
      dispatch(role.getRoleFo(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: ROLE.LIST_FO
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getListRoleByParam = (param) => {
  return async (dispatch) => {
    let urlData = getUrlParsingRole(param)
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: ROLE.LIST
      })
    )
    try {
      let res = await requestGet(ROLE_URL.GET_LIST + urlData)
      dispatch(role.getRole(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: ROLE.LIST
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const fetchPrivilege = (param) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: ROLE.GET_PRIVILEGE
      })
    )
    try {
      let res = await requestGet(ROLE_URL.GET_LIST_PRIVILEGE + param)
      dispatch(role.getPrivilege(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: ROLE.GET_PRIVILEGE
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const fetchPrivilegeByRole = (param) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: ROLE.GET_PRIVILEGE
      })
    )
    try {
      let res = await requestGet(ROLE_URL.GET_LIST_PRIVILEGE_BY_ROLE + param)
      dispatch(role.getPrivilegeById(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: ROLE.GET_PRIVILEGE
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const assignPrivilegeToRole = (data) => {
  return async (dispatch) => {
    if (data) {
      dispatch(
        general.setLoadingGlobal({
          loading: true,
          actionInitType: ROLE.ASSIGN_PRIVILEGE
        })
      )
      try {
        await requestPost(ROLE_URL.ASSIGN_PRIVILEGE_TO_ROLE, data)
        dispatch(
          general.setLoadingGlobal({
            loading: false,
            actionInitType: ROLE.ASSIGN_PRIVILEGE
          })
        )
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: ROLE.ASSIGN_PRIVILEGE
          })
        )
      } catch (e) {
        dispatch(general.setError(e))
      }
    }
  }
}