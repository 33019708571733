import React from "react"
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap"
import TableView, { formatterRowComponent } from "../../../../../../component/atoms/Tabel/index.jsx"
import { Card, CardHeader, CardBody } from "../../../../../../_metronic/_partials/controls/index.js"
import { EditSvgIcon, DeleteSvgIcon } from "../../../../../../component/atoms/Icons/SVG/index.jsx"
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers/index.js"
import { PopupQuestion } from "../../../../../../component/atoms/Popup/index.jsx"
import { useState } from "react"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  deleteReason,
  getDetailReason,
  updateReason
} from "../../../../../../redux/actions/MasterDataAction.jsx"
import swal from "sweetalert"
import { loadUserLogin } from "../../../../../../config/index.jsx"
import * as Yup from "yup"
import { useFormik } from "formik"
import {
  FORM_NEED,
  getMaximumErrorString,
  getStringErrorMassage
} from "../../../../../helpers/StringCollection.js"
import { Skeleton } from "@material-ui/lab"
import { TableSkeleton } from "../../../../../../component/index.jsx"
import { PRIVILEGE_ENUM } from "../../../../../../constants/PrivilegeConstants.js"
import { ILUSTRATION_CONSTANS } from "../../../../../../constants/ImageConstants.js"

export const TableDetail = ({
  title,
  data,
  general,
  history,
  setSwalMessage,
  swalMessage,
  swalIcon,
  setSwalIcon
}) => {
  const dispatch = useDispatch()
  const [showDelete, setShowDelete] = useState(false)
  const [idReason, setIdReason] = useState("")
  const [showEditReason, setShowEditReason] = useState(false)
  const [recentReason, setRecentReason] = useState("")
  const { General } = useSelector((state) => state)
  const privileges = loadUserLogin().privilege

  const formik = useFormik(
    {
      initialValues: {
        edit_reason: ""
      },
      validationSchema: Yup.object({
        edit_reason: Yup.string()
          .required(getStringErrorMassage("Reason", FORM_NEED.harusDiIsi))
          .max(100, getMaximumErrorString(100))
          .matches("^.*$", "Tidak Boleh di Enter")
      })
    },
    [recentReason]
  )

  useEffect(() => {
    if (General.isFinishUpdateOrDelete) {
      if (swalMessage) {
        swal({
          icon: swalIcon,
          text: swalMessage
        })
      }
      history.push("/master/manage-reason/unpackage_reason")
      setShowEditReason(false)
      setShowDelete(false)
      dispatch(getDetailReason())
    }
  }, [General.isFinishUpdateOrDelete])

  const submitDelete = () => {
    setSwalMessage("Reason berhasil di hapus")
    setSwalIcon(toAbsoluteUrl(ILUSTRATION_CONSTANS.SUCCESS_FORM))
    dispatch(deleteReason(idReason))
  }

  const submitUpdate = () => {
    setSwalMessage("Reason berhasil di perbaharui")
    setSwalIcon(toAbsoluteUrl(ILUSTRATION_CONSTANS.SUCCESS_FORM))
    const formData = {
      reason_name: formik.values.edit_reason
    }
    dispatch(updateReason(formData, idReason))
  }

  const uiName = (e) => {
    return (
      <>
        <p className="text-dark font-weight-bolder mb-1 font-size-lg">{e?.name ?? "-"}</p>
      </>
    )
  }

  const uiAction = (e) => {
    return (
      <>
        {privileges.indexOf(PRIVILEGE_ENUM.MODIFIED_MANAGE_REASON) > -1 && (
          <>
            <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Edit</Tooltip>}>
              <a
                onClick={() => {
                  setShowEditReason(true)
                  formik.setFieldValue("edit_reason", e?.name)
                  setRecentReason(e?.name)
                  setIdReason(e?.id)
                }}
                className="btn btn-icon  btn-hover-secondary btn-sm mx-3">
                <EditSvgIcon></EditSvgIcon>
              </a>
            </OverlayTrigger>
            <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Hapus</Tooltip>}>
              <a
                onClick={() => {
                  setShowDelete(true)
                  setIdReason(e?.id)
                }}
                className="btn btn-icon  btn-hover-primary btn-sm mx-3">
                <DeleteSvgIcon></DeleteSvgIcon>
              </a>
            </OverlayTrigger>
          </>
        )}
      </>
    )
  }
  const columns = [
    {
      dataField: "manageReason_name",
      text: "Reason",
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiName
      }
    },
    {
      dataField: "action",
      text: "ACTION",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiAction
      },
      headerStyle: () => {
        return { width: "15%" }
      }
    }
  ]

  return (
    <>
      <PopupQuestion
        disable={
          (formik.errors.edit_reason && true) ||
          (recentReason === formik.values.edit_reason && true)
        }
        onCancel={() => setShowEditReason(false)}
        title={"EDIT REASON"}
        onOk={submitUpdate}
        show={showEditReason}
        bodyCustom={
          <Form.Group className="mb-3">
            <Form.Label>Silahkan edit reason</Form.Label>
            <Form.Control
              as="textarea"
              onKeyPress={(e) => {
                e.key === "Enter" && e.preventDefault()
              }}
              name="edit_reason"
              className={formik.errors.edit_reason && "is-invalid"}
              rows={3}
              value={formik.values.edit_reason}
              onChange={formik.handleChange}
              placeholder="Input reason"
            />
            {formik.errors.edit_reason && (
              <div className="text-danger">{formik.errors.edit_reason}</div>
            )}
          </Form.Group>
        }
      />
      <PopupQuestion
        show={showDelete}
        onCancel={() => setShowDelete(false)}
        onOk={submitDelete}
        title={"HAPUS REASON"}
        bodyCustom={
          <div className="text-center">
            <span>Data reason yang di hapus akan </span>
            <span className="font-weight-bold">hilang</span>
          </div>
        }
      />

      <Card className="card-shadowless">
        <CardBody>
          <CardHeader
            title={
              General.loading ? (
                <Skeleton animation="wave" variant={"text"} height={50} width={200} />
              ) : (
                title
              )
            }
            className="px-0 border-0 capitalize"
          />
          {General.loading ? (
            <TableSkeleton column={3} row={10} />
          ) : (
            <TableView
              columnProperties={columns}
              dataTable={data}
              loadingGetData={general.loading}
              currentPage={null}
              currentElement={null}
              totalCount={null}
              showingTotalPage={false}
            />
          )}
        </CardBody>
      </Card>
    </>
  )
}