import { ENDPOINT } from "../../config/EndpointCollection.js"
import axios from "./baseAxiosAction"
import { PrivilegeCollection, PrivilegeCollectionParent } from "../../config/PrivilegeCollection.js"

export function fetchLogin(email, password) {
  return (dispatch) => {
    axios
      .post(ENDPOINT.userSetting.login, {
        email: email,
        password: password
      })
      .then(({ data }) => {
        var userData = data
        localStorage.setItem("userLogin", JSON.stringify(userData))
        localStorage.setItem("jwt_token", data.data.jwt_token)
        dispatch({ type: "USER_LOGIN", payload: userData })
      })
      .catch(() => {
        alert("Login Gagal")
      })
  }
}

export function loadUserLogin() {
  return JSON.parse(localStorage.getItem("userLogin"))
}

export const PrivilegeAll = () => {
  let listPrivilege = []
  listPrivilege = loadUserLogin().privilege
  return PrivilegeCollection.filter(
    (value) => value.enum.filter((value1) => listPrivilege.includes(value1)).length > 0
  )
}

export const PrivilegeSelected = (parent) => {
  let listPrivilege = []
  listPrivilege = loadUserLogin().privilege
  const listByParent = PrivilegeCollection.filter((value) => value.parent === parent)
  return listByParent
    .filter((value) => value.enum.filter((value1) => listPrivilege.includes(value1)).length > 0)
    .sort((a, b) =>
      a.indexMenu || b.indexMenu
        ? a.indexMenu > b.indexMenu
          ? 1
          : b.indexMenu > a.indexMenu
          ? -1
          : 0
        : 1
    )
}

export const isParentHaveChild = (parent) => {
  return PrivilegeSelected(parent).length > 0
}

export const PrivilegeSelectedParent = () => {
  const listPrivilege = PrivilegeAll()
  return PrivilegeCollectionParent.filter((value) => {
    return listPrivilege.filter((value1) => value.parent === value1.parent).length > 0
  })
}

export const addLinkParent = () => {
  const listParentSelected = PrivilegeSelectedParent()
  let navLink = []
  let parentPrev = ""
  listParentSelected.map((value) => {
    PrivilegeSelected(value.parent).map((value2) => {
      if (value2.navigateLink != null) {
        if (parentPrev != value2.parent) {
          navLink.push(value2.navigateLink)
        }
        parentPrev = value2.parent
      }
    })
  })

  let newList = []
  listParentSelected.map((value, index) => {
    newList.push({
      ...value,
      navigateLink: navLink[index]
    })
  })
  return newList
}

export const selectTitleSubMenu = (parent) => {
  const dataParent = PrivilegeSelectedParent()
  const result = dataParent.filter((value) => value.parent === parent)
  return result
}

export const haveAccess = (privilegeEnum) => {
  let listPrivilege = []
  listPrivilege = loadUserLogin().privilege
  let isHave = false
  listPrivilege.forEach((value) => {
    if (value == privilegeEnum) {
      isHave = true
    }
  })
  return isHave
}
export const hasAccessUrl = (urlParsing) => {
  let dataPriv = PrivilegeCollection.find((value) => value.url == urlParsing)
  if (dataPriv) {
    return haveAccess(dataPriv.enum)
  } else {
    return true
  }
}

export function getUserToken() {
  return localStorage.getItem("jwt_token")
}

export const listMenuAdd = () => {
  const listPrivilege = PrivilegeAll()
  return listPrivilege.filter((value) => value.parent === "ADD")
}

export const getUserRegional = () => {
  return localStorage.getItem("regional_id")
}

export const getUserProvince = () => {
  return localStorage.getItem("province_id")
}