import React, { useEffect, useState } from "react"
import { useSubheader } from "../../../../../_metronic/layout"
import { Button, Card } from "react-bootstrap"
import { CardBody } from "../../../../../_metronic/_partials/controls"
import { TableDetailDroneTeam } from "../TableTeamSchedule"
import { Popup } from "../../../../../component/atoms/Popup"
import { BigCalendar } from "../../../../../component"
import { toStringDateLocale } from "../../../../helpers/DateHelper"
import SVG from "react-inlinesvg"
import { ICON_CONSTANS } from "../../../../../constants/ImageConstants"
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers"
import { useDispatch, useSelector } from "react-redux"
import {
  clearCalendarActions,
  GetDetailCalendarTeamDrone,
  GetDetailHeaderTeamDrone,
  GetListDetailTeamDroneCalendar
} from "../../../../../redux/actions/FieldOfficerAction"
import { useLocation, useParams } from "react-router"
import { Skeleton } from "@material-ui/lab"
import { useUrlParam } from "../../../../hooks/useUrlParams"
import { useHistory } from "react-router-dom"
import { format } from "date-fns"
import { toThousandConvert } from "../../../../helpers/TextHelper"
import { RoutesConstants } from "../../../../../constants/RoutesConstants"
import { FIELD_OFFICER_ROLE_ENUM, PLANT_TYPE_ENUM } from "../../../../../constants/StringConstant"

export const DetailDroneTeam = () => {
  const [showModalPopup, setShowModalPopup] = useState(false)
  const [dateCalendar, setDateCalendar] = useState(undefined)
  const [currentDateCalendar, setCurrentDateCalendar] = useState(new Date())
  const [data, setData] = useState({})
  const [dataCalendar, setDataCalendar] = useState(undefined)
  const [loadingDetailPopup, setLoadingDetailPopup] = useState(true)
  const [dataPopup, setDataPopup] = useState(undefined)
  const { FieldOfficer, General } = useSelector((state) => state)

  const urlParams = useUrlParam()
  const history = useHistory()
  const location = useLocation()
  const subHeader = useSubheader()
  const dispatch = useDispatch()
  const { id } = useParams()

  useEffect(() => {
    dispatch(GetDetailHeaderTeamDrone(id))
    dispatch(clearCalendarActions())
  }, [])

  useEffect(() => {
    if (FieldOfficer?.detail) {
      setData(FieldOfficer?.detail)
    }
    if (FieldOfficer?.calendarDetail) {
      const result = FieldOfficer?.calendarDetail
      if (result?.length > 0) {
        setDataCalendar(result)
      } else {
        setDataCalendar([])
      }
    } else {
      setDataCalendar([])
    }
    if (FieldOfficer?.detailCalendarLoading) {
      setLoadingDetailPopup(true)
    } else {
      setLoadingDetailPopup(false)
    }
    if (FieldOfficer?.calendarList) {
      setDataPopup(FieldOfficer?.calendarList)
    }
  }, [FieldOfficer])

  useEffect(() => {
    const paramList = urlParams.stringifyObj({
      start_date: format(currentDateCalendar, "yyyy-MM-dd")
    })
    history.push({ pathname: location.pathname, search: paramList })
    dispatch(GetDetailCalendarTeamDrone(id, paramList))
  }, [currentDateCalendar])

  const BtnEdit = (
    <>
      <Button
        variant="outline-primary"
        onClick={() => {
          history.push(RoutesConstants.FIELD_OFFICER.EDIT_TEAM_DRONE("edit", id))
        }}>
        Edit
      </Button>
    </>
  )

  const onClickPopup = (e) => {
    setDateCalendar(e)
  }

  const onChangeCalendar = (e) => {
    setCurrentDateCalendar(e)
  }

  useEffect(() => {
    if (dateCalendar) {
      const paramDetail = urlParams.stringifyObj({
        date: format(dateCalendar, "yyyy-MM-dd")
      })
      dispatch(GetListDetailTeamDroneCalendar(id, paramDetail))
    }
  }, [dateCalendar])

  useEffect(() => {
    subHeader.setBreadcrumbs([
      {
        pathname: RoutesConstants.FIELD_OFFICER.LIST_FO(FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER),
        title: "FO"
      },
      {
        pathname: RoutesConstants.FIELD_OFFICER.LIST_FO_AGRONOMIS("list-fo"),
        title: "FO Agronomist"
      },
      {
        pathname: RoutesConstants.FIELD_OFFICER.LIST_FO_AGRONOMIS("team-drone"),
        title: "Tim Drone"
      },
      {
        title: data?.name ?? ""
      }
    ])
    subHeader.setButton(BtnEdit)
  }, [data])

  useEffect(() => {}, [dataCalendar])

  const BodyModal = (
    <div>{data?.member_list && <TableDetailDroneTeam data={data?.member_list} />}</div>
  )

  const BodyPopup = (
    <div>
      <div className={"w-100"}>
        <div className={"text-center mb-2"}>
          {loadingDetailPopup ? (
            <div className={"d-flex  justify-content-center"}>
              <div className={"w-50 d-flex flex-column mb-5"} style={{ gap: 5 }}>
                <Skeleton variant="rect" height={20} animation={"wave"} />
                <Skeleton variant="rect" height={10} animation={"wave"} />
              </div>
            </div>
          ) : (
            <>
              <h3>{toStringDateLocale(dataPopup?.date)}</h3>
              <div>
                Total Luas Pengerjaan : {toThousandConvert(dataPopup?.total_worked_area)} m²
                (Manual)
              </div>
            </>
          )}
        </div>
        <div className={"custom_height_calender_overflow"} style={{ overflowY: "auto" }}>
          {!loadingDetailPopup &&
            dataPopup?.area_list &&
            dataPopup?.area_list.map((item, index) => (
              <div
                key={index}
                className={"mt-6 rounded px-2 d-flex flex-column justify-content-start py-2"}
                style={{ background: "#F8F8F8" }}>
                <div>
                  <div className={"d-flex items-start mb-2 p-1 w-100"}>
                    <div className={"bg-primary calendar_data_badge"}></div>
                    <div className={"font-weight-bolder ml-4"}>{item?.area_name ?? "-"}</div>
                  </div>

                  <div className={"w-100 d-flex justify-content-between pr-5"}>
                    <div className={"d-flex flex-column justify-content-center mr-2"}>
                      <div className={"d-flex align-items-center"}>
                        <SVG src={toAbsoluteUrl(ICON_CONSTANS.IC_FARMER_GRAY)} />
                        <div className={"ml-3"}>{item.farmer_code}</div>
                      </div>
                      <div>{item?.requester_name ? item?.requester_name : ""}</div>
                    </div>

                    <div className={"d-flex"}>
                      <div
                        className={"calendar_data_badge"}
                        style={{ background: "#B5D3AE" }}></div>
                      <div className={"d-flex align-items-center pl-5"} style={{ maxWidth: "80%" }}>
                        <SVG src={toAbsoluteUrl(ICON_CONSTANS.IC_MAP_GRAY)} />
                        <div className={"ml-3"}>
                          {toThousandConvert(item?.land_area)} m² (Manual)
                        </div>
                      </div>
                    </div>
                    <div className={"d-flex"} style={{ maxWidth: "200px" }}>
                      <div
                        className={"calendar_data_badge"}
                        style={{ background: "#B5D3AE" }}></div>
                      <div className={"d-flex align-items-center pl-5"} style={{ maxWidth: "80%" }}>
                        <SVG
                          src={toAbsoluteUrl(
                            item.plant_type_enum === PLANT_TYPE_ENUM.PADI
                              ? ICON_CONSTANS.IC_PADDY_GRAY
                              : item.plant_type_enum === PLANT_TYPE_ENUM.JAGUNG
                              ? ICON_CONSTANS.IC_CORN_GRAY
                              : ICON_CONSTANS.IC_PLANTING_GRAY
                          )}
                        />
                        <div className={"ml-3"}>{item?.plant_type_enum_string}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          {loadingDetailPopup && (
            <div className={"d-flex flex-column"} style={{ gap: 10 }}>
              <Skeleton variant="rectangular" height={50} />
              <Skeleton variant="rectangular" height={50} />
              <Skeleton variant="rectangular" height={50} />
              <Skeleton variant="rectangular" height={50} />
              <Skeleton variant="rectangular" height={50} />
              <Skeleton variant="rectangular" height={50} />
            </div>
          )}
        </div>
      </div>
    </div>
  )

  return (
    <>
      <Popup
        contentClassName={"modal-main modal_detail_team_customs"}
        show={showModalPopup}
        titleCustom={<h1>Anggota Tim</h1>}
        closeButton={true}
        showButton={false}
        onClick={() => setShowModalPopup(false)}
        body={BodyModal}
        size={"lg"}
        onCancel={() => {
          setShowModalPopup(false)
        }}
      />
      <Card>
        <CardBody>
          {General.loading ? (
            <div className={"mb-5 border-bottom pb-5"}>
              <Skeleton animation="wave" variant={"text"} height={40} width={200} />
              <Skeleton animation="wave" variant={"text"} width={160} />
            </div>
          ) : (
            <div className={"mb-5 border-bottom pb-5"}>
              <h1>{data?.name}</h1>
              <div>{data?.description}</div>
            </div>
          )}
          <div className={"w-100"}>
            {General.loading ? (
              <div className={"d-flex align-items-end justify-content-between"}>
                <div>
                  <Skeleton animation="wave" variant={"text"} height={32} width={200} />
                  <Skeleton animation="wave" variant={"text"} width={160} />
                </div>
                <Skeleton animation="wave" variant={"text"} width={200} />
              </div>
            ) : (
              <>
                <h3>Anggota tim</h3>
                <div className={"d-flex align-items-center justify-content-between"}>
                  <div>
                    {data?.member_list &&
                      data?.member_list.map((item, i) => {
                        if (i !== data?.member_list?.length - 1) {
                          return item?.name + ", "
                        } else {
                          return item?.name
                        }
                      })}
                  </div>
                  <h5
                    onClick={() => setShowModalPopup(true)}
                    className={"cursor-pointer text-info underline"}>
                    Lihat Detail
                  </h5>
                </div>
              </>
            )}
          </div>
        </CardBody>
      </Card>

      <div className={"mt-14"}>
        <BigCalendar
          onChange={onChangeCalendar}
          onClick={(e) => onClickPopup(e)}
          popupBody={BodyPopup}
          dataCalendar={dataCalendar}
        />
      </div>
    </>
  )
}
