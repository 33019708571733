import React, { useEffect } from "react"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import { PersistGate } from "redux-persist/integration/react"
import { Routes } from "./Routes"
import { I18nProvider } from "../_metronic/i18n"
import { LayoutSplashScreen, MaterialThemeProvider } from "../_metronic/layout"
import "react-toastify/dist/ReactToastify.css"
import { ToastContainer } from "react-toastify"

export default function App({ store, persistor, basename }) {
  useEffect(() => {
    const script = document.createElement("script")

    script.src = process.env.REACT_APP_ZOHO_URL
    script.async = true

    document.body.appendChild(script)
  }, [])
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        <React.Suspense fallback={<LayoutSplashScreen />}>
          <BrowserRouter basename={basename}>
            <MaterialThemeProvider>
              <I18nProvider>
                <ToastContainer />
                <Routes />
              </I18nProvider>
            </MaterialThemeProvider>
          </BrowserRouter>
        </React.Suspense>
      </PersistGate>
    </Provider>
  )
}
