import { useSubheader } from "../../../../_metronic/layout/index.js"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { FilterCard, LocationMolecule, SelectInput } from "../../../../component/index.jsx"
import { initialFilterTable } from "../../../../component/atoms/Tabel/index.jsx"
import { getUrlParsingPageFilter } from "../../../../config/EndpointCollection.js"
import { useLocation } from "react-router"
import { useUrlParam } from "../../../hooks/useUrlParams.jsx"
import { useDispatch, useSelector } from "react-redux"
import {
  clearDataDetail,
  getMitraListKur,
  getTypeMitra
} from "../../../../redux/actions/MitraAction.jsx"
import { SetFilterAction } from "../../../../redux/actions/FilterAction.js"
import { useTranslation } from "react-i18next"
import { ROUTING_STATUS } from "../../../../constants/StringConstant.js"
import { Table } from "./component/Table.jsx"
import { Card, CardBody, CardHeader } from "../../../../_metronic/_partials/controls/index.js"
import { RowModule } from "../../../../component/atoms/RowModule/RowModule.jsx"
import { CheckboxAutoCompleted } from "../../../../component/atoms/CheckboxAutoCompleted.jsx"
import { getListRegionalSelected } from "../../../../redux/actions/MasterDataAction.jsx"

export function ListMitraKur({
  history,
  match: {
    params: { tab }
  }
}) {
  const subHeader = useSubheader()
  const formRef = useRef()
  const location = useLocation()
  const urlParams = useUrlParam()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { General, Mitra, MasterData } = useSelector((state) => state)

  const [search, setSearch] = useState({
    string_filter: ""
  })
  const [initialValue, setInitialValue] = useState({
    ...initialFilterTable,
    regional_id: "",
    mitra_type: "",
    province_id: "",
    regency_id: "",
    district_id: "",
    sub_district_id: "",
    mitra_status: ""
  })
  const [filterOn, setFilterOn] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [btnFilterDisable, setBtnFilterDisable] = useState(true)
  const [reset, setReset] = useState(false)
  const [isActiveSearch, setIsActiveSearch] = useState(false)
  const [queryStringObj, setQueryStringObj] = useState(undefined)
  const [selectedRegional, setSelectedRegional] = useState([])
  const [listRegional, setListRegional] = useState([])
  const [selectedMitraType, setSelectedMitraType] = useState()
  const [listMitraType, setListMitraType] = useState([])
  const [selectedRegionalUser, setSelectedRegionalUser] = useState()

  const userLogin = JSON.parse(localStorage.getItem("userLogin"))

  const isReadOnly = false
  const [mitraStatusSelect, setMitraStatusSelect] = useState()

  useEffect(() => {
    if (tab === ROUTING_STATUS.STATUS_ENUM.ACTIVE) {
      setTabActive(1)
    } else if (tab === ROUTING_STATUS.STATUS_ENUM.PENDING) {
      setTabActive(0)
    } else if (tab === ROUTING_STATUS.STATUS_ENUM.REJECTED) {
      setTabActive(2)
    } else if (tab === ROUTING_STATUS.STATUS_ENUM.INACTIVE) {
      setTabActive(3)
    }
  }, [tab])

  useEffect(() => {
    if (userLogin?.regional_id) {
      setSelectedRegionalUser(userLogin?.regional_id)
      setInitialValue({
        regional_id: userLogin?.regional_id,
        ...initialValue
      })
    }
  }, [])

  useEffect(() => {
    if (listRegional && selectedRegional) {
      const data = listRegional.find((e) => e.value === selectedRegionalUser)
      if (data) {
        setSelectedRegional([data])
      }
    }
  }, [listRegional, selectedRegionalUser])

  useEffect(() => {
    if (Mitra?.type) {
      setListMitraType(
        Mitra.type.map((item) => {
          return {
            label: item.title,
            value: item.mitra_type_enum
          }
        })
      )
    }
  }, [Mitra])

  useEffect(() => {
    subHeader.setTitle(t("sub_header.mitra_list_ca"))
    subHeader.setButton(false)
    dispatch(getTypeMitra())
    dispatch(getListRegionalSelected())
    return () => {
      dispatch(clearDataDetail())
    }
  }, [])

  useEffect(() => {
    if (MasterData?.listRegionalSelected) {
      setListRegional(
        MasterData.listRegionalSelected.map((item) => {
          return {
            label: item.name + " (" + item.code + ")",
            value: item.id
          }
        })
      )
    }
  }, [MasterData])

  useEffect(() => {
    if (
      formRef.current.values.province_id ||
      formRef.current.values.mitra_status ||
      formRef.current.values.mitra_type ||
      formRef.current.values.regional_id ||
      selectedMitraType ||
      (selectedRegional && selectedRegional.length)
    ) {
      setBtnFilterDisable(false)
    } else {
      setBtnFilterDisable(true)
    }
  }, [formRef.current, mitraStatusSelect, selectedRegional, selectedMitraType])

  function splitDataStringRegionalId(str) {
    if (str) {
      return str.split(",")
    } else {
      return ""
    }
  }

  function filterDataRegional(data) {
    return listRegional.filter((item) => data.includes(item.value)) || []
  }

  useEffect(() => {
    if (queryStringObj?.keyword) {
      setSearch({ keyword: queryStringObj?.keyword })
      setIsActiveSearch(true)
    }
    if (
      queryStringObj?.province_id ||
      queryStringObj?.regency_id ||
      queryStringObj?.district_id ||
      queryStringObj?.sub_district_id ||
      queryStringObj?.mitra_type ||
      queryStringObj?.regional_id ||
      queryStringObj?.mitra_status
    ) {
      if (userLogin?.regional_id) {
        if (
          queryStringObj?.province_id ||
          queryStringObj?.regency_id ||
          queryStringObj?.district_id ||
          queryStringObj?.sub_district_id ||
          queryStringObj?.mitra_type ||
          queryStringObj?.mitra_status
        ) {
          setFilterOn(true)
        } else {
          setFilterOn(false)
        }
      } else {
        setFilterOn(true)
      }
      const data = {
        ...initialFilterTable,
        keyword: queryStringObj?.keyword,
        province_id: queryStringObj?.province_id,
        regency_id: queryStringObj?.regency_id,
        district_id: queryStringObj?.district_id,
        sub_district_id: queryStringObj?.sub_district_id,
        mitra_status: queryStringObj?.mitra_status,
        mitra_type: queryStringObj?.mitra_type,
        regional_id: queryStringObj?.regional_id
      }
      if (data) {
        setInitialValue(data)
        setSelectedMitraType(data.mitra_type)
        setSelectedRegional(filterDataRegional(splitDataStringRegionalId(data?.regional_id)))
        setMitraStatusSelect(data?.mitra_status)
      }
    }
  }, [queryStringObj, dispatch])

  useEffect(() => {
    const result = location?.search
    fetchData(result)
  }, [location.search])

  useEffect(() => {
    setQueryStringObj(urlParams.getUrlParamsObj)
  }, [urlParams.getUrlParamsObj])

  const fetchData = (param) => {
    if (userLogin?.regional_id) {
      if (param === "" || param === null) param = param + "?regional_id=" + userLogin?.regional_id
    }
    dispatch(getMitraListKur(param))
  }

  const searchFunc = (values) => {
    setSearch({ string_filter: values.string_filter })
    setIsActiveSearch(true)
    let param = {
      ...initialFilterTable,
      ...initialValue,
      string_filter: values.string_filter
    }
    const urlParam = getUrlParsingPageFilter(param)
    history.push({ pathname: location.pathname, search: urlParam })
  }

  function checkRegionalSelected() {
    if (userLogin?.regional_id) {
      return userLogin?.regional_id
    } else {
      if (selectedRegional) {
        const data = selectedRegional.map((e) => {
          return e.value
        })
        if (data.length > 0) {
          return data.join(",")
        }
      }
    }
  }
  const handleReset = () => {
    let param = {
      ...initialFilterTable,
      ...initialValue,
      keyword: ""
    }
    const urlParam = getUrlParsingPageFilter(param)
    setSearch({ string_filter: "" })
    setSelectedMitraType("")
    setIsActiveSearch(false)

    setSelectedRegional([])
    history.push({ pathname: location.pathname, search: urlParam })
  }
  // filter
  const filterComponent = (
    <>
      <Card className="card-custom card-shadowless">
        <CardHeader title={t("label.regional")} className="px-0" />
        <CardBody className="px-0">
          <RowModule>
            <CheckboxAutoCompleted
              disable={!!selectedRegionalUser}
              options={listRegional}
              onChange={(e) => setSelectedRegional(e)}
              value={selectedRegional}
              label={t("label.regional_name")}
              placeholder={t("placeholder.insert_regional_name")}
            />
          </RowModule>
        </CardBody>
      </Card>

      <Card className="card-custom card-shadowless">
        <CardHeader title={t("label.mitra")} className="px-0" />
        <CardBody className="px-0">
          <RowModule>
            <SelectInput
              name="mitra_type"
              editable={!isReadOnly}
              label={t("label.mitra_type")}
              placeholder={t("placeholder.select_one")}
              options={listMitraType}
              withoutFeedback={true}
              clearNow={true}
              value={selectedMitraType}
              onChange={(e) => setSelectedMitraType(e)}
            />
          </RowModule>
        </CardBody>
      </Card>
      <LocationMolecule
        regionalId={checkRegionalSelected()}
        resetLocation={reset}
        name="filter"
        withoutFeedback={true}
        isReadOnly={isReadOnly}
        addressTitle="Lokasi"
        removeHorizontalPadding={true}
      />
    </>
  )
  const onSubmitForm = (e) => {
    setReset(false)
    const { values, submited } = e
    if (submited === true) {
      let param = {
        ...initialFilterTable,
        province_id: values.province_id,
        regency_id: values.regency_id,
        district_id: values.district_id,
        sub_district_id: values.sub_district_id,
        keyword: search.keyword,
        mitra_status: values.mitra_status,
        mitra_type: values.mitra_type,
        regional_id: selectedRegionalUser ? selectedRegionalUser : checkRegionalSelected()
      }
      if (param) {
        setInitialValue(param)
        setSelectedRegionalUser(userLogin?.regional_id)

        const urlParam = getUrlParsingPageFilter(param)
        history.push({ pathname: location.pathname, search: urlParam })
        setShowFilter(false)
        setFilterOn(true)
      }
    }
  }

  useEffect(() => {
    if (selectedRegionalUser) {
      const param = {
        ...initialFilterTable,
        ...initialValue,
        regional_id: selectedRegionalUser,
        string_filter: urlParams?.getUrlParamsObj?.string_filter,
        keyword: urlParams?.getUrlParamsObj?.keyword,
        mitra_type: urlParams?.getUrlParamsObj?.mitra_type,
        province_id: urlParams?.getUrlParamsObj?.province_id,
        regency_id: urlParams?.getUrlParamsObj?.regency_id,
        sub_district_id: urlParams?.getUrlParamsObj?.regency_id,
        district_id: urlParams?.getUrlParamsObj?.district_id
      }
      if (param) {
        setInitialValue(param)
        const urlParam = getUrlParsingPageFilter(param)
        history.push({ pathname: location.pathname, search: urlParam })
      }
    }
  }, [selectedRegionalUser])
  const filterOnReset = () => {
    dispatch(SetFilterAction())
    setReset(true)
    setShowFilter(false)
    setFilterOn(false)
    let param = {
      ...initialFilterTable,
      province_id: "",
      regency_id: "",
      district_id: "",
      sub_district_id: "",
      keyword: search.keyword,
      mitra_status: "",
      mitra_type: "",
      regional_id: selectedRegionalUser ? selectedRegionalUser : ""
    }
    setSelectedRegional([])
    setInitialValue(param)
    setSelectedMitraType("")

    setSelectedRegionalUser(userLogin?.regional_id || "")

    setMitraStatusSelect("")
    const urlParam = getUrlParsingPageFilter(param)
    history.push({ pathname: location.pathname, search: urlParam })
  }

  const handleOpenCloseFilter = (value) => {
    setShowFilter(value)
  }

  const changePage = useCallback(
    (param) => {
      let urlParam
      if (param.sortField != null) {
        urlParam = getUrlParsingPageFilter({
          ...initialValue,
          ...param,
          sort: param?.sortField ? param.sortField + "," + param.sortOrder : "",
          string_filter: search.string_filter
        })
      } else {
        urlParam = getUrlParsingPageFilter({
          ...initialValue,
          ...param,
          string_filter: search.string_filter
        })
      }
      history.push({ pathname: location.pathname, search: urlParam })
    },
    [dispatch, search, initialValue]
  )

  return (
    <>
      <FilterCard
        // seacrh
        isActiveSearch={isActiveSearch}
        placeholder={t("placeholder.search_mitra_name_or_id")}
        onSubmitForm={searchFunc}
        initialValues={search}
        name={"string_filter"}
        innerRef={formRef}
        trigerReset={handleReset}
        // filter
        filterInnerRef={formRef}
        filterHandleSubmit={onSubmitForm}
        filterFormInitialValues={initialValue}
        filterComponent={filterComponent}
        filterOnReset={filterOnReset}
        showFilter={showFilter}
        handleOpenCloseFilter={handleOpenCloseFilter}
        filterOn={filterOn}
        btnDisable={btnFilterDisable}></FilterCard>

      <Table
        data={Mitra}
        changePage={changePage}
        history={history}
        general={General}
        initialFilterTable={initialFilterTable}
        isFilter={filterOn}
      />
    </>
  )
}