import React, { useEffect, useState } from "react"
import { useSubheader } from "../../../../../_metronic/layout/index.js"
import { useDispatch, useSelector } from "react-redux"
import { Card } from "react-bootstrap"
import { CardBody } from "../../../../../_metronic/_partials/controls/index.js"
import { RowModule } from "../../../../../component/atoms/RowModule/RowModule.jsx"
import { DetailText, RowText, TableSkeleton } from "../../../../../component/index.jsx"
import BootstrapTable from "react-bootstrap-table-next"
import {
  getDetailHarvestEstimation,
  updateAreaActivityImage
} from "../../../../../redux/actions/AreaAction.jsx"
import { timeStampToDate } from "../../../../helpers/DateHelper.js"
import { toMeter, toRupiah } from "../../../../helpers/TextHelper.js"
import { AREA } from "../../../../../constants/InitTypeConstants.js"
import { RoutesConstants } from "../../../../../constants/RoutesConstants.js"
import { ACTIVITY_ICONS } from "../../../../../constants/ImageConstants.js"
import { ActivityDetailHeader } from "../../../../../component/molecule/ActivityDetailHeader/ActivityDetailHeader.jsx"

export const HarvestEstimationDetail = ({
  match: {
    params: { status, id, activity_id }
  }
}) => {
  const subHeader = useSubheader()
  const dispatch = useDispatch()
  const { MasterData, General, Area } = useSelector((state) => state)
  const [detail, setDetail] = useState({})
  const [idDocument, setIdDocument] = useState(null)

  useEffect(() => {
    dispatch(getDetailHarvestEstimation(activity_id))
  }, [])

  useEffect(() => {
    if (Area.data) {
      setDetail(Area.data)
    }
  }, [Area])

  useEffect(() => {
    let newStatus
    if (status === "active") {
      newStatus = "teregistrasi"
    }
    subHeader.setButton(null)
    subHeader.setBreadcrumbs([
      {
        pathname: RoutesConstants.AREA.LIST_AREA_TEREGISTRASI("all"),
        title: "Lahan"
      },
      {
        pathname: RoutesConstants.AREA.LIST_AREA_TEREGISTRASI("all"),
        title: newStatus
      },
      {
        pathname: "/lahan/list/" + status + "/" + id,
        title: detail?.activity_header?.area_name
      },
      {
        title: "Estimasi Panen"
      }
    ])
  }, [detail])

  useEffect(() => {
    if (General.isFinishUpdateOrDelete) {
      if (General.actionInitType === "UPLOAD") {
        const data = {
          id: detail?.activity_header?.image_and_id_activity[idDocument]?.id,
          url: MasterData.urlResponseUpload
        }

        dispatch(updateAreaActivityImage(data))
      } else if (General.actionInitType === AREA.UPDATE_ACTIVITY_IMAGE) {
        dispatch(getDetailHarvestEstimation(activity_id))
      }
    }
  }, [General])

  const titleActivity = (
    <ActivityDetailHeader
      title={`AKTIVITAS ESTIMASI PANEN`}
      icon={ACTIVITY_ICONS.IC_ESTIMASI_PANEN}
    />
  )

  const areaInformation = (
    <Card className={"mb-4"}>
      <CardBody>
        <h4 className={"mb-6 text-uppercase"}>INFORMASI LAHAN</h4>
        <RowModule customColumnCss={"col-md-6 mb-2"}>
          <RowModule>
            <RowText label={"Lahan"} value={detail?.area_information?.area_name ?? "-"} />
          </RowModule>
          <RowModule>
            <RowText
              label={"Luas Lahan (Manual)"}
              value={
                detail?.area_information?.area_land_manual
                  ? toMeter(detail?.area_information?.area_land_manual)
                  : "-"
              }
            />
          </RowModule>
          <RowModule>
            <RowText
              hasIcon={detail?.area_information?.has_kur}
              icon={detail?.area_information?.bank_image}
              label={"Petani"}
              value={
                detail?.area_information?.farmer_name
                  ? `${detail?.area_information?.farmer_name} (${detail?.area_information?.farmer_code})`
                  : "-"
              }
            />
          </RowModule>
          <RowModule>
            <RowText
              label={"Luas Lahan (Polygon)"}
              value={
                detail?.area_information?.area_land_polygon
                  ? toMeter(detail?.area_information?.area_land_polygon)
                  : "-"
              }
            />
          </RowModule>
          <RowModule>
            <RowText
              label={"Mitra"}
              value={
                detail?.area_information?.mitra_name
                  ? `${detail?.area_information?.mitra_name} (${detail?.area_information?.mitra_code})`
                  : "-"
              }
            />
          </RowModule>
          <RowModule>
            <RowText label={"Jenis Tanaman"} value={detail?.plant_name ?? "-"} />
          </RowModule>
          <RowModule>
            <RowText label={"Lokasi Lahan"} value={detail?.area_information?.full_address ?? "-"} />
          </RowModule>
          <RowModule>
            <RowText
              label={"Varietas Tanaman"}
              value={detail?.area_information?.variety_name ?? "-"}
            />
          </RowModule>
        </RowModule>
      </CardBody>
    </Card>
  )

  const detailActivitas = (
    <Card className={"mb-4"}>
      <CardBody>
        <h4 className={"mb-6 mt-6 text-uppercase"}>Detail Aktivitas</h4>
        <RowModule customColumnCss={"col-md-6 mb-2"}>
          <RowModule>
            <RowText label={"Submitted by"} value={detail?.activity_header?.submitted_by ?? "-"} />
          </RowModule>
          <RowModule>
            <RowText
              label={"Estimasi Luas Lahan Dikerjakan (Alsintan)"}
              value={
                detail?.activity_header?.worked_area
                  ? toMeter(detail?.activity_header?.worked_area)
                  : "-"
              }
            />
          </RowModule>
          <RowModule>
            <RowText
              label={"Submitted time"}
              value={
                detail?.activity_header?.submitted_date
                  ? timeStampToDate(detail?.activity_header?.submitted_date, "dd-MM-yyyy HH:mm:ss")
                  : "-"
              }
            />
          </RowModule>
          <RowModule>
            <RowText
              label={"Estimasi Panen"}
              value={`${detail?.start_estimated_date} s/d ${detail?.end_estimated_date}` ?? "-"}
            />
          </RowModule>
          <RowModule>
            <RowText
              label={"Requested by"}
              value={detail?.activity_header?.request_by_string ?? "-"}
            />
          </RowModule>
          <RowModule>
            <RowText
              label={"HST"}
              value={isNaN(detail?.hst_harvest) ? "-" : `${detail?.hst_harvest} HST`}
            />
          </RowModule>
          <RowModule>
            <RowText
              label={"Tanggal Survey"}
              value={
                detail?.activity_header?.activity_date
                  ? timeStampToDate(detail?.activity_header?.activity_date, "dd-MM-yyyy")
                  : "-"
              }
            />
          </RowModule>
        </RowModule>
        {detail?.activity_header?.image_and_id_activity.length > 0 ||
        detail?.activity_header?.note ? (
          <div className={"my-10"}>
            <h4 className={"mb-6"}>CATATAN</h4>
            <div className={"row mb-4"}>
              {detail?.activity_header?.image_and_id_activity.map((item, index) => {
                return (
                  <div className={"col-md-3"} key={index}>
                    <DetailText
                      type="image"
                      value={item.url}
                      indexKey={index}
                      menu={AREA.ACTIVITY_HARVEST_ESTIMATED}
                      setIdDocument={setIdDocument}
                    />
                  </div>
                )
              })}
            </div>
            <span className="font-weight-bold text-dark-50 font-size-sm">
              {detail?.activity_header?.note ?? "-"}
            </span>
          </div>
        ) : null}
      </CardBody>
    </Card>
  )

  const columns = [
    {
      dataField: "no",
      text: "No",
      headerClasses: "bg-gray"
    },
    {
      dataField: "parameter",
      text: "Parameter",
      headerClasses: "bg-gray"
    },
    {
      dataField: "harvest_estimate",
      text: "Estimasi Panen",
      headerClasses: "bg-gray"
    }
  ]

  const dataEstimate = () => {
    return [
      {
        no: "1",
        parameter: "Umur Panen",
        harvest_estimate: isNaN(detail?.hst_harvest) ? "-" : `${detail?.hst_harvest} HST`
      },
      {
        no: "2",
        parameter: "Harga per Kg",
        harvest_estimate: detail?.price_per_kg ? toRupiah(detail?.price_per_kg) : "-"
      },
      {
        no: "3",
        parameter: "Bobot",
        harvest_estimate: detail?.weight ? detail?.weight + " Kg" : "-"
      }
    ]
  }

  const estimated = (
    <>
      {General.loading ? (
        <TableSkeleton column={4} row={3} />
      ) : (
        <Card>
          <CardBody>
            <h4 className={"mb-6"}>DATA ESTIMASI PANEN</h4>
            <div className={"border rounded table-small-padding"}>
              <BootstrapTable
                condensed
                bordered={false}
                columns={columns}
                keyField={"name"}
                data={dataEstimate(detail?.plant_name)}
              />
            </div>
          </CardBody>
        </Card>
      )}
    </>
  )

  return (
    <>
      {titleActivity}
      {areaInformation}
      {detailActivitas}
      {estimated}
    </>
  )
}