import axios, { callTypes, initialDataResponse } from "./baseAxiosAction"
import { ENDPOINT } from "../../config/EndpointCollection.js"
import { getUserToken } from "./userManagementAction"
import { createSlice } from "@reduxjs/toolkit"

export const userDataSlice = createSlice({
  name: "userData",
  initialState: initialDataResponse,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false
      } else {
        state.actionsLoading = false
      }
      state.isHaveError = true
    },
    startCall: (state, action) => {
      state.error = null
      state.isHaveError = false
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true
      } else {
        state.actionsLoading = true
      }
    },
    // getUserLoginData
    getUserFatch: (state, action) => {
      const { data } = action.payload
      state.isHaveError = false
      state.listLoading = false
      state.error = null
      state.entities = data
    }
  }
})

const { actions } = userDataSlice

export const checkJwtData = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }))
  return axios
    .get(ENDPOINT.userSetting.checkJwt, {
      headers: {
        Authorization: "Bearer " + getUserToken()
      }
    })
    .then(response => {
      const { data } = response.data
      dispatch(actions.getUserFatch({ data }))
      localStorage.setItem("userLogin", JSON.stringify(data))
      localStorage.setItem("jwt_token", data.jwt_token)
    })
    .catch(error => {
      error.clientMessage = "Can't find products"
      dispatch(actions.catchError({ error, callTypes: callTypes.action }))
      if (window?.location?.pathname !== "/auth/login" && error?.response?.status === 401) {
        window.location.href = "/logout"
      }
    })
}