import React, { useEffect, useState } from "react"
import { RowModule } from "../../../../../component/atoms/RowModule/RowModule.jsx"
import { Button, Card, Col } from "react-bootstrap"
import { CardBody } from "../../../../../_metronic/_partials/controls/index.js"
import { useSubheader } from "../../../../../_metronic/layout/index.js"
import { PopupQuestion } from "../../../../../component/atoms/Popup/index.jsx"
import { FileList } from "../../../../../component/index.jsx"
import { fileTypeSplit } from "../../../../helpers/TextHelper.js"
import { useDispatch, useSelector } from "react-redux"
import {
  getDetailBroadcast,
  removeBroadcast
} from "../../../../../redux/actions/BroadcastAction.jsx"
import swal from "sweetalert"
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers/index.js"
import { ILUSTRATION_CONSTANS } from "../../../../../constants/ImageConstants.js"
import { useTranslation } from "react-i18next"
import { Skeleton } from "@material-ui/lab"

const BroadcastDetail = ({
  history,
  match: {
    params: { id }
  }
}) => {
  const subHeader = useSubheader()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { General, Broadcast } = useSelector((state) => state)
  const [showPopup, setShowPopup] = useState(false)
  const [data, setData] = useState([])
  const [attachments, setAttachments] = useState([])
  useEffect(() => {
    dispatch(getDetailBroadcast(id))
  }, [])

  useEffect(() => {
    setData(Broadcast?.data)
  }, [General])

  useEffect(() => {
    setAttachments(data?.attachments)
  }, [data])

  useEffect(() => {
    if (General.isFinishUpdateOrDelete) {
      swal({
        text: t("messages.response.broadcast_success_delete"),
        icon: toAbsoluteUrl(ILUSTRATION_CONSTANS.BROADCAST_SUCCESS_DELETE)
      })
      history.push("/broadcast/list")
    }
  }, [General.isFinishUpdateOrDelete])

  useEffect(() => {
    subHeader.setButton(deleteButton)
    subHeader.setBreadcrumbs([
      {
        pathname: "/role/list",
        title: "Admin Setting"
      },
      {
        pathname: "/broadcast/list",
        title: "Broadcast Message"
      },
      {
        title: data?.title
      }
    ])
  }, [id, data])

  const handleDelete = () => {
    dispatch(removeBroadcast(id))
  }

  const deleteButton = () => {
    return (
      <Button variant="danger" onClick={() => setShowPopup(true)}>
        Hapus
      </Button>
    )
  }

  return (
    <>
      <PopupQuestion
        onCancel={() => setShowPopup(false)}
        title={"HAPUS BROADCAST MESSAGE"}
        onOk={handleDelete}
        variantOkButton={"danger"}
        show={showPopup}
        bodyCustom={
          <Col>
            <p className={"text-center"}>
              Apakah anda yakin menghapus Broadcast Message? Broadcast Message yang sudah terhapus
              <strong> akan hilang.</strong>
            </p>
          </Col>
        }
      />

      <div style={{ display: "flex", flexDirection: "column", gap: 30 }}>
        {General?.loading ? (
          <Skeleton variant={"rect"} animation={"wave"} height={300} />
        ) : (
          <>
            {data?.banner_image && (
              <img className={"w-100"} src={data?.banner_image} alt="testing" />
            )}
          </>
        )}
        <Card>
          <CardBody>
            <Col>
              {General?.loading ? (
                <>
                  <Skeleton variant={"rect"} animation={"wave"} height={40} width={230} />
                  <Skeleton variant={"text"} animation={"wave"} />
                </>
              ) : (
                <h1 style={{ fontSize: 36 }} className={"mb-10"}>
                  {data?.title}
                </h1>
              )}
              <section dangerouslySetInnerHTML={{ __html: data?.body }} />
            </Col>
          </CardBody>
        </Card>
        {attachments?.length > 0 && (
          <Card>
            <CardBody>
              <h1 className={"p-2 mb-5"}>Lampiran Dokumen</h1>
              <RowModule customColumnCss="col-lg-6 col-sm-6 col-md-6">
                {attachments.map((item, i) => (
                  <FileList
                    key={i}
                    view={item?.file_url}
                    className={"mb-5"}
                    name={item?.file_name}
                    size={item?.file_size}
                    type={fileTypeSplit(item?.file_name)}
                  />
                ))}
              </RowModule>
            </CardBody>
          </Card>
        )}
      </div>
    </>
  )
}

export default BroadcastDetail