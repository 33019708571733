import React from "react"
import { ILLUSTRATION_ASSETS_V2, LOGOS_CONSTANT } from "../../../constants/ImageConstants.js"
import { checkValue, toHectare, toKilogram } from "../../../app/helpers/TextHelper.js"
import { timeStampToDate } from "../../../app/helpers/DateHelper.js"
import { DATE_TYPE_ENUM } from "../../../constants/StringConstant.js"

const LabelValue = ({ label, value }) => {
  return (
    <div className={"d-flex"}>
      <div className={"w-50"}>{label}</div>
      <div className={"mr-2"}>:</div>
      <div className={"flex-grow-1 "}>{value}</div>
    </div>
  )
}

const LabelValueFit = ({ label, value }) => {
  return (
    <div className={"d-flex"}>
      <div className={"w-30"}>{label}</div>
      <div className={"mr-2"}>:</div>
      <div className={"flex-grow-1 "}>{value}</div>
    </div>
  )
}
export const ApplicationSeedCertificationFormDownload = ({ data }) => {
  const lengthFemale = Math.ceil(data?.female_images?.length / 6)
  const lengthMale = Math.ceil(data?.male_images?.length / 6)

  const header = (
    <div className={"submission-header border border-1 border-dark"}>
      <div className={"w-full d-flex align-content-center justify-content-center"}>
        <div className={"d-flex justify-content-center align-items-center p-4"}>
          <img src={LOGOS_CONSTANT.MAI} className={""} />
        </div>
        <div
          className={
            "d-flex justify-content-center align-items-center flex-grow-1 border-right border-left border-1 border-dark font-size-h1 font-weight-bolder p-4"
          }>
          DOKUMEN FORMULIR
        </div>
        <div className={"d-flex flex-column flex-grow-1 p-4 txt-16"}>
          <LabelValue label={"No. Dokumen"} value={"DF.PO.S.FCP.04.01"} />
          <LabelValue label={"Tanggal Berlaku"} value={"7 Juli 2023"} />
          <LabelValue label={"Tanggal Revisi"} value={"8 Maret 2024"} />
          <LabelValue label={"Revisi"} value={"1"} />
          <LabelValue label={"Halaman"} value={""} />
        </div>
      </div>
      <div
        className={
          "w-full d-flex justify-content-center align-items-center text-uppercase txt-20 border-top border-1 border-dark p-4"
        }>
        FORMULIR PERMOHONAN SERTIFIKASI BENIH
      </div>
    </div>
  )

  const body = (
    <div className={"submission-body"}>
      <div className={"no-berkas d-flex justify-content-between align-items-start px-8 mb-8"}>
        <table className={"border border-1 border-dark table-fixed"}>
          <tr>
            <td className={"w-150px"}>No. Berkas</td>
            <td className={"w-250px"}>{checkValue(data?.doc_number)}</td>
          </tr>
        </table>
        <table className={"border border-1 border-dark table-fixed"}>
          <tr>
            <td className={"w-150px"}>No. Induk</td>
            <td className={"w-250px"}>{checkValue(data?.base_number)}</td>
          </tr>
          <tr>
            <td className={"w-150px"}>MT *)</td>
            <td className={"w-250px"}></td>
          </tr>
          <tr>
            <td className={"w-150px"}>Paraf/Tgl *)</td>
            <td className={"w-250px"}></td>
          </tr>
        </table>
      </div>

      <div className={"mb-8"}>
        <div className={"w-50"}>
          <LabelValue label={"Nomor"} value={checkValue(data?.request_number)} />
        </div>
        <div className={"d-flex justify-content-between"}>
          <div className={"flex-1"}>
            <LabelValue label={"Lampiran"} value={""} />
            <LabelValue label={"Perihal"} value={"Permohonan Sertifikasi"} />
          </div>
          <div className={"flex-1"}>
            <div>Kepada :</div>
            <div>Yth. Manager QA PT Maxxi Agri Indonesia</div>
          </div>
        </div>
      </div>

      <div className={"mb-8"}>
        <div className={"d-flex w-50"}>
          <div className={"w-30px"}>1</div>
          <div className={"flex-1"}>
            <LabelValue label={"Nama Pemohon"} value={"Mohamad Rizki Abadi"} />
            <LabelValue label={"Alamat"} value={"Jombang"} />
          </div>
        </div>

        <div>
          <div className={"d-flex w-full"}>
            <div className={"w-30px"}>2</div>
            <div className={"flex-1"}>
              <div>Sertifikasi untuk</div>
              <div className={"d-flex"}>
                <div className={"flex-1"}>
                  <LabelValue label={"Luas Pertanaman"} value={toHectare(data?.total_land_area)} />
                  <LabelValue label={"Jenis Tanaman"} value={checkValue(data?.plant_type_name)} />
                  <LabelValue label={"Tgl. Sebar (Non Hibrida)"} value={"-"} />
                </div>
                <div className={"flex-1"}>
                  <LabelValue label={"Varietas"} value={checkValue(data?.variety_name)} />
                  <LabelValue label={"Kelas Benih"} value={checkValue(data?.seed_class)} />
                  <LabelValue label={"Tgl. Tanam (Non Hibrida)"} value={"-"} />
                </div>
              </div>

              <div>Hibrida</div>
              <div className={"d-flex"}>
                <div className={"flex-1"}>
                  <LabelValue label={"Tgl/ Sebar Jantan/ Restorer 1"} value={"-"} />
                  <LabelValue label={"Tgl/ Sebar Jantan/ Restorer 2"} value={"-"} />
                  <LabelValue label={"Tgl/ Sebar Jantan/ Restorer 3"} value={"-"} />
                  <LabelValue label={"Tgl/ Sebar Betina/ CMS"} value={"-"} />
                </div>
                <div className={"flex-1"}>
                  <LabelValue
                    label={"Tgl/ Tanam Jantan/ Restorer 1"}
                    value={timeStampToDate(data?.male_planting_date, DATE_TYPE_ENUM.ISO_DATE_ID)}
                  />
                  <LabelValue label={"Tgl/ Tanam Jantan/ Restorer 2"} value={"-"} />
                  <LabelValue label={"Tgl/ Tanam Jantan/ Restorer 3"} value={"-"} />
                  <LabelValue
                    label={"Tgl/ Tanam Betina/ CMS"}
                    value={timeStampToDate(data?.female_planting_date, DATE_TYPE_ENUM.ISO_DATE_ID)}
                  />
                </div>
              </div>
              <div>
                <div className={"w-50"}>
                  <div>Letak Tanah</div>
                </div>
                <div className={"w-50"}>
                  <LabelValue label={"Blok"} value={checkValue(data?.block_code)} />
                </div>
                <div className={"d-flex"}>
                  <div className={"flex-1"}>
                    <LabelValue label={"Dusun"} value={checkValue(data?.sub_district_name)} />
                    <LabelValue label={"Desa"} value={checkValue(data?.sub_district_name)} />
                  </div>
                  <div className={"flex-1"}>
                    <LabelValue label={"Kecamatan"} value={checkValue(data?.district_name)} />
                    <LabelValue label={"Kabupaten"} value={checkValue(data?.regency_name)} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className={"d-flex w-full"}>
            <div className={"w-30px"}>3</div>
            <div className={"flex-1"}>
              <div>Tanaman Sebelumnya</div>
              <div className={"w-50"}>
                <LabelValue label={"Bero"} value={checkValue(data?.plant_history?.bero)} />
              </div>
              <div className={"d-flex"}>
                <div className={"flex-1"}>
                  <LabelValue
                    label={"Jenis Tanaman"}
                    value={checkValue(data?.plant_history?.plant_type)}
                  />
                  <LabelValue
                    label={"Tgl. Panen"}
                    value={timeStampToDate(
                      data?.plant_history?.harvest_date,
                      DATE_TYPE_ENUM.ISO_DATE_ID
                    )}
                  />
                  <LabelValue
                    label={"Disertifikasi"}
                    value={data?.plant_history?.certificate ? "Ya" : "Tidak"}
                  />
                </div>
                <div className={"flex-1"}>
                  <LabelValue
                    label={"Varietas"}
                    value={checkValue(data?.plant_history?.variety_name)}
                  />
                  <LabelValue
                    label={"Kelas Benih"}
                    value={checkValue(data?.plant_history?.seed_class)}
                  />
                  <LabelValue
                    label={"Pemeriksaan Lapangan"}
                    value={checkValue(data?.plant_history?.field_check)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className={"d-flex w-full"}>
            <div className={"w-30px"}>4</div>
            <div className={"flex-1"}>
              <div>Asal Benih</div>
              <div className={"d-flex"}>
                <div className={"flex-1"}>
                  <LabelValue label={"Asal Sumber Benih"} value={"PT. MAI"} />
                  <LabelValue
                    label={"Kelas Benih"}
                    value={checkValue(data?.seed_source?.seed_class)}
                  />
                </div>
                <div className={"flex-1"}>
                  <LabelValue
                    label={"Jumlah Benih Jantan"}
                    value={
                      <div className={"d-flex"}>
                        {data?.base_variant_male}= {toKilogram(data?.seed_source?.male_total_seed)}
                      </div>
                    }
                  />
                  <LabelValue
                    label={"Jumlah Benih Betina"}
                    value={
                      <div className={"d-flex"}>
                        {data?.base_variant_female}={" "}
                        {toKilogram(data?.seed_source?.female_total_seed)}
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={"my-4"}>
          No. Kelompok Benih (Lampiran Keterangan/ Label Benih Sumber): terlampir
        </div>
      </div>

      {/*pernyataan*/}
      <div>
        <div>Kami menyadari sepenuhnya bahwa :</div>
        <div className={"d-flex w-full"}>
          <div className={"w-30px"}>1.</div>
          <div className={"flex-1"}>
            Pertanaman kami tidak akan diterima sepenuhnya apabila tidak mengikuti prosedur
            sertifikasi benih tanaman pangan dan dibersihkan dari tanaman/ varietas lain untuk
            memenuhi standard lapangan.
          </div>
        </div>
        <div className={"d-flex w-full"}>
          <div className={"w-30px"}>2.</div>
          <div className={"flex-1"}>
            Kami wajib memberitahukan Ke Manager QA untuk pemeriksaan lapangan selambat lambatnya 3
            (tiga) hari sebelum pelaksanaan pemeriksaan.
          </div>
        </div>
        <div className={"d-flex w-full"}>
          <div className={"w-30px"}>3.</div>
          <div className={"flex-1"}>
            Kami tidak diperkenankan memindahkan letak pertanaman tanpa pemberitahuan kepada Manager
            QA.
          </div>
        </div>
        <div className={"d-flex w-full"}>
          <div className={"w-30px"}>4.</div>
          <div className={"flex-1"}>Pengolahan benih harus mendapat bimbingan dari QA.</div>
        </div>
        <div className={"d-flex w-full"}>
          <div className={"w-30px"}>5.</div>
          <div className={"flex-1"}>
            Sertifikat benih tanaman pangan akan diberikan apabila telah lulus pemeriksaan lapangan
            dan pengujian mutu benih di laboratorium.
          </div>
        </div>
        <div className={"d-flex justify-content-end pr-32 mt-10"}>
          <div className={"d-flex flex-column align-items-center"}>
            <div>Jombang, {timeStampToDate(data?.submitted_date, DATE_TYPE_ENUM.ISO_DATE_ID)}</div>
            <div className={"mb-20"}>Pemohon,</div>
            <div>{"Mohamad Rizki Abadi"}</div>
          </div>
        </div>
        {/*image betina*/}
        <div className={"pagebreak d-flex flex-column align-items-center"}>
          <div className={"txt-18"}>BLOK :</div>
          <div className={"txt-18"}>JUMLAH LABEL BETINA</div>
          {Array.from({ length: lengthFemale }).map((_, index) => {
            if (index === 0) {
              return (
                <div className={"d-flex flex-wrap justify-content-around"} key={index}>
                  {data?.female_images?.length > 0 &&
                    data?.female_images?.map((val, i) => {
                      if (i < 6) {
                        return (
                          <div className={`w-50 p-4 d-flex justify-content-center`} key={i}>
                            <img src={val} className={"h-auto max-h-280px w-auto"} />
                          </div>
                        )
                      }
                    })}
                </div>
              )
            } else {
              return (
                <div className={"pagebreak"} key={index}>
                  <div className={"d-flex flex-wrap justify-content-around"}>
                    {data?.female_images?.length > 0 &&
                      data?.female_images?.map((val, i) => {
                        if (i >= index * 6 && i < (index + 1) * 6) {
                          return (
                            <div className={`w-50 p-4 d-flex justify-content-center`} key={i}>
                              <img src={val} className={"h-auto max-h-280px w-auto"} />
                            </div>
                          )
                        }
                      })}
                  </div>
                </div>
              )
            }
          })}
        </div>
        {/*image jantan*/};
        <div className={"pagebreak d-flex flex-column align-items-center"}>
          <div className={"txt-18"}>BLOK :</div>
          <div className={"txt-18"}>JUMLAH LABEL JANTAN</div>
          {Array.from({ length: lengthMale }).map((_, index) => {
            if (index === 0) {
              return (
                <div className={"d-flex flex-wrap justify-content-around"} key={index}>
                  {data?.male_images?.length > 0 &&
                    data?.male_images?.map((val, i) => {
                      if (i < 6) {
                        return (
                          <div className={`w-50 p-4 d-flex justify-content-center`} key={i}>
                            <img src={val} className={"h-auto max-h-280px w-auto"} />
                          </div>
                        )
                      }
                    })}
                </div>
              )
            } else {
              return (
                <div className={"pagebreak"} key={index}>
                  <div className={"d-flex flex-wrap justify-content-around"}>
                    {data?.male_images?.length > 0 &&
                      data?.male_images?.map((val, i) => {
                        if (i >= index * 6 && i < (index + 1) * 6)
                          return (
                            <div className={`w-50 p-4 d-flex justify-content-center`} key={i}>
                              <img src={val} className={"h-auto max-h-280px w-auto"} />
                            </div>
                          )
                      })}
                  </div>
                </div>
              )
            }
          })}
        </div>
        {/*peta lokasi*/}
        <div className={"pagebreak d-flex flex-column align-items-center"}>
          <div className={"w-full d-flex justify-content-center txt-18 mb-4"}>PETA LOKASI</div>
          <div className={"d-flex w-full border border-bottom border-1 w-full border-dark mb-4"}>
            <div className={"flex-1"}>
              <div className={'w-30"'}>
                <LabelValueFit label={"Blok"} value={checkValue(data?.block_code)} />
                <LabelValueFit label={"Luas"} value={toHectare(data?.total_land_area)} />
              </div>
            </div>
            <div className={"flex-1"}>
              <div className={'w-30"'}>
                <LabelValueFit label={"Desa"} value={checkValue(data?.sub_district_name)} />
                <LabelValueFit label={"Kecamatan"} value={checkValue(data?.district_name)} />
              </div>
            </div>
          </div>
          <div className={"position-relative mt-8"}>
            <img
              src={ILLUSTRATION_ASSETS_V2.IL_DIRECTION}
              className={"position-absolute"}
              style={{ width: "80px", height: "auto", left: "30px", top: "30px" }}
            />
            <img src={data?.location_images} className={"w-full h-auto"} />
          </div>
        </div>
      </div>
    </div>
  )

  const footer = (
    <div className={"submission-footer px-10"}>
      <div className={"d-flex justify-content-between"}>
        <div className={"w-50"}>
          <LabelValueFit label={"Lembar pertama"} value={"QA PT Maxxi Agri Indonesia"} />
          <LabelValueFit label={"Lembar kedua"} value={"Tim Produksi"} />
          <LabelValueFit label={"Lembar ketiga"} value={"Dokumen Control"} />
        </div>
        <div className={"w-50"}>
          <LabelValueFit label={"*)"} value={"Diisi oleh Pengawas Benih"} />
          <LabelValueFit label={"**)"} value={"Lampiran peta lapangan"} />
          <LabelValueFit label={"***)"} value={"Coret yang tidak perlu"} />
        </div>
      </div>
    </div>
  )

  return (
    <div className={"d-none d-print-block position-relative w-full text-16"}>
      {header}
      {body}
      {footer}
    </div>
  )
}
