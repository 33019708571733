import { Skeleton } from "@material-ui/lab"
import React from "react"

export const CardAreaSertificaionSkeleton = () => {
  return (
    <div
      className={`px-4 py-3 rounded-card d-flex justify-content-between align-items-center mb-6 bg-white`}>
      <div>
        <div className={"font-size-lg font-weight-bold"}>
          <Skeleton animation="wave" variant={"text"} width={250} />
        </div>
        <div className={"d-flex color-gray mb-5 align-items-center"}>
          <div>
            <Skeleton animation="wave" variant={"text"} width={100} />
          </div>
          <div className={"circle mx-2"}></div>
          <div>
            <Skeleton animation="wave" variant={"text"} width={100} />
          </div>
        </div>
        <div className={"d-flex align-items-center mb-2"}>
          <Skeleton animation="wave" variant={"text"} width={200} />
        </div>
        <div className={"d-flex align-items-center mb-2"}>
          <Skeleton animation="wave" variant={"text"} width={200} />
        </div>
        <div className={"d-flex align-items-center mb-2"}>
          <Skeleton animation="wave" variant={"text"} width={200} />
        </div>
      </div>
      <Skeleton animation="wave" variant={"text"} width={100} />
    </div>
  )
}
