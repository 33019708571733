import { createSlice } from "@reduxjs/toolkit"

const initState = {
  pageData: [],
  page: false,
  combobox: [],
  data: false,
  detail: {},
  assignTab: 0,
  calendar: undefined,
  calendarDetail: undefined,
  calendarList: undefined,
  detailCalendarLoading: false,
  role: [],
  availableMember: [],
  listParam: undefined
}

export const FieldOfficerSlice = createSlice({
  name: "fieldOfficer",
  initialState: initState,
  reducers: {
    getPage: (state, action) => {
      state.data = action.payload
    },
    setCombobox: (state, action) => {
      state.combobox = action.payload
    },
    setPageData: (state, action) => {
      const { pageData, page, param } = action.payload
      state.pageData = pageData
      state.page = page
      state.listParam = param
    },
    getDetail: (state, { payload }) => {
      state.detail = payload
    },
    getAssignTab: (state, { payload }) => {
      state.assignTab = payload
    },
    setAssignTab: (state, action) => {
      const { tab } = action.payload
      state.assignTab = tab
    },
    getRole: (state, { payload }) => {
      state.role = payload
    },
    getAvailableMember: (state, { payload }) => {
      state.availableMember = payload
    },
    getCalendar: (state, { payload }) => {
      state.calendar = payload
    },
    getCalendarDetail: (state, { payload }) => {
      state.calendarDetail = payload
    },
    getCalendarList: (state, { payload }) => {
      state.calendarList = payload
    },
    getDetailCalendarLoading: (state, { payload }) => {
      state.detailCalendarLoading = payload
    }
  }
})
