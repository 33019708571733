import { createSlice } from "@reduxjs/toolkit"

export const initState = {
  dataLog: [],
  errorMsg: null,
  errorStatus: null
}

export const LogSlice = createSlice({
  name: "log",
  initialState: initState,
  reducers: {
    setData: (state, action) => {
      state.dataLog = action.payload
    }
  }
})
export const { setData } = LogSlice.actions
export default LogSlice.reducer
