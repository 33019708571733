import React from "react"

export const LogList = ({ data, dateTime }) => {
  return (
    <div className={"log-item d-flex justify-content-between align-items-center"}>
      <div className={"col-md-8 text-dark space-1 font-12"}>{data}</div>
      <div className={"col-md-4 text-right text-dark space-1 font-12"}>{dateTime}</div>
    </div>
  )
}
