import React from "react"
import { Search } from "../../atoms/Search"
import { Filter } from "../../atoms/Filter"

export const FilterCard = ({
  // prop for search
  isActiveSearch,
  placeholder,
  onSubmitForm,
  initialValues,
  name,
  innerRef,
  type = "text",
  trigerReset,

  // prop for filter
  filterInnerRef,
  filterHandleSubmit,
  filterFormInitialValues,
  filterComponent,
  filterOnReset,
  showFilter,
  handleOpenCloseFilter,
  filterOn,
  filterDisable,
  noneOverflow,
  btnDisable,
  uploadButton
}) => {
  return (
    <div className="filter-container">
      <div className="position-relative d-flex flex-wrap justify-content-between align-items-center mb-10">
        <Search
          placeholder={placeholder}
          onSubmitForm={onSubmitForm}
          initialValues={initialValues}
          isActiveSearch={isActiveSearch}
          name={name}
          innerRef={innerRef}
          type={type}
          trigerReset={trigerReset}
          disabled={filterDisable}
        />
        <div style={{ display: "flex", alignItems: "center ", gap: 12 }}>
          {uploadButton}
          <Filter
            filterInnerRef={filterInnerRef}
            filterHandleSubmit={filterHandleSubmit}
            filterFormInitialValues={filterFormInitialValues}
            filterComponent={filterComponent}
            filterOnReset={filterOnReset}
            showFilter={showFilter}
            handleOpenCloseFilter={handleOpenCloseFilter}
            filterOn={filterOn}
            disabled={filterDisable}
            noneOverflow={noneOverflow}
            btnDisable={btnDisable}
          />
        </div>
      </div>
    </div>
  )
}
