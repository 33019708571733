import React, { Fragment, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Skeleton } from "@material-ui/lab"

export const DateBox = ({
  value,
  currentValueDate,
  currentDate,
  dataList,
  classname,
  onClick,
  popupContainer,
  openPopup,
  clickPopup,
  popupID
}) => {
  const [data, setData] = useState(undefined)
  const [dataListContentCalendar, setDataListContentCalendar] = useState([])
  const { General } = useSelector((state) => state)

  useEffect(() => {
    if (data?.team_list?.length) {
      setDataListContentCalendar(data.team_list)
    } else {
      setDataListContentCalendar([])
    }
  }, [data?.team_list])

  useEffect(() => {
    if (data?.area_list?.length) {
      setDataListContentCalendar(data?.area_list)
    } else {
      setDataListContentCalendar([])
    }
  }, [data?.area_list])

  useEffect(() => {
    if (dataList?.length) {
      dataList.map((item) => {
        if (new Date(item?.date).getDate() === parseInt(value)) {
          if (new Date(item?.date).getMonth() === new Date(currentValueDate).getMonth()) {
            setData(item)
          }
        }
      })
    } else {
      setData(null)
    }
  }, [dataList])

  const onClickAction = () => {
    if (data) {
      const result = () => {
        const day = value
        const month = new Date(data?.date).getMonth() + 1
        const year = new Date(data?.date).getFullYear()
        const stringDate = `${year}-${month}-${day}`
        return new Date(stringDate)
      }
      onClick(result())
    }
    clickPopup()
  }

  return (
    <div className={"position-relative"}>
      {openPopup && popupContainer && data && popupID === value ? popupContainer : ""}
      <div
        onClick={onClickAction}
        className={`position-relative border p-5 px-5 d-flex flex-column align-items-start ${classname}`}
        style={{
          height: 150
        }}>
        <div className={"mb-2 d-flex align-items-center justify-content-center"}>
          <div
            style={{ width: 30, height: 30 }}
            className={`text-center d-flex align-items-center justify-content-center ${
              currentDate && "rounded-circle bg-primary text-white"
            }`}>
            <div className={"translate-y-[2px] "}>{value}</div>
          </div>
        </div>
        <div className={"w-100 h-100"}>
          {General.loading
            ? Array.from({ length: 3 }).map((item, index) => {
                return <Skeleton key={index} animation="wave" variant={"text"} height={24} />
              })
            : dataListContentCalendar.map((item, i) => (
                <Fragment key={i}>
                  {i < 2 && (
                    <div
                      className={"d-flex items-start mb-2 p-1 w-100 cursor-pointer"}
                      style={{
                        background: item?.mac ? "#DDEABF" : "#EFF8ED"
                      }}>
                      <div className={"bg-primary calendar_data_badge"}></div>
                      <div className={"text-xs ml-4 font-weight-bolder text-truncate"}>
                        {typeof item === "string"
                          ? item
                          : (item?.area_name ? item?.area_name : item?.team_name) ?? "-"}
                      </div>
                    </div>
                  )}
                </Fragment>
              ))}
          {dataListContentCalendar.length > 2 && (
            <div className={"font-weight-bolder text_more_calendar_box"}>
              {"+ " + (dataListContentCalendar.length - 2)} Lainnya
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
