import axios from "axios"
import { createSlice } from "@reduxjs/toolkit"
import { getUserToken } from "./userManagementAction"

const END_POINT = process.env.REACT_APP_API_ENDPOINT
const instance = axios.create({
  baseURL: END_POINT
})

export const http = () => {
  instance.interceptors.request.use(config => {
    const token = getUserToken()

    if (token) {
      config.headers.Authorization = "Bearer " + token
    }

    return config
  })

  return instance
}

export default instance

export const callTypes = {
  list: "list",
  action: "action",
  loadData: "loadData",
  uploadAction: "uploadAction",
  saveAction: "saveAction",
  loadListCombobox: "loadListCombobox"
}
export const initialDataResponse = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  currentPage: 0,
  currentElement: 0,
  entities: null,
  dataForEdit: null,
  lastError: null,
  isHaveError: false,
  error: "",
  finishPostData: 0,

  //AwsUpload
  isUploadImage: 1,
  imageUrl: null,
  uploadType: null,
  listUpload: false,
  imageUpload: null,
  businessDocument: null,
  idCardImage: null,

  selectListFieldOfficer: [],
  selectListMitra: [],
  bankList: [],
  provinceList: [],
  regencyList: [],
  districtList: [],
  subDistrictList: [],
  provinceListShop: [],
  regencyListShop: [],
  districtListShop: [],
  subDistrictListShop: []
}
export const sizePerPageList = [
  { text: "3", value: 3 },
  { text: "5", value: 5 },
  { text: "10", value: 10 },
  { text: "20", value: 20 },
  { text: "50", value: 50 },
  { text: "100", value: 100 }
]
export const initialFilter = {
  filter: {
    model: "",
    manufacture: "",
    VINCode: ""
  },
  sortOrder: "asc", // asc||desc
  sortField: "VINCode",
  pageNumber: 1,
  pageSize: 10
}

export const dataSlice = createSlice({
  name: "data",
  initialState: initialDataResponse,
  reducers: {
    catchError: (state, action) => {
      state.isHaveError = true
      state.listLoading = false
      state.error = "error"
      if (action.payload.callType === callTypes.saveAction) {
        state.actionsLoading = false
      } else if (action.payload.callType === callTypes.uploadAction) {
        state.isUploadImage = 0
        state.imageUrl = null
      } else if (action.payload.callType === callTypes.list) {
        state.listLoading = false
        state.entities = null
      } else {
        state.actionsLoading = false
        state.finishPostData = 3
      }
    },

    startCall: (state, action) => {
      state.finishPostData = 0
      state.isHaveError = false
      state.error = null
      state.listLoading = false
      state.selectList = []
      if (action.payload.callType === callTypes.saveAction) {
        state.finishPostData = 1
        state.actionsLoading = true
      } else if (action.payload.callType === callTypes.uploadAction) {
        state.isUploadImage = 0
      } else if (action.payload.callType === callTypes.list) {
        state.listLoading = true
        state.entities = null
      } else {
        state.actionsLoading = true
        // state.finishPostData = false;
      }
    },

    submitFetched: (state, actions) => {
      const { success } = actions.payload
      state.error = success
      state.finishPostData = 2
      state.actionsLoading = false
    },

    submitUploadAvatar: (state, actions) => {
      const { data } = actions.payload
      state.error = false
      state.uploadAvatar = data
    },

    submitBusinessDocument: (state, actions) => {
      const { data } = actions.payload
      state.businessDocument = data
      state.error = false
    },

    submitIdCardImage: (state, actions) => {
      const { data } = actions.payload
      state.idCardImage = data
      state.error = false
    },

    uploadImageToAws: (state, actions) => {
      const { data, typeUpload } = actions.payload
      state.isUploadImage = 1
      state.uploadType = typeUpload
      state.imageUrl = data
      state.error = false
    },

    loadSelectList: (state, action) => {
      const { data, isMitra } = action.payload
      if (isMitra) {
        state.selectListMitra = data
      } else {
        state.selectListFieldOfficer = data
      }
    },

    // findProducts
    tableFetched: (state, action) => {
      const { data, page, element, all_element } = action.payload

      state.listLoading = false
      state.error = null
      state.entities = data
      state.totalCount = all_element
      state.currentElement = element
      state.currentPage = page
    },
    dataFetched: (state, action) => {
      const { data } = action.payload
      state.listLoading = false
      state.dataForEdit = data
      state.error = null
    },
    bankFetched: (state, action) => {
      const { data } = action.payload
      state.actionsLoading = false
      state.bankList = data
      state.error = null
    },
    provinceFetched: (state, action) => {
      const { data } = action.payload
      state.actionsLoading = false
      state.provinceList = data
      state.error = null
    },
    regencyFetched: (state, action) => {
      const { data } = action.payload
      state.actionsLoading = false
      state.regencyList = data
      state.error = null
    },

    subDistrictFetched: (state, action) => {
      const { data } = action.payload
      state.actionsLoading = false
      state.subDistrictList = data
      state.error = null
    },

    districtFetched: (state, action) => {
      const { data } = action.payload
      state.actionsLoading = false
      state.districtList = data
      state.error = null
    },
    provinceShopFetched: (state, action) => {
      const { data } = action.payload
      state.actionsLoading = false
      state.provinceList = data
      state.error = null
    },
    regencyShopFetched: (state, action) => {
      const { data } = action.payload
      state.actionsLoading = false
      state.regencyListShop = data
      state.error = null
    },
    districtShopFetched: (state, action) => {
      const { data } = action.payload
      state.actionsLoading = false
      state.districtListShop = data
      state.error = null
    },
    subDistrictShopFetched: (state, action) => {
      const { data } = action.payload
      state.actionsLoading = false
      state.subDistrictListShop = data
      state.error = null
    },
    mitraDetailFetched: (state, action) => {
      const { data } = action.payload
      state.actionsLoading = false
      state.mitraDetail = data
      state.error = null
    }
  }
})
