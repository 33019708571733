import {
  FilterCard,
  LocationMolecule,
  RadioButtonGroup,
  SelectInput,
  TabsWithIcon
} from "../../../../component"
import { RowModule } from "../../../../component/atoms/RowModule/RowModule.jsx"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useSubheader } from "../../../../_metronic/layout"
import { useLocation } from "react-router"
import { useUrlParam } from "../../../hooks/useUrlParams"
import { loadUserLogin } from "../../../service/userManagementAction"
import { clearDataDetail, getAllMitra } from "../../../../redux/actions/MitraAction"
import { initialFilterTable } from "../../../../component/atoms/Tabel"
import { getUrlParsingPageFilter } from "../../../../config/EndpointCollection.js"
import { setDataTabMultiple, SetFilterAction } from "../../../../redux/actions/FilterAction"
import { RoutesConstants } from "../../../../constants/RoutesConstants"
import { Card, CardBody, CardHeader } from "../../../../_metronic/_partials/controls"
import { ListAll } from "./tab/ListAll"
import { ListArea } from "./tab/ListArea"
import { ListAreaKur } from "./tab/ListAreaKur"
import {
  getAreaKurList,
  getAreaNonKurList,
  getAreaVerifiedList,
  getPlantingStatusEnum
} from "../../../../redux/actions/AreaAction"
import { PRIVILEGE_ENUM } from "../../../../constants/PrivilegeConstants.js"

export const AreaListTeregistrasi = ({
  history,
  match: {
    params: { tab }
  }
}) => {
  const dispatch = useDispatch()
  const formRef = useRef()
  const subHeader = useSubheader()
  const location = useLocation()
  const urlParams = useUrlParam()
  const privileges = loadUserLogin().privilege

  const { General, Area, Mitralist } = useSelector((state) => state)

  const [search, setSearch] = useState({
    string_filter: ""
  })
  const [initialValue, setInitialValue] = useState({
    ...initialFilterTable,
    planting_status: "",
    mitra_id: "",
    province_id: "",
    regency_id: "",
    district_id: "",
    sub_district_id: ""
  })
  const [filterOn, setFilterOn] = useState(false)

  const [showFilter, setShowFilter] = useState(false)
  const [btnFilterDisable, setBtnFilterDisable] = useState(true)
  const [reset, setReset] = useState(false)
  const [mitra, setMitra] = useState("")
  const [stateMitra, setStateMitra] = useState()
  const [isActiveSearch, setIsActiveSearch] = useState(false)
  const [tabActive, setTabActive] = useState(tab === "area" ? 1 : tab === "area-kur" ? 2 : 0)
  const [queryStringObj, setQueryStringObj] = useState(undefined)
  const isReadOnly = false
  const [enumPlantingStatus, setEnumPlantingStatus] = useState([])
  const [plantStatus, setPlantStatus] = useState("")

  useEffect(() => {
    setTabActive(tab === "area" ? 1 : tab === "area-kur" ? 2 : 0)
  }, [tab])

  useEffect(() => {
    setEnumPlantingStatus(Area.plantingStatusEnum)
  }, [Area])

  useEffect(() => {
    subHeader.setTitle("Lahan Teregistrasi")
    subHeader.setButton(false)
    dispatch(getAllMitra())
    dispatch(getPlantingStatusEnum())
    return () => {
      dispatch(clearDataDetail())
    }
  }, [])

  useEffect(() => {
    if (Mitralist.data) {
      setStateMitra(
        Mitralist.data.map((value) => ({
          label: value.name,
          value: value.id_mitra
        }))
      )
    }
  }, [Mitralist.data])

  useEffect(() => {
    if (
      formRef.current.values.mitra_id ||
      formRef.current.values.province_id ||
      formRef.current.values.planting_status
    )
      setBtnFilterDisable(false)
    else setBtnFilterDisable(true)
  }, [formRef.current, mitra])

  useEffect(() => {
    if (queryStringObj?.string_filter) {
      setSearch({ string_filter: queryStringObj?.string_filter })
      setIsActiveSearch(true)
    }
    if (
      queryStringObj?.planting_status ||
      queryStringObj?.mitra_id ||
      queryStringObj?.province_id ||
      queryStringObj?.regency_id ||
      queryStringObj?.district_id ||
      queryStringObj?.sub_district_id
    ) {
      setFilterOn(true)
      const data = {
        ...initialFilterTable,
        string_filter: queryStringObj?.string_filter,
        planting_status: queryStringObj?.planting_status,
        mitra_id: queryStringObj?.mitra_id,
        province_id: queryStringObj?.province_id,
        regency_id: queryStringObj?.regency_id,
        district_id: queryStringObj?.district_id,
        sub_district_id: queryStringObj?.sub_district_id
      }
      if (data) {
        setInitialValue(data)
        setMitra(queryStringObj?.string_filter)
      }
    }
  }, [queryStringObj, dispatch])

  useEffect(() => {
    const result = location?.search
    fetchData(result)
  }, [location.search])

  useEffect(() => {
    setQueryStringObj(urlParams.getUrlParamsObj)
  }, [urlParams.getUrlParamsObj])

  const fetchData = (param) => {
    if (tabActive === 0) {
      return dispatch(getAreaVerifiedList(param))
    } else if (tabActive === 1) {
      return dispatch(getAreaNonKurList(param))
    } else if (tabActive === 2) {
      return dispatch(getAreaKurList(param))
    }
  }

  // search
  const searchFunc = (values) => {
    setSearch({ string_filter: values.string_filter })
    setIsActiveSearch(true)
    let param = {
      ...initialFilterTable,
      ...initialValue,
      string_filter: values.string_filter
    }
    const urlParam = getUrlParsingPageFilter(param)
    history.push({ pathname: location.pathname, search: urlParam })
  }

  const handleReset = () => {
    let param = {
      ...initialFilterTable,
      ...initialValue,
      string_filter: ""
    }
    const urlParam = getUrlParsingPageFilter(param)
    setSearch({ string_filter: "" })
    history.push({ pathname: location.pathname, search: urlParam })
  }

  // filter
  function onChangeMitra(e) {
    setMitra(e)
  }

  const filterComponent = (
    <>
      <Card className="card-custom card-shadowless">
        <CardHeader title="Mitra" className="px-0" />
        <CardBody className="px-0">
          <RowModule>
            <SelectInput
              name="mitra_id"
              onChange={onChangeMitra}
              editable={!isReadOnly}
              label="Nama Mitra"
              placeholder="Pilih Salah Satu"
              options={stateMitra}
              withoutFeedback={true}
              clearNow={true}
              value={mitra}
            />
          </RowModule>
        </CardBody>
      </Card>

      <LocationMolecule
        resetLocation={reset}
        name="filter"
        withoutFeedback={true}
        isReadOnly={isReadOnly}
        addressTitle="Lokasi"
        removeHorizontalPadding={true}
      />

      <Card className="card-custom card-shadowless">
        <CardHeader title="Status Tanam" className="px-0" />
        <CardBody className="px-0">
          {enumPlantingStatus.map((item, i) => (
            <RadioButtonGroup
              editable={false}
              key={i}
              label={item?.enum_value}
              value={item?.enum_name}
              onClick={() => setPlantStatus(item?.enum_name)}
              checked={plantStatus === item?.enum_name}
              name="planting_status"
            />
          ))}
        </CardBody>
      </Card>
    </>
  )
  const onSubmitForm = (e) => {
    setReset(false)
    const { values, submited } = e
    if (submited === true) {
      let param = {
        ...initialFilterTable,
        planting_status: values.planting_status,
        mitra_id: values.mitra_id,
        province_id: values.province_id,
        regency_id: values.regency_id,
        district_id: values.district_id,
        sub_district_id: values.sub_district_id,
        string_filter: search.string_filter
      }
      if (param) {
        setInitialValue(param)
        const urlParam = getUrlParsingPageFilter(param)
        history.push({ pathname: location.pathname, search: urlParam })
        setShowFilter(false)
        setFilterOn(true)
      }
    }
  }

  const filterOnReset = () => {
    dispatch(SetFilterAction())
    setReset(true)
    setMitra("")
    setShowFilter(false)
    setFilterOn(false)
    let param = {
      ...initialFilterTable,
      planting_status: "",
      mitra_id: "",
      province_id: "",
      regency_id: "",
      district_id: "",
      sub_district_id: "",
      string_filter: search.string_filter
    }
    setInitialValue(param)
    const urlParam = getUrlParsingPageFilter(param)
    history.push({ pathname: location.pathname, search: urlParam })
  }

  const handleOpenCloseFilter = (value) => {
    setShowFilter(value)
  }

  const changePage = useCallback(
    (param) => {
      let urlParam
      if (param.sortField != null) {
        urlParam = getUrlParsingPageFilter({
          ...initialValue,
          ...param,
          sort: param?.sortField ? param.sortField + "," + param.sortOrder : "",
          string_filter: search.string_filter
        })
      } else {
        urlParam = getUrlParsingPageFilter({
          ...initialValue,
          ...param,
          string_filter: search.string_filter
        })
      }
      history.push({ pathname: location.pathname, search: urlParam })
    },
    [dispatch, search, initialValue]
  )

  const changeTabs = (data) => {
    dispatch(setDataTabMultiple(data))
    setTabActive(data)
    let urlParam = ""
    let param = {
      ...initialValue,
      string_filter: search.string_filter
    }
    urlParam = getUrlParsingPageFilter(param)
    if (data === 1) {
      history.push(RoutesConstants.AREA.LIST_AREA_TEREGISTRASI("area") + urlParam ?? "")
    } else if (data === 2) {
      history.push(RoutesConstants.AREA.LIST_AREA_TEREGISTRASI("area-kur") + urlParam ?? "")
    } else {
      history.push(RoutesConstants.AREA.LIST_AREA_TEREGISTRASI("all") + urlParam ?? "")
    }
  }

  const getTabsContents = () => {
    const labelList = []
    const ComponentsList = []

    const all = (
      <ListAll
        data={Area}
        changePage={changePage}
        history={history}
        general={General}
        initialFilterTable={initialFilterTable}
        isActiveSearch={isActiveSearch}
        filterOn={filterOn}
      />
    )
    const area = (
      <ListArea
        data={Area}
        changePage={changePage}
        history={history}
        general={General}
        initialFilterTable={initialFilterTable}
        isActiveSearch={isActiveSearch}
        filterOn={filterOn}
      />
    )
    const areaKur = (
      <ListAreaKur
        data={Area}
        changePage={changePage}
        history={history}
        general={General}
        initialFilterTable={initialFilterTable}
        isActiveSearch={isActiveSearch}
        filterOn={filterOn}
      />
    )

    if (privileges.indexOf(PRIVILEGE_ENUM.LIST_AREA_REGISTERED_TAB_ALL) >= 0) {
      ComponentsList.push(all)
      labelList.push({
        label: "Semua",
        labelTooltip: "",
        icon: ""
      })
    }
    if (privileges.indexOf(PRIVILEGE_ENUM.LIST_AREA_REGISTERED_TAB_AREA) >= 0) {
      ComponentsList.push(area)
      labelList.push({
        label: "Lahan",
        labelTooltip: "",
        icon: ""
      })
    }
    if (privileges.indexOf(PRIVILEGE_ENUM.LIST_AREA_REGISTERED_TAB_AREA_KUR) >= 0) {
      ComponentsList.push(areaKur)
      labelList.push({
        label: "Lahan KUR",
        labelTooltip: "",
        icon: ""
      })
    }

    return {
      labels: labelList,
      contents: ComponentsList
    }
  }

  return (
    <>
      <FilterCard
        // seacrh
        isActiveSearch={isActiveSearch}
        placeholder={"Masukkan nama lahan"}
        onSubmitForm={searchFunc}
        initialValues={search}
        name={"string_filter"}
        innerRef={formRef}
        trigerReset={handleReset}
        // filter
        filterInnerRef={formRef}
        filterHandleSubmit={onSubmitForm}
        filterFormInitialValues={initialValue}
        filterComponent={filterComponent}
        filterOnReset={filterOnReset}
        showFilter={showFilter}
        handleOpenCloseFilter={handleOpenCloseFilter}
        filterOn={filterOn}
        btnDisable={btnFilterDisable}></FilterCard>
      <Card>
        <RowModule>
          <TabsWithIcon
            labelTab={getTabsContents().labels}
            componentTab={getTabsContents().contents}
            changeTab={changeTabs}
            initValue={tabActive}
          />
        </RowModule>
      </Card>
    </>
  )
}