import React, { useEffect, useState } from "react"
import { Card, CardBody } from "../../../../../../_metronic/_partials/controls"
import { SelectInput, TextAreaInput, TextInput } from "../../../../../../component"
import { handleSpace } from "../../../../../helpers/TextHelper"
import { RowModule } from "../../../../../../component/atoms/RowModule/RowModule.jsx"
import { useFormikContext } from "formik"
import { useDispatch, useSelector } from "react-redux"
import {
  getAvailableMember,
  getAvailableMemberEdit
} from "../../../../../../redux/actions/FieldOfficerAction"
import { renderLabelRequired } from "../../../../../helpers/FormikHelper"

export const CreateEditForm = ({ isReadOnly, setIsValid, btnSubmit, btnCancel, id }) => {
  const { values: formValues } = useFormikContext()
  const [stateTeam, setStateTeam] = useState([])
  const dispatch = useDispatch()
  const { FieldOfficer } = useSelector(state => state)

  useEffect(() => {
    if (id) {
      dispatch(getAvailableMemberEdit(id))
    } else {
      dispatch(getAvailableMember())
    }
  }, [])

  useEffect(() => {
    let newStateTeam = stateTeam
    newStateTeam.map(item => {
      if (
        item.value === formValues["member_list_1"] ||
        item.value === formValues["member_list_2"]
      ) {
        item.isDisabled = true
      } else {
        item.isDisabled = false
      }
    })
    setStateTeam(newStateTeam)
  }, [formValues, stateTeam])

  useEffect(() => {
    if (FieldOfficer.availableMember) {
      setStateTeam(
        FieldOfficer.availableMember.map(value => ({
          label: value.name,
          value: value.id,
          isDisabled: false
        }))
      )
    }
  }, [FieldOfficer])

  useEffect(() => {
    if (formValues["name"] && formValues["member_list_1"]) {
      setIsValid(false)
    } else {
      setIsValid(true)
    }
  }, [formValues])

  return (
    <>
      <Card className="card-custom card-shadowless">
        <CardBody>
          <h4 className="card-label mb-8">{id ? "Edit" : "Tambah"} Tim Drone</h4>
          <RowModule customColumnCss="mb-5 col-lg-12 col-sm-12 col-md-12">
            <TextInput
              onKeyDown={handleSpace}
              editable={!isReadOnly}
              label={renderLabelRequired("Nama Tim")}
              name="name"
              placeholder="Input nama tim"
            />
          </RowModule>
          <RowModule customColumnCss="mb-5 col-lg-12 col-sm-12 col-md-12">
            <SelectInput
              key={0}
              name="member_list_1"
              editable={!isReadOnly}
              label={renderLabelRequired("Anggota Tim")}
              placeholder="Input anggota tim"
              options={stateTeam}
              withoutFeedback={false}
              clearNow={true}
              value={formValues["member_list_1"]}
            />
            <SelectInput
              key={1}
              name="member_list_2"
              editable={!isReadOnly}
              placeholder="Input anggota tim"
              options={stateTeam}
              withoutFeedback={false}
              clearNow={true}
              value={formValues["member_list_2"]}
            />
          </RowModule>
          <RowModule customColumnCss="mb-5 col-lg-12 col-sm-12 col-md-12">
            <TextAreaInput
              editable={isReadOnly}
              label="Deskripsi"
              name="description"
              placeholder="Input Deskripsi"
              type={"text"}
            />
          </RowModule>
          <div className={"d-flex justify-content-end align-items-center"}>
            {btnCancel}
            {btnSubmit}
          </div>
        </CardBody>
      </Card>
    </>
  )
}
