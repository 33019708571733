import React, { useEffect, useState } from "react"
import { Form, Formik } from "formik"

import "../../../_metronic/_assets/sass/pages/error/error-3.scss"
import "../../../_metronic/_assets/sass/components/_wizard.scss"
import "../../../_metronic/_assets/sass/pages/wizard/wizard-4.scss"
import { Card, CardBody } from "../../../_metronic/_partials/controls"
import ButtonAction from "../Button"

export function TabsInputComponent({
  steps,
  stepDescription,
  componentTab,
  isEditTabs,
  formId,
  formInitialValues,
  validationSchema,
  onSubmitForm,
  innerRef,
  onChangeStep,
  backToStep
}) {
  const [activeStep, setActiveStep] = useState(0)
  const currentValidationSchema = validationSchema[activeStep]
  const isLastStep = activeStep === steps.length - 1

  useEffect(() => {
    if (activeStep > 0) {
      setActiveStep(backToStep)
    }
  }, [backToStep])

  useEffect(() => {
    if (onChangeStep) {
      return onChangeStep(activeStep)
    }
  }, [activeStep])

  function _handleSubmit(values, actions) {
    if (activeStep === steps.length - 2) onSubmitForm({ values: values, submited: false })
    if (isLastStep) {
      onSubmitForm({ values: values, submited: true })
    } else {
      setActiveStep(activeStep + 1)
      actions.setTouched({})
      actions.setSubmitting(false)
    }
  }

  function _handleBack() {
    setActiveStep(activeStep - 1)
  }

  function getStep(value) {
    return componentTab[value]
  }

  function isThisState(index) {
    if (activeStep === index) {
      return "current"
    } else {
      return "!current"
    }
  }

  function navLinkActive(index) {
    if (activeStep === index) {
      return " nav-link active"
    } else {
      return " cursor-pointer nav-link"
    }
  }

  function getContentTab() {
    return (
      <>
        {activeStep !== steps.length && (
          <Formik
            enableReinitialize
            innerRef={innerRef}
            initialValues={formInitialValues}
            validationSchema={currentValidationSchema}
            onSubmit={_handleSubmit}>
            {() => (
              <Form id={formId} className="form">
                <div className="row justify-content-center">
                  <div className="col-xl-9">
                    <div
                      className="my-6 step"
                      data-wizard-type="step-content"
                      data-wizard-state="current">
                      {getStep(activeStep)}
                    </div>
                    {isEditTabs || (
                      <div className="d-flex justify-content-between py-4 mt-10">
                        <div className="mr-2">
                          {activeStep !== 0 && (
                            <ButtonAction
                              onClick={_handleBack}
                              text="Previous"
                              className="btn btn-secondary font-weight-bolder px-9 py-4"></ButtonAction>
                          )}
                        </div>
                        {isLastStep || (
                          <div className="mr-2">
                            <ButtonAction
                              text="Next"
                              // disabled={isSubmitting}
                              type="submit"
                              className="btn btn-primary font-weight-bolder px-9 py-4"
                            />
                          </div>
                        )}
                        {/* {isLastStep && (
                                       <div className='mr-2'>
                                          <ButtonAction
                                             text='Submit'
                                             disabled={isSubmitting}
                                             type='submit'
                                             className='btn btn-primary font-weight-bolder px-9 py-4'
                                          />
                                       </div>
                                    )} */}
                      </div>
                    )}
                  </div>
                </div>
                {/*<FormikHelper isShow={true} />*/}
              </Form>
            )}
          </Formik>
        )}
      </>
    )
  }

  if (isEditTabs) {
    return (
      <>
        <div className="d-flex flex-column-fluid">
          <div className="container pl-0 pr-0">
            <div className="card card-custom">
              <div className="card-header card-header-tabs-line nav-tabs-line-3x">
                <div className="card-toolbar">
                  <div className="nav nav-tabs nav-bold nav-tabs-line nav-tabs-line-3x">
                    {steps.map((item, index) => {
                      return (
                        <div
                          key={"stepp" + index + formId}
                          className="nav-item mr-3 "
                          onClick={() => setActiveStep(index)}>
                          <div className={navLinkActive(index)} data-toggle="tab">
                            {/*/!* <span className='nav-icon'>*/}
                            {/*   <SVG*/}
                            {/*      src={toAbsoluteUrl(*/}
                            {/*         "/media/svg/icons/General/Search.svg"*/}
                            {/*      )}*/}
                            {/*   />*/}
                            {/*</span> *!/*/}
                            <span className="nav-text font-size-lg">{item}</span>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
              <div className="card-body px-0">{getContentTab()}</div>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <Card className="card-custom card-shadowless rounded-top-1">
        <CardBody className="p-0">
          <div className="row">
            {/* justify-content-center py-8 px-8 */}
            <div className="col-xl-12 col-xxl-12">
              <div className="wizard wizard-4 border rounded">
                <div className="wizard-nav">
                  <div className="wizard-steps">
                    {steps.map((item, index) => {
                      return (
                        <div
                          key={index + formId}
                          className="wizard-step"
                          style={{ flex: 1, borderRadius: 0 }}
                          data-wizard-type="step"
                          data-wizard-state={isThisState(index)}>
                          <div className="wizard-wrapper">
                            {item != null ? <div className="wizard-number">{index + 1}</div> : null}
                            <div className="wizard-label">
                              <div className="wizard-title">{item}</div>
                              <div className="wizard-desc">{stepDescription[index]}</div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
                {getContentTab()}
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  )
}
