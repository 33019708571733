import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { clearDataDetail, getAreaMap } from "../../../../redux/actions/AreaAction.jsx"
// import { getPlantTypeFilter } from "../../../redux/actions/AreaAction"
import { getAllMitra } from "../../../../redux/actions/MitraAction.jsx"
import { useSubheader } from "../../../../_metronic/layout/index.js"
import { Card, CardBody, CardHeader } from "../../../../_metronic/_partials/controls/index.js"
import { RowModule } from "../../../../component/atoms/RowModule/RowModule.jsx"
import { AreaMap } from "./component/AreaMap.jsx"
import { FilterCard } from "../../../../component/molecule/index.jsx"
import {
  CheckboxLabelButton,
  LocationMolecule,
  RadioButtonGroup,
  SelectInput,
  TextInput
} from "../../../../component/index.jsx"
import { Popup } from "../../../../component/atoms/Popup/index.jsx"
import { getLegendMap } from "../../../../redux/actions/MasterDataAction.jsx"

export const ListAreaMap = ({ history }) => {
  const subHeader = useSubheader()
  const dispatch = useDispatch()
  const { Areamap, Mitralist, MasterData } = useSelector((state) => state)
  const formRef = useRef()
  const formRefFilter = useRef()
  const [filterOn, setFilterOn] = useState(false)
  const [search, setSearch] = useState({
    area_name: ""
  })
  const [stateMitra, setStateMitra] = useState()
  const [showFilter, setShowFilter] = useState(false)
  const [initialValue, setInitialValue] = useState({
    mitra_id: "",
    province_id: "",
    regency_id: "",
    district_id: "",
    sub_district_id: "",
    min: "",
    max: "",
    planting_status: "",
    plant_type: []
  })
  const isReadOnly = false
  const [lahan, setLahan] = useState([])
  const [centerMap, setCenterMap] = useState({})
  const [radiusData, setRadiusData] = useState(4)
  const [radius, setRadius] = useState(4000)
  const [zoomMap, setZoomMap] = useState(14)
  const [isFilter, setIsFilter] = useState(false)
  const [strokeOpacity, setStrokeOpacity] = useState(0.3)
  const [currentLoc, setCurrentLoc] = useState({})
  const [showPopupError, setShowPopupError] = useState(false)
  const [reset, setReset] = useState(false)
  const [mitra, setMitra] = useState("")
  const [min, setMin] = useState("")
  const [max, setMax] = useState("")
  const [isStatus, setIsStatus] = useState([
    {
      label: "Teregistrasi",
      enum: "ACTIVE",
      checked: false
    },
    {
      label: "Pending",
      enum: "PENDING",
      checked: false
    },
    {
      label: "Rejected",
      enum: "REJECTED",
      checked: false
    },
    {
      label: "Unassign",
      enum: "UNASSIGNED",
      checked: false
    },
    {
      label: "Calon Lahan",
      enum: "LEAD_AREA",
      checked: false
    }
  ])
  const [statusValue, setStatusValue] = useState([])
  const [btnFilterDisable, setBtnFilterDisable] = useState(true)
  const [statePlantingStatus] = useState([
    {
      label: "Active",
      value: "ACTIVE"
    },
    {
      label: "Inactive",
      value: "INACTIVE"
    }
  ])
  const [plantingStatusSelect, setPlantingStatusSelect] = useState()
  const [listLegendMap, setListLegendMap] = useState([])
  // const [plantType, setPlantType] = useState([])
  // const [plantTypeValue, setPlantTypeValue] = useState([])
  useEffect(() => {
    if (
      formRefFilter.current.values.mitra_id ||
      formRefFilter.current.values.province_id ||
      formRefFilter.current.values.min ||
      formRefFilter.current.values.max ||
      formRefFilter.current.values.planting_status ||
      formRefFilter.current.values.plant_type
    )
      setBtnFilterDisable(false)
    else setBtnFilterDisable(true)
  }, [formRefFilter.current, mitra, min, max, plantingStatusSelect])

  useEffect(() => {
    dispatch(getAllMitra())
    dispatch(getLegendMap())
    // dispatch(getPlantTypeFilter())
    return () => {
      dispatch(clearDataDetail())
    }
  }, [])

  // useEffect(() => {
  //   if (master-data?.plantType) {
  //     setPlantType(
  //       master-data?.plantType.map((value) => ({
  //         label: value?.enum_string,
  //         enum: value?.enum_value,
  //         checked: false
  //       }))
  //     )
  //   }
  // }, [master-data?.plantType])

  useEffect(() => {
    if (MasterData.legendMap) {
      setListLegendMap(MasterData.legendMap)
    }
  }, [MasterData.legendMap])

  useEffect(() => {
    if (Mitralist.data) {
      setStateMitra(
        Mitralist.data.map((value) => ({
          label: value.name,
          value: value.id_mitra
        }))
      )
    }
  }, [Mitralist.data])

  useEffect(() => {
    if (Areamap.pageData.length > 0) {
      setLahan(
        Areamap.pageData.map((item) => {
          return {
            ...item,
            info_marker: false
          }
        })
      )
    } else {
      setLahan([])
    }
  }, [Areamap])

  useEffect(() => {
    subHeader.setButton(null)
    subHeader.setTitle("Lokasi Lahan")
  }, [])

  const changeZoom = (data, current) => {
    setCenterMap(current)
    if (!isFilter) {
      switch (data) {
        case 16:
          return setRadiusData(2), setRadius(2000)
        case 15:
          return setRadiusData(3), setRadius(3000)
        case 14:
          return setRadiusData(4), setRadius(4000)
        case 13:
          return setRadiusData(8), setRadius(8000)
        case 12:
          return setRadiusData(12), setRadius(12000)
        case 11:
          return setRadiusData(24), setRadius(24000)
        case 10:
          return setRadiusData(48), setRadius(48000)
        case 9:
          return setRadiusData(96), setRadius(96000)
        case 8:
          return setRadiusData(150), setRadius(150000)
        case 7:
          return setRadiusData(700), setRadius(700000)
        case 6:
          return setRadiusData(300), setRadius(300000)
        case 5:
          return setRadiusData(600), setRadius(600000)
      }
    }
  }

  useEffect(() => {
    if (!isFilter) {
      if (centerMap.lat) {
        let param = {
          lat: centerMap.lat,
          lon: centerMap.lng,
          radius: radiusData,
          area_status: statusValue
        }
        dispatch(getAreaMap(param))
      }
    }
  }, [centerMap, radius, radiusData])

  const handleInfo = (id) => {
    const placeIndex = lahan.findIndex((item) => {
      if (item.status === "LEAD_AREA") return item.area_lead_id === id
      else return item.area_id === id
    })
    const update = {
      ...lahan[placeIndex]
    }
    update.info_marker = !update.info_marker

    const newPlace = [...lahan]
    newPlace[placeIndex] = update
    setLahan(newPlace)
  }

  const onLoadMap = (data) => {
    let param = {
      lat: data.lat,
      lon: data.lng,
      radius: radiusData
    }
    setCurrentLoc(param)
  }

  useEffect(() => {
    if (isFilter) {
      if (Areamap.pageData.length === 0) {
        setShowPopupError(true)
      }
    }
  }, [Areamap.pageData])

  const onSubmitForm = (e) => {
    setReset(false)
    const { values, submited } = e
    setMin(values.min)
    setMax(values.max)
    setZoomMap(10)
    setStrokeOpacity(0)
    setIsFilter(true)
    if (submited === true) {
      let param = {
        mitra_id: values.mitra_id,
        province_id: values.province_id,
        regency_id: values.regency_id,
        district_id: values.district_id,
        sub_district_id: values.sub_district_id,
        min: values.min,
        max: values.max,
        area_name: search.area_name,
        area_status: statusValue,
        planting_status: values.planting_status
        // plant_type: plantTypeValue
      }

      if (param) {
        setInitialValue(param)
        dispatch(getAreaMap(param))
        setShowFilter(false)
        setFilterOn(true)
      }
    }
  }

  const onReset = () => {
    setReset(true)
    setMitra("")
    setMin("")
    setMax("")
    setIsFilter(false)
    setStrokeOpacity(0.3)
    setInitialValue({
      mitra_id: "",
      province_id: "",
      regency_id: "",
      district_id: "",
      sub_district_id: "",
      min: "",
      max: "",
      planting_status: "",
      plant_type: ""
    })
    // setPlantTypeValue([])
    setPlantingStatusSelect("")
    setStatusValue([])
    let newStatus = isStatus.map((el) => ({
      label: el.label,
      enum: el.enum,
      checked: false
    }))
    setIsStatus(newStatus)
    let param = {
      mitra_id: "",
      province_id: "",
      regency_id: "",
      district_id: "",
      sub_district_id: "",
      min: "",
      max: "",
      area_name: search.area_name,
      area_status: "",
      planting_status: "",
      plant_type: ""
    }
    // setPlantType(
    //   plantType.map((value) => ({
    //     label: value.label,
    //     enum: value?.enum,
    //     checked: false
    //   }))
    // )
    if (search.area_name.length > 0) {
      dispatch(getAreaMap(param))
    } else {
      dispatch(getAreaMap(currentLoc))
      setZoomMap(14)
      setRadius(4000)
    }
    setShowFilter(false)
    setFilterOn(false)
  }

  const handleCenter = (data) => {
    if (!isFilter) {
      let param = {
        lat: data.lat,
        lon: data.lng,
        radius: radiusData,
        area_status: statusValue
      }
      setCenterMap(data)
      dispatch(getAreaMap(param))
    }
  }

  const seacrhFunc = (values) => {
    setIsFilter(true)
    setZoomMap(10)
    setStrokeOpacity(0)
    setSearch({
      area_name: values.area_name
    })
    let param = {
      ...initialValue,
      area_name: values.area_name
    }
    dispatch(getAreaMap(param))
  }

  const handleReset = () => {
    setIsFilter(false)
    setStrokeOpacity(0.3)
    setSearch({
      area_name: ""
    })
    let param = {
      ...initialValue,
      area_name: ""
    }
    if (filterOn) {
      dispatch(getAreaMap(param))
    } else {
      dispatch(getAreaMap(currentLoc))
      setZoomMap(14)
      setRadius(4000)
    }
  }

  const handleOpenCloseFilter = (value) => {
    setShowFilter(value)
  }

  function onChangeMitra(e) {
    setMitra(e)
  }

  // const handlePlantType = (data) => {
  //   const { name, checked } = data.target
  //   let filtered = plantType.filter((item) => item.enum === name)
  //   let newList = {
  //     label: filtered[0].label,
  //     enum: filtered[0].enum,
  //     checked: checked
  //   }
  //   let newPlantType = plantType.map((el) => (el.enum === newList.enum ? { ...newList } : el))
  //   setPlantType(newPlantType)
  //   if (checked) {
  //     setPlantTypeValue([...plantTypeValue, name])
  //   } else {
  //     let filteredPlantType = plantTypeValue.filter((item) => item !== name)
  //     setPlantTypeValue(filteredPlantType)
  //   }
  // }

  const filterComponent = (
    <>
      <Card className="card-custom card-shadowless">
        <CardHeader title="Mitra" className="px-0" />
        <CardBody className="px-0">
          <RowModule>
            <SelectInput
              name="mitra_id"
              onChange={onChangeMitra}
              editable={!isReadOnly}
              label="Nama Mitra"
              placeholder="Pilih Salah Satu"
              options={stateMitra}
              withoutFeedback={true}
              clearNow={true}
              value={mitra}
            />
          </RowModule>
        </CardBody>
      </Card>

      <LocationMolecule
        resetLocation={reset}
        name="filter"
        withoutFeedback={true}
        isReadOnly={isReadOnly}
        addressTitle="Lokasi"
        removeHorizontalPadding={true}
      />
      <Card className="card-custom card-shadowless">
        <CardHeader title="Luas Lahan (m2)" className="px-0" />
        <CardBody className="px-0">
          <RowModule>
            <TextInput
              editable={!isReadOnly}
              label="Minimal"
              name="min"
              placeholder="Nilai Minimal"
              withoutFeedback={true}
              value={min}
              onChangeValue={setMin}
            />
          </RowModule>
          <RowModule>
            <TextInput
              editable={!isReadOnly}
              label="Maksimal"
              name="max"
              placeholder="Nilai Maksimal"
              withoutFeedback={true}
              value={max}
              onChangeValue={setMax}
            />
          </RowModule>
        </CardBody>
      </Card>
      <Card className="card-custom card-shadowless">
        <CardHeader title="Status Tanam" className="px-0" />
        <CardBody className="px-0">
          {statePlantingStatus.map((item) => (
            <RadioButtonGroup
              editable={false}
              key={item.value}
              checked={plantingStatusSelect == item.value}
              name="planting_status"
              label={item.label}
              value={item.value}
            />
          ))}
        </CardBody>
      </Card>
      {/*<Card className={"card-custom card-shadowless"}>*/}
      {/*  <CardHeader title="Jenis Tanaman" className="px-0" />*/}
      {/*  <CardBody className="px-0">*/}
      {/*    <CheckboxLabelButton*/}
      {/*      formInitialValues={plantTypeValue}*/}
      {/*      isStatus={plantType}*/}
      {/*      name={"plantType"}*/}
      {/*      handleChange={handlePlantType}*/}
      {/*    />*/}
      {/*  </CardBody>*/}
      {/*</Card>*/}
    </>
  )

  useEffect(() => {
    let param = {
      ...initialValue,
      ...currentLoc,
      radius: radiusData,
      area_name: search.area_name,
      area_status: statusValue
    }
    if (param?.lat && param?.lon && param?.radius) {
      dispatch(getAreaMap(param))
    }
  }, [statusValue, currentLoc])

  const handleStatus = (data) => {
    const { name, checked } = data.target
    let filteredStatus = isStatus.filter((item) => item.enum === name)
    let newListStatus = {
      label: filteredStatus[0].label,
      enum: filteredStatus[0].enum,
      checked: checked
    }
    let newStatus = isStatus.map((el) =>
      el.enum === newListStatus.enum ? { ...newListStatus } : el
    )
    setIsStatus(newStatus)
    if (checked) {
      setStatusValue([...statusValue, name])
    } else {
      let filteredStatus = statusValue.filter((item) => item !== name)
      setStatusValue(filteredStatus)
    }
  }

  return (
    <>
      <Popup
        type={"empty"}
        body={<span>Data kosong</span>}
        centered={true}
        persistent={true}
        show={showPopupError}
        onClick={() => {
          setShowPopupError(false)
        }}
      />
      <FilterCard
        // seacrh
        placeholder={"Masukkan nama lahan"}
        onSubmitForm={seacrhFunc}
        initialValues={search}
        name={"area_name"}
        innerRef={formRefFilter}
        trigerReset={handleReset}
        // filter
        filterInnerRef={formRefFilter}
        filterHandleSubmit={onSubmitForm}
        filterFormInitialValues={initialValue}
        filterComponent={filterComponent}
        filterOnReset={onReset}
        showFilter={showFilter}
        handleOpenCloseFilter={handleOpenCloseFilter}
        filterOn={filterOn}
        btnDisable={btnFilterDisable}></FilterCard>

      <Card>
        <RowModule>
          <Card className="card-custom card-shadowless p-12 mb-0">
            <div className={"mb-10"}>
              <CheckboxLabelButton
                formInitialValues={statusValue}
                isStatus={isStatus}
                name={"status"}
                innerRef={formRef}
                handleChange={handleStatus}
              />
            </div>
            <AreaMap
              onLoadMap={onLoadMap}
              zoom={zoomMap}
              area={lahan}
              handleInfo={handleInfo}
              history={history}
              handleCenter={handleCenter}
              radius={radius}
              changeZoom={changeZoom}
              strokeOpacity={strokeOpacity}
              legendMap={listLegendMap}
              isFilter={isFilter}
            />
          </Card>
        </RowModule>
      </Card>
    </>
  )
}