import { TableView } from "../../../../component"
import React, { useEffect, useState } from "react"
import { formatterRowComponent } from "../../../../component/atoms/Tabel"
import { toMeter } from "../../../helpers/TextHelper"

export const TableTeamSchedule = ({ data }) => {
  const [dataList, setDataList] = useState([])

  useEffect(() => {
    if (data) {
      setDataList(data)
    }
  }, [data])

  const uiID = (e) => {
    return (
      <>
        <div className={"font-weight-bolder"}>{e?.area_code ?? "-"}</div>
        <div>{e?.land_area ? toMeter(e?.land_area) : "-"}</div>
      </>
    )
  }

  const UIMitra = (e) => {
    return (
      <>
        <div className={"font-weight-bolder"}>{e?.mitra_name ?? "-"}</div>
        <div>{e?.mitra_code ?? "-"}</div>
      </>
    )
  }
  const UiActivity = (e) => {
    return (
      <>
        <div className={"font-weight-bolder"}>{e?.activity_name ?? "-"}</div>
      </>
    )
  }
  const colums = [
    {
      dataField: "area_code",
      text: "ID",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiID
      },
      headerStyle: () => {
        return { width: "15%" }
      }
    },
    {
      dataField: "activity_name",
      text: "AKTIVITAS",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: UiActivity
      }
    },
    {
      dataField: "commodity",
      text: "KOMODITAS",
      formatter: formatterRowComponent
    },
    {
      dataField: "mitra_name",
      text: "MITRA",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: UIMitra
      }
    }
  ]

  const changePage = () => {
    return
  }

  return (
    <div className={"table_list_popup_schedule"} style={{ textAlign: "start" }}>
      <TableView
        callbackAfterPageChange={changePage}
        columnProperties={colums}
        dataTable={dataList}
        currentPage={0}
        currentElement={0}
        loadingGetData={false}
        ignoreSinglePage
        showingTotalPage={false}
        rowClasses={() => {
          return "cursor-pointer"
        }}
      />
    </div>
  )
}

export const TableDetailDroneTeam = ({ data }) => {
  const [dataListDetailTeam, setDataListDetailTeam] = useState([])
  useEffect(() => {
    if (data) {
      setDataListDetailTeam(data)
    }
  }, [data])
  const uiID = (e) => {
    return (
      <>
        <div className={"font-weight-bolder"}>{e?.code ?? "-"}</div>
      </>
    )
  }
  const colums = [
    {
      dataField: "area_code",
      text: "ID FO",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiID
      },
      headerStyle: () => {
        return { width: "15%" }
      }
    },
    {
      dataField: "name",
      text: "NAMA",
      formatter: formatterRowComponent
    },
    {
      dataField: "phone_number",
      text: "NO. HP",
      formatter: formatterRowComponent,
      headerStyle: () => {
        return { width: "15%" }
      }
    }
  ]
  return (
    <div className={"table_list_popup_schedule"}>
      <TableView
        columnProperties={colums}
        dataTable={dataListDetailTeam}
        loadingGetData={false}
        ignoreSinglePage
        showingTotalPage={false}
        rowClasses={() => {
          return "cursor-pointer"
        }}
      />
    </div>
  )
}
