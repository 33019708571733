import { Card } from "react-bootstrap"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../_metronic/_helpers"
import React from "react"
import { ICON_CONSTANS } from "../../../constants/ImageConstants"

export const CardImage = ({ name, className, onDelete, imageUrl }) => {
  return (
    <>
      <Card
        className={`card-image-preview d-flex justify-content-center align-items-center ${className}`}
        key={name}>
        <img src={imageUrl} alt={name} />
        <div className={"card-hover"}>
          <div onClick={onDelete}>
            <SVG src={toAbsoluteUrl(ICON_CONSTANS.IC_BULLET_REMOVE)} />
          </div>
        </div>
      </Card>
    </>
  )
}
