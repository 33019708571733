import React from "react"
import PropTypes from "prop-types"
import { Alert } from "react-bootstrap"

const AlertComponent = prop => {
  const { isOpen, variant, titleText, description, closeNegativeCase } = prop
  if (isOpen) {
    return (
      <Alert open={false} variant={variant} onClose={closeNegativeCase} dismissible>
        <Alert.Heading>{titleText}</Alert.Heading>
        <p>{description}</p>
      </Alert>
    )
  } else {
    return <></>
  }
}

export default AlertComponent

AlertComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  variant: PropTypes.oneOf([
    "primary",
    "secondary",
    "success",
    "danger",
    "warning",
    "info",
    "light",
    "dark"
  ]).isRequired
}
