import React, { useEffect, useState } from "react"
import { FieldFeedbackLabel } from "./FieldFeedbackLabel"
import { useSelector } from "react-redux"

const getFieldCSSClasses = (touched, errors, editable) => {
  const classes = ["form-control"]
  if (!editable) {
    if (touched && errors) {
      classes.push("is-invalid")
    }

    if (touched && !errors) {
      classes.push("is-valid")
    }
  }
  return classes.join(" ")
}

export function Textarea({
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  editable = true,
  customFeedbackLabel,
  value,
  hidden,
  placeholder,
  onKeyUp,
  required
}) {
  const { General } = useSelector((state) => state)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(General.loading)
  }, [General])
  const disabledInput = () => {
    if (editable) {
      return !loading
    }
    return editable
  }
  useEffect(() => {
    if (value) {
      if (field.value !== value) {
        form.setFieldValue(field.name, value)
      }
    }
  }, [value])

  return (
    <>
      {label && (
        <label>
          {label} {required && <span style={{ color: "red" }}>*</span>}
        </label>
      )}
      <textarea
        className={getFieldCSSClasses(form.touched[field.name], form.errors[field.name], editable)}
        hidden={hidden}
        disabled={disabledInput()}
        {...field}
        name={field.name}
        value={field.value}
        placeholder={placeholder}
        onKeyUp={onKeyUp}
      />
      {form.errors[field.name] && (
        <FieldFeedbackLabel
          error={form.errors[field.name]}
          label={label}
          customFeedbackLabel={customFeedbackLabel}
          touched={form.touched[field.name]}
          type={"text"}
          value={value}
        />
      )}
    </>
  )
}