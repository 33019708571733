import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSubheader } from "../../../../../../_metronic/layout/index.js"
import { Card, Form } from "react-bootstrap"
import { CardBody } from "../../../../../../_metronic/_partials/controls/index.js"
import { ChipsMultipleInput } from "../../../../../../component/atoms/ChipsMultipleInput.jsx"
import { useDispatch, useSelector } from "react-redux"
import {
  addDataRegional,
  clearDetailRegional,
  EdiDataRegional,
  getDetailRegional,
  getListProvinceEditRegional,
  getListProvinceForCreateRegional
} from "../../../../../../redux/actions/MasterDataAction.jsx"
import { useFormik } from "formik"
import { RoutesConstants } from "../../../../../../constants/RoutesConstants.js"
import { PopupQuestion } from "../../../../../../component/atoms/Popup/index.jsx"
import { useParams } from "react-router"
import { MASTER } from "../../../../../../constants/InitTypeConstants.js"
import swal from "sweetalert"
import { ILLUSTRATION_ASSETS_V2 } from "../../../../../../constants/ImageConstants.js"
import { useHistory } from "react-router-dom"

export function CreateRegionalPage() {
  const { t } = useTranslation()
  const { MasterData, General } = useSelector((state) => state)
  const subheader = useSubheader()
  const dispatch = useDispatch()
  const { id } = useParams()
  const history = useHistory()

  const [listProvince, setListProvince] = useState([])
  const [disableBtn, setDisableBtn] = useState(true)
  const [selectedListProvince, setSelectedListProvince] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [dataForm, setDataForm] = useState()
  const [dataDetail, setDataDetail] = useState()

  const formik = useFormik({
    initialValues: {
      name: "",
      province: []
    },
    onSubmit: (values) => {
      setShowModal(true)
      setDataForm({
        regional_name: values.name,
        province_id: selectedListProvince.map((item) => item.value)
      })
    }
  })

  useEffect(() => {
    if (formik.values.name && selectedListProvince.length > 0) {
      setDisableBtn(false)
    } else {
      setDisableBtn(true)
    }
  }, [formik.values, selectedListProvince])

  useEffect(() => {
    fetchData()
    return () => {
      dispatch(clearDetailRegional())
    }
  }, [])

  useEffect(() => {
    if (MasterData?.detailRegional) {
      setDataDetail(MasterData?.detailRegional)
    }
  }, [MasterData])

  useEffect(() => {
    if (dataDetail && id) {
      formik.setFieldValue("name", dataDetail?.regional_name)
    }
  }, [dataDetail])

  useEffect(() => {
    formik.setFieldValue("name", "")
    setSelectedListProvince([])
  }, [])

  function fetchData() {
    if (id) {
      dispatch(getListProvinceEditRegional(id))
      dispatch(getDetailRegional(id)).then()
      setSelectedListProvince([])
    } else {
      dispatch(getListProvinceForCreateRegional()).then()
    }
  }

  useEffect(() => {
    if (MasterData?.listAvailableProvince) {
      setListProvince(
        MasterData.listAvailableProvince.map((item) => {
          return {
            label: item.name,
            value: item.id
          }
        })
      )
    }
  }, [MasterData])

  useEffect(() => {
    subheader.setTitle(t(id ? "label.edit_regional" : "label.add_regional"))
    subheader.setBreadcrumbs([
      {
        pathname: RoutesConstants.MASTER_DATA.REGIONAL.PAGE(),
        title: t("label.regional")
      },
      {
        title: t(id ? "label.edit_regional" : "label.add_regional")
      }
    ])
  }, [id])

  function onCancelPopup() {
    setShowModal(false)
  }

  function findData() {
    if (dataDetail && listProvince) {
      const data = []
      const dataProvinceName = dataDetail?.province_name
      const selectedData = dataProvinceName.split(", ")
      const foundItems = listProvince.filter((item) => selectedData.includes(item.label))
      if (foundItems.length > 0) {
        foundItems.forEach((item) => {
          data.push(item)
        })
      }
      setSelectedListProvince(data)
    }
  }

  useEffect(() => {
    findData()
  }, [dataDetail, listProvince])
  function onSubmitCreateRegional() {
    setShowModal(false)
    if (id) {
      dispatch(EdiDataRegional(dataForm, id))
    } else {
      dispatch(addDataRegional(dataForm))
    }
  }

  useEffect(() => {
    if (
      General?.isFinishUpdateOrDelete &&
      (General?.actionInitType === MASTER.ADD_REGIONAL_DATA ||
        General?.actionInitType === MASTER.EDIT_REGIONAL_MASTER_DATA)
    ) {
      swal({
        icon:
          General?.actionInitType === MASTER.ADD_REGIONAL_DATA
            ? ILLUSTRATION_ASSETS_V2.IL_SUCCESS_ADD
            : ILLUSTRATION_ASSETS_V2.IL_POSITIVE_DIPERBARUI,
        text: MasterData?.messages
      })
      setShowModal(false)
      history.push(RoutesConstants.MASTER_DATA.REGIONAL.PAGE())
    }
  }, [General, MasterData])

  useEffect(() => {
    subheader.setButton(btnSubmit())
  }, [disableBtn])

  function componentPreview() {
    return (
      <div>
        <div style={{ textAlign: "center" }}>
          {t(id ? "messages.confirmation.edit_regional" : "messages.confirmation.create_regional")}
        </div>
      </div>
    )
  }
  function btnSubmit() {
    return (
      <button
        disabled={disableBtn}
        type="button"
        className="btn btn-primary"
        onClick={() => formik.handleSubmit()}>
        {t("button.submit")}
      </button>
    )
  }

  return (
    <div>
      <PopupQuestion
        textCancel={t("button.cancel")}
        textOk={t("button.continue")}
        onOk={onSubmitCreateRegional}
        onCancel={onCancelPopup}
        title={t(id ? "label.edit_regional" : "label.add_regional").toUpperCase()}
        show={showModal}
        bodyCustom={componentPreview()}
      />
      <Card>
        <CardBody>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "center",
              paddingTop: 32,
              paddingBottom: 32
            }}>
            <div style={{ width: 720, display: "flex", flexDirection: "column", gap: 12 }}>
              <h3>{t("label.detail_regional").toUpperCase()}</h3>
              <Form.Group>
                <div style={{ paddingBottom: 2 }}>
                  {t("label.regional_name")} <span className={"text-danger"}> *</span>
                </div>
                <Form.Control
                  name="name"
                  className={formik.errors.name && "is-invalid"}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder={t("label.insert_regional_name")}
                />
              </Form.Group>
              <ChipsMultipleInput
                required={true}
                onChange={(e) => setSelectedListProvince(e)}
                options={listProvince}
                value={selectedListProvince}
                placeholder={"Silahkan pilih atau ketik provinsi"}
                label={t("label.province")}
              />
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  )
}