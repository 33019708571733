import React, { useState } from "react"
import { PopupImage } from "../Popup/index.jsx"
import { useSelector } from "react-redux"
import { Skeleton } from "@material-ui/lab"

export const RowText = ({
  label,
  value,
  secondLabel,
  secondValue,
  secondColor,
  hasIcon,
  icon,
  hasLink,
  handleClick = () => {},
  images
}) => {
  const [previewImage, setPreviewImage] = useState(undefined)
  const { General } = useSelector((state) => state)
  return (
    <>
      <PopupImage
        show={!!previewImage}
        images={previewImage}
        type={"image-preview"}
        onCancel={() => setPreviewImage(null)}
      />
      {General.loading ? (
        <div>
          <Skeleton animation="wave" variant={"text"} width={100} />
          <Skeleton animation="wave" variant={"text"} width={200} />
        </div>
      ) : (
        <div className={"mb-4"}>
          <div className={"text-dark-50 txt-12 mb-2"}>{label}</div>
          <div
            onClick={handleClick}
            className={`font-weight-bolder txt-14 d-flex align-items-center ${
              hasLink ? "text-success cursor-pointer" : "text-dark"
            }`}>
            {value}
            {secondLabel || secondValue ? (
              <div
                className={`${
                  secondColor ? secondColor : "font-weight-bolder text-warning"
                } font-size-sm`}>
                &nbsp;({secondLabel} {secondValue})
              </div>
            ) : (
              ""
            )}
            {hasIcon && (
              <div className={"kur-bni detail "} style={{ marginLeft: 4 }}>
                {icon ? <img draggable={"false"} src={icon} alt="icon" /> : " -"}
              </div>
            )}
            {images && (
              <div
                className={"d-flex  align-items-center"}
                style={{ gap: 14, width: "fit-content" }}>
                {images?.map((item, i) => (
                  <>
                    <div
                      style={{
                        width: 128,
                        height: 128,
                        cursor: "pointer"
                      }}>
                      <img
                        onClick={() => setPreviewImage(item)}
                        alt={item}
                        src={item}
                        key={i}
                        className={"h-100 w-100 rounded"}
                      />
                    </div>
                  </>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}
