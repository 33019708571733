import React from "react"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers"

export const ApproveSvgIcon = () => {
  return (
    <span className="svg-icon svg-icon-md svg-icon-success">
      <SVG src={toAbsoluteUrl("/media/svg/icons/Detail/approval.svg")} />
    </span>
  )
}