import { useTranslation } from "react-i18next"
import React, { Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useFormikContext } from "formik"
import { getRegionOrder } from "../../../../../../redux/actions/OrderAction"
import { ICON_CONSTANS } from "../../../../../../constants/ImageConstants"
import SVG from "react-inlinesvg"
import { Accordion } from "../../../../../../component/atoms/Expands"
import { RowModule } from "../../../../../../component/atoms/RowModule/RowModule.jsx"
import { SelectInput } from "../../../../../../component"
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers"
import { convertM2ToHectare } from "../../../../../helpers/TextHelper"
import { AccordionContent } from "./AccordionContent"

export const PreviewBulkErpForm = ({ data, setIsValid }) => {
  const { t } = useTranslation()
  const { values: formValues } = useFormikContext()
  const dispatch = useDispatch()

  const [datalist, setDataList] = useState([])
  const { Order } = useSelector((state) => state)
  const [listRegion, setListRegion] = useState([])

  const [area, setArea] = useState(0)
  const [selectedRegion, setSelectedRegion] = useState("")

  useEffect(() => {
    dispatch(getRegionOrder())
  }, [])

  useEffect(() => {
    if (formValues.hasOwnProperty("regional_id")) {
      setSelectedRegion(formValues["regional_id"])
    }
  }, [formValues["regional_id"]])

  useEffect(() => {
    if (Order?.region) {
      setListRegion(Order?.region.map((value) => ({ label: value.name, value: value.id })))
    }
  }, [Order.region])

  useEffect(() => {
    if (data) {
      setDataList(data)
      setArea(
        data.reduce((accumulator, item) => {
          return accumulator + parseFloat(item.worked_area)
        }, 0)
      )
    }
  }, [data])

  useEffect(() => {
    if (formValues["regional_id"]) {
      setIsValid(true)
    } else {
      setIsValid(false)
    }
  }, [formValues])

  const dataListCardHeader = [
    {
      title: t("label.total_activity"),
      value: `${data ? data?.length : "-"} Kegiatan`,
      icon: ICON_CONSTANS.IC_ROUNDED_FIELD
    },
    {
      title: t("label.land_area_worked"),
      value: `${data ? convertM2ToHectare(area) : "-"} Ha`,
      icon: ICON_CONSTANS.IC_ROUNDED_LOCATION
    }
  ]

  return (
    <>
      <div className={"px-21"}>
        <div className={"border p-10 mt-10 d-flex align-items-center justify-content-between"}>
          {dataListCardHeader.map((item, i) => (
            <div key={i} className={"d-flex align-items-center  m-auto"} style={{ gap: 8 }}>
              <SVG src={item.icon} />
              <div>
                <div>{item.title}</div>
                <div className={"font-weight-bolder"}>{item.value}</div>
              </div>
            </div>
          ))}
        </div>
        <div className="overview mt-8 pb-8 mb-8 border-bottom">
          <h4 className="mb-6">Regional Pelaporan Aktivitas</h4>
          <div className={"mt-10"}>
            <RowModule customColumnCss="mb-2 col-12 col-md-12">
              <div>
                <SelectInput
                  name="regional_id"
                  label={t("label.regional")}
                  required={true}
                  placeholder={t("placeholder.select_regional")}
                  clearNow={true}
                  options={listRegion}
                  value={selectedRegion}
                />
                <div className="mb-6 d-flex mt-2 align-items-center">
                  <SVG src={toAbsoluteUrl(ICON_CONSTANS.IC_INFO_REGION)} />
                  <span className={"ml-2 mb-0"}>
                    Daerah regional keuangan untuk pelaporan kegiatan
                  </span>
                </div>
              </div>
            </RowModule>
          </div>
        </div>
        <div className={"mt-10 accordion_bulk_approve_order"}>
          <Accordion
            items={datalist.map((item, index) => ({
              header: (
                <>
                  <h3 className="card-label mb-8">
                    {item?.area_name ?? "-"} ({item?.activity_name ?? "-"})
                  </h3>
                </>
              ),
              content: (
                <Fragment key={index}>
                  <AccordionContent data={item} />
                </Fragment>
              )
            }))}
          />
        </div>
      </div>
    </>
  )
}
