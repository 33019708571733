import React, { useCallback, useEffect, useState } from "react"
import TableView, { formatterRowComponent } from "../../../../../component/atoms/Tabel/index.jsx"
import { sortCaret } from "../../../../../_metronic/_helpers/index.js"
import { EmptyCard, TableSkeleton } from "../../../../../component/index.jsx"
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { RoutesConstants } from "../../../../../constants/RoutesConstants.js"
import { DetailSvgIcon } from "../../../../../component/atoms/Icons/SVG/index.jsx"
import { loadUserLogin } from "../../../../service/userManagementAction.js"
import { ROUTING_STATUS } from "../../../../../constants/StringConstant.js"
import { getUrlParsingPageFilter } from "../../../../../config/EndpointCollection.js"
import { PRIVILEGE_ENUM } from "../../../../../constants/PrivilegeConstants.js"

export const ListMitra = ({ data, history }) => {
  const { t } = useTranslation()
  const { General } = useSelector((state) => state)
  const [dataScoped, setDataScoped] = useState([])

  const privileges = loadUserLogin().privilege

  const [options, setOptions] = useState({
    page: 0,
    total: (data ?? []).length,
    perPage: 10
  })

  const changePage = useCallback((param) => {
    setOptions({
      ...options,
      perPage: param.pageSize,
      page: param.pageNumber - 1
    })
  }, [])

  useEffect(() => {
    const tmp = Array.from(data || []).slice(
      options.page * options.perPage,
      (options.page + 1) * options.perPage
    )
    setDataScoped(tmp)
  }, [options])

  const UiStatus = (e) => {
    let status = e.status === "ACTIVE" ? "Aktif" : "Tidak Aktif"
    return (
      <div>
        <div
          className={`font-weight-bolder font-size-lg`}
          style={e.status === "ACTIVE" ? { color: "#6CC049" } : { color: "#808080" }}>
          {status ?? "-"}
        </div>
      </div>
    )
  }

  const uiAction = (e) => {
    return (
      <div>
        {privileges.indexOf(PRIVILEGE_ENUM.DETAIL_MITRA_REGISTERED) > -1 && (
          <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Detail</Tooltip>}>
            <div
              className="btn btn-icon btn-detail btn-hover-secondary btn-sm mx-3"
              onClick={() =>
                history.push(
                  RoutesConstants.MITRA.DETAIL_MITRA(ROUTING_STATUS.STATUS_STRING.ACTIVE, e.id)
                )
              }>
              <DetailSvgIcon />
            </div>
          </OverlayTrigger>
        )}
      </div>
    )
  }

  const columns = [
    {
      dataField: "code",
      text: "ID MITRA",
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: (e) => (
          <a className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">
            {e?.code ?? "-"}
          </a>
        )
      },
      sortCaret: sortCaret
    },
    {
      dataField: "name",
      text: "NAMA",
      sort: true,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: (e) => (
          <>
            <a className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">
              {e?.name ?? "-"}
            </a>
            <span className="text-muted font-weight-bold d-block">{e?.phone_number ?? "-"}</span>
          </>
        )
      },
      sortCaret: sortCaret,
      onSort: (field, order) => {
        let param = getUrlParsingPageFilter({
          ...initialFilterTable,
          sort: field + "," + order
        })
        history.push({ pathname: location.pathname, search: param })
      }
    },
    {
      dataField: "address",
      text: "ALAMAT",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: (e) => <span>{e?.address ?? "-"}</span>
      },
      sortCaret: sortCaret
    },
    {
      dataField: "status",
      text: "STATUS",
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: UiStatus
      },
      sortCaret: sortCaret
    },
    {
      dataField: "action",
      text: "Action",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiAction
      }
    }
  ]

  return (
    <>
      {General.loading ? (
        <TableSkeleton column={7} row={10} />
      ) : (
        <Card>
          <Card.Body>
            {dataScoped?.length > 0 ? (
              <TableView
                callbackAfterPageChange={changePage}
                columnProperties={columns}
                dataTable={dataScoped ?? []}
                currentPage={options.page}
                currentElement={options.perPage}
                totalCount={options.total}
                loadingGetData={false}
              />
            ) : (
              <EmptyCard body={t("messages.response.empty_state", { name: "Mitra" })} />
            )}
          </Card.Body>
        </Card>
      )}
    </>
  )
}