import React from "react"
import { Field } from "formik"

export const ComponentCustomCheckbox = ({
  field,
  name,
  label,
  type,
  inneRef,
  onClick,
  value,
  ...props
}) => {
  return (
    <>
      <label className={"label-container"}>
        {label}
        <input
          type={type}
          onClick={onClick}
          name={name}
          value={value}
          ref={inneRef}
          {...field}
          {...props}
        />
        <span className="checkmark"></span>
      </label>
    </>
  )
}

export const CheckboxCustomGroup = ({
  label,
  name,
  editable,
  value,
  checked,
  inneRef,
  onClick,
  ...props
}) => {
  return (
    <Field
      name={name}
      component={ComponentCustomCheckbox}
      label={label}
      editable={editable}
      onClick={onClick}
      value={value}
      defaultChecked={checked}
      type="checkbox"
      inneRef={inneRef}
      {...props}
    />
  )
}
