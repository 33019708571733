import { initialFilterTable } from "../../component/atoms/Tabel"
import { createSlice } from "@reduxjs/toolkit"

const initState = {
  pageData: [],
  page: initialFilterTable,
  combobox: [],
  data: undefined,
  rejectReasons: [],
  areas: [],
  list: [],
  urlDownloadExcel: null,
  canDeactivate: null,
  messages: null
}

export const FarmerSlice = createSlice({
  name: "farmer",
  initialState: initState,
  reducers: {
    getPage: (state, action) => {
      state.data = action.payload
    },
    setPageData: (state, action) => {
      const { pageData, page } = action.payload
      state.pageData = pageData
      state.page = page
    },
    getRejectReason: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.rejectReasons = action.payload
      }
    },
    getAreas: (state, action) => {
      state.areas = action.payload
    },
    getList: (state, action) => {
      state.list = action.payload
    },
    getUrlDownloadExcel: (state, action) => {
      state.urlDownloadExcel = action.payload
    },
    getCanDeactivate: (state, action) => {
      state.canDeactivate = action.payload
    },
    setMessages: (state, action) => {
      state.messages = action.payload
    }
  }
})
