import { CalenderSvgIcon } from "../Icons/SVG/calender.jsx"
import { DateRange } from "react-date-range"
import React, { useEffect, useState } from "react"
import { dateSelect } from "../../../app/helpers/DateHelper.js"
import { format } from "date-fns"
import moment from "moment/moment.js"

export const initDate = [
  {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection"
  }
]

export const SelectDateRange = ({
  label,
  required,
  openDate = false,
  setOpenDate,
  value,
  setValue,
  setSelectedDate,
  selectedDate,
  maxRange,
  placeholder
}) => {
  const [dateRange, setDateRange] = useState("")
  const resetDate = () => {
    setOpenDate(false)
    setDateRange("")
    setValue(initDate)
    setSelectedDate(false)
  }

  const onChangeDate = () => {
    setOpenDate(false)
    setSelectedDate(true)
  }

  useEffect(() => {
    if (selectedDate) {
      const valueArray = 0

      let selectedDate =
        dateSelect(format(value[valueArray].startDate, "yyyy-MM-dd")) +
        " s/d " +
        dateSelect(format(value[valueArray].endDate, "yyyy-MM-dd"))
      setDateRange(selectedDate)
    }
  }, [selectedDate, value])

  const selectDate = (
    <>
      {label && (
        <label>
          {label} {required && <span style={{ color: "red" }}>*</span>}
        </label>
      )}
      <div className={"d-flex justify-content-end mb-8"}>
        <div className={"w-full"}>
          <div
            onClick={() => setOpenDate(!openDate)}
            className={
              "select-date full d-flex justify-content-between align-items-center cursor-pointer"
            }>
            <label
              className={`mb-0 txt-12 cursor-pointer ${dateRange ? "color-black" : "color-gray"}`}>
              {dateRange ? dateRange : placeholder}
            </label>
            <CalenderSvgIcon />
          </div>
          {maxRange &&
            new moment(value[0].endDate).diff(new moment(value[0].startDate), "days") >
              maxRange - 1 && (
              <label className={`mb-0 txt-12 feedback`}>Pastikan maksimal {maxRange} hari</label>
            )}
        </div>
      </div>
    </>
  )

  const dateRangePicker = (
    <div className={"position-relative"}>
      <div className={"date-picker-custom"}>
        <DateRange
          editableDateInputs={true}
          onChange={(item) => setValue([item.selection])}
          moveRangeOnFirstSelection={false}
          months={1}
          ranges={value}
          direction="horizontal"
          calendarFocus="backwards"
          inputRanges={[]}
        />
        <div className={"action-wrapper d-flex justify-content-end py-4 px-4 border-top"}>
          <button onClick={resetDate} type={"button"} className={"btn btn-outline-primary mx-4"}>
            Reset Tanggal
          </button>
          <button onClick={onChangeDate} type={"button"} className={"btn btn-primary mx-4"}>
            Terapkan
          </button>
        </div>
      </div>
    </div>
  )

  return (
    <>
      {selectDate}
      {openDate && dateRangePicker}
    </>
  )
}
