import React from "react"
import SVG from "react-inlinesvg"
import { useHistory } from "react-router-dom"
import { toAbsoluteUrl } from "../../../../_helpers"
import { loadUserLogin } from "../../../../../app/service/userManagementAction"
import { useTranslation } from "react-i18next"
import { lngs } from "../../../../../app/helpers/messages/i18n.js"

export function QuickUser() {
  const history = useHistory()
  // const user = useSelector((state) => state.auth.user, shallowEqual);
  const userData = loadUserLogin()

  const { t, i18n } = useTranslation()
  const logoutClick = () => {
    const toggle = document.getElementById("kt_quick_user_toggle")
    if (toggle) {
      toggle.click()
    }
    history.push("/logout")
  }

  const resetClick = () => {
    const toggle = document.getElementById("kt_quick_user_toggle")
    if (toggle) {
      toggle.click()
    }
    history.push("/account/edit-password")
  }

  return (
    <div id="kt_quick_user" className="offcanvas offcanvas-left offcanvas p-10 d-flex flex-column">
      <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5 ">
        <h3 className="font-weight-bold m-0">{t("profile.label_profile")}</h3>
        <a
          href="#"
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close">
          <i className="ki ki-close icon-xs text-muted" />
        </a>
      </div>

      <div
        className="offcanvas-content pr-5 mr-n5 d-flex flex-column justify-content-between"
        style={{ height: "100%" }}>
        <div>
          <div className="d-flex align-items-center mt-5 border-bottom pb-8">
            <div className="symbol symbol-100 mr-5">
              <div
                className="symbol-label"
                style={{
                  backgroundImage: `url(${toAbsoluteUrl("/media/users/300_21.jpg")})`
                }}
              />
              <i className="symbol-badge bg-success" />
            </div>
            <div className="d-flex flex-column">
              <a href="#" className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary">
                {userData.name}
              </a>
              {/*<div className="text-muted mt-1">{user.occupation}</div>*/}
              <div className="navi mt-2">
                <a href="#" className="navi-item">
                  <span className="navi-link p-0 pb-2">
                    <span className="navi-icon mr-1">
                      <span className="svg-icon-lg svg-icon-primary">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Mail-notification.svg"
                          )}></SVG>
                      </span>
                    </span>
                    <span className="navi-text text-muted text-hover-primary">
                      {userData.email}
                    </span>
                  </span>
                </a>
              </div>
              <a className="link font-weight-normal" onClick={resetClick}>
                Edit Password
              </a>
            </div>
          </div>
          <div className={"mt-6 d-flex justify-content-between align-items-center select-language"}>
            <h3 className="font-weight-bold mb-0">{t("profile.label_language")}</h3>
            <div>
              {Object.keys(lngs).map((lng) => (
                <button
                  key={lng}
                  className={i18n.resolvedLanguage === lng ? "btn active" : "btn normal"}
                  type="submit"
                  onClick={() => i18n.changeLanguage(lng)}>
                  {lngs[lng].nativeName}
                </button>
              ))}
            </div>
          </div>
        </div>

        <div className={"d-flex justify-content-center"}>
          <button className="btn btn-danger btn-bold" onClick={logoutClick}>
            Sign out
          </button>
        </div>

        {/*<div className="navi navi-spacer-x-0 p-0">*/}
        {/*  <Link to="/user-profile" className="navi-item">*/}
        {/*    <div className="navi-link">*/}
        {/*      <div className="symbol symbol-40 bg-light mr-3">*/}
        {/*        <div className="symbol-label">*/}
        {/*          <span className="svg-icon svg-icon-md svg-icon-success">*/}
        {/*            <SVG*/}
        {/*              src={toAbsoluteUrl(*/}
        {/*                "/media/svg/icons/General/Notification2.svg"*/}
        {/*              )}*/}
        {/*            ></SVG>*/}
        {/*          </span>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      <div className="navi-text">*/}
        {/*        <div className="font-weight-bold">My profile</div>*/}
        {/*        <div className="text-muted">*/}
        {/*          account settings and more{" "}*/}
        {/*          <span className="label label-light-danger label-inline font-weight-bold">*/}
        {/*            update*/}
        {/*          </span>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </Link>*/}

        {/*  <Link to="/user-profile" className="navi-item">*/}
        {/*    <div className="navi-link">*/}
        {/*      <div className="symbol symbol-40 bg-light mr-3">*/}
        {/*        <div className="symbol-label">*/}
        {/*          <span className="svg-icon svg-icon-md svg-icon-warning">*/}
        {/*            <SVG*/}
        {/*              src={toAbsoluteUrl(*/}
        {/*                "/media/svg/icons/Shopping/Chart-bar1.svg"*/}
        {/*              )}*/}
        {/*            ></SVG>*/}
        {/*          </span>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      <div className="navi-text">*/}
        {/*        <div className="font-weight-bold">My Messages</div>*/}
        {/*        <div className="text-muted">Inbox and tasks</div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </Link>*/}

        {/*  <Link to="/user-profile" className="navi-item">*/}
        {/*    <div className="navi-link">*/}
        {/*      <div className="symbol symbol-40 bg-light mr-3">*/}
        {/*        <div className="symbol-label">*/}
        {/*          <span className="svg-icon svg-icon-md svg-icon-danger">*/}
        {/*            <SVG*/}
        {/*              src={toAbsoluteUrl(*/}
        {/*                "/media/svg/icons/Files/Selected-file.svg"*/}
        {/*              )}*/}
        {/*            ></SVG>*/}
        {/*          </span>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      <div className="navi-text">*/}
        {/*        <div className="font-weight-bold">My Activities</div>*/}
        {/*        <div className="text-muted">Logs and notifications</div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </Link>*/}

        {/*  <Link to="/user-profile" className="navi-item">*/}
        {/*    <div className="navi-link">*/}
        {/*      <div className="symbol symbol-40 bg-light mr-3">*/}
        {/*        <div className="symbol-label">*/}
        {/*          <span className="svg-icon svg-icon-md svg-icon-primary">*/}
        {/*            <SVG*/}
        {/*              src={toAbsoluteUrl(*/}
        {/*                "/media/svg/icons/Communication/Mail-opened.svg"*/}
        {/*              )}*/}
        {/*            ></SVG>*/}
        {/*          </span>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      <div className="navi-text">*/}
        {/*        <div className="font-weight-bold">My Tasks</div>*/}
        {/*        <div className="text-muted">latest tasks and projects</div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </Link>*/}
        {/*</div>*/}

        {/*<div className="separator separator-dashed my-7"></div>*/}

        {/*<div>*/}
        {/*  <h5 className="mb-5">Recent Notifications</h5>*/}

        {/*  <div className="d-flex align-items-center bg-light-warning rounded p-5 gutter-b">*/}
        {/*    <span className="svg-icon svg-icon-warning mr-5">*/}
        {/*      <SVG*/}
        {/*        src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}*/}
        {/*        className="svg-icon svg-icon-lg"*/}
        {/*      ></SVG>*/}
        {/*    </span>*/}

        {/*    <div className="d-flex flex-column flex-grow-1 mr-2">*/}
        {/*      <a*/}
        {/*        href="#"*/}
        {/*        className="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1"*/}
        {/*      >*/}
        {/*        Another purpose persuade*/}
        {/*      </a>*/}
        {/*      <span className="text-muted font-size-sm">Due in 2 Days</span>*/}
        {/*    </div>*/}

        {/*    <span className="font-weight-bolder text-warning py-1 font-size-lg">*/}
        {/*      +28%*/}
        {/*    </span>*/}
        {/*  </div>*/}

        {/*  <div className="d-flex align-items-center bg-light-success rounded p-5 gutter-b">*/}
        {/*    <span className="svg-icon svg-icon-success mr-5">*/}
        {/*      <SVG*/}
        {/*        src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}*/}
        {/*        className="svg-icon svg-icon-lg"*/}
        {/*      ></SVG>*/}
        {/*    </span>*/}
        {/*    <div className="d-flex flex-column flex-grow-1 mr-2">*/}
        {/*      <a*/}
        {/*        href="#"*/}
        {/*        className="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1"*/}
        {/*      >*/}
        {/*        Would be to people*/}
        {/*      </a>*/}
        {/*      <span className="text-muted font-size-sm">Due in 2 Days</span>*/}
        {/*    </div>*/}

        {/*    <span className="font-weight-bolder text-success py-1 font-size-lg">*/}
        {/*      +50%*/}
        {/*    </span>*/}
        {/*  </div>*/}

        {/*  <div className="d-flex align-items-center bg-light-danger rounded p-5 gutter-b">*/}
        {/*    <span className="svg-icon svg-icon-danger mr-5">*/}
        {/*      <SVG*/}
        {/*        src={toAbsoluteUrl(*/}
        {/*          "/media/svg/icons/Communication/Group-chat.svg"*/}
        {/*        )}*/}
        {/*        className="svg-icon svg-icon-lg"*/}
        {/*      ></SVG>*/}
        {/*    </span>*/}
        {/*    <div className="d-flex flex-column flex-grow-1 mr-2">*/}
        {/*      <a*/}
        {/*        href="#"*/}
        {/*        className="font-weight-normel text-dark-75 text-hover-primary font-size-lg mb-1"*/}
        {/*      >*/}
        {/*        Purpose would be to persuade*/}
        {/*      </a>*/}
        {/*      <span className="text-muted font-size-sm">Due in 2 Days</span>*/}
        {/*    </div>*/}

        {/*    <span className="font-weight-bolder text-danger py-1 font-size-lg">*/}
        {/*      -27%*/}
        {/*    </span>*/}
        {/*  </div>*/}

        {/*  <div className="d-flex align-items-center bg-light-info rounded p-5">*/}
        {/*    <span className="svg-icon svg-icon-info mr-5">*/}
        {/*      <SVG*/}
        {/*        src={toAbsoluteUrl("/media/svg/icons/General/Attachment2.svg")}*/}
        {/*        className="svg-icon svg-icon-lg"*/}
        {/*      ></SVG>*/}
        {/*    </span>*/}

        {/*    <div className="d-flex flex-column flex-grow-1 mr-2">*/}
        {/*      <a*/}
        {/*        href="#"*/}
        {/*        className="font-weight-normel text-dark-75 text-hover-primary font-size-lg mb-1"*/}
        {/*      >*/}
        {/*        The best product*/}
        {/*      </a>*/}
        {/*      <span className="text-muted font-size-sm">Due in 2 Days</span>*/}
        {/*    </div>*/}

        {/*    <span className="font-weight-bolder text-info py-1 font-size-lg">*/}
        {/*      +8%*/}
        {/*    </span>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </div>
  )
}