import React from "react"
import { DropdownButton, Dropdown } from "react-bootstrap"
import SVG from "react-inlinesvg"

export const ButtonDropdown = ({ title, dropdownList }) => {
  return (
    <DropdownButton
      variant="outline-primary"
      alignRight
      title={title}
      id="dropdown-menu-align-right">
      {dropdownList.map((item, index) => {
        if (item.showed) {
          return (
            <React.Fragment key={index}>
              <Dropdown.Item eventKey={index} onClick={item.action}>
                <SVG className="mr-2 mb-1" src={item.icon} />
                <div className={"text"}>{item.title}</div>
              </Dropdown.Item>
            </React.Fragment>
          )
        }
      })}
    </DropdownButton>
  )
}
