export const FIELD_OFFICER_ROLE_ENUM = {
  FIELD_OFFICER: "FIELD_OFFICER_GENERAL",
  FIELD_OFFICER_HARVEST: "FIELD_OFFICER_HARVEST",
  FIELD_OFFICER_AGRONOMIST: "FIELD_OFFICER_AGRONOMIST",
  FIELD_OFFICER_COORDINATOR: "FIELD_OFFICER_COORDINATOR",
  FIELD_OFFICER_QA: "FIELD_OFFICER_QA"
}

export const ORDER_STATUS_ENUM = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  RESCHEDULED: "RESCHEDULED",
  REJECTED: "REJECTED",
  CANCELED: "CANCELED",
  CONFIRMED_FO: "CONFIRMED_FO",
  OVERDUE: "OVERDUE",
  CONFIRMED_ERP: "CONFIRMED_ERP",
  SPK_CREATED: "SPK_CREATED"
}

export const ORDER_STATUS_PLANTING_ENUM = {
  PRE_PLANTING: "PRE_PLANTING",
  PASCA_PLANTING: "PASCA_PLANTING"
}

export const ROUTING_STATUS = {
  SCHEDULE_STRING: {
    PENDING: "Pending",
    RESCHEDULE: "Rescheduled",
    APPROVE: "Approved",
    REJECT: "Rejected",
    ALL: "all"
  },
  SPRAYING_PASCA_PLANTING_STRING: {
    PENDING: "pending",
    RESCHEDULED: "rescheduled",
    APPROVED: "scheduled",
    REJECT: "rejected",
    CANCEL: "canceled",
    DONE: "done",
    OVERDUE: "overdue"
  },
  MAC_SPRAYING_STRING: {
    PENDING: "pending",
    RESCHEDULED: "rescheduled",
    APPROVED: "approved",
    REJECT: "rejected",
    CANCEL: "canceled",
    DONE: "done",
    OVERDUE: "overdue"
  },
  STATUS_STRING: {
    PENDING: "pending",
    ACTIVE: "active",
    REJECT: "reject",
    REJECTED: "rejected",
    INACTIVE: "inactive"
  },
  STATUS_ENUM: {
    PENDING: "PENDING",
    ACTIVE: "ACTIVE",
    REJECT: "REJECT",
    REJECTED: "REJECTED",
    INACTIVE: "INACTIVE",
    SUBMITTED: "SUBMITTED",
    COMPLETED: "COMPLETED"
  },
  CERTIFICATION_STATUS_STRING: {
    PENDING: "pending",
    SUBMITTED: "submitted",
    APPROVED: "approved",
    REJECTED: "rejected",
  }
}

export const ACTIVITY_STATUS_ENUM = {
  WAITING: "WAITING",
  NEED_BA: "NEED_BA",
  APPROVED: "APPROVED",
  COMPLETED: "COMPLETED"
}

export const ACTIVITY_METHODE_ENUM = {
  MANUAL: "MANUAL",
  AUTOMATIC: "AUTOMATIC"
}

export const PLANT_TYPE_ENUM = {
  PADI: "PADI",
  JAGUNG: "JAGUNG"
}

export const HARVEST_TYPE_ENUM = {
  PRODUCTION: "PRODUCTION",
  FREE_MARKET: "FREE_MARKET"
}

export const TEMPLATE_TYPE_ENUM = {
  FULL: "FULL",
  DEFAULT: "DEFAULT"
}

export const DATE_TYPE_ENUM = {
  ISO_DATE_ID: "dd MMMM yyyy",
  ISO_DATETIME_ID: "dd MMMM yyyy HH:mm:ss",
  ISO_TIME_ID: "HH:mm:ss",
  ISO_DATE_EURO: "yyyy-MM-dd",
  ISO_DATETIME_STRAP: "dd-MM-yyyy HH:mm:ss",
  ISO_DATE_STRAP: "dd-MM-yyyy",
}

export const GENDER_ENUM = {
  MALE: "MALE",
  FEMALE: "FEMALE"
}
