import React, { useEffect, useState } from "react"
import { Form } from "react-bootstrap"

export const FarmerRejectReasons = ({ items = [], onChange = () => {} }) => {
  const [selected, setSelected] = useState({})

  useEffect(() => {
    const temp = {}

    items.forEach(e => {
      temp[e?.enum_name] = null
    })

    setSelected(temp)
  }, [items])

  useEffect(() => {
    const list = []

    for (const key in selected) {
      const el = selected[key]
      if (el) {
        list.push(el)
      }
    }

    onChange(list)
  }, [selected])

  const onLocalChange = item => {
    const oldValue = selected[item?.enum_name]
    const oldSelected = JSON.parse(JSON.stringify(selected))

    oldSelected[item?.enum_name] = oldValue ? null : item?.enum_name

    setSelected(oldSelected)
  }

  return (
    <>
      {items.map((e, i) => (
        <Form.Check
          key={e?.enum_name}
          id={e?.enum_name}
          className={
            (i === 0 ? "mb-2" : i === items.length - 1 ? "mt-2" : "my-2") + " cursor-pointer"
          }
          type="checkbox"
          label={e?.enum_value}
          checked={selected[e?.enum_name] === e?.enum_name}
          onChange={() => onLocalChange(e)}
        />
      ))}
    </>
  )
}
