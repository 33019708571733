import React, { useEffect, useRef, useState } from "react"
import { CardDetail } from "../../../../../component/atoms/CardDetail/index.jsx"
import { RowModule } from "../../../../../component/atoms/RowModule/RowModule.jsx"
import { useDispatch, useSelector } from "react-redux"
import {
  EmptyCard,
  Filter,
  SelectInput,
  Status,
  Text2Row
} from "../../../../../component/index.jsx"
import { Card } from "react-bootstrap"
import { loadUserLogin } from "../../../../service/userManagementAction.js"
import { CardBody, CardHeader } from "../../../../../_metronic/_partials/controls/index.js"
import { CalenderSvgIcon } from "../../../../../component/atoms/Icons/SVG/calender.jsx"
import { DateRange } from "react-date-range"
import { format } from "date-fns"
import { activityDate, dateSelect } from "../../../../helpers/DateHelper.js"
import { getHistoryActivityList } from "../../../../../redux/actions/AreaAction.jsx"
import { getVariantByArea } from "../../../../../redux/actions/MasterDataAction.jsx"
import { toRupiah } from "../../../../helpers/TextHelper.js"
import { Skeleton } from "@material-ui/lab"
import { PRIVILEGE_ENUM } from "../../../../../constants/PrivilegeConstants.js"
import { ICON_CONSTANS } from "../../../../../constants/ImageConstants.js"

export const ActivityHistoryTab = ({ id, history }) => {
  const formRef = useRef()
  const dispatch = useDispatch()
  const privileges = loadUserLogin().privilege
  const isReadOnly = false
  const { General, Area, MasterData } = useSelector((state) => state)
  const [listActivity, setListActivity] = useState([])
  const [listVariant, setListVariant] = useState([])
  const [variant, setVariant] = useState("")
  const [showFilter, setShowFilter] = useState(false)
  const [filterOn, setFilterOn] = useState(false)
  const [initialValue, setInitialValue] = useState({
    start_date: "",
    end_date: ""
  })
  const [dateRange, setDateRange] = useState("")
  const [openDate, setOpenDate] = useState(false)
  const [filterDate, setFilterDate] = useState(false)
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection"
    }
  ])

  useEffect(() => {
    dispatch(getHistoryActivityList(id, ""))
  }, [])
  useEffect(() => {
    dispatch(getVariantByArea(id))
  }, [])

  useEffect(() => {
    if (MasterData.variantList) {
      setListVariant(
        MasterData.variantList.map((value) => ({
          label: value.name,
          value: value.id
        }))
      )
    }
  }, [MasterData.variantList])

  useEffect(() => {
    let result = Area.historyList?.reduce(function (r, a) {
      r[a.year] = r[a.year] || []
      r[a.year].push(a)
      return r
    }, Object.create(null))
    setListActivity(result)
  }, [Area.historyList])

  function onChangeVariant(e) {
    setVariant(e)
  }

  const capitalizeCase = (string) => {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : ""
  }
  const iconCard = (item) => {
    if (item?.has_harvest_failure) {
      return item?.plant_type_name?.toLowerCase() === "padi"
        ? ICON_CONSTANS.IC_PADDY_O
        : ICON_CONSTANS.IC_CORN_O
    } else {
      return item?.plant_type_name?.toLowerCase() === "padi"
        ? ICON_CONSTANS.IC_PADDY_O
        : ICON_CONSTANS.IC_CORN_O
    }
  }

  const Text2Rows = (item) => {
    return (
      <>
        <Text2Row title="Tanaman:" description={capitalizeCase(item?.plant_type_name ?? "-")} />
        <Text2Row title="Varietas:" description={capitalizeCase(item?.variety_name ?? "-")} />
        <Text2Row
          title="Tanggal Aktivitas:"
          description={`${activityDate(item?.start_date_full, item?.end_date_full)}`}
        />
        <Text2Row title="Jumlah Aktivitas:" description={item?.count_activity ?? "-"} />
        {privileges.indexOf(PRIVILEGE_ENUM.HARVEST_TOTAL) > -1 && !item?.has_harvest_failure && (
          <Text2Row
            title="Total Panen:"
            description={item?.total_harvest ? item?.total_harvest + " Kg" : "-"}
          />
        )}
        {privileges.indexOf(PRIVILEGE_ENUM.TOTAL_INCOME) > -1 && !item?.has_harvest_failure && (
          <Text2Row
            title="Total Pendapatan:"
            description={item?.total_income ? toRupiah(item?.total_income) : "-"}
          />
        )}
        {privileges.indexOf(PRIVILEGE_ENUM.DETAIL_PLANTING_SEASON) > -1 && (
          <div className={"d-flex justify-content-end"}>
            {General.loading ? (
              <Skeleton animation="wave" variant={"text"} width={100} />
            ) : (
              <a
                className={"link"}
                onClick={() => {
                  history.push("/lahan/list/active/" + id + "/history-activity/" + item?.id)
                }}>
                Lihat Detail
              </a>
            )}
          </div>
        )}
      </>
    )
  }

  const selectDate = (
    <div className={"d-flex justify-content-end mb-8"}>
      <div
        onClick={() => setOpenDate(!openDate)}
        className={"select-date d-flex justify-content-between align-items-center cursor-pointer"}>
        <label className={"mb-0 txt-12 cursor-pointer"}>
          {dateRange ? dateRange : "Pilih Range Tanggal"}
        </label>
        <CalenderSvgIcon />
      </div>
    </div>
  )
  const submitLog = () => {
    const valueArray = 0
    let selectedDate =
      dateSelect(format(state[valueArray].startDate, "yyyy-MM-dd")) +
      " s/d " +
      dateSelect(format(state[valueArray].endDate, "yyyy-MM-dd"))
    setDateRange(selectedDate)
    setFilterDate(true)
    setOpenDate(false)
  }
  const resetDate = () => {
    setOpenDate(false)
    setFilterDate(false)
    setDateRange("")
    setState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection"
      }
    ])
  }
  const dateRangePicker = (
    <div className={"position-relative"}>
      <div className={"date-picker-custom"}>
        <DateRange
          onChange={(item) => setState([item.selection])}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={1}
          ranges={state}
          direction="horizontal"
          preventSnapRefocus={true}
          calendarFocus="backwards"
        />
        <div className={"action-wrapper d-flex justify-content-end py-4 px-4 border-top"}>
          <button onClick={resetDate} type={"button"} className={"btn btn-outline-primary mx-4"}>
            Reset Tanggal
          </button>
          <button onClick={submitLog} type={"button"} className={"btn btn-primary mx-4"}>
            Terapkan
          </button>
        </div>
      </div>
    </div>
  )

  const filterComponent = (
    <>
      <Card className="card-custom card-shadowless">
        <CardHeader title="Varietas Tanaman" className="px-0" />
        <CardBody className="px-0">
          <RowModule>
            <SelectInput
              name="variety_id"
              onChange={onChangeVariant}
              editable={!isReadOnly}
              label="Nama Varietas"
              placeholder="Pilih Salah Satu"
              options={listVariant}
              withoutFeedback={true}
              clearNow={true}
              value={variant}
            />
          </RowModule>
        </CardBody>
      </Card>
      <Card className="card-custom card-shadowless">
        <CardHeader title="Tanggal Aktivitas" className="px-0" />
        <CardBody className="px-0">
          {selectDate}
          {openDate && dateRangePicker}
        </CardBody>
      </Card>
    </>
  )

  const filterOnSubmitForm = (e) => {
    const valueArray = 0
    const { values, submited } = e
    if (submited === true) {
      let param
      if (filterDate) {
        param = {
          variety_id: values.variety_id,
          start_date: format(state[valueArray].startDate, "yyyy-MM-dd"),
          end_date: format(state[valueArray].endDate, "yyyy-MM-dd")
        }
      } else {
        param = {
          variety_id: values.variety_id
        }
      }

      if (param) {
        setInitialValue(param)
        dispatch(getHistoryActivityList(id, param))
        setShowFilter(false)
        setFilterOn(true)
      }
    }
  }
  const onReset = () => {
    setShowFilter(false)
    setFilterOn(false)
    setInitialValue({
      start_date: "",
      end_date: ""
    })
    resetDate()
    setVariant("")
    let param = {
      variety_id: "",
      start_date: "",
      end_date: ""
    }
    dispatch(getHistoryActivityList(id, param))
  }
  const handleOpenCloseFilter = (value) => {
    setShowFilter(value)
  }

  const titleCard = (start, end, year, data) => {
    let title = ""
    if (start && end) {
      title = `${start} - ${end} ${year}`
    } else {
      title = `${start} ${year}`
    }
    return (
      <div className={"d-flex align-items-center w-100  justify-content-between "}>
        <div>
          <div>{title}</div>
        </div>
        {data?.has_harvest_failure && <Status text={"Gagal Panen"} variant={"danger"} />}
      </div>
    )
  }

  const filter = (
    <div className="filter-container">
      <div className="position-relative d-flex flex-wrap justify-content-end align-items-center mb-10">
        <Filter
          filterInnerRef={formRef}
          filterHandleSubmit={filterOnSubmitForm}
          filterFormInitialValues={initialValue}
          filterComponent={filterComponent}
          filterOnReset={onReset}
          showFilter={showFilter}
          handleOpenCloseFilter={handleOpenCloseFilter}
          filterOn={filterOn}
          noneOverflow={true}
        />
      </div>
    </div>
  )

  return (
    <>
      {filter}
      {Object.keys(listActivity).length == 0 && General.loading == false ? (
        <EmptyCard body={<h6>Belum ada riwayat aktivitas.</h6>} />
      ) : (
        Object.keys(listActivity).map((item, index) => {
          return (
            <>
              <div className="my-5">
                <span key={index} className="h3">
                  {item}
                </span>
              </div>
              {listActivity[item].map((history, indexHistory) => {
                return (
                  <RowModule className="m-5" key={indexHistory}>
                    <div className="mb-5 card_activity_history_customs">
                      <CardDetail
                        titleClassName={"w-100 "}
                        titleCardDetail={titleCard(
                          history?.start_date,
                          history?.end_date,
                          history?.year,
                          history
                        )}
                        flatIconAndText2Rows={Text2Rows(history)}
                        svgImage={iconCard(history)}
                      />
                    </div>
                  </RowModule>
                )
              })}
            </>
          )
        })
      )}
    </>
  )
}