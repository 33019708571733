import React, { useEffect, useRef, useState } from "react"
import * as Yup from "yup"
import { useSubheader } from "../../../../../_metronic/layout/index.js"
import { Col } from "react-bootstrap"
import { TabsInputComponent } from "../../../../../component/index.jsx"
import {
  FORM_NEED,
  getMaximumErrorString,
  getMinimumErrorString,
  getStringErrorMassage
} from "../../../../helpers/StringCollection.js"
import { useDispatch, useSelector } from "react-redux"
import {
  clearBroadcast,
  createBroadcast,
  getAppList,
  uploadUrlAttachemnt
} from "../../../../../redux/actions/BroadcastAction.jsx"
import { PopupQuestion } from "../../../../../component/atoms/Popup/index.jsx"
import swal from "sweetalert"
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers/index.js"
import { useHistory } from "react-router-dom"
import { ILUSTRATION_CONSTANS } from "../../../../../constants/ImageConstants.js"
import FormViewCreate from "./step/FormViewCreate.jsx"
import { VARIABLE_CONSTANT } from "../../../../../constants/VariableConstant.js"
import {
  clearUploadAction,
  resetUrlResponse
} from "../../../../../redux/actions/MasterDataAction.jsx"
import { useTranslation } from "react-i18next"

const BroadcastCreate = () => {
  const subHeader = useSubheader()
  const history = useHistory()
  const formRef = useRef()
  const dispatch = useDispatch()
  const { General, Broadcast } = useSelector((state) => state)
  const { t } = useTranslation()
  const stepsDescription = [null]

  const [stepsTitle] = useState([null])
  const [noFile, setNoFile] = useState(false)
  const [attachment, setAttachment] = useState([])
  const [banner, setBanner] = useState("")
  const [dataBody, setDataBody] = useState("")
  const [fileList, setFileList] = useState([])
  const [showPopup, setShowPopup] = useState(false)
  const fileListRef = useRef(fileList)
  const [actionFile, setActionFile] = useState([])
  const [dataFile, setDataFile] = useState([])
  const [appList, setAppList] = useState([])
  const [attachmentsData, setAttachmentsData] = useState([])
  const [successSend, setSuccessSend] = useState(false)
  const [isValidMain, setIsValidMain] = useState(true)
  const [sizeFiles, setSizeFiles] = useState(0)
  const [fileFormList, setFileFormList] = useState([])
  const [fileCounter, setFileCounter] = useState(false)
  const [isValid, setIsValid] = useState(true)
  const [initialValue, setInitialValue] = useState({
    send_to: [],
    banner_image: "",
    title: "",
    body: "",
    attachments: []
  })

  const { MasterData } = useSelector((state) => state)
  let urlAttachment = [...attachment]
  let newFileList = []

  useEffect(() => {
    if (fileFormList.length > 0) {
      if (
        fileFormList.length <= 10 &&
        isValidMain === true &&
        sizeFiles <= VARIABLE_CONSTANT.MAX_SIZE_UPLOAD
      ) {
        setIsValid(false)
      } else {
        setIsValid(true)
      }
    } else {
      if (isValidMain === true) {
        setIsValid(false)
      } else {
        setIsValid(true)
      }
    }
  }, [isValidMain, fileFormList, fileCounter, sizeFiles])
  useEffect(() => {
    fileListRef.current = fileList
    for (let i = 0; i < fileList.length; i++) {
      newFileList.push({ data: fileList[i], url: attachment[i] })

      setActionFile(newFileList.reverse())
    }
  }, [fileList])

  useEffect(() => {
    subHeader.setButton(buttonSubmited)
  }, [isValid])

  useEffect(() => {
    if (MasterData.uploadType === "AVATAR") {
      setBanner(MasterData.urlResponseUpload)
    }
    return () => {
      dispatch(resetUrlResponse())
    }
  }, [MasterData.urlResponseUpload])

  useEffect(() => {
    if (Broadcast.urlAttachment) {
      urlAttachment.push(Broadcast.urlAttachment)
      setAttachment(urlAttachment)
    }
  }, [Broadcast.urlAttachment])

  useEffect(() => {
    subHeader.setBreadcrumbs([
      {
        pathname: "/role/list",
        title: "Admin Setting"
      },
      {
        pathname: "/broadcast/list",
        title: "Broadcast Message"
      },
      {
        title: "Tambah"
      }
    ])
  }, [])

  useEffect(() => {
    dispatch(getAppList())
  }, [])

  useEffect(() => {
    setAppList(Broadcast?.pageData)
  }, [Broadcast, General])
  let mainData = []
  useEffect(() => {
    if (fileList.length === fileFormList.length) {
      for (let i = 0; i < actionFile.length; i++) {
        const data = {
          file_url: actionFile[i]?.url,
          file_size: actionFile[i]?.data?.size,
          file_name: actionFile[i]?.data?.path
        }

        mainData.push(data)
      }

      if (mainData.length > 0) {
        setInitialValue({
          banner_image: banner ? banner : null,
          title: dataFile?.title,
          // send_to: dataFile?.send_to.length > 1 ? ["ALL"] : dataFile?.send_to,
          send_to: [appList[0]?.enum_name],
          body: dataBody,
          attachments: mainData
        })
        setAttachmentsData(mainData)
      }
    }
  }, [actionFile])

  useEffect(() => {
    if (attachmentsData.length > 0) {
      setSuccessSend(true)
    }
  }, [attachmentsData])

  useEffect(() => {
    if (initialValue.title !== "") {
      setSuccessSend(true)
      dispatch(createBroadcast(initialValue))
      dispatch(clearUploadAction())
      dispatch(clearBroadcast())
    }
  }, [initialValue])
  useEffect(() => {
    if (noFile === true) {
      setInitialValue({
        banner_image: banner ? banner : null,
        title: dataFile?.title,
        // send_to: dataFile?.send_to.length > 1 ? ["ALL"] : dataFile?.send_to,
        send_to: [appList[0]?.enum_name],
        body: dataBody,
        attachments: []
      })
    }
  }, [noFile])

  useEffect(() => {
    if (successSend) {
      if (General.isFinishUpdateOrDelete) {
        swal({
          text: t("messages.response.broadcast_create_success"),
          icon: toAbsoluteUrl(ILUSTRATION_CONSTANS.BROADCAST_SUCCESS_CREATE)
        })
        history.push("/broadcast/list")
      }
    }
  }, [General.isFinishUpdateOrDelete && successSend])
  const submitedAction = ({ values }) => {
    setDataFile(values)
    if (fileFormList.length > 0) {
      for (let i = 0; i < fileFormList.length; i++) {
        dispatch(
          uploadUrlAttachemnt({
            data: fileFormList[i],
            lenght: fileFormList.length,
            type: fileFormList[i].type === "application/pdf" ? "INBOX_FILE" : "AVATAR"
          })
        ).then(() => {
          if (fileFormList[i]) {
            let data = [...fileListRef.current, fileFormList[i]]
            setFileList(data)
          }
        })
      }
    } else {
      setNoFile(true)
    }
  }
  const BcSchema = [
    Yup.object().shape({
      send_to: Yup.array().required(getStringErrorMassage("Send To", FORM_NEED.harusDiIsi)),
      title: Yup.string()
        .required(getStringErrorMassage("Headline", FORM_NEED.harusDiIsi))
        .min(3, getMinimumErrorString(3))
        .max(130, getMaximumErrorString(130))
    })
  ]
  const buttonSubmited = (
    <button
      type="button"
      disabled={isValid}
      className={isValid ? "btn button-disabled" : "btn btn-primary"}
      onClick={() => setShowPopup(true)}>
      Submit
    </button>
  )

  const handleOk = () => {
    if (formRef.current) {
      formRef.current?.handleSubmit()
    }
  }

  const InputCreateBC = [
    <FormViewCreate
      key={1}
      setFileListForm={setFileFormList}
      counterFile={setFileCounter}
      setDataBodySend={setDataBody}
      setValidBtn={setIsValidMain}
      setFileSize={setSizeFiles}
    />
  ]

  return (
    <>
      <PopupQuestion
        onCancel={() => setShowPopup(false)}
        title={"KONFIRMASI BROADCAST MESSAGE"}
        onOk={handleOk}
        show={showPopup}
        bodyCustom={
          <Col>
            <p className={"text-center"}>
              Apakah anda yakin mengirim Broadcast Message? Broadcast Message yang sudah terkirim
              <strong> tidak bisa diedit.</strong>
            </p>
          </Col>
        }
      />
      <TabsInputComponent
        isEditTabs={false}
        steps={stepsTitle}
        stepDescription={stepsDescription}
        formInitialValues={initialValue}
        formId="CreateBC"
        innerRef={formRef}
        componentTab={InputCreateBC}
        validationSchema={BcSchema}
        onSubmitForm={submitedAction}
      />
    </>
  )
}

export default BroadcastCreate