import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { loadUserLogin } from "../../../service/userManagementAction.js"
import { useLocation } from "react-router"
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap"
import { RoutesConstants } from "../../../../constants/RoutesConstants.js"
import { FIELD_OFFICER_ROLE_ENUM, ROUTING_STATUS } from "../../../../constants/StringConstant.js"
import { DetailSvgIcon, EditSvgIcon } from "../../../../component/atoms/Icons/SVG/index.jsx"
import { formatterRowComponent } from "../../../../component/atoms/Tabel/index.jsx"
import { sortCaret } from "../../../../_metronic/_helpers/index.js"
import { getUrlParsingPageFilter } from "../../../../config/EndpointCollection.js"
import { CardBody } from "../../../../_metronic/_partials/controls/index.js"
import { EmptyCard, TableSkeleton, TableView } from "../../../../component/index.jsx"
import { PRIVILEGE_ENUM } from "../../../../constants/PrivilegeConstants.js"
export function FoQaTabInActive({ isActiveSearch, changePage, history, initialFilterTable }) {
  const { t } = useTranslation()
  const { FieldOfficer, General } = useSelector((state) => state)
  const data = FieldOfficer
  const general = General

  const privileges = loadUserLogin().privilege
  const location = useLocation()

  const UiId = (e) => {
    return (
      <div>
        <div className={"font-weight-bolder font-size-lg"}>{e.code ?? "-"}</div>
      </div>
    )
  }

  const UiName = (e) => {
    return (
      <div>
        <div className={"font-weight-bolder font-size-lg"}>{e.name ?? "-"}</div>
        <div>{e.phone ?? "-"}</div>
      </div>
    )
  }

  const UiDate = (e) => {
    return (
      <>
        <div className="mb-1">{e.create_date}</div>
        <div>{e.create_time}</div>
      </>
    )
  }

  const uiAction = (e) => {
    return (
      <div>
        {privileges.indexOf(PRIVILEGE_ENUM.DETAIL_FIELD_OFFICER_QUALITY_ASSURANCE) > -1 && (
          <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Detail</Tooltip>}>
            <div
              onClick={() => {
                history.push(
                  RoutesConstants.FIELD_OFFICER.DETAIL_FO_QA(
                    FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER_QA,
                    ROUTING_STATUS.STATUS_STRING.INACTIVE,
                    e?.id
                  )
                )
              }}
              className="btn btn-icon btn-detail btn-hover-secondary btn-sm mx-3">
              <DetailSvgIcon />
            </div>
          </OverlayTrigger>
        )}
        {privileges.indexOf(PRIVILEGE_ENUM.EDIT_FIELD_OFFICER) > -1 && (
          <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Edit</Tooltip>}>
            <div
              onClick={() => {
                history.push(
                  RoutesConstants.FIELD_OFFICER.EDIT_FO_QA(
                    FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER_QA,
                    ROUTING_STATUS.STATUS_STRING,
                    e?.id
                  )
                )
              }}
              className="btn btn-icon btn-detail btn-hover-secondary btn-sm mx-3">
              <EditSvgIcon />
            </div>
          </OverlayTrigger>
        )}
      </div>
    )
  }
  const colums = [
    {
      dataField: "staff.code",
      text: "ID FO",
      formatter: formatterRowComponent,
      headerStyle: () => {
        return { width: "10%" }
      },
      formatExtraData: {
        externalStyle: UiId
      },
      sortCaret: sortCaret,
      sort: true,
      onSort: (field, order) => {
        let param = getUrlParsingPageFilter({
          ...initialFilterTable,
          sort: field + "," + order
        })
        history.push({ pathname: location.pathname, search: param })
      }
    },
    {
      dataField: "name",
      text: "NAMA",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: UiName
      },
      sortCaret: sortCaret,
      sort: true,
      headerStyle: () => {
        return { width: "15%" }
      },
      onSort: (field, order) => {
        let param = getUrlParsingPageFilter({
          ...initialFilterTable,
          sort: field + "," + order
        })
        history.push({ pathname: location.pathname, search: param })
      }
    },
    {
      dataField: "full_address",
      text: "ALAMAT",
      formatter: formatterRowComponent,
      headerStyle: () => {
        return { width: "40%" }
      }
    },
    {
      dataField: "mitra_total",
      text: "jumlah mitra",
      formatter: formatterRowComponent,
      headerStyle: () => {
        return { width: "10%" }
      },
      formatExtraData: {
        externalStyle: (e) => <div>{e?.mitra_total || 0} Mitra</div>
      }
    },
    {
      dataField: "staff.createdDate",
      text: "TANGGAL DIBUAT",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: UiDate
      },
      onSort: (field, order) => {
        let param = getUrlParsingPageFilter({
          ...initialFilterTable,
          sort: field + "," + order
        })
        history.push({ pathname: location.pathname, search: param })
      },
      sortCaret: sortCaret,
      sort: true,
      headerStyle: () => {
        return { width: "15%" }
      }
    },
    {
      dataField: "action",
      text: "ACTION",
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiAction
      },
      headerStyle: () => {
        return { width: "15%" }
      }
    }
  ]
  return (
    <Card>
      <CardBody>
        {general.loading ? (
          <TableSkeleton column={6} row={10} />
        ) : data?.pageData?.length === 0 ? (
          <EmptyCard
            body={
              isActiveSearch
                ? t("messages.response.data_not_found")
                : t("messages.response.empty_state", { name: "FO tidak aktif" })
            }
          />
        ) : (
          ""
        )}
        <TableView
          callbackAfterPageChange={changePage}
          columnProperties={colums}
          dataTable={data?.pageData ?? []}
          currentPage={data?.page?.currentPage ?? 1}
          currentElement={data?.page?.currentElement ?? 10}
          totalCount={data?.page?.totalCount ?? 0}
          loadingGetData={general.loading}
          ignoreSinglePage
          rowClasses={() => {
            return "cursor-pointer"
          }}
        />
      </CardBody>
    </Card>
  )
}