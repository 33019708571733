export const ID_CONTENT = {
  button: {
    bulk_erp: "Bulk ERP ({{count}} Terpilih)",
    cancel: "Batal",
    confirmation_planting_season: "Konfirmasi Musim Tanam",
    continue: "Lanjut",
    download_so_selected: "Unduh So ({{selected}}) dipilih",
    oke: "Oke",
    previous: "Kembali",
    send_to_finance: "Kirim ke Finance",
    submit: "Kirim",
    approve: "Approve",
    add: "Tambah",
    reject: "Reject",
    see_detail: "Lihat Detail",
    next: "Lanjut",
    apply: "Terapkan"
  },
  content: {
    attachment_maximum_size: "Akumulasi maksimal ukuran file 15Mb.",
    attachment_maximum_file: "Jumlah maksimal upload file adalah 10.",
    attachment_format_file: "Format file .pdf, .jpeg, .jpg, .png."
  },
  description: {},
  menu: {
    add: "Tambah",
    area: "Lahan",
    dashboard: "Dashboard",
    farmer: "Petani",
    fo: "FO",
    mac: "MAC",
    master_data: "Master Data",
    mitra: "Mitra",
    order: "Penjadwalan",
    setting: "Pengaturan",
    submission: "Pengajuan"
  },
  menu_aside: {
    area_location: "Lokasi Lahan",
    area_terverifikasi: "Teregistrasi",
    area_pending: "Sedang Menunggu",
    area_reject: "Ditolak",
    area_unassigned: "Unassigned",
    area_lead: "Calon Lahan",
    area_activity_report: "Laporan Aktivitas",
    area_activity_report_kur: "Laporan Aktivitas KUR",
    area_sales_order: "Sales Order",
    all_schedule: "Semua Jadwal",
    approved_schedule: "Terjadwal",
    schedule_pasca_planting: "Pasca Tanam",
    schedule_pra_planting: "Pra Tanam",
    mac_profile: "Profil",
    mac_user: "Pengguna",
    pending: "Sedang Menunggu",
    rescheduled: "Jadwal Ulang",
    pre_planting: "Pra Tanam",
    spraying: "Penyemprotan",
    regional: "Regional",
    sertification_and_block: "Sertifikasi & Blok"
  },
  sub_header: {
    activity_report: "Laporan Aktivitas",
    activity_report_kur: "Laporan Aktivitas Kur",
    title: "Sales Order",
    all_schedule: "Semua Jadwal",
    mac_activity_report: "Laporan Aktivitas MAC",
    spraying_pre_planting: "Penyemprotan Pra Tanam",
    pre_planting: "Pra Tanam",
    mitra_list_ca: "Daftar Mitra CA",
    regional_information: "Informasi Regional",
    mitra_list: "Daftar Mitra"
  },
  placeholder: {
    insert_package_id_or_name: "Masukkan ID paket / nama paket",
    select_one: "Pilih Salah Satu",
    select_regional: "Pilih daerah regional",
    insert_plant_type: "Masukkan nama tanaman",
    insert_mac_username_or_request_id: "Cari nama pengguna / ID pengajuan",
    input_reason: "Input alasan disini",
    search_activity_report: "Cari nama pengguna / ID pengajuan",
    select_user_type: "Pilih tipe pengguna",
    insert_regional_id_or_name: "Masukkan ID regional / nama regional",
    insert_area_name: "Masukkan nama lahan",
    select_team_drone: "Pilih Tim Drone",
    insert_province: "Masukan Nama Provinsi",
    search_mitra_name_or_id: "Cari ID mitra / nama mitra",
    search_farmer_name_or_id: "Cari ID petani / nama petani",
    insert_regional_name: "Masukan nama regional",
    insert_sub_regional_name: "Input nama sub regional",
    search_area_block: "Cari nama lahan / blok"
  },
  label: {
    edit_package: "PERBARUI PAKET",
    add_package: "tambah paket",
    total_pesticide: "Jumlah Pestisida",
    package_status: "Status Paket",
    total_treatment: "Total treatment",
    package_id: "ID Paket",
    spraying_package: "Paket Penyemprotan",
    detail_regional: "Detail Regional",
    farmer_id: "Id Petani",
    list_mitra_ca: "Daftar Mitra CA",
    farmer_list: "Daftar Petani",
    inactive: "Tidak Aktif",
    edit_sub_regional: "Edit Sub Regional",
    rejection_reason: "Alasan ditolak",
    edit_data_regional: "Edit Data Regional",
    regional_id: "ID regional",
    name: "Nama",
    province_name: "Nama Provinsi",
    rejected_date: "Tanggal ditolak",
    reject: "ditolak",
    sub_regional: "Sub Regional",
    sub_regional_name: "Nama Sub Regional",
    district_or_city: "Kabupaten / kota",
    add_sub_regional: "Tambah Sub Regional",
    id_sub_regional: "ID Sub Regional",
    sub_regional_amount: "Jumlah Sub Regional",
    active: "Aktif",
    address: "Alamat",
    mitra_id: "ID Mitra",
    mitra_type: "Tipe Mitra",
    treatment_name: "Treatment Name",
    activity: "Aktivitas",
    activity_detail: "Detail Aktivitas",
    activity_type: "Jenis Aktivitas",
    activity_working_date: "Tanggal Aktivitas Dikerjakan",
    activity_picture: "foto aktivitas",
    area_name: "Lahan",
    area_information: "Informasi Lahan",
    area_address: "Alamat Lahan",
    activity_date: "Tanggal Aktivitas",
    area_location: "Lokasi Lahan",
    action: "action",
    bulk_scheduling: "Penjadwalan Massal",
    cabang: "Cabang",
    company_name: "Nama Perusahaan",
    company_phone: "No. Telephone Perusahaan",
    company_full_address: "Alamat Lengkap Perusahaan",
    company_email: "Email Perusahaan",
    company_document: "Dokumen Perusahaan",
    category_item: "Kategori Item",
    created_date: "Tanggal Dibuat",
    canceled_by: "Dibatalkan Oleh",
    date: "Tanggal",
    data_activity: "Data Aktivitas",
    data_rescheduled: "Data Jadwal Ulang",
    edit: "Edit",
    farmer: "Petani",
    farmer_name: "Nama Petani",
    full_address: "Alamat Lengkap",
    harvest_date: "Tanggal Panen",
    input_reason: "Input Alasan",
    land_area_worked: "Luas Lahan Dikerjakan",
    land_area_total: "Total Luas Lahan",
    land_conditions: "Kondisi Lahan",
    mitra: "Mitra",
    mitra_name: "Nama Mitra",
    manual_area_size: "Luas lahan (Manual)",
    machine_owner: "Pemilik Alsintan",
    mac_profile_information: "Informasi Profil MAC",
    mac_user_information: "Informasi Pengguna MAC",
    mac_id_user: "ID Pengguna MAC",
    mac_user_name: "Nama Pengguna MAC",
    mac_full_address: "Alamat Pengguna MAC",
    mac_data_scheduled: "Data Pengajuan",
    scheduled_data: "Data Pengajuan",
    mac_data_activity: "Data Aktivitas",
    mac_data_rescheduled: "Data Jadwal Ulang",
    member: "anggota",
    nominal: "nominal",
    npwp: "Nomor NPWP",
    nik: "NIK",
    note: "Catatan",
    cp_name: "Narahubung",
    cp_phone: "No. Telephone",
    order_id: "ID Order",
    overview_activity: "Overview Aktivitas",
    overview_land_data: "Overview Data Lahan",
    operator: "Operator",
    others: "Lainnya",
    operator_name: "Nama Operator",
    plant_data: "Data Tanaman",
    plant_type: "Jenis Tanaman",
    plant_varieties: "Varietas tanaman",
    planting_date: "Tanggal Tanam",
    plant_age: "Umur Tanaman",
    phone_number: "No Telephone",
    province: "Provinsi",
    phone: "No. Telephone",
    pic: "PIC",
    pasca_planting: "Pasca Tanam",
    first_pic: "PIC Awal Terjadwal",
    regional: "Regional",
    request_id: "ID Pengajuan",
    request_detail: "detail pengajuan",
    pilot_name: "Nama Pilot",
    product: "Produk",
    pending: "Sedang Menunggu",
    payment_receipt: "bukti pembayaran",
    polygon_area_size: "Luas lahan (Polygon)",
    upload_so: "unggah SO",
    total_activity: "Jumlah Kegiatan",
    type_schedule: "Tipe Pengajuan",
    warehouse: "Gudang Pestisida",
    worker_area_size: "Luas Lahan yang di kerjakan (Alsintan)",
    receipt_number: "No. Bukti",
    submit_spk: "KIRIM SPK",
    submission_data: "Data Pengajuan",
    select_date_range: "Pilih Range Tanggal",
    status: "Status",
    supporting_photo: "Foto Pendukung",
    scheduled: "Terjadwal",
    scheduling: "Penjadwalan",
    schedule_date: "Pengajuan Tanggal Aktivitas",
    submitted_by: "Diajukan Oleh",
    submitted_time: "Waktu Diajukan",
    scheduled_date: "Tanggal Terjadwal",
    first_scheduled_date: "Tanggal Awal Terjadwal",
    scheduled_by: "Dijadwalkan Oleh",
    scheduled_reason: "Alasan Pengajuan",
    user_name: "Nama Pengguna",
    spraying_pasca_planting: "Penyemprotan Pasca Tanam",
    spraying_activity: "Aktivitas Penyemprotan",
    spraying_activity_mac: "Aktivitas Penyemprotan MAC",
    spraying: "Penyemprotan",
    team_drone: "Tim Drone",
    team_name: "Nama Tim",
    reschedule: "Jadwal Ulang",
    rescheduled_date: "Tanggal Pengganti",
    submitted_date: "Tanggal Pengajuan",
    rescheduled_reason: "Alasan Jadwal Ulang",
    reason_reject: "Alasan Penolakan",
    reason_cancelation: "Alasan Pembatalan",
    reject_by: "ditolak oleh",
    overdue: "Terlewat",
    done: "Selesai",
    canceled: "Dibatalkan",
    rejected: "Ditolak",
    rejected_by: "Ditolak Oleh",
    reset_date: "Reset Tanggal",
    apply: "Terapkan",
    mac_user_merchant_name: "Nama Pengguna MAC",
    add_plant_type: "TAMBAH JENIS TANAMAN",
    edit_plant_type: "EDIT JENIS TANAMAN",
    insert_plant_type_name: "Masukkan nama jenis tanaman",
    input_plant_name: "Input Nama Tanaman",
    add_regional: "Tambah Regional",
    edit_regional: "Edit Regional",
    varietas: "Varietas",
    regional_name: "Nama Regional",
    list_province: "List Provinsi",
    insert_regional_name: "Input nama regional",
    spraying_schedule: "Jadwal Penyemprotan",
    preview: "Preview",
    detail_sub_regional: "List Sub Regional",
    spraying_activity_scheduling: "Penjadwalan Aktivitas Penyemprotan",
    review_and_submit: "Review & Submit",
    bulk_approval: "Penjadwalan Massal",
    land_area: "Luas Lahan",
    delete_sub_regional: "Hapus Sub Regional",
    add_treatment: "Tambah Treatment",
    edit_treatment: "Perbarui Treatment",
    id_treatment: "ID Treatment",
    hst: "HST",
    description: "Deskripsi",
    submitted: "Diajukan",
    received: "Diterima",
    submission_certification_block: "Pengajuan Sertifikasi & Blok",
    create_certification: "Buat Pengajuan Sertifikasi",
    canot_more_than: "Total lahan tidak boleh lebih dari 100.000 m² (10 Ha)",
    total_area: "Jumlah Lahan",
    female_planting_date: "Rencana Tanam Betina",
    see_map: "Lihat Peta",
    data_previous_plant: "Data Tanaman Sebelumnya",
    data_from_area_bigger:
      "Informasi diambil dari data Aktivasi Pembenihan dari ukuran lahan terbesar.",
    land_history: "Sejarah Lahan",
    former_bero: "Bekas Bero",
    disertifikasi: "Disertifikasi",
    seed_class: "Kelas Benih",
    field_inspection: "Pemeriksaan Lapangan",
    cancel_submission: "Batalkan Pengajuan",
    cancel_submission_certification: "BATALKAN PENGAJUAN SERTIFIKASI",
    submission_download: "Unduh Pengajuan",
    block_area: "Luas Blok",
    land_data: "Data Lahan",
    seed_source_data: "Data Asal Benih",
    base_seed_source: "Asal Sumber Benih",
    total_seed: "Jumlah Total Benih",
    female_seed: "Benih Betina",
    male_seed: "Benih Jantan",
    base_seed_name: "Nama Induk",
    no_lot: "Nomot Lot",
    count_seed: "Jumlah Benih",
    label_number: "Nomor Seri Label",
    label_images: "Foto Label",
    block_location: "Lokasi Blok Lahan",
    note_fo_qa: "Catatan dari FO Quality Assurance",
    planting_male_date: "Tanggal Tanam Jantan",
    planting_female_date: "Tanggal Tanam Betina",
    planting_date_data: "Data Tanggal Tanam",
    location_image: "Foto Lokasi",
    block_selected: "Blok Lahan Terpilih",
    sub_district: "Kelurahan",
    variety_name: "Nama Varietas",
    list_area: "Daftar Lahan"
  },
  messages: {
    alert: {
      invalid_file_type: "tipe file tidak valid",
      max_mb: "max {{size}} mb",
      note_fo: `Lahan "{{block}}" tidak memenuhi kriteria untuk melakukan aktivitas pembenihan jagung hibrida.`
    },
    title: {
      change_status_package: "UBAH STATUS PAKET",
      activity_send_to_erp: "KIRIM AKTIVITAS KE ERP",
      activity_select_regional: "REGIONAL PELAPORAN AKTIVITAS",
      approve_schedule: "APPROVE PENJADWALAN",
      cancel_schedule: "CANCEL PENJADWALAN",
      cancel_reschedule: "CANCEL EDIT PENJADWALAN",
      edit_order: "EDIT ORDER",
      reschedule_order: "RESCHEDULE ORDER",
      planting_season_confirmation: "KONFIRMASI MUSIM TANAM",
      reject_order: "REJECT ORDER",
      cancel_order: "ORDER CANCELATION",
      preview_information_regional: "Preview Informasi Regional"
    },
    confirmation: {
      edit_package:
        "Apakah anda yakin ingin memperbarui paket ini? Tindakan ini akan berpengaruh kepada semua data yang terkait dengan paket ini.",
      change_status_package:
        "Apakah anda yakin ingin menonaktifkan paket ini? Tindakan ini akan berpengaruh kepada semua data yang terkait dengan paket ini.",
      change_status_package_active:
        "Apakah anda yakin ingin mengaktifkan paket ini? Setelah diaktifkan, paket ini dapat digunakan kembali.",
      add_package:
        "Data paket yang sudah diisi akan masuk kedalam daftar paket. Pastikan data yang diisi sudah benar sebelum melanjutkan",
      edit_sub_regional:
        "Apakah anda yakin ingin memperbarui data sub regional ini? Tindakan ini akan berpengaruh kepada semua data yang terkait dengan sub regional ini.",
      edit_regional:
        "Apakah anda yakin ingin memperbarui data regional ini? Tindakan ini akan berpengaruh kepada semua data yang terkait dengan regional ini.",
      create_sub_regional:
        "Data sub regional yang sudah diisi akan masuk kedalam daftar sub regional terkait. Pastikan data yang diisi sudah benar sebelum melanjutkan",
      create_regional:
        "Data regional yang sudah diisi akan masuk kedalam daftar regional. Pastikan data yang diisi sudah benar sebelum melanjutkan",
      submit_add_regional: "Pastikan data regional yang di isi sudah benar sebelum melanjutkan.",
      activity_approve_to_erp_confirmation:
        "Dengan menyetujui aktivitas ini, maka data yang tersedia akan dikirim ke ERP. Pastikan seluruh data telah benar.",
      activity_approve_to_erp_confirmation_bulk:
        "Kegiatan yang dipilih akan di kirim ke sistem ERP (<strong>{{count}} Kegiatan</strong>). Pastikan semua data yang dipilih telah benar.",
      create_so:
        "Data yang diisi akan dikirim menjadi SPK dalam ERP. Pastikan semua data yang diinput telah benar.",
      create_so_success: "SO telah berhasil dibuat.",
      order_spraying_cancel:
        "Dengan membatalkan pengisian form, order tidak akan dijadwalkan dan semua data yang telah diisi akan hilang.",
      order_edit_spraying_cancel:
        "Dengan membatalkan edit form maka semua data yang telah diisi akan hilang.",
      order_spraying_approve:
        "Order yang di approve akan masuk kedalam tanggal penjadwalan. Pastikan data yang diinput telah benar.",
      order_edit: "Setelah menyetujui, data order akan berubah. Data yang berubah:",
      planting_season_confirmation_need_ba:
        "Dengan mengonfirmasi musim tanam, aktivitas musim tanam ini akan dipindahkan ke tab Riwayat Aktivitas. " +
        "Pastikan berita acara telah dibuat.",
      planting_season_confirmation:
        "Dengan mengonfirmasi musim tanam, aktivitas musim tanam ini akan dipindahkan ke tab Riwayat Aktivitas. ",
      team_edit_cancel:
        "Dengan membatalkan edit form maka semua data yang telah diisi akan hilang.",
      team_create_cancel:
        "Dengan membatalkan pengisian form, tim baru tidak akan dibuat dan semua data yang telah diisi akan hilang.",
      team_edit:
        "Setelah menyetujui, data tim drone akan berubah. Pastikan semua data telah diisi dengan benar.",
      team_create:
        "Dengan menambahkan tim drone, tim tersebut dapat dijadwalkan dalam aktivitas penyemprotan. Pastikan data yang diinput telah benar.",
      team_create_with_schedule:
        "Tim yang diedit masih memiliki jadwal penyemprotan dan Anggota tim yang baru akan mengikuti semua jadwal yang ada. Apakah anda yakin untuk mengganti anggota tim?",
      add_treatment:
        "Data treatment yang sudah diisi akan masuk kedalam daftar treatment terkait. Pastikan data yang diisi sudah benar sebelum melanjutkan",
      edit_treatment:
        "Apakah anda yakin ingin memperbarui treatment ini? Tindakan ini akan berpengaruh kepada semua data yang terkait dengan treatment ini.",
      cancel_submission:
        "Apakah anda yakin ingin membatalkan pengajuan lahan terpilih? Lahan yang dibatalkan akan kembali ke Field Officer."
    },
    response: {
      area_unassigned_approve:
        "Unassign request disetujui dan status lahan berubah menjadi unassigned.",
      area_unassigned_reject:
        "Unassign request ditolak dan status lahan tetap menjadi teregistrasi.",
      activity_approve_to_erp_success: "Aktivitas berhasil dikirim ke ERP.",
      broadcast_success_delete: "Pesan broadcast berhasil dihapus.",
      broadcast_create_success: "Pesan broadcast berhasil dikirim.",
      data_not_found: "Data Tidak Ditemukan",
      erp_sync: "Sinkronisasi data petani ke ERP berhasil.",
      empty_state: "Data {{name}} kosong",
      searching_not_found: "Pencarian anda tidak ditemukan",
      empty_search_subtitle: "Silahkan periksa filter atau kata kunci dan coba lagi.",
      farmer_rejected: "Petani berhasil ditolak",
      farmer_approved: "Petani berhasil diverifikasi",
      farmer_edit_success: "Data petani berhasil diperbarui",
      farmer_create_success: "Petani berhasil diregistrasi.",
      order_rejected: "Aktivitas berhasil ditolak.",
      order_cancel: "Aktivitas berhasil dibatalkan.",
      order_success: "Aktivitas berhasil dijadwalkan.",
      order_success_edit: "Aktivitas berhasil diedit.",
      success_approve_area: "Lahan berhasil diverifikasi",
      team_drone_change_success: "Tim drone telah berhasil diperbarui.",
      team_drone_add_success: "Tim drone telah berhasil ditambah.",
      success_create_plant_type: "Tanaman berhasil ditambah",
      success_edit_plant_type: "Tanaman berhasil diedit",
      success_create_regional: "Sub regional berhasil ditambah.",
      success_edit_regional: "Pembaruan regional berhasil.",
      success_edit_sub_regional: "Pembaruan sub regional berhasil.",
      response_not_found_data: "Pencarian anda tidak ditemukan",
      sub_response_not_found_data: "Silahkan periksa filter atau kata kunci dan coba lagi.",
      success_download_submission: "Pengunduhan pengajuan sertifikasi berhasil."
    }
  },
  profile: {
    label_profile: "Profil Pengguna",
    label_language: "Pilih Bahasa"
  },
  sales_order: {
    title: "Sales Order",
    table_label_order_id: "ID Order",
    table_label_area_name: "Nama Area",
    table_label_farmer: "Petani",
    table_label_activity_type: "Jenis Aktivitas",
    table_label_created_date: "Tanggal di buat",
    table_label_no_spk: "No SPK",
    order_information: "INFORMASI ORDER",
    title_detail_spk: "DETAIL SURAT PERINTAH KERJA"
  },
  table_header: {
    area_name: "Nama Lahan",
    block_name: "Nama Blok",
    activity_type: "Jenis Aktivitas",
    created_date: "Tanggal Dibuat",
    farmer: "Petani",
    mitra: "Mitra",
    planting_type: "Jenis Tanaman",
    status: "Status",
    request_id: "ID PENGAJUAN",
    user_mac: "Pengguna MAC ",
    land_area: "LUAS LAHAN",
    full_address_mac: "Alamat Pengguna MAC",
    plant_type: "Jenis Tanaman",
    plant_age: "Umur Tanaman",
    request_date: "TANGGAL PENGAJUAN",
    activity_date: "Tanggal Aktivitas",
    overdue_date: "HARI TERLEWAT",
    plant_name: "Nama Tanaman",
    user: "Pengguna",
    canceled_date: "TANGGAL DIBATALKAN",
    reject_date: "Tanggal Ditolak",
    profile_mac: "PROFIL MAC",
    scheduled_by: "Dijadwalkan oleh",
    scheduled_date: "Tanggal Dijadwalkan",
    submit_by: "Diajukan Oleh",
    canceled_by: "Dibatalkan oleh",
    reject_by: "Ditolak oleh",
    regional_id: "ID regional",
    province: "Provinsi",
    sub_regional_amount: "Jumlah sub regional",
    area: "Luas",
    varietas: "Varietas",
    female_date: "Rencana Tanam Betina",
    submission_date: "Tanggal Diajukan",
    submitted_by: "Diajukan Oleh",
    female_planting_date: "Tanggal Tanam Betina",
    created_by: "Dibuat Oleh",
    received_by: "Diterima Oleh",
    received_date: "Tanggal Diterima"
  }
}