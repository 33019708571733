import { GeneralSlice } from "../reducers/General"

const { actions: general } = GeneralSlice

export const ResetIsFinish = () => {
  return async dispatch => {
    dispatch(
      general.finishUpdateApi({
        isFinishUpdateOrDelete: undefined,
        actionInitType: undefined
      })
    )
  }
}
