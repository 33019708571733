import { GeneralSlice } from "../reducers/General.jsx"
import { SubmissionSlice } from "../reducers/Submission.jsx"
import { SUBMISSION } from "../../constants/InitTypeConstants.js"
import { requestGet, requestPost } from "../../config/index.jsx"
import { SUBMISSION_URL } from "../../constants/UrlConstants.js"

const { actions: general } = GeneralSlice
const { actions: submission } = SubmissionSlice

function setEmptyPageDataBeforeFetching(dispatch) {
  dispatch(
    submission.setPageData({
      pageData: [],
      page: {
        totalCount: 0,
        currentElement: 0,
        currentPage: 0
      }
    })
  )
}
export const getCertificationList = (status, param) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: SUBMISSION.GET_LIST_CERTIFICATION
      })
    )
    setEmptyPageDataBeforeFetching(dispatch)
    await requestGet(SUBMISSION_URL.GET_LIST_CERTIFICATION(status) + param)
      .then((res) => {
        dispatch(
          submission.setPageData({
            pageData: res.data,
            page: {
              totalCount: res.all_element,
              currentElement: res.element,
              currentPage: res.page
            }
          })
        )
        dispatch(
          general.setLoading({
            loading: false,
            action: SUBMISSION.GET_LIST_CERTIFICATION
          })
        )
      })
      .catch((e) => {
        dispatch(general.setError(e))
      })
  }
}

export const getMitraFilterCertification = () => {
  return async (dispatch) => {
    dispatch(
      general.setLoadingComponent({
        loading: true,
        actionInitType: SUBMISSION.GET_LIST_MITRA
      })
    )
    try {
      let res = await requestGet(SUBMISSION_URL.GET_LIST_MITRA())
      dispatch(submission.setFilterMitra(res.data))
      dispatch(
        general.setLoadingComponent({
          loading: false,
          actionInitType: SUBMISSION.GET_LIST_MITRA
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getSubDistrictByMitraFilterCertification = (param) => {
  return async (dispatch) => {
    dispatch(
      general.setLoadingComponent({
        loading: true,
        actionInitType: SUBMISSION.GET_LIST_SUBDISTRICT
      })
    )
    try {
      let res = await requestGet(SUBMISSION_URL.GET_LIST_SUBDISTRICT() + param)
      dispatch(submission.setFilterSubdistrict(res.data))
      dispatch(
        general.setLoadingComponent({
          loading: false,
          actionInitType: SUBMISSION.GET_LIST_SUBDISTRICT
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getVarietyFilterCertification = () => {
  return async (dispatch) => {
    dispatch(
      general.setLoadingComponent({
        loading: true,
        actionInitType: SUBMISSION.GET_LIST_VARIETAS
      })
    )
    try {
      let res = await requestGet(SUBMISSION_URL.GET_LIST_VARIETAS())
      dispatch(submission.setFilterVarietas(res.data))
      dispatch(
        general.setLoadingComponent({
          loading: false,
          actionInitType: SUBMISSION.GET_LIST_VARIETAS
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getListSeedClass = () => {
  return async (dispatch) => {
    dispatch(
      general.setLoadingComponent({
        loading: true,
        actionInitType: SUBMISSION.GET_LIST_SEED_CLASS
      })
    )
    try {
      let res = await requestGet(SUBMISSION_URL.GET_LIST_SEED_CLASS())
      dispatch(submission.setListSeedClass(res.data))
      dispatch(
        general.setLoadingComponent({
          loading: false,
          actionInitType: SUBMISSION.GET_LIST_SEED_CLASS
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getListAreaCertification = (param) => {
  return async (dispatch) => {
    dispatch(
      general.setLoadingComponent({
        loading: true,
        actionInitType: SUBMISSION.GET_LIST_AREA
      })
    )
    try {
      let res = await requestGet(SUBMISSION_URL.GET_LIST_AREA() + param)
      dispatch(submission.setListArea(res.data))
      dispatch(
        general.setLoadingComponent({
          loading: false,
          actionInitType: SUBMISSION.GET_LIST_AREA
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const clearListAreaCertification = () => {
  return async (dispatch) => {
    dispatch(submission.setListArea([]))
  }
}

export const getListAreaMapCertification = (param) => {
  return async (dispatch) => {
    dispatch(
      general.setLoadingComponent({
        loading: true,
        actionInitType: SUBMISSION.GET_LIST_AREA_MAP
      })
    )
    try {
      let res = await requestGet(SUBMISSION_URL.GET_LIST_AREA_MAP() + param)
      dispatch(submission.setListAreaMap(res.data))
      dispatch(
        general.setLoadingComponent({
          loading: false,
          actionInitType: SUBMISSION.GET_LIST_AREA_MAP
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const clearListAreaMapCertification = () => {
  return async (dispatch) => {
    dispatch(submission.setListAreaMap([]))
  }
}

export const initCreateCertification = (body) => {
  return async (dispatch) => {
    dispatch(
      general.setLoadingComponent({
        loading: true,
        actionInitType: SUBMISSION.GET_INIT_CREATE_CERTIFICATION
      })
    )
    dispatch(submission.setInitCreateCertification(null))
    try {
      let res = await requestPost(SUBMISSION_URL.GET_INIT_CREATE_CERTIFICATION(), body)
      dispatch(submission.setInitCreateCertification(res.data))
      dispatch(
        general.setLoadingComponent({
          loading: false,
          actionInitType: SUBMISSION.GET_INIT_CREATE_CERTIFICATION
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const createCertification = (body) => {
  return async (dispatch) => {
    dispatch(
      general.setLoadingGlobal({
        loading: true,
        actionInitType: SUBMISSION.CREATE_SUBMISSION
      })
    )
    try {
      let res = await requestPost(SUBMISSION_URL.CREATE_SUBMISSION(), body)
      dispatch(submission.setResponseCreateSubmission(res?.data))
      dispatch(
        general.setLoadingGlobal({
          loading: false,
          actionInitType: SUBMISSION.CREATE_SUBMISSION
        })
      )
      dispatch(
        general.finishUpdateApi({
          isFinishUpdateOrDelete: true,
          actionInitType: SUBMISSION.CREATE_SUBMISSION
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getSummaryDataCertification = () => {
  return async (dispatch) => {
    dispatch(
      general.setLoadingComponent({
        loading: true,
        actionInitType: SUBMISSION.GET_SUMMARY_DATA
      })
    )
    try {
      let res = await requestGet(SUBMISSION_URL.GET_SUMMARY_DATA())
      dispatch(submission.setSummaryData(res.data))
      dispatch(
        general.setLoadingComponent({
          loading: false,
          actionInitType: SUBMISSION.GET_SUMMARY_DATA
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}
export const getDetailCertification = (id) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: SUBMISSION.GET_DETAIL_CERTIFICATION
      })
    )
    dispatch(submission.setData({ data: null }))
    await requestGet(SUBMISSION_URL.GET_DETAIL_CERTIFICATION_AREA(id))
      .then((res) => {
        dispatch(submission.setData(res.data))
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: SUBMISSION.GET_DETAIL_CERTIFICATION
          })
        )
      })
      .catch((e) => {
        dispatch(general.setError(e))
      })
  }
}

export const cancelSubmissionCertification = (body) => {
  return async (dispatch) => {
    general.setLoadingGlobal({
      loading: true,
      actionInitType: SUBMISSION.CANCEL_SUBMISSION
    })
    try {
      let res = await requestPost(SUBMISSION_URL.CANCEL_SUBMISSION(), body)
      dispatch(submission.setResponseCancelSubmission(res?.data))
      general.setLoadingGlobal({
        loading: true,
        actionInitType: SUBMISSION.CANCEL_SUBMISSION
      })
      dispatch(
        general.finishUpdateApi({
          isFinishUpdateOrDelete: true,
          actionInitType: SUBMISSION.CANCEL_SUBMISSION
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getDetailCertificationBlock = (id) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: SUBMISSION.GET_DETAIL_CERTIFICATION_BLOCK
      })
    )
    dispatch(submission.setData({ data: null }))
    await requestGet(SUBMISSION_URL.GET_DETAIL_CERTIFICATION_BLOCK(id))
      .then((res) => {
        dispatch(submission.setData(res.data))
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: SUBMISSION.GET_DETAIL_CERTIFICATION_BLOCK
          })
        )
      })
      .catch((e) => {
        dispatch(general.setError(e))
      })
  }
}

export const getDetailDownloadSubmission = (body) => {
  return async (dispatch) => {
    dispatch(
      general.setLoadingGlobal({
        loading: true,
        actionInitType: SUBMISSION.GET_DETAIL_DOWNLOAD_SUBMISSION
      })
    )
    dispatch(submission.setDetailDownloadSubmission([]))
    await requestPost(SUBMISSION_URL.GET_DETAIL_DOWNLOAD_SUBMISSION(), body)
      .then((res) => {
        dispatch(submission.setDetailDownloadSubmission(res.data))
        dispatch(
          general.setLoadingGlobal({
            loading: false,
            actionInitType: SUBMISSION.GET_DETAIL_DOWNLOAD_SUBMISSION
          })
        )
      })
      .catch((e) => {
        dispatch(general.setError(e))
      })
  }
}

export const clearDEtailDownloadSubmission = () => {
  return async (dispatch) => {
    dispatch(submission.setDetailDownloadSubmission([]))
  }
}
