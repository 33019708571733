import React from "react"
import { Button, Col, Modal, Row } from "react-bootstrap"

export const PopupQuestion = ({
  show = false,
  title,
  titleCustom,
  body,
  bodyCustom,
  closeButton = false,
  centered = true,
  textCancel = "Cancel",
  textOk = "Lanjut",
  onCancel = () => {},
  onOk = () => {},
  onlyOk = false,
  disable = false,
  variantCancelButton = "secondary-info",
  variantOkButton = "primary",
  size,
  onCloseButton = () => {}
}) => {
  return (
    <Modal show={show} centered={centered} size={size}>
      {(title || titleCustom) && (
        <Modal.Header closeButton={closeButton} onClick={onCloseButton}>
          {title ? <h6 className="mb-0">{title}</h6> : titleCustom}
        </Modal.Header>
      )}

      {(body || bodyCustom) && <Modal.Body>{body ? body : bodyCustom}</Modal.Body>}

      <Modal.Footer>
        <Row className="justify-content-center">
          {!onlyOk && (
            <Col>
              <Button
                className="font-weight-bold"
                variant={variantCancelButton}
                block={true}
                onClick={onCancel}>
                {textCancel}
              </Button>
            </Col>
          )}
          <Col>
            <Button
              className="font-weight-bold"
              variant={variantOkButton}
              disabled={disable}
              block={true}
              onClick={onOk}>
              {textOk}
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  )
}
