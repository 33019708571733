import React from "react"
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap"
import TableView, {
  formatterRowComponent,
  initialFilterTable
} from "../../../../../../component/atoms/Tabel/index.jsx"
import { Card, CardBody } from "../../../../../../_metronic/_partials/controls/index.js"
import { DetailSvgIcon } from "../../../../../../component/atoms/Icons/SVG/index.jsx"
import { sortCaret } from "../../../../../../_metronic/_helpers/index.js"
import { useSelector } from "react-redux"
import { EmptyCard, TableSkeleton } from "../../../../../../component/index.jsx"
import { useTranslation } from "react-i18next"
import { ILLUSTRATION_ASSETS_V2 } from "../../../../../../constants/ImageConstants.js"
import { RoutesConstants } from "../../../../../../constants/RoutesConstants.js"
import { getUrlParsingPageFilter } from "../../../../../../config/EndpointCollection.js"

export const TableList = ({ data, general, changePage, history, activeFilter }) => {
  const { t } = useTranslation()

  const { General } = useSelector((state) => state)
  const uiCode = (e) => {
    return (
      <>
        <p className="text-dark font-weight-bolder mb-1 font-size-lg">{e?.code ?? "-"}</p>
      </>
    )
  }
  const uiName = (e) => {
    return (
      <>
        <p className="text-dark font-weight-bolder mb-1 font-size-lg">{e?.name ?? "-"}</p>
      </>
    )
  }

  const uiTypeName = (e) => {
    return (
      <>
        <p className="text-dark font-weight-bolder mb-1 font-size-lg">{e?.plant_type ?? "-"}</p>
      </>
    )
  }

  const uiAction = (e) => {
    return (
      <>
        <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Detail</Tooltip>}>
          <a
            onClick={() => {
              history.push(RoutesConstants.MASTER_DATA.PACKAGE.DETAIL(e.id))
            }}
            className="btn btn-icon btn-hover-primary btn-sm mx-3">
            <DetailSvgIcon></DetailSvgIcon>
          </a>
        </OverlayTrigger>
      </>
    )
  }

  function uiRegional(e) {
    return (
      <div>
        <div className={"text-dark font-weight-bolder mb-1 font-size-lg"}>
          {e?.regional_name || "-"}
        </div>
        <div>{e?.regional_code || "-"}</div>
      </div>
    )
  }

  const uiStatus = (e) => {
    const status = e?.status
    if (status === "Active") {
      return <Badge variant="primary">{status}</Badge>
    } else {
      return (
        <Badge style={{ background: "#808080", color: "white" }} variant="secondary">
          {status}
        </Badge>
      )
    }
  }

  function uiTreatment(e) {
    return <div style={{ textAlign: "start" }}>{e?.treatment_total || "0"}</div>
  }

  const columns = [
    {
      dataField: "code",
      text: "ID PAKET",
      sort: true,
      sortCaret: sortCaret,
      formatter: formatterRowComponent,
      onSort: (field, order) => {
        let param = getUrlParsingPageFilter({
          ...initialFilterTable,
          sort: field + "," + order
        })
        history.push({ pathname: location.pathname, search: param })
      },
      formatExtraData: {
        externalStyle: uiCode
      }
    },
    {
      dataField: "name",
      text: "NAMA PAKET",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiName
      },
      headerStyle: () => {
        return { width: "15%" }
      }
    },
    {
      dataField: "plantType.name",
      text: "TIPE TANAMAN",
      sort: true,
      onSort: (field, order) => {
        let param = getUrlParsingPageFilter({
          ...initialFilterTable,
          sort: field + "," + order
        })
        history.push({ pathname: location.pathname, search: param })
      },
      sortCaret: sortCaret,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiTypeName
      }
    },
    {
      dataField: "treatment_total",
      text: "JUMLAH TREATMENT",
      sort: false,
      align: "center",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiTreatment
      }
    },
    {
      dataField: "regional.name",
      text: t("label.regional"),
      sort: true,
      onSort: (field, order) => {
        let param = getUrlParsingPageFilter({
          ...initialFilterTable,
          sort: field + "," + order
        })
        history.push({ pathname: location.pathname, search: param })
      },
      sortCaret: sortCaret,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiRegional
      }
    },
    {
      dataField: "status",
      text: t("label.status"),
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiStatus
      }
    },

    {
      dataField: "action",
      text: "ACTION",
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiAction
      }
    }
  ]

  return (
    <Card className="card-shadowless">
      <CardBody>
        {General?.loading ? (
          <TableSkeleton column={5} row={10} />
        ) : data?.pageData?.length === 0 ? (
          <EmptyCard
            subtitle={activeFilter ? t("messages.response.empty_search_subtitle") : ""}
            image={
              activeFilter
                ? ILLUSTRATION_ASSETS_V2.IL_EMPTY_DESKTOP
                : ILLUSTRATION_ASSETS_V2.IL_NOT_FOUND_DESKTOP
            }
            body={
              activeFilter
                ? t("messages.response.searching_not_found")
                : t("messages.response.empty_state", { name: "paket" })
            }
          />
        ) : (
          <></>
        )}
        <TableView
          callbackAfterPageChange={changePage}
          columnProperties={columns}
          dataTable={data?.pageData || []}
          currentPage={data?.page?.currentPage || 0}
          currentElement={data?.page?.currentElement || 20}
          totalCount={data?.page?.totalCount || 0}
          loadingGetData={general?.loading}
          ignoreSinglePage
        />
      </CardBody>
    </Card>
  )
}