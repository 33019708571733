import React from "react"
import { CloseSvgIcon } from "../Icons/SVG/close"

const mapStyle = {
  position: "absolute",
  top: "200px",
  right: "50px",
  zIndex: "9",
  background: "#353535",
  borderRadius: "10px",
  padding: "24px 8px 8px 8px",
  cursor: "pointer"
}
const style = data => {
  return {
    backgroundColor: "#" + data.color,
    width: "40px",
    height: "28px",
    border: "1px solid white"
  }
}
const mapInfoHeader = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderBottom: "1px solid white",
  paddingBottom: "16px",
  marginBottom: "16px"
}
const mapInfoHeaderTitle = {
  fontWeight: "600",
  fontSize: "14px",
  color: "white",
  marginBottom: "0"
}

export const MapInfo = ({ data, title, handleClick, show }) => {
  return (
    <div style={mapStyle} onClick={handleClick}>
      {show && (
        <div style={mapInfoHeader}>
          <h4 style={mapInfoHeaderTitle}>{title}</h4>
          <CloseSvgIcon />
        </div>
      )}
      {data.map((item, i) => (
        <div className={"d-flex align-items-center"} key={i}>
          <div className={"map-info-item mb-4"} style={style(item)}></div>
          {show && <p className={"mx-4 color-white"}>{item.description}</p>}
        </div>
      ))}
    </div>
  )
}
