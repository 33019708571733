import React from "react"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers"
import SVG from "react-inlinesvg"
import { ICON_CONSTANS } from "../../../../constants/ImageConstants.js"

export const Arrow = ({ direktion }) => {
  return (
    <>
      {direktion === "up" ? (
        <SVG src={toAbsoluteUrl(ICON_CONSTANS.IC_ARROW_UP)} />
      ) : (
        <SVG src={toAbsoluteUrl(ICON_CONSTANS.IC_ARROW_DOWN)} />
      )}
    </>
  )
}