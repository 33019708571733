import React, { useEffect } from "react"
import { AssignTab } from "./tab/AssignTab.jsx"
import { AssignListTab } from "./tab/AssignListTab.jsx"
import { TabsComponent } from "../../../../component/index.jsx"
import { useSubheader } from "../../../../_metronic/layout/index.js"
import { loadUserLogin } from "../../../service/userManagementAction.js"
import { PRIVILEGE_ENUM } from "../../../../constants/PrivilegeConstants.js"

export const AssignMitraWrapper = () => {
  const privileges = loadUserLogin().privilege
  const subHeader = useSubheader()
  useEffect(() => {
    subHeader.setButton(<></>)
  }, [])

  const render = () => {
    const labels = ["ASSIGN"]
    const components = [<AssignTab key={1} />]

    if (privileges.indexOf(PRIVILEGE_ENUM.LIST_FIELD_OFFICER_ASSIGN) > -1) {
      labels.push("LIST ASSIGN")
      components.push(<AssignListTab />)
    }

    return {
      labels,
      components
    }
  }
  return (
    <>
      <TabsComponent labelTab={render().labels} componentTab={render().components} />
    </>
  )
}