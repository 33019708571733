import { createSlice } from "@reduxjs/toolkit"

const initSubmition = {
  pageData: [],
  page: false,
  filterMitra: [],
  filterSubDistrict: [],
  filterVarietas: [],
  listAreaSertification: [],
  listAreaMapSertification: [],
  listArea: [],
  listAreaMap: [],
  summaryData: "",
  data: null,
  initCreateCertification: null,
  listSeedClass: [],
  responseCreateSubmission: null,
  responseCancelSubmission: null,
  detailDownloadSubmission: []
}

export const SubmissionSlice = createSlice({
  name: "submission",
  initialState: initSubmition,
  reducers: {
    getPage: (state, action) => {
      state.data = action.payload
    },
    setPageData: (state, action) => {
      const { pageData, page } = action.payload
      state.pageData = pageData
      state.page = page
    },
    setFilterMitra: (state, action) => {
      state.filterMitra = action.payload
    },
    setFilterSubdistrict: (state, action) => {
      state.filterSubDistrict = action.payload
    },
    setFilterVarietas: (state, action) => {
      state.filterVarietas = action.payload
    },
    setListArea: (state, action) => {
      state.listArea = action.payload
    },
    setListAreaMap: (state, action) => {
      state.listAreaMap = action.payload
    },
    setInitCreateCertification: (state, action) => {
      state.initCreateCertification = action.payload
    },
    setSummaryData: (state, action) => {
      state.summaryData = action.payload
    },
    setData: (state, action) => {
      state.data = action.payload
    },
    setListSeedClass: (state, action) => {
      state.listSeedClass = action.payload
    },
    setResponseCreateSubmission: (state, action) => {
      state.responseCreateSubmission = action.payload
    },
    setResponseCancelSubmission: (state, action) => {
      state.responseCancelSubmission = action.payload
    },
    setDetailDownloadSubmission: (state, action) => {
      state.detailDownloadSubmission = action.payload
    }
  }
})
