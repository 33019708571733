import React from "react"
import { Skeleton } from "@material-ui/lab"
import PropTypes from "prop-types"

export const TableSkeleton = ({ column, row }) => {
  return (
    <>
      <table className={"table table-head-custom table-vertical-center overflow-hidden"}>
        <thead>
          <tr>
            {Array.from({ length: column }).map((item, index) => {
              return (
                <th key={index}>
                  <Skeleton animation="wave" variant={"text"} height={24} />
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: row }).map((item, index) => {
            return (
              <tr key={index}>
                {Array.from({ length: column }).map((item, index) => {
                  return (
                    <td key={index}>
                      <Skeleton animation="wave" variant={"text"} />
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      <Skeleton variant={"text"} />
    </>
  )
}

TableSkeleton.prototype = {
  column: PropTypes.number.isRequired,
  row: PropTypes.number.isRequired
}
