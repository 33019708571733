import { UrlParam } from "../reducers/UrlParam"

const { actions: urlParam } = UrlParam
export const SetFilterAction = value => {
  return async dispatch => {
    dispatch(
      urlParam.setFilter({
        data: value ? value : null
      })
    )
  }
}

export const setDataTabMultiple = value => {
  return async dispatch => {
    dispatch(urlParam.setDataMultiple({ dataMultipleTab: value }))
  }
}

export const setSearchAction = value => {
  return async dispatch => {
    dispatch(
      urlParam.setSearch({
        search: value ? value : null
      })
    )
  }
}

export const setDataValueAction = value => {
  return async dispatch => {
    dispatch(
      urlParam.setDataValue({
        dataValue: value ? value : null
      })
    )
  }
}
export const resetUrlParam = () => {
  return async dispatch => {
    dispatch(
      urlParam.setResetValue({
        dataValue: null,
        pageString: null,
        data: null,
        search: null
      })
    )
  }
}

export const resetFilter = () => {
  return async dispatch => {
    dispatch(urlParam.setResetFilter({}))
  }
}

export const setPageStringActions = value => {
  return async dispatch => {
    dispatch(
      urlParam.setPageString({
        pageString: value
      })
    )
  }
}
