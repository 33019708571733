import { Field } from "formik"
import { Input, InputRadio, Textarea } from "../../../_metronic/_partials/controls"
import React, { Fragment } from "react"

export const TextInput = ({
  name,
  onKeyDown,
  placeholder,
  label,
  editable,
  value,
  onChange,
  onChangeValue = (e) => e,
  type = "text",
  withoutFeedback,
  allowEmpty = false,
  withTogglePass = false,
  counter,
  counterValue,
  required,
  onKeyUp = (e) => e,
  className,
  unit,
  ...props
}) => {
  return (
    <div className={"position-relative"}>
      <Field
        onKeyDown={onKeyDown}
        name={name}
        component={Input}
        placeholder={placeholder}
        label={label}
        editable={editable}
        value={value}
        type={type}
        onChange={onChange}
        withoutFeedback={withoutFeedback}
        allowEmpty={allowEmpty}
        withTogglePass={withTogglePass}
        onChangeValue={onChangeValue}
        counter={counter}
        countervalue={counterValue}
        required={required}
        className={className}
        onKeyUp={onKeyUp}
        unit={unit}
        {...props}
      />
    </div>
  )
}
export const TextAreaInput = ({
  name,
  placeholder,
  label,
  editable,
  value,
  type = "text",
  required,
  onChange = (e) => e,
  onKeyUp = (e) => e,
  ...props
}) => {
  return (
    <>
      <Field
        name={name}
        component={Textarea}
        placeholder={placeholder}
        label={label}
        editable={editable}
        value={value ?? ""}
        type={type}
        required={required}
        onKeyUp={onKeyUp}
        onChange={onChange}
        {...props}
      />
    </>
  )
}
export const TextInputRadio = ({
  name,
  placeholder,
  label,
  editable,
  value,
  type = "radio",
  withoutFeedback,
  required,
  onClick = (e) => e,
  ...props
}) => {
  return (
    <>
      <Field
        name={name}
        component={InputRadio}
        placeholder={placeholder}
        label={label}
        editable={editable}
        value={value ?? ""}
        type={type}
        withoutFeedback={withoutFeedback}
        required={required}
        onClick={onClick}
        {...props}
      />
    </>
  )
}
