import { Card, Row } from "react-bootstrap"
import { CardBody } from "../../../_metronic/_partials/controls"
import {
  add,
  addMonths,
  differenceInDays,
  endOfMonth,
  format,
  startOfMonth,
  sub,
  subMonths
} from "date-fns"
import React, { Fragment, useEffect, useState } from "react"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../_metronic/_helpers"
import { ICON_CONSTANS } from "../../../constants/ImageConstants"
import { DateBox } from "./DateBox"
import { Close } from "@material-ui/icons"

const Cell = ({ children, className, onClick }) => {
  return (
    <div onClick={onClick} className={`p-3 border ${className}`}>
      {children}
    </div>
  )
}

export const BigCalendar = ({ onClick, dataCalendar, popupBody, disablePopup, onChange }) => {
  const [currentDate, setCurrentData] = useState(new Date())
  const [nextMonthDate, setNextMonthDate] = useState(undefined)
  const [prevMonthDate, setPrevMonthDate] = useState(undefined)
  const [arrNextMonth, setArrNextMonth] = useState([])
  const [arrPrevMonth, setArrPrevMonth] = useState([])
  const [data, setData] = useState([])
  const dayOfWeek = ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"]
  const startDate = startOfMonth(currentDate)
  const endDate = endOfMonth(currentDate)
  const numDays = differenceInDays(endDate, startDate) + 1
  const prefixDay = startDate.getDay()

  useEffect(() => {
    if (dataCalendar?.length) {
      setData(dataCalendar)
    } else {
      setData([])
    }
  }, [dataCalendar])

  const suffixDay = () => {
    let arr = []
    const check = prefixDay + numDays + 1

    for (let i = 1; i < 37 - check; i++) {
      arr.push(i)
    }
    return arr.length
  }

  useEffect(() => {
    const nxt = addMonths(currentDate, 1)
    const pre = subMonths(currentDate, 1)
    const startDateNext = startOfMonth(nxt)
    const endDateNext = endOfMonth(nxt)
    const startDatePrev = startOfMonth(pre)
    const endDatePrev = endOfMonth(pre)
    setNextMonthDate(differenceInDays(endDateNext, startDateNext) + 1)
    setPrevMonthDate(differenceInDays(endDatePrev, startDatePrev) + 1)
    if (onChange) {
      onChange(currentDate)
    }
  }, [currentDate])

  useEffect(() => {
    if (nextMonthDate) {
      let arr = []
      for (let i = 1; i < nextMonthDate + 1; i++) {
        arr.push(i)
      }
      setArrNextMonth(arr)
    }
  }, [nextMonthDate, currentDate])

  useEffect(() => {
    if (prevMonthDate) {
      let arr = []
      let arrReverse = []
      let arrResult = []
      for (let i = 0; i < prevMonthDate + 1; i++) {
        arr.push(i)
      }
      arrReverse = arr.reverse()
      if (arr.length) {
        for (let i = 0; i < prefixDay; i++) {
          arrResult.push(arrReverse[i])
        }
        setArrPrevMonth(arrResult.reverse())
      }
    }
  }, [prevMonthDate, currentDate])

  const prevMonth = () => {
    setCurrentData(sub(currentDate, { months: 1 }))
    setData(undefined)
  }
  const nextMonth = () => {
    setCurrentData(add(currentDate, { months: 1 }))
    setData(undefined)
  }
  const [openPopup, setOpenPopup] = useState(false)
  const [idPopup, setIdPopup] = useState(undefined)

  const clickPopup = (id) => {
    setOpenPopup(true)
    setIdPopup(id + 1)
    if (idPopup === id + 1) {
      setIdPopup(undefined)
      setOpenPopup(false)
    }
    // setIdPopup()
  }

  const HeaderComp = (
    <div style={{ gap: 20 }} className={"d-flex align-items-center"}>
      <h1 className={"font-weight-bolder"}>
        {format(currentDate, "LLLL") + " " + format(currentDate, "yyyy")}
      </h1>
      <div className={"d-flex align-items-center"} style={{ gap: 30 }}>
        <div
          onClick={prevMonth}
          className={"d-flex align-items-center justify-content-center "}
          style={{
            width: 25,
            height: 25,
            cursor: "pointer",
            transform: "rotate(180deg)"
          }}>
          <SVG src={toAbsoluteUrl(ICON_CONSTANS.ANGLE_ARROW)} />
        </div>
        <div
          onClick={nextMonth}
          className={"d-flex align-items-center justify-content-center"}
          style={{ width: 25, height: 25, cursor: "pointer" }}>
          <SVG src={toAbsoluteUrl(ICON_CONSTANS.ANGLE_ARROW)} />
        </div>
      </div>
    </div>
  )

  const popupContainer = (
    <div className={"popup_detail_calendar"} style={{ width: "600px", zIndex: 1000 }}>
      <Card>
        <CardBody>
          <div
            onClick={() => setOpenPopup(false)}
            className={"cursor-pointer w-100 d-flex justify-content-end"}>
            <Close />
          </div>
          {popupBody}
        </CardBody>
      </Card>
    </div>
  )
  return (
    <>
      <Card>
        <CardBody>
          {HeaderComp}

          <div className={"w-100 "}>
            <Row className={"calendar-layout mt-10 px-5"}>
              {dayOfWeek.map((item) => (
                <Fragment key={item}>
                  <Cell className={"text-center font-weight-bolder border-0 text-gray-500"}>
                    {item}
                  </Cell>
                </Fragment>
              ))}
              {Array.from({ length: prefixDay }).map((_, i) => (
                <Fragment key={i}>
                  <DateBox classname={"bg-calendar-box-customs"} value={arrPrevMonth[i]} />
                </Fragment>
              ))}
              {Array.from({ length: numDays }).map((_, index) => {
                const date = index + 1
                const isCurrentDate =
                  date === currentDate.getDate() && currentDate.getMonth() === new Date().getMonth()
                return (
                  <Fragment key={index}>
                    <DateBox
                      popupID={idPopup}
                      clickPopup={() => !disablePopup && clickPopup(index)}
                      popupContainer={!disablePopup ? popupContainer : undefined}
                      onClick={onClick}
                      currentValueDate={currentDate}
                      dataList={data}
                      openPopup={openPopup}
                      currentDate={isCurrentDate}
                      className={"bg-purple-100 border-purple-500"}
                      value={index + 1}
                    />
                  </Fragment>
                )
              })}
              {Array.from({ length: suffixDay() }).map((_, i) => (
                <Fragment key={i}>
                  <DateBox classname={"bg-calendar-box-customs"} key={i} value={arrNextMonth[i]} />
                </Fragment>
              ))}
            </Row>
          </div>
        </CardBody>
      </Card>
    </>
  )
}
