import React from "react"
import { useContext } from "react"
import {
  Accordion as AccordionBs,
  AccordionContext,
  Card,
  useAccordionToggle
} from "react-bootstrap"
import { Arrow } from "../Icons/SVG/arrow.jsx"

export const Accordion = ({ items = [] }) => {
  const AccordionToggle = ({ children, eventKey, callback }) => {
    const currentEventKey = useContext(AccordionContext)

    const onClick = useAccordionToggle(eventKey, () => callback && callback(eventKey))

    const isCurrentEventKey = currentEventKey === eventKey

    return (
      <div
        className="d-flex justify-content-between align-items-center px-5 py-3 cursor-pointer"
        onClick={onClick}>
        {children}
        <Arrow direktion={isCurrentEventKey ? "up" : "down"}></Arrow>
      </div>
    )
  }

  return (
    <AccordionBs defaultActiveKey="0" defaultValue>
      {items.map((e, i) => (
        <Card className="mb-3" key={i}>
          <AccordionToggle eventKey={i.toString()} key={i}>
            {e?.header}
          </AccordionToggle>
          <AccordionBs.Collapse eventKey={i.toString()}>
            <Card.Body>{e?.content}</Card.Body>
          </AccordionBs.Collapse>
        </Card>
      ))}
    </AccordionBs>
  )
}
