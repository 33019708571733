import { useSubheader } from "../../../../../_metronic/layout/index.js"
import { useDispatch, useSelector } from "react-redux"
import React, { useCallback, useEffect, useRef, useState } from "react"
import {
  clearResetPassword,
  getPageData,
  resetPassword
} from "../../../../../redux/actions/AccountAction.jsx"
import TableView, {
  formatterRowComponent,
  initialFilterTable
} from "../../../../../component/atoms/Tabel/index.jsx"
import { sortCaret } from "../../../../../_metronic/_helpers/index.js"
import { Card, CardBody } from "../../../../../_metronic/_partials/controls/index.js"
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap"
import { DetailSvgIcon } from "../../../../../component/atoms/Icons/SVG/index.jsx"
import { Popup, PopupQuestion } from "../../../../../component/atoms/Popup/index.jsx"
import { getPageDataLead } from "../../../../../redux/actions/FarmerAction.jsx"
import { EmptyCard, Search, TableSkeleton } from "../../../../../component/index.jsx"
import { ResetSvgIcon } from "../../../../../component/atoms/Icons/SVG/reset.jsx"
import { loadUserLogin } from "../../../../service/userManagementAction.js"
import { linkWithCtrl } from "../../../../helpers/Utils.js"
import { useTranslation } from "react-i18next"
import { PRIVILEGE_ENUM } from "../../../../../constants/PrivilegeConstants.js"

export const ListAccount = ({ history }) => {
  const privileges = loadUserLogin().privilege
  const { Account, General } = useSelector((state) => state)
  const { t } = useTranslation()
  const subHeader = useSubheader()
  const [showPopupReset, setShowPopupReset] = useState(false)
  const [showPopupSuccessReset, setShowPopupSuccessReset] = useState(false)
  const dispatch = useDispatch()
  const formRef = useRef()
  const [search, setSearch] = useState({
    string_filter: ""
  })
  const [accountIDReset, setAccountIDReset] = useState({
    id: null,
    name: null,
    email: null
  })
  const [showPopupError, setShowPopupError] = useState(false)

  useEffect(() => {
    subHeader.setTitle("User CMS")
    dispatch(getPageData({ pageReq: initialFilterTable }))
    dispatch(clearResetPassword())
  }, [dispatch])

  useEffect(() => {
    if (General.isFinishUpdateOrDelete) {
      if (Account.passwordReset) {
        if (accountIDReset?.name) {
          setShowPopupReset(false)
          setShowPopupSuccessReset(true)
        }
      }
    }
  }, [General.isFinishUpdateOrDelete])

  const changePage = useCallback(
    (param) => {
      let newParam
      if (param.sortField != null) {
        newParam = {
          ...search,
          ...param,
          sort: param.sortField + "," + param.sortOrder
        }
      } else {
        newParam = {
          ...search,
          ...param
        }
      }
      dispatch(getPageData({ pageReq: newParam }))
    },
    [dispatch, search]
  )

  const searchFunc = (values) => {
    let param = {
      ...initialFilterTable,
      string_filter: values.string_filter
    }
    setSearch({
      string_filter: values.string_filter
    })
    dispatch(getPageData({ pageReq: param }))
  }

  const handleResetSearch = () => {
    setSearch({
      string_filter: ""
    })
    let param = {
      ...initialFilterTable,
      string_filter: ""
    }
    dispatch(getPageData({ pageReq: param }))
  }

  const uiRegional = (e) => {
    return (
      <>
        <span className="text-muted  d-block">{e.regional_name ? e.regional_name : "All"}</span>
      </>
    )
  }

  const uiAction = (e) => {
    return (
      <div>
        {privileges.indexOf(PRIVILEGE_ENUM.DETAIL_USER_CMS) > -1 && (
          <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Detail User</Tooltip>}>
            <div
              className="btn btn-icon btn-detail btn-hover-secondary btn-sm mx-3"
              onClick={() => {
                history.push("/account/list/detail/" + e.account_id)
              }}>
              <DetailSvgIcon />
            </div>
          </OverlayTrigger>
        )}
        {privileges.indexOf(PRIVILEGE_ENUM.RESET_USER_PASSWORD) > -1 && (
          <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Reset Password</Tooltip>}>
            <div
              className="btn btn-icon btn-detail btn-hover-secondary btn-sm mx-3"
              onClick={() => {
                setAccountIDReset({
                  id: e?.account_id,
                  name: e?.account_name,
                  email: e?.account_email
                })
                setShowPopupReset(true)
              }}>
              <ResetSvgIcon />
            </div>
          </OverlayTrigger>
        )}
      </div>
    )
  }
  const uiName = (e) => {
    return (
      <>
        <a className="text-dark font-weight-bolder mb-1 font-size-lg">
          {e.account_name ? e.account_name : "-"}
        </a>
        <span className="text-muted font-weight-bold d-block">{e.phone ? e.phone : "-"}</span>
      </>
    )
  }
  const uiRole = (e) => {
    return (
      <>
        <span className="text-dark font-weight-bolder mb-1 font-size-lg">{e.account_role}</span>
      </>
    )
  }
  const uiStatus = (e) => {
    if (e.status === "Active") {
      return <Badge variant="primary">{e.status}</Badge>
    } else if (e.status === "Inactive") {
      return <Badge variant="secondary">{e.status}</Badge>
    }
  }

  const uiDate = (e) => {
    return (
      <>
        <div>
          {e.created_date} - {e.created_time}
        </div>
      </>
    )
  }

  const columns = [
    {
      dataField: "name",
      text: "NAMA",
      formatter: formatterRowComponent,
      sortCaret: sortCaret,
      formatExtraData: {
        externalStyle: uiName
      },
      sort: true,
      onSort: (field, order) => {
        let param = {
          ...initialFilterTable,
          search: search.string_filter,
          sort: field + "," + order
        }
        dispatch(getPageDataLead({ pageReq: param }))
      }
    },
    {
      dataField: "account_email",
      text: "EMAIL",
      sort: false,
      formatter: formatterRowComponent,
      sortCaret: sortCaret
    },
    {
      dataField: "account_regional",
      text: "REGIONAL",
      sort: false,
      formatter: formatterRowComponent,
      sortCaret: sortCaret,
      formatExtraData: {
        externalStyle: uiRegional
      }
    },
    {
      dataField: "role",
      text: "ROLE",
      formatter: formatterRowComponent,
      sortCaret: sortCaret,
      formatExtraData: {
        externalStyle: uiRole
      },
      sort: true,
      onSort: (field, order) => {
        let param = {
          ...initialFilterTable,
          search: search.string_filter,
          sort: field + "," + order
        }
        dispatch(getPageDataLead({ pageReq: param }))
      }
    },
    {
      dataField: "status",
      text: "STATUS",
      sort: false,
      formatter: formatterRowComponent,
      sortCaret: sortCaret,
      formatExtraData: {
        externalStyle: uiStatus
      }
    },
    {
      dataField: "create",
      text: "CREATE DATE",
      sort: false,
      formatter: formatterRowComponent,
      sortCaret: sortCaret,
      formatExtraData: {
        externalStyle: uiDate
      }
    },
    {
      dataField: "action",
      text: "ACTION",
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiAction
      }
    }
  ]

  return (
    <div>
      <Popup
        type={"empty"}
        body={<span>Data kosong</span>}
        centered={true}
        persistent={true}
        show={showPopupError}
        onClick={() => {
          setShowPopupError(false)
        }}
      />
      <PopupQuestion
        title={`Reset Password`}
        body={
          <span>
            Apakah Anda yakin ingin melakukan Reset Password User <br />{" "}
            <b>
              {" "}
              {accountIDReset?.name} ({accountIDReset?.email}){" "}
            </b>{" "}
            ?
          </span>
        }
        centered={true}
        persistent={true}
        show={showPopupReset}
        textOk="RESET"
        onCancel={() => {
          setAccountIDReset({ id: null, name: null })
          setShowPopupReset(false)
        }}
        onOk={() => {
          dispatch(resetPassword({ account_id: accountIDReset?.id }))
        }}
      />
      <Popup
        type={"berhasil"}
        body={
          <span>
            Password Baru dari User <br />
            <b>
              {" "}
              {accountIDReset?.name} ({accountIDReset?.email}){" "}
            </b>{" "}
            :
            <br /> <br />
            {Account?.passwordReset && <h2>{Account?.passwordReset.password}</h2>}
          </span>
        }
        centered={true}
        persistent={true}
        show={showPopupSuccessReset}
        onClick={() => {
          setShowPopupSuccessReset(false)
          setAccountIDReset({ id: null, name: null })
          dispatch(clearResetPassword())
        }}
      />
      <div className="filter-container">
        <div className="d-flex flex-wrap justify-content-between align-items-center mb-10">
          <Search
            placeholder={"Masukkan Pencarian"}
            onSubmitForm={searchFunc}
            initialValues={search}
            name={"string_filter"}
            innerRef={formRef}
            type={"text"}
            trigerReset={handleResetSearch}
          />
          {
            // privileges.indexOf(Privilege.CREATE_USER_CMS) > -1 &&
            // <Button variant="primary" onClick={() => {
            //     history.push("/account/list/new")
            // }}>Tambah user</Button>
          }
        </div>
      </div>
      <Card>
        <CardBody>
          {General.loading ? (
            <TableSkeleton column={7} row={10} />
          ) : Account.pageData.length === 0 ? (
            <EmptyCard body={t("messages.response.empty_state")} />
          ) : (
            ""
          )}

          <TableView
            callbackAfterPageChange={changePage}
            columnProperties={columns}
            dataTable={Account.pageData}
            currentPage={Account.page.currentPage}
            currentElement={Account.page.currentElement}
            totalCount={Account.page.totalCount}
            loadingGetData={General.loading}
            rowClasses={() => {
              return "cursor-pointer"
            }}
            rowEvents={{
              onClick: (e, row) => {
                if (privileges.indexOf(PRIVILEGE_ENUM.DETAIL_USER_CMS) > -1) {
                  if (e.target.cellIndex < 4) {
                    const url = "/account/list/detail/" + row.account_id
                    if (e.ctrlKey) {
                      linkWithCtrl(url)
                    } else {
                      history.push(url)
                    }
                  }
                }
              }
            }}
          />
        </CardBody>
      </Card>
    </div>
  )
}