import { initialFilterTable } from "../../component/atoms/Tabel"
import { createSlice } from "@reduxjs/toolkit"

const initState = {
  pageData: [],
  urlAttachment: null,
  combobox: [],
  data: undefined,
  page: initialFilterTable
}

export const BroadcastSlice = createSlice({
  name: "broadcast",
  initialState: initState,
  reducers: {
    getPage: (state, action) => {
      state.data = action.payload
    },
    setPageData: (state, action) => {
      const { pageData, page } = action.payload
      state.pageData = pageData
      state.page = page
    },
    setUrlAttachment: (state, action) => {
      state.urlAttachment = action.payload.url
    },
    setClear: state => {
      state.pageData = []
      state.urlAttachment = null
      state.combobox = []
      state.data = undefined
      state.page = initialFilterTable
    }
  }
})
