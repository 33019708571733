import SVG from "react-inlinesvg"
import React from "react"

export const AlertInfo = ({ label, icon, variant, className }) => {
  const checkVariant = () => {
    switch (variant) {
      case "gray":
        return "bg-gray"
      case "warning":
        return "bg-warning"
      default:
        return "bg-success"
    }
  }
  return (
    <div
      className={`alert-info w-full d-flex align-items-center py-4 px-6 rounded ${checkVariant()} ${className}`}>
      <SVG className="mr-6" src={icon} />
      <div className={"color-neutral"}>{label}</div>
    </div>
  )
}
