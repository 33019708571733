import React, { Fragment } from "react"
import { Route } from "react-router-dom"
import { Content } from "./Content"

export function ContentRoute({ children, key, location, component, render, ...props }) {
  return (
    <Fragment key={key}>
      <Route {...props} key={location?.pathname}>
        {routeProps => {
          if (typeof children === "function") {
            return <Content>{children(routeProps)}</Content>
          }

          if (!routeProps.match) {
            return null
          }

          if (children) {
            return <Content>{children}</Content>
          }

          if (component) {
            return <Content>{React.createElement(component, routeProps)}</Content>
          }

          if (render) {
            return <Content>{render(routeProps)}</Content>
          }

          return null
        }}
      </Route>
    </Fragment>
  )
}
