import React, { useLayoutEffect, useMemo } from "react"
import objectPath from "object-path"
import { useLocation } from "react-router-dom"
import { BreadCrumbs } from "./components/BreadCrumbs"
import { getBreadcrumbsAndTitle, useSubheader } from "../../_core/MetronicSubheader"
import { useHtmlClassService } from "../../_core/MetronicLayout"

export function SubHeader() {
  const uiService = useHtmlClassService()
  const location = useLocation()
  const subheader = useSubheader()

  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderFixed: objectPath.get(uiService.config, "subheader.fixed"),
      subheaderMobileToggle: objectPath.get(uiService.config, "subheader.mobile-toggle"),
      subheaderCssClasses: uiService.getClasses("subheader", true),
      subheaderContainerCssClasses: uiService.getClasses("subheader_container", true)
    }
  }, [uiService])

  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname)
    const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname)
    const breadcrumbs =
      aside && aside.breadcrumbs.length > 0 ? aside.breadcrumbs : header.breadcrumbs
    subheader.setBreadcrumbs(breadcrumbs)
    subheader.setTitle(aside && aside.title && aside.title.length > 0 ? aside.title : header.title)
    // eslint-disable-next-line
  }, [location.pathname])

  return (
    <div id="kt_subheader" className={`subheader  py-3 py-lg-8 ${layoutProps.subheaderCssClasses}`}>
      <div className={`${layoutProps.subheaderContainerCssClasses}`}>
        <div
          className={"d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"}>
          {/* Info */}
          <div className="d-flex align-items-center flex-wrap mr-1">
            {/* begin::Mobile Toggle */}
            {layoutProps.subheaderMobileToggle && (
              <button
                className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
                id="kt_subheader_mobile_toggle">
                <span />
              </button>
            )}

            <div className="d-flex align-items-baseline mr-5">
              {subheader.breadcrumbs?.length < 1
                ? subheader.title && (
                    <h2 className="subheader-title text-dark font-weight-bolder">
                      {subheader.title}
                    </h2>
                  )
                : subheader.breadcrumbs && <BreadCrumbs items={subheader.breadcrumbs} />}
            </div>
          </div>
          <div className="d-flex align-items-center flex-wrap">{subheader.button}</div>
        </div>
        {subheader.breadcrumbs?.length < 1
          ? null
          : subheader.title && (
              <h2 className="subheader-title text-dark font-weight-bolder mt-8 mr-3">
                {subheader.title}
              </h2>
            )}
      </div>
    </div>
  )
}
