import React from "react"
import { useSelector } from "react-redux"
import { Skeleton } from "@material-ui/lab"

export function SwitchToggle({
  checked,
  onChange = () => {},
  withLabel,
  statusText,
  label,
  withStatusText,
  disabled
}) {
  const { General } = useSelector((state) => state)
  return (
    <>
      {General.loading ? (
        <div>
          <Skeleton animation="wave" variant={"text"} width={100} />
          <Skeleton animation="wave" variant={"text"} width={50} height={42} />
          <Skeleton animation="wave" variant={"text"} width={50} />
        </div>
      ) : (
        <>
          {withLabel && <label>{label}</label>}
          <label className="switch-toggle">
            <input type="checkbox" onChange={onChange} checked={checked} disabled={disabled} />
            <span className="slider-switch-toggle round"></span>
          </label>
          {withStatusText && (
            <label className="text-muted" style={{ fontSize: "12px" }}>
              {statusText}
            </label>
          )}
        </>
      )}
    </>
  )
}
