import React, { useEffect, useState } from "react"
import { Portal } from "react-portal"

const animationStartClass = "dialog-shown"
const animationEndClass = "dialog-hidden"
const initCssClasses = [
  "dialog",
  "dialog-default",
  "dialog-loader",
  "dialog-top-center",
  "dialog-loader-full"
]

export function LoadingDialog({ isLoading, text }) {
  const [cssClasses, setCssClasses] = useState(initCssClasses)

  useEffect(() => {
    setCssClasses(initCssClasses)
    const timeoutId = setTimeout(() => {
      const cssClassForAdding = isLoading ? animationStartClass : animationEndClass
      setCssClasses([...initCssClasses, cssClassForAdding])
    }, 200)
    return () => {
      clearTimeout(timeoutId)
    }
  }, [isLoading])

  return (
    <Portal node={document && document.getElementById("layout-portal")}>
      <div className={cssClasses.join(" ")}>
        <div className="spinner spinner-primary mr-10" />
        {text}
      </div>
    </Portal>
  )
}
