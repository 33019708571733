import React from "react"
import { Badge } from "react-bootstrap"

export const BadgeStatus = ({ status = "ACTIVE", variant, className = "" }) => {
  const statuses = {
    PENDING: {
      variant: "warning"
    },
    REJECTED: {
      variant: "danger"
    },
    ACTIVE: {
      variant: "primary"
    },
    INACTIVE: {
      variant: "secondary"
    },
    VERIFIED: {
      variant: "success"
    }
  }

  return (
    <Badge
      className={className}
      variant={variant ?? statuses[status.toUpperCase()]?.variant ?? "dark"}>
      {status}
    </Badge>
  )
}
