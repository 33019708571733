import React from "react"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../_metronic/_helpers"
import { Card } from "react-bootstrap"
import { bytesToMegaBytes } from "../../../app/helpers/TextHelper"

export const FileList = ({ name, size, type, className, view, onDelete }) => {
  const filtertype = type => {
    if (type === "image/jpeg") {
      return "ic_jpeg"
    } else if (type === "image/png") {
      return "ic_png"
    } else if (type === "image/jpg") {
      return "ic_jpg"
    } else {
      return "ic_pdf"
    }
  }

  const onViewFile = () => {
    window.open(view, "_blank")
  }
  return (
    <>
      <Card
        className={`p-7  shadow-xs d-flex flex-row justify-content-between align-items-center   ${className}`}>
        <div
          onClick={view && onViewFile}
          className={`d-flex align-items-center ${view && "cursor-pointer"}`}
          style={{ gap: 16 }}>
          <SVG src={toAbsoluteUrl(`/media/svg/files/${filtertype(type)}.svg`)} />
          <div className={"h-100 d-flex flex-column justify-content-center align-items-start"}>
            <h4 className={"text-dark"}>{name}</h4>
            <span>{bytesToMegaBytes(size, true)}</span>
          </div>
        </div>
        {onDelete && (
          <div className={"cursor-pointer zindex-5"} onClick={onDelete}>
            <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Delete-solid-red.svg")} />
          </div>
        )}
      </Card>
    </>
  )
}
