export const EndpointRouter = {
  action: {
    approve: "approve",
    newAction: "new",
    edit: "edit",
    detail: "detail",
    list: "list"
  },
  reqName: {
    pending: "pending",
    list: "data"
  }
}
