import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { TabsComponent } from "../../../../../component/index.jsx"
import { DetailVideo } from "./component/DetailVideo.jsx"
import {
  clearDataDetail,
  getDetail,
  removeVideo
} from "../../../../../redux/actions/VideoAction.jsx"
import { useSubheader } from "../../../../../_metronic/layout/index.js"
import { Button } from "react-bootstrap"
import { RowModule } from "../../../../../component/atoms/RowModule/RowModule.jsx"
import { PopupQuestion } from "../../../../../component/atoms/Popup/index.jsx"
import swal from "sweetalert"
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers/index.js"
import { loadUserLogin } from "../../../../service/userManagementAction.js"
import { PRIVILEGE_ENUM } from "../../../../../constants/PrivilegeConstants.js"

export const ListVideoDetail = ({
  history,
  match: {
    params: { id }
  }
}) => {
  const privileges = loadUserLogin().privilege
  const subHeader = useSubheader()
  const dispatch = useDispatch()
  const [showPopupRemove, setShowPopupRemove] = useState(false)
  const { Video, General } = useSelector((state) => state)
  const [detail, setDetail] = useState({})
  useEffect(() => {
    return () => {
      dispatch(clearDataDetail())
    }
  }, [])

  useEffect(() => {
    subHeader.setBreadcrumbs([
      {
        pathname: "/video/list",
        title: "Setting"
      },
      {
        pathname: "/video/list",
        title: "Manage video Tutorial"
      },
      {
        title: detail?.title
      }
    ])
    subHeader.setTitle("video list")
  }, [detail?.title])

  useEffect(() => {
    if (General.isFinishUpdateOrDelete) {
      swal({
        text: "video Tutorial berhasil dihapus",
        icon: toAbsoluteUrl("/media/svg/icons/Detail/Illustration-delete-propose.svg")
      })
      history.push("/video/list")
    }
  }, [General.isFinishUpdateOrDelete])

  useEffect(() => {
    if (id) {
      dispatch(getDetail(id))
    }
  }, [dispatch])

  useEffect(() => {
    if (Video?.data) {
      setDetail(Video?.data)
    }
  }, [Video?.data])

  useEffect(() => {
    subHeader.setButton(
      <RowModule>
        <>
          {privileges.indexOf(PRIVILEGE_ENUM.DELETE_VIDEO) > -1 && (
            <Button variant="danger" onClick={() => setShowPopupRemove(true)}>
              Hapus
            </Button>
          )}
          {privileges.indexOf(PRIVILEGE_ENUM.UPDATE_VIDEO) > -1 && (
            <Button
              variant="outline-primary"
              className="ml-2 btn-primary"
              style={{ backgroundColor: "#fff", color: "green" }}
              onClick={() => history.push("/video/action/edit/" + detail?.id)}>
              Edit
            </Button>
          )}
        </>
      </RowModule>
    )
  }, [detail])

  const getTabContents = () => {
    const labelList = ["DETAIL VIDEO"]

    const componentList = [<DetailVideo key={1} data={detail} />]

    return {
      labels: labelList,
      contents: componentList
    }
  }

  const videoRemove = async (id) => {
    return dispatch(removeVideo(id))
  }

  return (
    <>
      <PopupQuestion
        show={showPopupRemove}
        title="HAPUS VIDEO"
        bodyCustom={
          <span className={"text-center"}>
            Apakah anda yakin akan menghapus Video Tutorial? Data yang terhapus akan hilang.
          </span>
        }
        textOk="Hapus"
        onCancel={() => {
          setShowPopupRemove(false)
        }}
        onOk={() => {
          videoRemove(id)
          setShowPopupRemove(false)
        }}
      />
      <TabsComponent labelTab={getTabContents().labels} componentTab={getTabContents().contents} />
    </>
  )
}