import MockAdapter from "axios-mock-adapter"
import mockAuth from "../../component/template/Auth/__mocks__/mockAuth"
import mockCustomers from "../../component/template/ECommerce/__mocks__/mockCustomer"
import mockProducts from "../../component/template/ECommerce/__mocks__/mockProduct"
import mockRemarks from "../../component/template/ECommerce/__mocks__/mockRemark"
import mockSpecifications from "../../component/template/ECommerce/__mocks__/mockSpecification"

export default function mockAxios(axios) {
  const mock = new MockAdapter(axios, { delayResponse: 300 })

  mockAuth(mock)
  mockCustomers(mock)
  mockProducts(mock)
  mockRemarks(mock)
  mockSpecifications(mock)

  return mock
}