import React, { useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import { connect } from "react-redux"
import { FormattedMessage, injectIntl } from "react-intl"
import * as auth from "../_redux/authRedux.js"
import { login } from "../_redux/authCrud.js"
import {
  FORM_NEED,
  getMaximumErrorString,
  getMinimumErrorString,
  getStringErrorMassage
} from "../../../../app/helpers/StringCollection.js"
import { EyeSvgIcon } from "../../../atoms/Icons/SVG/index.jsx"
import swal from "sweetalert"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers/index.js"
import { ILUSTRATION_CONSTANS } from "../../../../constants/ImageConstants.js"

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  email: "",
  password: ""
}

function Login(props) {
  const { intl } = props
  const [loading, setLoading] = useState(false)

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Format email tidak sesuai.")
      .min(3, getMinimumErrorString(3))
      .max(50, getMaximumErrorString(50))
      .required(getStringErrorMassage("Email ", FORM_NEED.harusDiIsi)),
    password: Yup.string()
      .min(3, getMinimumErrorString(3))
      .max(50, getMaximumErrorString(50))
      .required(getStringErrorMassage("Password ", FORM_NEED.harusDiIsi))
  })

  const enableLoading = () => {
    setLoading(true)
  }

  const disableLoading = () => {
    setLoading(false)
  }

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid"
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid"
    }

    return ""
  }

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading()
      setTimeout(() => {
        login(values.email, values.password)
          .then(({ data }) => {
            disableLoading()
            props.login(data)
          })
          .catch((error) => {
            disableLoading()
            setSubmitting(false)
            const message = error.response.data.data
            swal({
              text: "Login gagal karena " + message,
              icon: toAbsoluteUrl(ILUSTRATION_CONSTANS.FAIL)
            })
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_LOGIN"
              })
            )
          })
      }, 1000)
    }
  })

  const [eyeShow, setEyeShow] = useState(false)

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">Silahkan masukkan email dan password.</p>
      </div>

      {/* end::Head */}

      {/*begin::step*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework">
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )} ${formik.errors.email && "border-danger"}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container pt-2">
              <div className="fv-help-block text-danger">{formik.errors.email}</div>
            </div>
          ) : (
            <div className="fv-plugins-message-container pt-2">
              <div className="fv-help-block">Contoh: email@maxxitani.id</div>
            </div>
          )}
        </div>

        <div className="form-group fv-plugins-icon-container">
          <div className="position-relative">
            <input
              placeholder="Password"
              type={eyeShow ? "text" : "password"}
              className={`form-control form-control-solid h-auto py-5 px-6 pr-16 ${
                formik.errors.password && "border-danger"
              }`}
              name="password"
              {...formik.getFieldProps("password")}
            />

            <div
              style={{
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
                right: 15
              }}
              onClick={() => setEyeShow(!eyeShow)}>
              <EyeSvgIcon slash={eyeShow} />
            </div>
          </div>

          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container pt-2">
              <div className="fv-help-block text-danger">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap justify-content-end align-items-center">
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.errors.email || formik.errors.password ? true : false}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3 w-100 w-md-auto`}>
            <span>Log In</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
      {/*end::step*/}
    </div>
  )
}

export default injectIntl(connect(null, auth.actions)(Login))