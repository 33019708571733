import React, { useCallback, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getPackageList } from "../../../../../redux/actions/MasterDataAction.jsx"
import { useSubheader } from "../../../../../_metronic/layout/index.js"
import { Card } from "../../../../../_metronic/_partials/controls/index.js"
import { RowModule } from "../../../../../component/atoms/RowModule/RowModule.jsx"
import { TableList } from "./table/TableList.jsx"
import { initialFilterTable } from "../../../../../component/atoms/Tabel/index.jsx"
import { Search } from "../../../../../component/atoms/index.jsx"
import { Button } from "react-bootstrap"
import { loadUserLogin } from "../../../../service/userManagementAction.js"
import { useTranslation } from "react-i18next"
import { useUrlParam } from "../../../../hooks/useUrlParams.jsx"
import { getUrlParsingPageFilter } from "../../../../../config/EndpointCollection.js"
import { PRIVILEGE_ENUM } from "../../../../../constants/PrivilegeConstants.js"

export const PackageList = ({ history }) => {
  const { t } = useTranslation()
  const privileges = loadUserLogin().privilege
  const subHeader = useSubheader()
  const dispatch = useDispatch()
  const { General, MasterData } = useSelector((state) => state)
  const formRef = useRef()
  const urlParams = useUrlParam()

  const [queryStringObj, setQueryStringObj] = useState(undefined)

  const [search, setSearch] = useState({
    string_filter: ""
  })
  const [activeFilter, setActiveFilter] = useState(false)

  useEffect(() => {
    subHeader.setButton(btnCreate)
    subHeader.setTitle(t("label.spraying_package"))
  }, [])

  const changePage = useCallback(
    (param) => {
      console.log(param)
      let newParam = {
        ...search,
        ...param
      }
      if (param.sortField) {
        newParam = {
          ...newParam,
          sort: param.sortField + "," + param.sortOrder
        }
      }
      const urlParam = getUrlParsingPageFilter({
        ...newParam,
        keyword: search.keyword
      })
      history.push({ pathname: location.pathname, search: urlParam })
    },
    [dispatch, search]
  )
  useEffect(() => {
    const result = location?.search
    fetchData(result)
  }, [location.search])

  function fetchData(param) {
    dispatch(getPackageList(param))
  }

  useEffect(() => {
    if (queryStringObj?.keyword) {
      setSearch({ keyword: queryStringObj?.keyword })
      setActiveFilter(true)
    }
  }, [queryStringObj, dispatch])

  useEffect(() => {
    setQueryStringObj(urlParams.getUrlParamsObj)
  }, [urlParams.getUrlParamsObj])
  const seacrhFunc = (values) => {
    let param = {
      ...initialFilterTable,
      string_filter: values.string_filter?.toUpperCase()
    }
    setSearch({
      string_filter: values.string_filter
    })
    setActiveFilter(true)
    const urlParam = getUrlParsingPageFilter(param)
    history.push({ pathname: location.pathname, search: urlParam })
  }

  const handleReset = () => {
    setSearch({
      string_filter: ""
    })
    let param = {
      ...initialFilterTable,
      string_filter: ""
    }
    setActiveFilter(false)
    const urlParam = getUrlParsingPageFilter(param)

    history.push({ pathname: location.pathname, search: urlParam })
  }

  const btnCreate = (
    <>
      {privileges.indexOf(PRIVILEGE_ENUM.MODIFIED_PACKAGE) > -1 && (
        <Button
          variant="primary"
          onClick={() => {
            history.push("/master/package/new")
          }}>
          Tambah Paket
        </Button>
      )}
    </>
  )

  return (
    <>
      <div className="filter-container">
        <div className="d-flex flex-wrap justify-content-between align-items-center mb-10">
          <Search
            placeholder={t("placeholder.insert_package_id_or_name")}
            onSubmitForm={seacrhFunc}
            initialValues={search}
            name={"string_filter"}
            innerRef={formRef}
            type={"text"}
            trigerReset={handleReset}
          />
        </div>
      </div>

      <Card>
        <RowModule>
          <TableList
            data={MasterData}
            general={General}
            changePage={changePage}
            history={history}
            activeFilter={activeFilter}
          />
        </RowModule>
      </Card>
    </>
  )
}