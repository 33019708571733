import React from "react"
import { useSelector } from "react-redux"
import { Skeleton } from "@material-ui/lab"

export function Text2Row({ title, description }) {
  const { General } = useSelector((state) => state)
  return (
    <>
      {General.loading ? (
        <div className={"d-flex align-items-center flex-lg-fill mr-5 mb-2"}>
          <div className="d-flex flex-column text-dark-75">
            <Skeleton animation="wave" variant={"text"} width={100} />
            <Skeleton animation="wave" variant={"text"} height={32} />
          </div>
        </div>
      ) : (
        <div className={"d-flex align-items-center flex-lg-fill mr-5 mb-2"}>
          <div className="d-flex flex-column text-dark-75">
            <span className="font-weight-bold text-dark-50 font-size-sm">{title}</span>
            <span className="font-weight-bolder font-size-h5">{description}</span>
          </div>
        </div>
      )}
    </>
  )
}
