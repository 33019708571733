import React, { useEffect, useState } from "react"
import { useHtmlClassService } from "../../../../../_metronic/layout/index.js"
import { Stepper } from "../../../../../component/atoms/Stepper/index.jsx"
import { RoutesConstants } from "../../../../../constants/RoutesConstants.js"
import { MenuBack } from "../../../../../component/atoms/MenuBack/index.jsx"
import { SelectArea } from "./StepForm/SelectArea.jsx"
import { FormulirSertification } from "./StepForm/FormulirSertification.jsx"
import { useFormik } from "formik"
import * as yup from "yup"
import { FORM_NEED, getStringErrorMassage } from "../../../../helpers/StringCollection.js"
import { Review } from "./StepForm/Review.jsx"
import {
  createCertification,
  initCreateCertification
} from "../../../../../redux/actions/SubmissionAction.jsx"
import { useDispatch, useSelector } from "react-redux"
import { PopupQuestion } from "../../../../../component/atoms/Popup/index.jsx"
import { Col } from "react-bootstrap"
import swal from "sweetalert"
import { ILLUSTRATION_ASSETS_V2 } from "../../../../../constants/ImageConstants.js"
import { useHistory } from "react-router-dom"
import { SUBMISSION } from "../../../../../constants/InitTypeConstants.js"
export const CreateSubmissionPage = () => {
  const uiService = useHtmlClassService()
  const dispatch = useDispatch()
  const { Submission, General, MasterData } = useSelector((state) => state)
  const history = useHistory()

  const labelStep = ["Pilih Lahan", "Isi Formulir Sertifikasi", "Review"]
  const [activeStep, setActiveStep] = useState(0)
  const [selectedArea, setSelectedArea] = useState([])
  const [dataInit, setDataInit] = useState()
  const [showPopupExit, setShowPopupExit] = useState(false)

  useEffect(() => {
    uiService.setFullPage()
  }, [])

  useEffect(() => {
    if (selectedArea && activeStep === 1) {
      const body = {
        area_ids: selectedArea
      }
      dispatch(initCreateCertification(body))
    }
  }, [selectedArea, activeStep])

  useEffect(() => {
    if (Submission.initCreateCertification) {
      setDataInit(Submission.initCreateCertification)
    }
  }, [Submission.initCreateCertification])

  const initForm = {
    variety_id: "",
    seed_class: "",
    male_planting_date: "",
    female_planting_date: "",
    female_seed_total: 0,
    female_lot_number: "",
    female_seri_label: "",
    female_label_images: [],
    male_seed_total: 0,
    male_lot_number: "",
    male_seri_label: "",
    male_label_images: [],
    location_image: ""
  }

  const validationForm = yup.object({
    variety_id: yup
      .string()
      .nullable()
      .required(getStringErrorMassage("Varietas", FORM_NEED.harusDiIsi)),
    seed_class: yup
      .string()
      .nullable()
      .required(getStringErrorMassage("Kelas Benih", FORM_NEED.harusDiIsi)),
    source_seed_class: yup
      .string()
      .nullable()
      .required(getStringErrorMassage("Asal Kelas Benih", FORM_NEED.harusDiIsi)),
    male_planting_date: yup
      .string()
      .nullable()
      .required(getStringErrorMassage("Tanggal Tanam Jantan", FORM_NEED.harusDiIsi)),
    female_planting_date: yup
      .string()
      .nullable()
      .required(getStringErrorMassage("Tanggal Tanam Betina", FORM_NEED.harusDiIsi)),
    female_lot_number: yup
      .string()
      .nullable()
      .required(getStringErrorMassage("Nomor Lot Betina", FORM_NEED.harusDiIsi)),
    female_seri_label: yup
      .string()
      .nullable()
      .required(getStringErrorMassage("Nomor Seri Label Betina", FORM_NEED.harusDiIsi)),
    female_label_images: yup
      .array()
      .nullable()
      .required(getStringErrorMassage("Foto Label Betina", FORM_NEED.harusDiIsi)),
    female_seed_total: yup
      .number()
      .integer("Angka harus bilangan bulat")
      .moreThan(0, "Harus lebih dari 0")
      .typeError("Harus angka")
      .required(getStringErrorMassage("Jumlah Benih ", FORM_NEED.harusDiIsi)),
    male_lot_number: yup
      .string()
      .nullable()
      .required(getStringErrorMassage("Nomor Lot Jantan", FORM_NEED.harusDiIsi)),
    male_seri_label: yup
      .string()
      .nullable()
      .required(getStringErrorMassage("Nomor Seri Label Jantan", FORM_NEED.harusDiIsi)),
    male_label_images: yup
      .array()
      .nullable()
      .required(getStringErrorMassage("Foto Label Jantan", FORM_NEED.harusDiIsi)),
    male_seed_total: yup
      .number()
      .integer("Angka harus bilangan bulat")
      .moreThan(0, "Harus lebih dari 0")
      .typeError("Harus angka")
      .required(getStringErrorMassage("Jumlah Benih ", FORM_NEED.harusDiIsi)),
    location_image: yup
      .string()
      .nullable()
      .required(getStringErrorMassage("Foto Lokasi", FORM_NEED.harusDiIsi))
  })

  const formFormik = useFormik({
    initialValues: initForm,
    validationSchema: validationForm,
    onSubmit: (values) => {
      const body = {
        area_ids: selectedArea,
        variety_id: values.variety_id,
        seed_class: values.seed_class,
        source_seed_class: values.source_seed_class,
        male_planting_date: values.male_planting_date,
        female_planting_date: values.female_planting_date,
        female_seed_total: values.female_seed_total,
        female_lot_number: values.female_lot_number,
        female_seri_label: values.female_seri_label,
        female_label_images: values.female_label_images,
        male_seed_total: values.male_seed_total,
        male_lot_number: values.male_lot_number,
        male_seri_label: values.male_seri_label,
        male_label_images: values.male_label_images,
        location_image: values.location_image
      }
      dispatch(createCertification(body))
    }
  })

  useEffect(() => {
    if (
      General?.isFinishUpdateOrDelete &&
      General.actionInitType === SUBMISSION.CREATE_SUBMISSION
    ) {
      swal({
        text: Submission?.responseCreateSubmission?.message,
        icon: ILLUSTRATION_ASSETS_V2.IL_SUCCESS_CREATE_SUBMISSION,
        buttons: {
          detail: {
            text: "Lihat Pengajuan",
            className: "sweet-outline-success"
          },
          okey: { text: "Ok", className: "sweet-success" }
        }
      }).then((value) => {
        if (value === "detail") {
          history.push(
            RoutesConstants.SUBMISSION.DETAIL_BLOCK_CERTIFICATION(
              "submitted",
              Submission?.responseCreateSubmission?.id
            )
          )
        } else {
          history.push(RoutesConstants.SUBMISSION.LIST_SUBMISSION_BY_STATUS("pending"))
        }
      })
    }
  }, [General, MasterData])

  const header = (
    <div
      className={
        "bg-white d-flex justify-content-between align-items-center p-6 border-1 border-bottom border-bottom-light-dark"
      }>
      <MenuBack title={"Buat Pengajuan Sertifikasi"} onClick={() => setShowPopupExit(true)} />
      <Stepper step={labelStep} activeStep={activeStep} setActiveStep={setActiveStep} />
    </div>
  )

  const dataStepbody = [
    <SelectArea
      key={0}
      selectedArea={selectedArea}
      setSelectedArea={setSelectedArea}
      activeStep={activeStep}
      setActiveStep={setActiveStep}
    />,
    <FormulirSertification
      key={1}
      dataInit={dataInit}
      formik={formFormik}
      activeStep={activeStep}
      setActiveStep={setActiveStep}
    />,
    <Review
      formik={formFormik}
      dataInit={dataInit}
      key={2}
      activeStep={activeStep}
      setActiveStep={setActiveStep}
    />
  ]

  const getBodyStep = (step) => {
    return dataStepbody[step]
  }

  const modalConfirmationExit = (
    <PopupQuestion
      title={"KELUAR DARI PEMBUATAN SERTIFIKASI"}
      onCancel={() => setShowPopupExit(false)}
      centered={true}
      variantOkButton={"danger"}
      textOk={"Oke"}
      textCancel={"Kembali"}
      onOk={() => history.push(RoutesConstants.SUBMISSION.LIST_SUBMISSION_BY_STATUS("pending"))}
      show={showPopupExit}
      bodyCustom={
        <Col>
          <p className={"text-center"}>
            Apakah Anda yakin ingin keluar dari pembuatan sertifikasi? Perubahan yang belum disimpan
            akan hilang.
          </p>
        </Col>
      }
    />
  )

  // handle back browser
  useEffect(() => {
    // hanadle back browser
    window.history.pushState(null, document.title, window.location.href)
    window.addEventListener("popstate", function () {
      window.history.pushState(null, document.title, window.location.href)
      setShowPopupExit(true)
    })

    //   handle refresh page
    const handleBeforeUnload = (event) => {
      event.preventDefault()
    }
    window.addEventListener("beforeunload", handleBeforeUnload)
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload)
    }
  })

  return (
    <div>
      {modalConfirmationExit}
      {header}
      {getBodyStep(activeStep)}
    </div>
  )
}