import React, { useState } from "react"
import { Image, Modal } from "react-bootstrap"
import { toAbsoluteUrl } from "../../../_metronic/_helpers/index.js"

export const ImageWithModal = ({
  className,
  value,
  titleModal,
  titleCustom,
  size = "lg",
  imageType //auto or rectangle
}) => {
  const [showPopup, setShowPopup] = useState(false)
  function PopupImageModal() {
    return (
      <Modal
        show={showPopup}
        size={size}
        centered={true}
        onHide={() => setShowPopup(false)}
        contentClassName="modal-image">
        <Modal.Header closeButton={true} onHide={() => setShowPopup(false)}>
          <Modal.Title>
            {titleModal ? <h6 className="mb-0">{titleModal}</h6> : titleCustom}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <img alt={"image"} src={value} width="100%" draggable="false" />
        </Modal.Body>
      </Modal>
    )
  }

  return (
    <div
      className={`${className} ${
        imageType === "rectangle" ? "w-90px h-90px overflow-hidden" : ""
      } d-flex justify-content-center align-items-center rounded`}>
      {showPopup && PopupImageModal()}
      <Image
        src={
          (value && value.length > 0) || value !== null
            ? value
            : toAbsoluteUrl("/media/users/blank.png")
        }
        onClick={() => {
          setShowPopup(true)
        }}
        className={`w-full cursor-pointer ${imageType === "rectangle" ? "h-full" : "h-auto"}`}
      />
    </div>
  )
}
