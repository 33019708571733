import { createSlice } from "@reduxjs/toolkit"

const initState = {
  pageData: [],
  page: false,
  combobox: [],
  data: false,
  detail: {},
  status: [],
  reasonList: null,
  cancelEnumList: null,
  region: [],
  droneTeam: [],
  calendarStatus: {},
  listDataInitFormApproveBulk: null,
  message: undefined,
  listScheduleForm: [],
  summary: {}
}

export const OrderSlice = createSlice({
  name: "order",
  initialState: initState,
  reducers: {
    getPage: (state, action) => {
      state.data = action.payload
    },
    setCombobox: (state, action) => {
      state.combobox = action.payload
    },
    setPageData: (state, action) => {
      const { pageData, page } = action.payload
      state.pageData = pageData
      state.page = page
    },
    setStatus: (state, action) => {
      state.status = action.payload
    },
    setData: (state, action) => {
      state.data = action.payload.data
    },
    setDetail: (state, action) => {
      state.detail = action.payload
    },
    setRegion: (state, action) => {
      state.region = action.payload
    },
    setDroneTeam: (state, action) => {
      state.droneTeam = action.payload
    },
    setReasonList: (state, action) => {
      state.reasonList = action.payload.data
    },
    setCancelEnumList: (state, action) => {
      state.cancelEnumList = action.payload.data
    },
    setCalendarStatus: (state, action) => {
      state.calendarStatus = action.payload
    },
    setListDataInitFormApproveBulk: (state, action) => {
      state.listDataInitFormApproveBulk = action.payload
    },
    setMessage: (state, action) => {
      state.message = action.payload
    },
    setListScheduleForm: (state, action) => {
      state.listScheduleForm = action.payload
    },
    setSummary: (state, action) => {
      state.summary = action.payload
    }
  }
})
