import React, { forwardRef, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import DatePicker from "react-datepicker"
import { Badge } from "react-bootstrap"
import { CalenderSvgIcon } from "../../../../../../../../component/atoms/Icons/SVG/calender.jsx"
import {
  getCalenderStatus,
  getDroneTeam,
  getRegionOrder
} from "../../../../../../../../redux/actions/OrderAction.jsx"
import { timeStampToDate } from "../../../../../../../helpers/DateHelper.js"
import { ORDER_STATUS_ENUM } from "../../../../../../../../constants/StringConstant.js"
import { checkValue, toMeter } from "../../../../../../../helpers/TextHelper.js"
import { useTranslation } from "react-i18next"

export const FormOverviewAccordion = ({ data, listSelected, resultData, submit, isPrePlating }) => {
  const { t } = useTranslation()
  const [teamSelected] = useState("")
  const [selectedDate, setSelectedDate] = useState([])
  const [calendarStatus, setCalendarStatus] = useState([])
  const [selectedMonth, setSelectedMonth] = useState(new Date())
  const [headerDesc, setHeaderDesc] = useState([])

  const dispatch = useDispatch()
  const { Order } = useSelector((state) => state)

  const CustomSelectDatePicker = forwardRef(({ value, onClick }, ref) => (
    <div className={"date-picker-custom-order"}>
      <input
        className="form-control"
        onClick={onClick}
        ref={ref}
        value={value}
        placeholder={"Pilih tanggal penyemprotan"}></input>
      <CalenderSvgIcon />
    </div>
  ))

  useEffect(() => {
    if (teamSelected) {
      let newStartDate = selectedMonth
      newStartDate.setDate(1)
      let newEndDate = new Date(newStartDate)
      newEndDate.setDate(1)
      newEndDate.setMonth(newEndDate.getMonth() + 1)
      dispatch(
        getCalenderStatus(teamSelected, {
          start_date: timeStampToDate(newStartDate, "yyyy-MM-dd"),
          end_date: timeStampToDate(newEndDate, "yyyy-MM-dd")
        })
      )
    }
  }, [selectedDate, teamSelected, selectedMonth])

  useEffect(() => {
    if (Order.calendarStatus.length > 0) {
      setCalendarStatus(Order.calendarStatus.map((item) => timeStampToDate(item, "dd-MM-yyyy")))
    }
  }, [Order.calendarStatus])
  const [newData, setNewData] = useState()
  useEffect(() => {
    if (data.spraying_count) {
      const result = Array.from({ length: data.spraying_count }).map((_, i) => {
        if (selectedDate[i] !== undefined) {
          return {
            seq: i + 1,
            date: timeStampToDate(selectedDate[i], "yyyy-MM-dd")
          }
        }
      })
      const dataObj = {
        order_id: data?.id,
        spraying_date_list: result
      }
      setNewData(dataObj)
    }
  }, [selectedDate, data.spraying_count])

  useEffect(() => {
    if (submit) {
      resultData(newData)
    }
  }, [newData, submit])
  useEffect(() => {
    let newSraying = []
    for (let i = 0; i < 4; i++) {
      newSraying.push(i)
    }
  }, [])

  useEffect(() => {
    if (listSelected) {
      setSelectedDate(listSelected)
    }
  }, [listSelected])

  const datePicker = (index) => {
    const orderData = data.order_list[index]
    if (
      orderData.status === ORDER_STATUS_ENUM.PENDING ||
      orderData.status === ORDER_STATUS_ENUM.RESCHEDULED
    ) {
      return (
        <div className={"mb-8"}>
          {status.toUpperCase() === ORDER_STATUS_ENUM.APPROVED ? (
            <label>
              Tanggal Penyemprotan <span style={{ color: "red" }}>*</span>
            </label>
          ) : (
            <label>
              Tanggal Penyemprotan {data?.spraying_count > 1 ? ` ke-${index + 1} ` : ""}
              {index === 0 ? <span style={{ color: "red" }}>*</span> : ""}
            </label>
          )}
          <DatePicker
            selected={selectedDate[index]}
            onChange={(date) => {
              const newArray = [...selectedDate]
              newArray[index] = date
              setSelectedDate(newArray)
            }}
            monthsShown={1}
            dayClassName={(date) =>
              calendarStatus.indexOf(timeStampToDate(date, "dd-MM-yyyy")) > -1
                ? "haveSchedule"
                : undefined
            }
            onMonthChange={(date) => setSelectedMonth(date)}
            customInput={<CustomSelectDatePicker index={index} />}
            dateFormat="dd MMMM yyyy"
          />
        </div>
      )
    } else {
      return (
        <div>
          <label>
            Tanggal Penyemprotan {data?.spraying_count > 1 ? ` ke  ${index + 1} ` : ""}
            {index === 0 ? <span style={{ color: "red" }}>*</span> : ""}
          </label>
          <div className={"d-flex"} style={{ gap: 10, marginBottom: 10 }}>
            {orderData.status !== ORDER_STATUS_ENUM.CANCELED && (
              <h3>
                {" " + orderData.activity_date
                  ? timeStampToDate(orderData.activity_date, "dd-MM-yyyy")
                  : "-"}{" "}
              </h3>
            )}
            <div>
              <Badge variant="info">
                <div style={{ color: "#fff" }}>{orderData.status}</div>
              </Badge>
            </div>
          </div>
        </div>
      )
    }
  }

  useEffect(() => {
    dispatch(getRegionOrder())
    dispatch(getDroneTeam())
  }, [])

  const dataDescription = [
    {
      label: t("label.area_location"),
      value: checkValue(data?.full_address)
    },
    {
      label: t("label.manual_area_size"),
      value: toMeter(data?.area_land_manual)
    },
    {
      label: t("label.plant_type"),
      value: checkValue(data?.plant_type)
    },
    {
      label: t("label.planting_date"),
      value: data?.planting_date ? timeStampToDate(data?.planting_date, "dd MMMM yyyy") : "-"
    },
    {
      label: t("label.plant_age"),
      value: `${checkValue(data?.hst)} HST`
    }
  ]

  const dataDescPrePlanting = [
    {
      label: "Alasan Pengajuan",
      value: data?.reason ? data?.reason : "-"
    }
  ]

  useEffect(() => {
    if (isPrePlating) {
      setHeaderDesc(dataDescPrePlanting)
    } else {
      setHeaderDesc(dataDescription)
    }
  }, [data])
  return (
    <div className="overview pb-8">
      <h4 className="mb-6">Overview Data Lahan</h4>

      {headerDesc &&
        headerDesc.map((item, i) => (
          <div key={i} className="row mb-3">
            <div className="col-3 color-gray">{item?.label}</div>
            <div className="col-9">
              <div className="d-flex">
                <span className={"mr-2"}>:</span>
                <span>{item.value ?? "-"}</span>
              </div>
            </div>
          </div>
        ))}
      <h4 className={"pt-4 pb-2"}>Penjadwalan Aktivitas Penyemprotan</h4>
      <div className={"mt-10"}>
        {Array.from({ length: data.spraying_count }).map((item, index) => datePicker(index))}
      </div>
    </div>
  )
}