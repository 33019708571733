import React from "react"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../_metronic/_helpers"
import { RowModule } from "../RowModule/RowModule.jsx"

/**
 *
 * @param flatIcon src/_metronic/_assets/plugins/flaticon/flaticon.css
 */
export function Submited({ name, time }) {
  return (
    <div className="col-lg-4">
      <RowModule withoutSeparator={true}>
        <RowModule withoutSeparator={true}>
          <span className="text-dark-50 font-weight-bold">Submited By:</span>
        </RowModule>
        <RowModule withoutSeparator={true}>
          <span className="font-weight-bold">{name}</span>
        </RowModule>
      </RowModule>
      <RowModule withoutSeparator={true}>
        <RowModule withoutSeparator={true}>
          <span className="text-dark-50 font-weight-bold">Submited Time:</span>
        </RowModule>
        <RowModule withoutSeparator={true}>
          <span className="font-weight-bold">{time}</span>
        </RowModule>
      </RowModule>
    </div>
  )
}

/**
 *
 * @param flatIcon  src/_metronic/_assets/plugins/flaticon/flaticon.css
 */
export function FlatIconAndText2Row({ flatIcon, title, description, onClick, svg }) {
  let classNameFlatIcon = "flaticon-pie-chart display-4 text-muted font-weight-bold"
  if (flatIcon) classNameFlatIcon = flatIcon + " display-4 text-muted font-weight-bold"
  let classNameDiv = "d-flex align-items-center flex-lg-fill mr-5 mb-2"
  if (onClick) classNameDiv = "text-hover-primary d-flex align-items-center flex-lg-fill mr-5 mb-2"
  return (
    <div className={classNameDiv}>
      <span className="mr-4">
        {svg ? (
          <SVG className="mr-2 mb-1" src={toAbsoluteUrl(svg)} />
        ) : (
          <i className={classNameFlatIcon} />
        )}
      </span>
      <div className="d-flex flex-column text-dark-75">
        <span className="font-weight-bolder font-size-sm">{title}</span>
        <span className="font-weight-bolder font-size-h5">{description}</span>
      </div>
    </div>
  )
}
