import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { TabLog, TabsComponent } from "../../../../../component/index.jsx"
import { AreaTab } from "../../detail/tab/AreaTab.jsx"
import {
  clearDataDetail,
  getDetailUnassign,
  updateImage,
  updateStatusUnassign
} from "../../../../../redux/actions/AreaAction.jsx"
import { useSubheader } from "../../../../../_metronic/layout/index.js"
import { Button } from "react-bootstrap"
import { FlatIconAndText } from "../../../../../component/atoms/FlaticonAndText/index.jsx"
import { RowModule } from "../../../../../component/atoms/RowModule/RowModule.jsx"
import { PopupQuestion } from "../../../../../component/atoms/Popup/index.jsx"
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers/index.js"
import swal from "sweetalert"
import { ENUM_LOG } from "../../../../../constants/InitTypeConstants.js"
import { ICON_CONSTANS, ILUSTRATION_CONSTANS } from "../../../../../constants/ImageConstants.js"
import { toThousandConvert } from "../../../../helpers/TextHelper.js"
import { useTranslation } from "react-i18next"

export const ListAreaDetailUnassign = ({
  history,
  match: {
    params: { status, id }
  }
}) => {
  const subHeader = useSubheader()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { General, Area, MasterData } = useSelector((state) => state)
  const [detail, setDetail] = useState({})
  const [coordinat, setCoordinat] = useState([])
  const [showPopupReject, setShowPopupReject] = useState(false)
  const [showPopupApprove, setShowPopupApprove] = useState(false)

  useEffect(() => {
    return () => {
      dispatch(clearDataDetail())
    }
  }, [])

  useEffect(() => {
    if (id) {
      dispatch(getDetailUnassign(id))
    }
  }, [dispatch])

  useEffect(() => {
    if (Area?.data) {
      setDetail(Area?.data)
    }
  }, [Area?.data])

  useEffect(() => {
    if (detail?.lat_long_cms) {
      setCoordinat(
        detail.lat_long_cms.map((item) => {
          return {
            lat: item?.lat,
            lng: item?.lng
          }
        })
      )
    }
  }, [detail?.lat_long_cms])

  useEffect(() => {
    subHeader.setTitle("Unassigned Lahan list")
    subHeader.setBreadcrumbs([
      {
        pathname: "/lahan/list/map",
        title: "Lahan"
      },
      {
        pathname: status === "unassign" ? "/lahan/list-unassign/unassign" : "/lahan/list/pending",
        title: status
      },
      {
        title: detail?.area_code
      }
    ])
    switch (status) {
      case "active":
        subHeader.setButton(null)
        break
      case "reject":
        subHeader.setButton(null)
        break

      case "unassign":
        subHeader.setButton(null)
        break
      case "pending":
        subHeader.setButton(
          <RowModule>
            <>
              <Button
                variant="danger"
                onClick={() => {
                  setShowPopupReject(true)
                  setSwalRedirect("/lahan/list/active/" + id)
                  setSwalMessage(t("messages.response.area_unassigned_reject"))
                  setSwallIcon(toAbsoluteUrl(ILUSTRATION_CONSTANS.AREA_REJECT))
                }}>
                Reject
              </Button>
              <Button
                variant="primary"
                className="ml-2"
                onClick={() => {
                  setShowPopupApprove(true)
                  setSwalRedirect("/lahan/list-unassign/unassign/" + id)
                  setSwalMessage(t("messages.response.area_unassigned_approve"))
                  setSwallIcon(toAbsoluteUrl(ILUSTRATION_CONSTANS.AREA_SUCCESS))
                }}>
                Approve
              </Button>
            </>
          </RowModule>
        )
        break
      default:
        break
    }
  }, [detail?.area_code, status])

  const descriptionLeft = (data) => {
    return (
      <>
        <div className="d-flex flex-wrap mb-4">
          <FlatIconAndText
            text={toThousandConvert(data?.area_land_polygon) + " m2 (Polygon)" ?? "-"}
            svg={ICON_CONSTANS.IC_FIELD_2}
          />
          <FlatIconAndText
            text={toThousandConvert(data?.area_land_document) + " m2 (Dokumen)" ?? "-"}
            svg={ICON_CONSTANS.IC_FIELD_2}
          />
          <FlatIconAndText text={data?.farmer_name ?? "-"} svg={ICON_CONSTANS.IC_FARMER} />
        </div>
        <span className="font-weight-bold text-dark-50">{data?.full_address ?? "-"}</span>
      </>
    )
  }

  const flatIconAndText2Rows = (data) => {
    return (
      <>
        <div className="flex-direction-row mr-5">
          <div className="flex-grow-1 justify-content-between">
            <span>Submitted By: </span> <b className="ml-4">{data?.submitted_by}</b>
          </div>
          <div className="flex-grow-1 justify-content-between">
            <span>Submitted Time: </span> <b className="ml-4">{data?.submitted_date}</b>
          </div>
        </div>
        {status === "unassign" && (
          <div className="flex-direction-row">
            <div className="flex-grow-1 justify-content-between">
              <span>Approved By: </span> <b className="ml-4">{data?.approved_by ?? "-"}</b>
            </div>
            <div className="flex-grow-1 justify-content-between">
              <span>Approved Time: </span> <b className="ml-4">{data?.approved_date ?? "-"}</b>
            </div>
          </div>
        )}
      </>
    )
  }

  const getTabContents = () => {
    const labelList = ["DETAIL LAHAN", "LOG"]

    const componentList = [
      <AreaTab
        key={0}
        titleCardDetail={detail?.area_code}
        descriptionLeft={descriptionLeft(detail)}
        descriptionRight={null}
        flatIconAndText2Rows={flatIconAndText2Rows(detail)}
        coordinate={coordinat}
        zoom={16}
        documentLahan={detail?.land_area_document}
        titleImage={ICON_CONSTANS.IC_MAP_PROFILE}
        reasonTitle="Unassign Reason"
        reason={detail?.reason}
        setIdDocument={setIdDocument}
        status={status}
      />,
      <TabLog key={1} historyTabEnum={ENUM_LOG.AREA} id={id} />
    ]

    return {
      labels: labelList,
      contents: componentList
    }
  }
  const [swalMessage, setSwalMessage] = useState("")
  const [swalIcon, setSwallIcon] = useState("")
  const [swalRedirect, setSwalRedirect] = useState("")
  const [idDocument, setIdDocument] = useState(null)
  const areaUpdateStatus = async (status = "") => {
    dispatch(updateStatusUnassign(id, status.toUpperCase()))
  }
  useEffect(() => {
    if (General.isFinishUpdateOrDelete) {
      if (General.actionInitType === "UPDATE_STATUS") {
        swal({
          text: swalMessage,
          icon: toAbsoluteUrl(swalIcon),
          buttons: {
            detail: {
              text: "Lihat Detail",
              className: "sweet-outline-success"
            },
            okey: { text: "Ok", className: "sweet-success" }
          }
        }).then((value) => {
          switch (value) {
            case "detail":
              history.push(swalRedirect)
              break
            default:
              break
          }
        })
        history.push("/lahan/list/pending")
      } else if (General.actionInitType === "UPDATE_STATUS_FARMER") {
        swal({
          text: swalMessage,
          icon: toAbsoluteUrl(swalIcon)
        })
        history.push("/lahan/list/active")
      } else if (General.actionInitType === "UPLOAD") {
        const indexIdDocument = idDocument ?? 0
        const data = {
          area_document_id: detail?.land_area_document[indexIdDocument]?.id,
          url: MasterData.urlResponseUpload
        }

        dispatch(updateImage({ id, data }))
      } else if (General.actionInitType === "UPDATE_IMAGE") {
        dispatch(getDetailUnassign(id))
      }
    }
  }, [General])
  return (
    <>
      <PopupQuestion
        show={showPopupReject}
        title="REJECT FARMER REMOVAL REQUEST"
        bodyCustom={
          <p className="text-center">
            Status lahan akan tetap menjadi <b>Teregistrasi.</b>
          </p>
        }
        textOk="Reject"
        onCancel={() => {
          setShowPopupReject(false)
        }}
        onOk={() => {
          areaUpdateStatus("REJECTED")
          setShowPopupReject(false)
        }}
      />

      {/* Popup for approve */}
      <PopupQuestion
        show={showPopupApprove}
        title="APPROVE FARMER REMOVAL REQUEST"
        bodyCustom={
          <p className="text-center">
            Petani <b> {detail.farmer_code} </b> dihapus dari lahan <b>{detail?.area_code}</b>,
            status lahan akan menjadi unassigned.
          </p>
        }
        textOk="Approve"
        onCancel={() => {
          setShowPopupApprove(false)
        }}
        onOk={() => {
          areaUpdateStatus("APPROVE")
          setShowPopupApprove(false)
        }}
      />
      <TabsComponent labelTab={getTabContents().labels} componentTab={getTabContents().contents} />
    </>
  )
}