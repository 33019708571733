import React from "react"
import { toAbsoluteUrl } from "../../../_metronic/_helpers"
import SVG from "react-inlinesvg"

export const ButtonWithIcon = ({ label, icon, onClick, disabled, className }) => {
  return (
    <div className={`button-with-icon ${disabled}`} onClick={onClick}>
      <SVG src={toAbsoluteUrl(icon)} />
      <span className={`ml-4 ${className}`}>{label}</span>
    </div>
  )
}
