import { useTranslation } from "react-i18next"
import React, { Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useFormikContext } from "formik"
import { useHistory } from "react-router-dom"
import { ORDER } from "../../../../../../../constants/InitTypeConstants.js"
import {
  approveBulkActions,
  approveBulkPrePlanting,
  resetListDataFormApproveBulk,
  SavedGlobalListScheduleOrder
} from "../../../../../../../redux/actions/OrderAction.jsx"
import { RoutesConstants } from "../../../../../../../constants/RoutesConstants.js"
import {
  ORDER_STATUS_PLANTING_ENUM,
  ROUTING_STATUS
} from "../../../../../../../constants/StringConstant.js"
import swal from "sweetalert"
import {
  ICON_CONSTANS,
  ILUSTRATION_CONSTANS
} from "../../../../../../../constants/ImageConstants.js"
import alert from "../../../../../../../component/atoms/Alert/index.jsx"
import { PopupQuestion } from "../../../../../../../component/atoms/Popup/index.jsx"
import SVG from "react-inlinesvg"
import { Accordion } from "../../../../../../../component/atoms/Expands/index.jsx"
import ButtonAction from "../../../../../../../component/atoms/Button/index.jsx"
import { convertM2ToHectare } from "../../../../../../helpers/TextHelper.js"
import { BulkFormAccordionContentPrePlanting } from "./BulkFormAccordionContentPrePlanting.jsx"

export const BulkPreviewPrePlanting = ({ data, listSelectedDate, dataTeamSelected, planting }) => {
  const { t } = useTranslation()
  const [datalist, setDataList] = useState([])
  const [submit, setSubmit] = useState(false)
  const dispatch = useDispatch()
  const [resultValue, setResultValue] = useState()
  const formikContext = useFormikContext()
  const { General } = useSelector((state) => state)
  const history = useHistory()

  useEffect(() => {
    if (General.isFinishUpdateOrDelete && General.actionInitType === ORDER.APPROVE_BULK_ORDER) {
      if (General.isFinishUpdateOrDelete && General.actionInitType === ORDER.APPROVE_BULK_ORDER) {
        dispatch(resetListDataFormApproveBulk())
        history.push(
          RoutesConstants.ORDER.LIST_PRE_PLANTING_BY_STATUS(
            ROUTING_STATUS.MAC_SPRAYING_STRING.PENDING
          )
        )
        swal({
          text: t("messages.response.order_success"),
          icon: ILUSTRATION_CONSTANS.SUCCESS_PLACEHOLDER_ORDER,
          buttons: {
            okey: { text: "Ok", className: "sweet-success" }
          }
        }).then()
      }
    }
  }, [General])

  useEffect(() => {
    if (data) {
      setDataList(data?.order_list)
    }
  }, [data])

  const dataListCardHeader = [
    {
      title: "Jumlah Lahan",
      value: `${data?.order_list ? data?.order_list?.length : "-"} Lahan (${
        data?.total_land_area ? convertM2ToHectare(data?.total_land_area) : "-"
      } Ha)`,
      icon: ICON_CONSTANS.IC_ROUNDED_FIELD
    },
    {
      title: "Tim Drone",
      value: dataTeamSelected?.team ?? "-",
      icon: ICON_CONSTANS.IC_ROUNDED_PLANT_SPRAYING
    }
  ]

  const [dataNewSet] = useState(new Set())

  function checkResultData(e) {
    if (e.spraying_date_list[0] !== undefined) {
      dataNewSet.add(e)
    }
  }

  function onSubmitOrderApproveBulk() {
    alert("masuk sini")
    setSubmit(true)
  }

  useEffect(() => {
    if (submit) {
      const dataList = Array.from(dataNewSet)
      const values = formikContext.values
      const resultData = {
        team_id: values.team_id,
        approval_bulk_list: dataList
      }
      setResultValue(resultData)
    }
  }, [submit])

  useEffect(() => {
    if (resultValue) {
      setShowPopupSubmit(false)
      setSubmit(false)
      if (planting === ORDER_STATUS_PLANTING_ENUM.PASCA_PLANTING) {
        dispatch(SavedGlobalListScheduleOrder([]))
        dispatch(approveBulkActions(resultValue))
      } else {
        dispatch(SavedGlobalListScheduleOrder([]))
        dispatch(approveBulkPrePlanting(resultValue))
      }
    }
  }, [resultValue])

  const [showPopupSubmit, setShowPopupSubmit] = useState(false)

  function BodyPopup() {
    return (
      <div className={"py-4 text-center"}>
        Order yang di approve akan masuk kedalam tanggal penjadwalan. Pastikan semua data yang
        diinput telah benar.
      </div>
    )
  }

  return (
    <>
      <PopupQuestion
        show={showPopupSubmit}
        title={"APPROVE ORDER"}
        textCancel={"Batal"}
        textOk={t("button.next")}
        onOk={onSubmitOrderApproveBulk}
        variantOkButton={"primary"}
        body={BodyPopup()}
        disable={false}
        onCancel={() => setShowPopupSubmit(false)}
      />
      <div className={"px-21"}>
        <div className={"border p-10 mt-10 d-flex align-items-center justify-content-between"}>
          {dataListCardHeader.map((item, i) => (
            <div key={i} className={"d-flex align-items-center  m-auto"} style={{ gap: 8 }}>
              <SVG src={item.icon} />
              <div>
                <div>{item.title}</div>
                <div className={"font-weight-bolder"}>{item.value}</div>
              </div>
            </div>
          ))}
        </div>
        <div className={"mt-10 accordion_bulk_approve_order"}>
          <Accordion
            items={datalist.map((item, index) => ({
              header: (
                <>
                  <h3 className="card-label mb-8">
                    {item?.area_name ?? "-"} ({item?.activity_type ?? "-"})
                  </h3>
                </>
              ),
              content: (
                <Fragment key={index}>
                  <BulkFormAccordionContentPrePlanting
                    isPrePlating={planting === ORDER_STATUS_PLANTING_ENUM.PRE_PLANTING}
                    submit={submit}
                    resultData={checkResultData}
                    listSelected={listSelectedDate}
                    data={item}
                  />
                </Fragment>
              )
            }))}
          />
        </div>
        <div
          style={{
            position: "absolute",
            bottom: 12,
            right: 0
          }}>
          <ButtonAction
            onClick={() => setShowPopupSubmit(true)}
            text="Submit"
            className="btn btn-primary font-weight-bolder px-9 py-4"></ButtonAction>
        </div>
      </div>
    </>
  )
}