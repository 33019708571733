import React, { useCallback, useEffect, useRef, useState } from "react"
import { useSubheader } from "../../../../../_metronic/layout/index.js"
import { useTranslation } from "react-i18next"
import { Card } from "react-bootstrap"
import { CardBody } from "../../../../../_metronic/_partials/controls/index.js"
import { EmptyCard, Search, TableSkeleton, TableView } from "../../../../../component/index.jsx"
import {
  formatterRowComponent,
  initialFilterTable
} from "../../../../../component/atoms/Tabel/index.jsx"
import { sortCaret } from "../../../../../_metronic/_helpers/index.js"
import { toMeter } from "../../../../helpers/TextHelper.js"
import { timeStampToDate } from "../../../../helpers/DateHelper.js"
import { useHistory } from "react-router-dom"
import { getUrlParsingPageFilter } from "../../../../../config/EndpointCollection.js"
import { useUrlParam } from "../../../../hooks/useUrlParams.jsx"
import { useLocation } from "react-router"
import { useDispatch, useSelector } from "react-redux"
import { getListActivityReportMac } from "../../../../../redux/actions/MacAction.jsx"
import { linkWithCtrl } from "../../../../helpers/Utils.js"
import { RoutesConstants } from "../../../../../constants/RoutesConstants.js"
import { loadUserLogin } from "../../../../service/userManagementAction.js"
import { PRIVILEGE_ENUM } from "../../../../../constants/PrivilegeConstants.js"

export function MacActivityReportList() {
  const { t } = useTranslation()
  const { Mac, General } = useSelector((state) => state)
  const privileges = loadUserLogin().privilege

  const subheader = useSubheader()
  const history = useHistory()
  const formRef = useRef()
  const urlParams = useUrlParam()
  const location = useLocation()
  const dispatch = useDispatch()

  const [dataListActivityReport, setDataListActivityReport] = useState(undefined)
  const [searchValue, setSearchValue] = useState("")
  const [isActiveSearch, setIsActiveSearch] = useState(false)
  const [queryStringObj, setQueryStringObj] = useState(undefined)

  const columns = [
    {
      dataField: "externalSchedule.code",
      text: t("table_header.request_id"),
      sort: true,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiCode
      },
      sortCaret: sortCaret
    },
    {
      dataField: "user",
      text: t("table_header.user_mac"),
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiUserMac
      },
      sortCaret: sortCaret
    },
    {
      dataField: "profile",
      text: t("table_header.profile_mac"),
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiProfileMac
      },
      sortCaret: sortCaret
    },
    {
      dataField: "land_area",
      text: t("table_header.land_area"),
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiLandArea
      },
      sortCaret: sortCaret
    },
    {
      dataField: "plant_type_name",
      text: t("table_header.plant_type"),
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiPlantType
      },
      headerStyle: () => {
        return { width: "20%" }
      }
    },
    {
      dataField: "createdDate",
      text: t("table_header.created_date"),
      sort: true,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiDate
      },
      sortCaret: sortCaret
    }
  ]

  useEffect(() => {
    subheader.setTitle(t("menu_aside.area_activity_report"))
  }, [])

  useEffect(() => {
    const result = location?.search
    setQueryStringObj(urlParams.getUrlParamsObj)
    fetchData(result)
  }, [location.search, urlParams.getUrlParamsObj])

  function handleResetSearch() {
    setSearchValue("")
    setIsActiveSearch(false)
    const param = {
      ...initialFilterTable,
      keyword: ""
    }
    const urlParam = getUrlParsingPageFilter(param)
    history.push({ search: urlParam })
  }

  const changePage = useCallback(
    (param) => {
      let urlParam
      if (param.sortField != null) {
        urlParam = getUrlParsingPageFilter({
          ...param,
          sort: param?.sortField ? param.sortField + "," + param.sortOrder : "",
          keyword: searchValue
        })
      } else {
        urlParam = getUrlParsingPageFilter({
          ...param,
          keyword: searchValue
        })
      }
      history.push({ search: urlParam })
    },
    [dispatch, searchValue]
  )
  useEffect(() => {
    if (queryStringObj?.keyword) {
      setIsActiveSearch(true)
      setSearchValue(queryStringObj?.keyword)
    }
  }, [queryStringObj])

  useEffect(() => {
    if (Mac?.pageData) {
      setDataListActivityReport(Mac)
    }
  }, [Mac])

  function fetchData(param) {
    dispatch(getListActivityReportMac(param))
  }

  function onSearch(values) {
    setSearchValue(values.keyword)
    setIsActiveSearch(true)
    let param = {
      keyword: values.keyword
    }
    const urlParam = getUrlParsingPageFilter(param)
    history.push({ search: urlParam })
  }

  function uiUserMac(e) {
    return (
      <div>
        <div className={"text-dark font-weight-bolder  font-size-lg mb-1"}>
          {e?.user_external_name ? e.user_external_name : "-"}
        </div>
        <div>{e.user_external_code ? e.user_external_code : "-"}</div>
      </div>
    )
  }

  function uiDate(e) {
    return (
      <div className={"text-dark   font-size-lg"}>
        <div>{e.created_date ? timeStampToDate(e.created_date, "dd-MM-yyyy") : "-"}</div>
        <div>{e.created_date ? timeStampToDate(e.created_date, "HH:mm:ss") : "-"}</div>
      </div>
    )
  }

  function uiPlantType(e) {
    return <div>{e?.plant_type_name ? e?.plant_type_name : "-"}</div>
  }
  function uiLandArea(e) {
    return <div>{e?.worked_area ? toMeter(e.worked_area) : "-"}</div>
  }

  function uiProfileMac(e) {
    return (
      <div>
        <div className={"text-dark font-weight-bolder  font-size-lg mb-1"}>
          {e?.external_name ? e.external_name : "-"}
        </div>
        <div>{e.external_code ? e.external_code : "-"}</div>
      </div>
    )
  }

  function uiCode(e) {
    return (
      <div className={"text-dark font-weight-bolder  font-size-lg"}>
        {e?.schedule_code ? e?.schedule_code : "-"}
      </div>
    )
  }

  const onClickTableDetail = (id, e) => {
    const url = RoutesConstants.MAC.DETAIL_ACTIVITY_REPORT(id)
    if (e?.ctrlKey) {
      linkWithCtrl(url)
    } else {
      history.push(url)
    }
  }

  return (
    <div>
      <div className={"filter-container"}>
        <div className="d-flex flex-wrap justify-content-between align-items-center mb-10">
          <Search
            isActiveSearch={isActiveSearch}
            placeholder={t("placeholder.search_activity_report")}
            onSubmitForm={onSearch}
            initialValues={{ keyword: searchValue }}
            name={"keyword"}
            innerRef={formRef}
            trigerReset={handleResetSearch}
          />
        </div>
      </div>

      <Card>
        <CardBody>
          {General.loading ? (
            <TableSkeleton column={6} row={10} />
          ) : dataListActivityReport?.pageData?.length === 0 ? (
            <EmptyCard
              body={
                isActiveSearch
                  ? t("messages.response.data_not_found")
                  : t("messages.response.empty_state")
              }
            />
          ) : (
            ""
          )}

          <TableView
            dataTable={dataListActivityReport?.pageData ?? []}
            currentPage={dataListActivityReport?.page?.currentPage}
            currentElement={dataListActivityReport?.page?.currentElement}
            totalCount={dataListActivityReport?.page?.totalCount}
            loadingGetData={false}
            callbackAfterPageChange={changePage}
            columnProperties={columns}
            rowClasses={() => {
              return "cursor-pointer"
            }}
            rowEvents={{
              onClick: (e, row) => {
                if (
                  privileges.indexOf(PRIVILEGE_ENUM?.DETAIL_ACTIVITY_REPORT_MAC) > -1 &&
                  e.target.cellIndex < 6
                ) {
                  onClickTableDetail(row?.id, e)
                }
              }
            }}
          />
        </CardBody>
      </Card>
    </div>
  )
}