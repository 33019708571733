import React from "react"
import { AREA_ACTIVITY_TYPE } from "../../../constants/InitTypeConstants"

export function ErpNumber({ noOrderErp, noOrderSpk, noOrderSj, noFaktur, status }) {
  return (
    <div className={"mt-2 d-flex"} style={{ gap: 12 }}>
      <div className={""}>
        <span className={"text-black-50"}>No. Order : </span>
        {noOrderErp ? noOrderErp : "-"}
      </div>

      <div className={"border-right w-1"}></div>

      <div>
        <span className={"text-black-50"}>No. SPK : </span>
        {noOrderSpk ? noOrderSpk : "-"}
      </div>
      <div className={"border-right w-1"}></div>
      {(status === AREA_ACTIVITY_TYPE.SPRAYING || status === AREA_ACTIVITY_TYPE.PRE_PLANTING) && (
        <>
          <div>
            <span className={"text-black-50"}>No. SJ : </span>
            {noOrderSj ? noOrderSj : "-"}
          </div>
          <div className={"border-right w-1"}></div>
        </>
      )}
      <div>
        <span className={"text-black-50"}>No. Faktur : </span>
        {noFaktur ? noFaktur : "-"}
      </div>
    </div>
  )
}
