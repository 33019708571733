import { useSubheader } from "../../../../../_metronic/layout/index.js"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import React, { Fragment, useEffect, useState } from "react"
import { Button, Card, Form } from "react-bootstrap"
import { RoutesConstants } from "../../../../../constants/RoutesConstants.js"
import { ORDER_STATUS_ENUM, ROUTING_STATUS } from "../../../../../constants/StringConstant.js"
import {
  ACTIVITY_ICONS,
  ICON_CONSTANS,
  ILUSTRATION_CONSTANS
} from "../../../../../constants/ImageConstants.js"
import { ButtonDropdown, RowText, Status, TableView } from "../../../../../component/index.jsx"

import {
  ApproveRejectOrder,
  CancelOrderActions,
  GetCancelEnumList,
  GetDetailOrderPascaTanam,
  GetRejectReasonList
} from "../../../../../redux/actions/OrderAction.jsx"
import { RowModule } from "../../../../../component/atoms/RowModule/RowModule.jsx"
import { ORDER } from "../../../../../constants/InitTypeConstants.js"
import { clearFinishUpdateOrDelete } from "../../../../../redux/actions/MasterDataAction.jsx"
import { ActivityDetailHeader } from "../../../../../component/molecule/ActivityDetailHeader/ActivityDetailHeader.jsx"
import { timeStampToDate } from "../../../../helpers/DateHelper.js"
import { formatterRowComponent } from "../../../../../component/atoms/Tabel/index.jsx"
import { checkCommaInDataPic, checkValue, toMeter } from "../../../../helpers/TextHelper.js"
import { CardBody, CardHeader } from "../../../../../_metronic/_partials/controls/index.js"
import { Skeleton } from "@material-ui/lab"
import { Popup, PopupQuestion } from "../../../../../component/atoms/Popup/index.jsx"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers/index.js"

export const DetailPascaTanam = ({
  history,
  match: {
    params: { status, id }
  }
}) => {
  const subHeader = useSubheader()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { Order, General } = useSelector((state) => state)
  const [detail, setDetail] = useState({})
  const [dataPopUpPic, setDataPopUpPic] = useState([])
  const [openPopupTableList, setOpenPopupTableList] = useState(false)

  const [showModalSuccess, setShowModalSuccess] = useState(false)
  const [messageModal, setMessageModal] = useState("")
  const [listCancelEnum, setListCancelEnum] = useState([])
  const [listRejectEnum, setListRejectEnum] = useState([])
  const [listCheckBox, setListCheckbox] = useState([])
  const [selected, setSelected] = useState(new Set())
  const [showPopupRejectCancel, setShowPopupRejectCancel] = useState(false)
  const [reasonOtherString, setReasonOtherString] = useState(null)
  const [checkedSelectReason, setCheckedSelectReason] = useState([])
  const [isDisableBtnReject, setIsDisableBtnReject] = useState(true)

  const btnApprove = (
    <Button
      className={"btn btn-primary mx-6"}
      onClick={() =>
        history.push(
          RoutesConstants.ORDER.APPROVAL_PASCA_TANAM(
            ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.PENDING,
            id
          )
        )
      }>
      {t("button.approve")}
    </Button>
  )
  const btnReject = (
    <Button variant="danger" onClick={() => setShowPopupRejectCancel(true)}>
      {t("button.reject")}
    </Button>
  )

  const listDropdown = [
    {
      title: "Edit Aktivitas",
      icon: ICON_CONSTANS.IC_EDIT,
      action: () =>
        history.push(
          RoutesConstants.ORDER.APPROVAL_PASCA_TANAM(
            ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.APPROVED,
            id
          )
        ),
      showed: true
    },
    {
      title: "Batalkan Aktivitas",
      icon: ICON_CONSTANS.IC_SUSPEND,
      showed: true,
      action: () => setShowPopupRejectCancel(true)
    }
  ]

  const listDropdownCancel = [
    {
      title: "Batalkan Aktivitas",
      icon: ICON_CONSTANS.IC_SUSPEND,
      showed: true,
      action: () => setShowPopupRejectCancel(true)
    }
  ]

  const ButtonEditAndCancel = <ButtonDropdown title={"Action"} dropdownList={listDropdown} />
  const ButtonCancel = <ButtonDropdown title={"Action"} dropdownList={listDropdownCancel} />
  const ButtonApproveReschedule = (
    <Button
      className={"btn btn-primary mx-6"}
      onClick={() =>
        history.push(
          RoutesConstants.ORDER.APPROVAL_PASCA_TANAM(
            ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.RESCHEDULED,
            id
          )
        )
      }>
      {t("button.approve")}
    </Button>
  )

  useEffect(() => {
    dispatch(GetDetailOrderPascaTanam(id))
    if (
      status === ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.APPROVED ||
      status === ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.OVERDUE
    ) {
      dispatch(GetCancelEnumList())
    } else if (status === ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.PENDING) {
      dispatch(GetRejectReasonList())
    }
  }, [])

  useEffect(() => {
    if (
      status === ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.APPROVED ||
      status === ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.OVERDUE
    ) {
      setListCheckbox(listCancelEnum)
    } else if (status === ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.PENDING) {
      setListCheckbox(listRejectEnum)
    }
  }, [status, listCancelEnum, listRejectEnum])

  useEffect(() => {
    if (Order?.data) setDetail(Order?.data)
  }, [Order?.data])

  useEffect(() => {
    if (Order?.cancelEnumList) {
      const data = [...Order.cancelEnumList].reverse()
      setListCancelEnum(data)
    }
    if (Order?.reasonList) {
      const data = [...Order.reasonList].reverse()
      if (data) {
        setListRejectEnum(data)
      }
    }
  }, [Order])

  useEffect(() => {
    const data = Array.from(selected)
    const value = data.map((item) => {
      return item?.enum_value
    })
    setCheckedSelectReason(value)
  }, [selected])

  useEffect(() => {
    switch (status) {
      case ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.PENDING:
        subHeader.setButton(
          <RowModule>
            <>
              {btnReject}
              {btnApprove}
            </>
          </RowModule>
        )
        break
      case ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.APPROVED:
        subHeader.setButton(
          detail?.reschedule_detail?.reschedule_date ? ButtonCancel : ButtonEditAndCancel
        )
        break
      case ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.OVERDUE:
        subHeader.setButton(
          detail?.reschedule_detail?.reschedule_date ? ButtonCancel : ButtonEditAndCancel
        )
        break
      case ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.RESCHEDULED:
        subHeader.setButton(ButtonApproveReschedule)
        break
      default:
        subHeader.setButton(null)
    }

    subHeader.setBreadcrumbs([
      {
        pathname: RoutesConstants.ORDER.LIST_PASCA_PLANTING_BY_STATUS(
          ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.PENDING
        ),
        title: t("label.scheduling")
      },
      {
        pathname: RoutesConstants.ORDER.LIST_PASCA_PLANTING_BY_STATUS(
          ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.PENDING
        ),
        title: t("label.pasca_planting")
      },
      {
        pathname: RoutesConstants.ORDER.LIST_PASCA_PLANTING_BY_STATUS(status),
        title: detail?.status_string
      },
      {
        title: checkValue(detail?.area_information_detail?.area_name)
      }
    ])
  }, [status, detail])

  useEffect(() => {
    if (checkedSelectReason.length > 0) {
      if (checkedSelectReason.find((result) => result === "OTHER")) {
        if (reasonOtherString) {
          setIsDisableBtnReject(false)
        } else {
          setIsDisableBtnReject(true)
        }
      } else {
        setIsDisableBtnReject(false)
      }
    } else {
      setIsDisableBtnReject(true)
    }
  }, [checkedSelectReason, reasonOtherString])

  useEffect(() => {
    if (General.isFinishUpdateOrDelete) {
      if (General.actionInitType === ORDER.REJECT_ORDER) {
        setShowModalSuccess(true)
        setMessageModal(t("messages.response.order_rejected"))
        dispatch(clearFinishUpdateOrDelete())
      } else if (General.actionInitType === ORDER.CANCEL_ORDER) {
        setShowModalSuccess(true)
        setMessageModal(t("messages.response.order_cancel"))
        dispatch(clearFinishUpdateOrDelete())
      }
    }
  }, [General.isFinishUpdateOrDelete])

  const bottomContent = (title, value) => (
    <div className={"mt-2 d-flex"} style={{ gap: 12 }}>
      <div className={""}>
        <span className={"text-black-50"}>{title} : </span>
        {value ?? "-"}
      </div>
    </div>
  )

  const titleActivity = (
    <ActivityDetailHeader
      title={`${t("label.activity")} ${detail?.activity_name}`}
      icon={ACTIVITY_ICONS.IC_PENYEMPROTAN}
      rightContent={<Status variant={detail?.status_enum} text={detail?.status_string} />}
      bottomContent={
        detail?.status_enum === ORDER_STATUS_ENUM.PENDING
          ? bottomContent(t("label.submitted_by"), checkValue(detail?.submitted_by))
          : detail?.status_enum === ORDER_STATUS_ENUM.APPROVED ||
            detail?.status_enum === ORDER_STATUS_ENUM.CONFIRMED_FO
          ? bottomContent(t("label.scheduled_by"), detail?.approved_by)
          : detail?.status_enum === ORDER_STATUS_ENUM.REJECTED
          ? bottomContent(t("label.rejected_by"), detail?.rejected_by)
          : detail?.status_enum === ORDER_STATUS_ENUM.CANCELED
          ? bottomContent(t("label.canceled_by"), detail?.canceled_by)
          : ""
      }
    />
  )

  const uiName = (e) => {
    return <div className={"font-weight-bolder"}>{e?.name ?? "-"}</div>
  }

  const uiAnggota = (e) => {
    return (
      <Fragment className={"font-weight-bolder"}>
        {e?.member.map((item, i) => {
          if (i === e?.member.length - 1) return item
          else return item + ", "
        })}
      </Fragment>
    )
  }
  const columnsPic = [
    {
      dataField: "name",
      text: "NAMA TIM",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiName
      },
      headerStyle: () => {
        return { width: "25%" }
      }
    },
    {
      dataField: "member",
      text: "ANGGOTA",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiAnggota
      }
    }
  ]

  const dataPic = () => {
    return (
      <>
        {detail?.activity_detail?.pic && (
          <div>
            {detail?.activity_detail?.pic?.map((item, i) => (
              <>
                {i < 2 && (
                  <Fragment>{`${item?.name} (${item?.member.map((mem, i) => {
                    if (i === item?.member?.length - 1) {
                      if (item?.member?.length === 1) return mem
                      else return " " + mem
                    } else return mem
                  })})${
                    checkCommaInDataPic(i, detail?.activity_detail?.pic?.length) ? "," : ""
                  } `}</Fragment>
                )}
              </>
            ))}
            {detail?.activity_detail?.pic?.length > 2 && (
              <span
                onClick={() => {
                  setOpenPopupTableList(true)
                  setDataPopUpPic(detail?.activity_detail?.pic)
                }}
                className={"text-success font-weight-bolder  underline-hover cursor-pointer"}
                style={{ fontSize: 12, textDecoration: "underline" }}>
                +{detail?.activity_detail?.pic?.length - 2} Lainnya
              </span>
            )}
          </div>
        )}
      </>
    )
  }

  const dataPicRescheduled = () => {
    return (
      <>
        {detail?.reschedule_detail?.pic?.length ? (
          <div>
            {detail?.reschedule_detail?.pic?.map((item, i) => (
              <>
                {i < 2 && (
                  <Fragment>{`${item?.name} (${item?.member.map((mem, i) => {
                    if (i === item?.member?.length - 1) {
                      if (item?.member?.length === 1) return mem
                      else return " " + mem
                    } else return mem
                  })})${
                    checkCommaInDataPic(i, detail?.reschedule_detail?.pic?.length) ? "," : ""
                  } `}</Fragment>
                )}
              </>
            ))}
            {detail?.reschedule_detail?.pic?.length > 2 && (
              <span
                onClick={() => {
                  setOpenPopupTableList(true)
                  setDataPopUpPic(detail?.reschedule_detail?.pic)
                }}
                className={"text-success font-weight-bolder  underline-hover cursor-pointer"}
                style={{ fontSize: 12, textDecoration: "underline" }}>
                +{detail?.reschedule_detail?.pic?.length - 2} Lainnya
              </span>
            )}
          </div>
        ) : (
          "-"
        )}
      </>
    )
  }

  const areaInformation = (
    <Card className={"mb-4"}>
      <CardBody>
        <h4 className={"mb-6 text-uppercase"}>
          {General.loading ? (
            <Skeleton animation="wave" variant={"text"} height={32} width={200} />
          ) : (
            t("label.area_information")
          )}
        </h4>
        <RowModule customColumnCss={"col-md-6"}>
          <RowText
            label={t("label.area_name")}
            value={checkValue(detail?.area_information_detail?.area_name)}
          />
          <RowText
            label={t("label.manual_area_size")}
            value={toMeter(checkValue(detail?.area_information_detail?.area_land_manual))}
          />
          <RowText
            label={t("label.farmer")}
            value={checkValue(detail?.area_information_detail?.farmer_name)}
          />
          <RowText
            label={t("label.polygon_area_size")}
            value={toMeter(checkValue(detail?.area_information_detail?.area_land_polygon))}
          />
          <RowText
            label={t("label.mitra")}
            value={checkValue(detail?.area_information_detail?.mitra_name)}
          />
        </RowModule>
        <RowModule customColumnCss={"col-md-6"}>
          <RowText
            label={t("label.area_location")}
            value={checkValue(detail?.area_information_detail?.full_address)}
          />
        </RowModule>
      </CardBody>
    </Card>
  )

  const plantInformation = (
    <Card className={"mb-4"}>
      <CardBody>
        <h4 className={"mb-6 mt-6 text-uppercase"}>
          {General.loading ? (
            <Skeleton animation="wave" variant={"text"} height={32} width={200} />
          ) : (
            t("label.plant_data")
          )}
        </h4>
        <RowModule customColumnCss={"col-md-6 mb-2"}>
          <RowText
            label={t("label.plant_type")}
            value={checkValue(detail?.plant_detail?.plant_type)}
          />
          <RowText
            label={t("label.planting_date")}
            value={checkValue(timeStampToDate(detail?.plant_detail?.planting_date, "dd MMMM yyyy"))}
          />
          <RowText
            label={t("label.varietas")}
            value={checkValue(detail?.plant_detail?.variety_name)}
          />
          {(status === ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.PENDING ||
            status === ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.APPROVED) && (
            <RowText
              label={t("label.plant_age")}
              value={`${checkValue(detail?.plant_detail?.hst)} HST`}
            />
          )}
        </RowModule>
      </CardBody>
    </Card>
  )

  const submission_data = (
    <Card className={"mb-4"}>
      <CardBody>
        <h4 className={"mb-6 mt-6 text-uppercase"}>
          {General.loading ? (
            <Skeleton animation="wave" variant={"text"} height={32} width={200} />
          ) : (
            t("label.submission_data")
          )}
        </h4>
        <RowModule customColumnCss={"col-md-12 mb-2"}>
          <RowText
            label={t("label.schedule_date")}
            value={checkValue(
              timeStampToDate(detail?.submission_data?.submission_date, "dd MMMM yyyy")
            )}
            secondLabel={checkValue(detail?.submission_data?.hst)}
            secondValue={"HST"}
            secondColor={"text-black-50"}
          />
          <RowText
            label={t("label.scheduled_reason")}
            value={checkValue(detail?.submission_data?.reason)}
          />
          <RowText
            label={t("label.supporting_photo")}
            images={detail?.submission_data?.images ?? "-"}
          />
        </RowModule>
      </CardBody>
    </Card>
  )

  const dataActivity = (
    <Card className={"mb-4"}>
      <CardBody>
        <h4 className={"mb-6 mt-6 text-uppercase"}>
          {General.loading ? (
            <Skeleton animation="wave" variant={"text"} height={32} width={200} />
          ) : (
            t("label.data_activity")
          )}
        </h4>
        <RowModule customColumnCss={"col-md-12 mb-2"}>
          <RowText
            label={
              detail?.reschedule_detail?.reschedule_date
                ? t("label.first_scheduled_date")
                : t("label.scheduled_date")
            }
            value={timeStampToDate(detail?.activity_detail?.schedule_date, "dd MMMM yyyy") ?? "-"}
            secondLabel={checkValue(detail?.activity_detail?.hst)}
            secondValue={"HST"}
            secondColor={"text-black-50"}
          />
          <RowText
            label={
              detail?.reschedule_detail?.reschedule_date ? t("label.first_pic") : t("label.pic")
            }
            value={dataPic()}
          />
          {detail?.activity_detail?.reason && (
            <RowText
              label={t("label.scheduled_reason")}
              value={checkValue(detail?.activity_detail?.reason)}
            />
          )}
          {detail?.activity_detail?.images?.length > 0 && (
            <RowText
              label={t("label.supporting_photo")}
              images={detail?.activity_detail?.images ?? "-"}
            />
          )}
        </RowModule>
      </CardBody>
    </Card>
  )

  function bodyPopupTable() {
    return (
      <div className={"table_list_popup_schedule table_hidden_bottom"}>
        <TableView dataTable={dataPopUpPic} loadingGetData={false} columnProperties={columnsPic} />
      </div>
    )
  }

  const reason = (
    <RowModule>
      <Card className={"reject-list-card"}>
        <CardHeader
          title={
            General.loading ? (
              <Skeleton animation="wave" variant={"text"} height={32} width={200} />
            ) : detail?.status_enum === ORDER_STATUS_ENUM.REJECTED ? (
              t("label.reason_reject")
            ) : (
              t("label.reason_cancelation")
            )
          }
          className="mb-0 pb-2"
        />
        <CardBody>
          {!detail ? null : detail?.reject_cancel_reason?.length === 0 ? (
            <span>Alasan tidak ditemukan.</span>
          ) : (
            <ul>
              {detail?.reject_cancel_reason?.map((e) => (
                <li key={e}>{e}</li>
              ))}
            </ul>
          )}
        </CardBody>
      </Card>
    </RowModule>
  )

  const dataRescheduled = (
    <Card className={"mb-4"}>
      <CardBody>
        <h4 className={"mb-6 mt-6 text-uppercase"}>
          {General.loading ? (
            <Skeleton animation="wave" variant={"text"} height={32} width={200} />
          ) : (
            t("label.data_rescheduled")
          )}
        </h4>
        <RowModule customColumnCss={"col-md-12 mb-2"}>
          <RowText
            label={t("label.scheduled_date")}
            value={
              timeStampToDate(detail?.reschedule_detail?.reschedule_date, "dd MMMM yyyy") ?? "-"
            }
            secondLabel={checkValue(detail?.reschedule_detail?.hst)}
            secondValue={"HST"}
            secondColor={"text-black-50"}
          />
          <RowText label={t("label.pic")} value={dataPicRescheduled()} />
          <RowText
            label={t("label.rescheduled_reason")}
            value={checkValue(detail?.reschedule_detail?.reason)}
          />
          <RowText
            label={t("label.supporting_photo")}
            images={detail?.reschedule_detail?.images ?? "-"}
          />
        </RowModule>
      </CardBody>
    </Card>
  )

  const OrderRejectCancelComponents = (
    <Form>
      {listCheckBox && (
        <>
          {listCheckBox.map((e, i) => (
            <Form.Check
              key={e?.enum_value}
              id={e?.enum_value}
              className={
                (i === 0 ? "mb-2" : i === listCheckBox.length - 1 ? "mt-2" : "my-2") +
                " cursor-pointer"
              }
              type="checkbox"
              label={e?.enum_string}
              checked={
                checkedSelectReason.find((result) => result === e?.enum_value) === e?.enum_value
              }
              onChange={() => onChangeCheckBox(e)}
            />
          ))}
          <div className={"mt-4"}>
            {checkedSelectReason.find((result) => result === "OTHER") && (
              <textarea
                rows={3}
                className="form-control"
                value={reasonOtherString}
                onChange={(e) => {
                  setReasonOtherString(e.target.value)
                }}
                placeholder="Input alasan"
              />
            )}
          </div>
        </>
      )}
    </Form>
  )

  const onChangeCheckBox = (value) => {
    const newSet = new Set(selected)
    if (newSet.has(value)) newSet.delete(value)
    else newSet.add(value)
    setSelected(newSet)
  }

  const onRejectOrder = () => {
    setShowPopupRejectCancel(false)
    if (detail?.status_enum === ORDER_STATUS_ENUM.APPROVED) {
      const data = {
        cancel_enum_list: checkedSelectReason,
        cancel_reason: reasonOtherString ?? null
      }
      dispatch(CancelOrderActions(id, data))
    } else {
      if (!detail?.is_additional) {
        const data = {
          rejection_enum_list: checkedSelectReason,
          rejection_reason: reasonOtherString ?? null
        }
        dispatch(ApproveRejectOrder(id, data, ORDER.REJECT_ORDER))
      } else {
        const data = {
          regional_id: null,
          team_id: null,
          spraying_date_list: null,
          rejection_enum_list: checkedSelectReason,
          rejection_reason: reasonOtherString ?? null
        }
        dispatch(ApproveRejectOrder(id, data, ORDER.REJECT_ORDER))
      }
    }
  }

  return (
    <>
      {/*popup team*/}
      <Popup
        contentClassName={"modal-main modal_detail_team_customs"}
        show={openPopupTableList}
        titleCustom={<h1>Anggota Tim</h1>}
        closeButton={true}
        showButton={false}
        onClick={() => setOpenPopupTableList(false)}
        body={bodyPopupTable()}
        size={"lg"}
        onCancel={() => {
          setOpenPopupTableList(false)
        }}
      />
      {/*popup reject / cancel*/}
      <PopupQuestion
        show={showPopupRejectCancel}
        title={
          status === ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.APPROVED ||
          status === ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.OVERDUE
            ? t("messages.title.cancel_order")
            : t("messages.title.reject_order")
        }
        textCancel={t("button.cancel")}
        textOk={
          status === ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.APPROVED ||
          status === ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.OVERDUE
            ? "Submit"
            : "Reject"
        }
        onOk={onRejectOrder}
        variantOkButton={isDisableBtnReject ? "primary" : "danger"}
        body={OrderRejectCancelComponents}
        disable={isDisableBtnReject}
        onCancel={() => {
          setShowPopupRejectCancel(false)
          setSelected(new Set())
          setCheckedSelectReason([])
          setReasonOtherString(null)
        }}
      />

      {/*popup success after reject or cancel*/}
      <PopupQuestion
        onCancel={() =>
          history.push(
            RoutesConstants.ORDER.DETAIL_PASCA_PLANTING(
              detail?.status_enum === ORDER_STATUS_ENUM.PENDING
                ? ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.REJECT
                : ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.CANCEL,
              id
            )
          )
        }
        textCancel={t("button.see_detail")}
        textOk={t("button.oke")}
        onOk={() =>
          history.push(
            RoutesConstants.ORDER.LIST_PASCA_PLANTING_BY_STATUS(
              detail?.status_enum === ORDER_STATUS_ENUM.PENDING
                ? ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.PENDING
                : status === ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.OVERDUE
                ? ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.OVERDUE
                : ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.APPROVED
            )
          )
        }
        show={showModalSuccess}
        bodyCustom={
          <div className="d-flex flex-column align-items-center">
            <SVG src={toAbsoluteUrl(ILUSTRATION_CONSTANS.SUCCESS_PLACEHOLDER_ORDER)} />
            <p className={"swal-text text-center"}>{messageModal}</p>
          </div>
        }
      />

      {(detail?.status_enum === ORDER_STATUS_ENUM.REJECTED ||
        detail?.status_enum === ORDER_STATUS_ENUM.CANCELED) &&
        reason}

      {titleActivity}
      {areaInformation}
      {plantInformation}
      {(detail?.status_enum === ORDER_STATUS_ENUM.PENDING ||
        detail?.status_enum === ORDER_STATUS_ENUM.REJECTED) &&
        detail?.submission_data?.submission_date &&
        submission_data}
      {(detail?.status_enum === ORDER_STATUS_ENUM.APPROVED ||
        detail?.status_enum === ORDER_STATUS_ENUM.CANCELED ||
        detail?.status_enum === ORDER_STATUS_ENUM.OVERDUE ||
        detail?.status_enum === ORDER_STATUS_ENUM.RESCHEDULED ||
        detail?.status_enum === ORDER_STATUS_ENUM.CONFIRMED_FO) &&
        dataActivity}
      {detail?.reschedule_detail?.reschedule_date && dataRescheduled}
    </>
  )
}