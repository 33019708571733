import React, { Fragment, useEffect, useRef, useState } from "react"
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api"
import { toAbsoluteUrl } from "../../../_metronic/_helpers/index.js"
import { ICON_CONSTANS } from "../../../constants/ImageConstants.js"
import { GoogleMapKey } from "../../../app/helpers/MapHelper.js"

export const PriviewMap = ({ centroid, areas }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GoogleMapKey()
  })
  const mapRef = useRef(null)
  const [center, setCenter] = useState({})
  const [zoom, setZoom] = useState(14)

  const onMapLoad = (map) => {
    mapRef.current = map

    areas.map((item) => {
      const polygon = new google.maps.Polygon({
        paths: item.coordinates,
        strokeColor: "#353535",
        strokeOpacity: 1,
        strokeWeight: 1,
        fillColor: "#CC4435",
        fillOpacity: 0.5
      })

      polygon.setMap(map)
    })
  }

  useEffect(() => {
    if (centroid) {
      setCenter(centroid)
      setZoom(17)
    }
  }, [centroid])

  const renderMap = () => {
    return (
      <Fragment>
        <GoogleMap
          mapContainerStyle={{
            width: "inherit",
            height: "inherit"
          }}
          zoom={zoom}
          center={center}
          onLoad={(map) => onMapLoad(map)}
          mapTypeId={"satellite"}>
          {areas.map((item, index) => {
            return (
              <div key={index}>
                <Marker
                  position={item.centroid}
                  label={{
                    className: "label-map"
                  }}
                  icon={toAbsoluteUrl(ICON_CONSTANS.IC_MARKER_TRANSPARENT)}></Marker>
              </div>
            )
          })}

        </GoogleMap>
      </Fragment>
    )
  }
  return isLoaded ? renderMap() : null
}