import { format } from "date-fns"
import { id } from "date-fns/locale"

export function myGetYear(date) {
  return date.getFullYear()
}

export function getMonth(date) {
  return date.getMonth() | new Date(date).getMonth()
}

export function dateSelect(date) {
  const [year, month, day] = date.split("-")
  return `${day}-${month}-${year}`
}

export function dateSelectReserve(date) {
  const [day, month, year] = date.split("-")
  return `${year}-${month}-${day}`
}

export const handleSubmitDate = value => {
  return value.replace(" ", " - ")
}

export const activityDate = (date1 = null, date2 = null) => {
  if (date1 && date2) {
    return `${timeStampToDate(date1, "dd-MM-yyyy")} s/d ${timeStampToDate(date2, "dd-MM-yyyy")}`
  } else if (date1) {
    return `${timeStampToDate(date1, "dd-MM-yyyy")}`
  } else if (date2) {
    return `${timeStampToDate(date2, "dd-MM-yyyy")}`
  } else {
    return "-"
  }
}

export const activityMonth = (date1 = null, date2 = null) => {
  const formatter = new Intl.DateTimeFormat("id", { month: "long" })
  const formatterYear = new Intl.DateTimeFormat("id", { year: "numeric" })
  const month1 = formatter.format(new Date(date1))
  const month2 = formatter.format(new Date(date2))
  if (date1 && date2) {
    return `${month1} - ${month2} ${formatterYear.format(new Date(date2))}`
  } else if (date1) {
    return `${month1} ${formatterYear.format(new Date(date1))}`
  } else if (date2) {
    return `${month2} ${formatterYear.format(new Date(date2))}`
  } else {
    return "-"
  }
}

export const timeStampToDate = (date, formatTime) => {
  const parsedDate = Date.parse(date)

  if (date && !isNaN(parsedDate)) {
    return format(new Date(date.toString()), formatTime, { locale: id })
  } else {
    return "-"
  }
}

export const toStringDate = time => {
  const date = new Date(time)
  var options = { year: "numeric", month: "long", day: "numeric" }
  const newDate = date.toLocaleDateString("id-ID", options)
  return newDate
}

export const toStringDateLocale = data => {
  const day = new Date(data).toLocaleString("id-ID", { weekday: "long" })
  const month = new Date(data).toLocaleString("id-ID", { month: "long" })
  const date = new Date(data).getDate()
  const year = new Date(data).getFullYear()
  return `${day}, ${date} ${month} ${year}`
}
