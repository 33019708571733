import { AreaList } from "./table/AreaList"
import React from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

export function ApproveAndManualTab({ changePage, isFilter }) {
  const history = useHistory()
  const { Area, General } = useSelector((state) => state)

  return (
    <AreaList
      isFilter={isFilter}
      data={Area}
      general={General}
      changePage={changePage}
      history={history}
    />
  )
}