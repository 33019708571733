import React, { useEffect, useState } from "react"
import Checkbox from "@material-ui/core/Checkbox"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"
import CheckBoxIcon from "@material-ui/icons/CheckBox"

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />
export function CheckboxAutoCompleted({
  placeholder,
  label,
  options = [],
  onChange,
  value,
  disable
}) {
  const [selectedValue, setSelectedValue] = useState(value || [])

  function onChanges(item) {
    setSelectedValue((prevValues) => {
      // Check if an item with the same 'value' is already in the array
      const isItemInArray = prevValues.some((value) => value.value === item.value)

      // If the item is in the array, remove it; otherwise, add it
      if (isItemInArray) {
        const updatedValues = prevValues.filter((value) => value.value !== item.value)
        onChange(updatedValues)
        return updatedValues
      } else {
        const updatedValues = [...prevValues, item]
        onChange(updatedValues)
        return updatedValues
      }
    })
  }

  useEffect(() => {
    setSelectedValue(value)
  }, [value])

  function checkCheckedValue(e) {
    return !!selectedValue.find((v) => v.value === e.value)
  }
  return (
    <div style={{ width: "100%" }}>
      <div className={"mb-2"}>{label}</div>
      <Autocomplete
        multiple
        disabled={disable}
        id="checkboxes-tags-demo"
        options={options}
        value={selectedValue}
        disableCloseOnSelect
        disableClearable={true}
        getOptionLabel={(option) => option.label}
        renderOption={(option) => (
          <div
            style={{ display: "flex", gap: 6, alignItems: "center" }}
            onClick={() => onChanges(option)}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={checkCheckedValue(option)}
            />
            {option.label}
          </div>
        )}
        style={{ width: "100%" }}
        renderTags={(_, getTagProps) =>
          selectedValue.map((option, index) => (
            <span key={index} {...getTagProps({ index })}>
              {option.label},
            </span>
          ))
        }
        renderInput={(params) => (
          <TextField {...params} variant="outlined" placeholder={placeholder} />
        )}
      />
    </div>
  )
}
