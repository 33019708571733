import { GeneralSlice } from "../reducers/General"
import { requestGet, requestPut } from "../../config"
import { MAC_URL } from "../../constants/UrlConstants"
import { MacSlice } from "../reducers/Mac"
import { MAC } from "../../constants/InitTypeConstants"
const { actions: general } = GeneralSlice
const { actions: mac } = MacSlice

function setEmptyPageDataBeforeFetching(dispatch) {
  dispatch(
    mac.setPageData({
      pageData: [],
      page: {
        totalCount: 0,
        currentElement: 0,
        currentPage: 0
      }
    })
  )
}

export const resetDetailMac = () => {
  return async (dispatch) => {
    dispatch(mac.setDetail({}))
  }
}

export function getDetailActivityReportMac(id) {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: MAC.GET_DETAIL_ACTIVITY_REPORT_MAC
      })
    )
    dispatch(mac.setPage(null))
    await requestGet(MAC_URL.GET_DETAIL_ACTIVITY_REPORT_MAC(id))
      .then((res) => {
        dispatch(mac.setPage(res.data))
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: MAC.GET_DETAIL_ACTIVITY_REPORT_MAC
          })
        )
      })
      .catch((e) => {
        dispatch(general.setError(e))
      })
  }
}

export function getListActivityReportMac(param) {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: MAC.GET_ACTIVITY_REPORT_MAC
      })
    )
    setEmptyPageDataBeforeFetching(dispatch)
    await requestGet(MAC_URL.GET_ACTIVITY_REPORT_MAC() + param)
      .then((res) => {
        dispatch(
          mac.setPageData({
            pageData: res.data,
            page: {
              totalCount: res.all_element,
              currentElement: res.element,
              currentPage: res.page
            }
          })
        )
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: MAC.GET_ACTIVITY_REPORT_MAC
          })
        )
      })
      .catch((e) => {
        dispatch(general.setError(e))
      })
  }
}
export function getListSchedulingMac(status, param) {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: MAC.GET_LIST_SCHEDULING_MAC
      })
    )
    setEmptyPageDataBeforeFetching(dispatch)
    await requestGet(MAC_URL.LIST_SCHEDULING_MAC(status) + (param ? param : ""))
      .then((res) => {
        dispatch(
          mac.setPageData({
            pageData: res.data,
            page: {
              totalCount: res.all_element,
              currentElement: res.element,
              currentPage: res.page
            }
          })
        )
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: MAC.GET_LIST_SCHEDULING_MAC
          })
        )
      })
      .catch((e) => {
        dispatch(general.setError(e))
      })
  }
}

export function getSummaryCardSprayingMac() {
  return async (dispatch) => {
    dispatch(
      general.setLoadingComponent({
        loading: true,
        actionInitType: MAC.GET_SUMMARY_CARD_SPRAYING
      })
    )
    await requestGet(MAC_URL.GET_SUMMARY_CARD_SPRAYING())
      .then((res) => {
        dispatch(
          general.setLoadingComponent({
            loading: false,
            actionInitType: MAC.GET_SUMMARY_CARD_SPRAYING
          })
        )
        dispatch(mac.setPage(res.data))
      })
      .catch((e) => {
        dispatch(general.setError(e))
      })
  }
}

export const getListMerchant = (param) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: MAC.GET_LIST_MERCHANT
      })
    )
    setEmptyPageDataBeforeFetching(dispatch)
    try {
      let res = await requestGet(MAC_URL.GET_LIST_MERCHANT() + param)
      dispatch(
        mac.setPageData({
          pageData: res.data,
          page: {
            totalCount: res.all_element,
            currentElement: res.element,
            currentPage: res.page
          }
        })
      )
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: MAC.GET_LIST_MERCHANT
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getDetailProfile = (id) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: MAC.GET_DETAIL_PROFILE
      })
    )
    dispatch(mac.setDetail({}))
    try {
      let res = await requestGet(MAC_URL.GET_DETAIL_MERCHANT(id))
      dispatch(mac.setDetail(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: MAC.GET_DETAIL_PROFILE
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getListUser = (param) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: MAC.GET_LIST_USER
      })
    )
    setEmptyPageDataBeforeFetching(dispatch)
    try {
      let res = await requestGet(MAC_URL.GET_LIST_USER() + param)
      dispatch(
        mac.setPageData({
          pageData: res.data,
          page: {
            totalCount: res.all_element,
            currentElement: res.element,
            currentPage: res.page
          }
        })
      )
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: MAC.GET_LIST_USER
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getDetailUser = (id) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: MAC.GET_DETAIL_USER
      })
    )
    dispatch(mac.setDetail({}))
    try {
      let res = await requestGet(MAC_URL.GET_DETAIL_USER(id))
      dispatch(mac.setDetail(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: MAC.GET_DETAIL_USER
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getDetailSprayingMac = (id) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: MAC.GET_DETAIL_SPRAYING
      })
    )
    dispatch(mac.setDetail({}))
    await requestGet(MAC_URL.GET_DETAIL_SPRAYING(id))
      .then((res) => {
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: MAC.GET_DETAIL_SPRAYING
          })
        )
        dispatch(mac.setDetail(res.data))
      })
      .catch((e) => {
        dispatch(general.setError(e))
      })
  }
}

export const ApproveSpraying = (id, data) => {
  return async (dispatch) => {
    dispatch(general.setLoadingGlobal({ loading: true, actionInitType: MAC.APPROVE_SPRAYING }))
    dispatch(
      general.finishUpdateApi({
        isFinishUpdateOrDelete: false,
        actionInitType: MAC.APPROVE_SPRAYING
      })
    )
    try {
      let res = await requestPut(MAC_URL.APPROVED_SPRAYING(id), null, data)
      dispatch(mac.setMessage(res.data))
      dispatch(
        general.setLoadingGlobal({
          loading: false,
          actionInitType: MAC.APPROVE_SPRAYING
        })
      )
      dispatch(
        general.finishUpdateApi({
          isFinishUpdateOrDelete: true,
          actionInitType: MAC.APPROVE_SPRAYING
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const EditSpraying = (id, data) => {
  return async (dispatch) => {
    dispatch(general.setLoadingGlobal({ loading: true, actionInitType: MAC.EDIT_SPRAYING }))
    await requestPut(MAC_URL.EDIT_SPRAYING(id), "", data)
      .then((res) => {
        dispatch(mac.setMessage(res.data))
        dispatch(
          general.setLoadingGlobal({
            loading: false,
            actionInitType: MAC.EDIT_SPRAYING
          })
        )
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: MAC.EDIT_SPRAYING
          })
        )
      })
      .catch((e) => {
        dispatch(general.setError(e))
      })
  }
}

export const RescheduleSpraying = (id, data) => {
  return async (dispatch) => {
    dispatch(general.setLoadingGlobal({ loading: true, actionInitType: MAC.RESCHEDULE_SPRAYING }))
    await requestPut(MAC_URL.RESCHEDULE_SPRAYING(id), "", data)
      .then(() => {
        dispatch(
          general.setLoadingGlobal({
            loading: false,
            actionInitType: MAC.RESCHEDULE_SPRAYING
          })
        )
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: MAC.RESCHEDULE_SPRAYING
          })
        )
      })
      .catch((e) => {
        dispatch(general.setError(e))
      })
  }
}

export const rejectScheduleSpraying = (id, data) => {
  return async (dispatch) => {
    general.setLoadingGlobal({ loading: true, actionInitType: MAC.REJECT_SPRAYING })
    await requestPut(MAC_URL.REJECT_SPRAYING(id), null, data)
      .then(() => {
        dispatch(
          general.setLoadingGlobal({
            loading: false,
            actionInitType: MAC.REJECT_SPRAYING
          })
        )
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: MAC.REJECT_SPRAYING
          })
        )
      })
      .catch((e) => {
        dispatch(general.setError(e))
      })
  }
}

export const cancelScheduleSpraying = (id, data) => {
  return async (dispatch) => {
    general.setLoadingGlobal({ loading: true, actionInitType: MAC.CANCEL_SPRAYING })
    await requestPut(MAC_URL.CANCEL_SPRAYING(id), null, data)
      .then(() => {
        dispatch(
          general.setLoadingGlobal({
            loading: false,
            actionInitType: MAC.CANCEL_SPRAYING
          })
        )
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: MAC.CANCEL_SPRAYING
          })
        )
      })
      .catch((e) => {
        dispatch(general.setError(e))
      })
  }
}
