export const currentUrl = () => {
  const url = window.location.href
  const urlResult = url.split("/").slice(3)
  const urlId = urlResult.slice(0, 3)
  const result = urlResult.join().replaceAll(",", "-")
  const idResult = urlId.join().replaceAll(",", "-")
  if (urlResult.length > 3) {
    return idResult
  } else {
    return result
  }
}

export const keepStateUrlParamActivity = url => {
  const urlSlice = url.split("-")
  const joinUrl = urlSlice[0] + "_" + urlSlice[1]
  if (joinUrl === "lahan_active") {
    return false
  } else {
    return true
  }
}
