import React from "react"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers"
import SVG from "react-inlinesvg"

export const EyeSvgIcon = ({ slash, className }) => {
  return (
    <div className={className}>
      {slash ? (
        <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/ic_unsee.svg")} />
      ) : (
        <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/ic_see.svg")} />
      )}
    </div>
  )
}
