import { Card } from "react-bootstrap"
import { CardBody } from "../../../_metronic/_partials/controls"
import React from "react"
import { useSelector } from "react-redux"
import { Skeleton } from "@material-ui/lab"

export function ActivityDetailHeader({ icon, title, rightContent, bottomContent, cardBottom }) {
  const { General } = useSelector((state) => state)
  return (
    <Card className={"mb-4"}>
      <CardBody>
        <div>
          <div className={"d-flex justify-content-between align-items-start"}>
            <div className={"d-flex align-items-center"}>
              <div>
                {General.loading ? (
                  <Skeleton animation="wave" variant={"circle"} height={50} width={50} />
                ) : (
                  <img alt={"icon"} width={48} height={48} draggable={false} src={icon} />
                )}
              </div>
              <div className={"ml-5"}>
                <div
                  className={"text-uppercase  font-bold "}
                  style={{ fontSize: 20, fontWeight: 600 }}>
                  {General.loading ? (
                    <Skeleton animation="wave" variant={"text"} height={32} width={100} />
                  ) : (
                    title
                  )}
                </div>
                {General.loading ? (
                  <Skeleton animation="wave" variant={"text"} width={150} />
                ) : (
                  bottomContent && bottomContent
                )}
              </div>
            </div>
            {General.loading ? (
              <Skeleton animation="wave" variant={"text"} height={24} width={200} />
            ) : (
              rightContent && rightContent
            )}
          </div>
          {cardBottom && cardBottom}
        </div>
      </CardBody>
    </Card>
  )
}