import { createSlice } from "@reduxjs/toolkit"

const initState = {
  pageData: [],
  page: false,
  combobox: [],
  data: false,
  detail: {},
  status: [],
  reasonList: null,
  cancelEnumList: null,
  region: [],
  droneTeam: [],
  calendarStatus: {},
  listDataInitFormApproveBulk: undefined,
  message: undefined,
  listScheduleForm: []
}

export const MacSlice = createSlice({
  name: "mac",
  initialState: initState,
  reducers: {
    setPage: (state, action) => {
      state.data = action.payload
    },
    setCombobox: (state, action) => {
      state.combobox = action.payload
    },
    setPageData: (state, action) => {
      const { pageData, page } = action.payload
      state.pageData = pageData
      state.page = page
    },
    setDetail: (state, action) => {
      state.detail = action.payload
    },
    setMessage: (state, action) => {
      state.message = action.payload
    }
  }
})
