import React, { useCallback, useEffect, useRef, useState } from "react"
import { useSubheader } from "../../../../../../../_metronic/layout/index.js"
import { useTranslation } from "react-i18next"
import { sortCaret } from "../../../../../../../_metronic/_helpers/index.js"
import {
  formatterRowComponent,
  initialFilterTable
} from "../../../../../../../component/atoms/Tabel/index.jsx"
import {
  EmptyCard,
  Search,
  TableSkeleton,
  TableView
} from "../../../../../../../component/index.jsx"
import { CardBody } from "../../../../../../../_metronic/_partials/controls/index.js"
import { Button, Card, Form, OverlayTrigger, Tooltip } from "react-bootstrap"
import { timeStampToDate } from "../../../../../../helpers/DateHelper.js"
import { EditSvgIcon } from "../../../../../../../component/atoms/Icons/SVG/index.jsx"
import { loadUserLogin } from "../../../../../../service/userManagementAction.js"
import { getUrlParsingPageFilter } from "../../../../../../../config/EndpointCollection.js"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useUrlParam } from "../../../../../../hooks/useUrlParams.jsx"
import {
  createNewPlantType,
  editPlantType,
  getListPlantTypePageable,
  getPlantTypeUser
} from "../../../../../../../redux/actions/MasterDataAction.jsx"
import { ILUSTRATION_CONSTANS } from "../../../../../../../constants/ImageConstants.js"
import { PopupQuestion } from "../../../../../../../component/atoms/Popup/index.jsx"
import { useFormik } from "formik"
import * as Yup from "yup"
import {
  FORM_NEED,
  getMaximumErrorString,
  getStringErrorMassage
} from "../../../../../../helpers/StringCollection.js"
import swal from "sweetalert"
import { useLocation } from "react-router"
import { MASTER_DATA } from "../../../../../../../constants/InitTypeConstants.js"
import { PRIVILEGE_ENUM } from "../../../../../../../constants/PrivilegeConstants.js"

export function DetailPlantType() {
  const subHeader = useSubheader()
  const privileges = loadUserLogin().privilege
  const history = useHistory()
  const { t } = useTranslation()
  const formRef = useRef()
  const dispatch = useDispatch()
  const urlParams = useUrlParam()
  const { MasterData, General } = useSelector((state) => state)
  const location = useLocation()

  const [searchValue, setSearchValue] = useState("")
  const [isActiveSearch, setIsActiveSearch] = useState(false)
  const [queryStringObj, setQueryStringObj] = useState(undefined)
  const [isShowPoup, setIsShowPoup] = useState(false)
  const [listPlantTypeUser, setListPlantTypeUser] = useState([])
  const [EditId, setEditId] = useState(undefined)
  const [disableButtonSubmit, setDisableButtonSubmit] = useState(true)
  const [dataEdit, setDataEdit] = useState()

  const tableColumns = [
    {
      dataField: "plant_type",
      text: t("table_header.plant_name"),
      sort: false,
      sortCaret: sortCaret,
      formatter: formatterRowComponent
    },
    {
      dataField: "user_type",
      text: t("table_header.user"),
      sort: false,
      sortCaret: sortCaret,
      formatter: formatterRowComponent
    },
    {
      dataField: "createdDate",
      text: t("table_header.created_date"),
      sort: true,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiDate
      },
      sortCaret: sortCaret
    },
    {
      dataField: "action",
      text: t("label.action"),
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiActions
      },
      headerStyle: () => {
        return { width: "12%" }
      }
    }
  ]

  useEffect(() => {
    dispatch(getPlantTypeUser())
  }, [])

  useEffect(() => {
    if (MasterData.plantTypeUser) {
      const data = MasterData.plantTypeUser.map((item) => {
        return {
          label: item.user_type_string,
          value: item.user_type_enum
        }
      })
      setListPlantTypeUser(data)
    }
  }, [MasterData.plantTypeUser])

  useEffect(() => {
    if (queryStringObj?.keyword) {
      setIsActiveSearch(true)
      setSearchValue(queryStringObj?.keyword)
    }
  }, [queryStringObj])

  function buttonSubheader() {
    return (
      <>
        <Button onClick={onClickAddPlantType}>{t("button.add")}</Button>
      </>
    )
  }

  const initialValue = {
    name: "",
    user: ""
  }

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: Yup.object({
      name: Yup.string()
        .max(100, getMaximumErrorString(100))
        .matches("^.*$", "Tidak Boleh di Enter"),
      user: Yup.string()
        .required(getStringErrorMassage("user", FORM_NEED.harusDiIsi))
        .max(100, getMaximumErrorString(100))
        .matches("^.*$", "Tidak Boleh di Enter")
    }),
    onSubmit: (values) => {
      const data = {
        plant_type_name: values.name,
        user_type_enum: values.user
      }
      if (EditId) {
        dispatch(editPlantType(data, EditId))
      } else {
        dispatch(createNewPlantType(data))
      }
    }
  })
  useEffect(() => {
    subHeader.setTitle(t("table_header.planting_type"))
    subHeader.setButton(buttonSubheader())
    subHeader.setBreadcrumbs([
      {
        pathname: "/master/category-item",
        title: t("label.category_item")
      },
      {
        title: t("table_header.planting_type")
      }
    ])
  }, [])

  useEffect(() => {
    if (dataEdit) {
      if (
        dataEdit?.user_type_enum === formik.values.user &&
        dataEdit?.plant_type === formik.values.name
      ) {
        setDisableButtonSubmit(true)
      } else {
        if (formik.values.user && formik.values.name) {
          setDisableButtonSubmit(false)
        } else {
          setDisableButtonSubmit(true)
        }
      }
    } else {
      if (formik.values.user && formik.values.name) {
        setDisableButtonSubmit(false)
      } else {
        setDisableButtonSubmit(true)
      }
    }
  }, [formik.values])

  useEffect(() => {
    if (
      General.isFinishUpdateOrDelete &&
      General.actionInitType === MASTER_DATA.CREATE_PLANT_TYPE
    ) {
      swal("", t("messages.response.success_create_plant_type"), {
        icon: ILUSTRATION_CONSTANS.SUCCESS_PLACEHOLDER_PLANT
      }).then()
      setIsShowPoup(false)
      const result = location?.search
      setQueryStringObj(urlParams.getUrlParamsObj)
      fetchData(result)
    } else if (
      General.isFinishUpdateOrDelete &&
      General.actionInitType === MASTER_DATA.EDIT_PLANT_TYPE
    ) {
      swal("", t("messages.response.success_edit_plant_type"), {
        icon: ILUSTRATION_CONSTANS.SUCCESS_PLACEHOLDER_PLANT
      }).then()
      setIsShowPoup(false)
      const result = location?.search
      setQueryStringObj(urlParams.getUrlParamsObj)
      fetchData(result)
    }
  }, [General.isFinishUpdateOrDelete])

  useEffect(() => {
    const result = location?.search
    setQueryStringObj(urlParams.getUrlParamsObj)
    fetchData(result)
  }, [location.search, urlParams.getUrlParamsObj])

  const changePage = useCallback(
    (param) => {
      let urlParam
      if (param.sortField != null) {
        urlParam = getUrlParsingPageFilter({
          ...param,
          sort: param?.sortField ? param.sortField + "," + param.sortOrder : "",
          keyword: searchValue
        })
      } else {
        urlParam = getUrlParsingPageFilter({
          ...param,
          keyword: searchValue
        })
      }
      history.push({ search: urlParam })
    },
    [dispatch, searchValue]
  )

  function fetchData(param) {
    dispatch(getListPlantTypePageable(param))
  }

  function onSearch(values) {
    setSearchValue(values.keyword)
    setIsActiveSearch(true)
    let param = {
      keyword: values.keyword
    }
    const urlParam = getUrlParsingPageFilter(param)
    history.push({ search: urlParam })
  }

  function onClickEditPlantType(e) {
    setEditId(e?.id)
    setDataEdit(e)
    setIsShowPoup(true)
    formik.setFieldValue("name", e?.plant_type)
    formik.setFieldValue("user", e?.user_type_enum)
  }

  function handleResetSearch() {
    setSearchValue("")
    setIsActiveSearch(false)
    const param = {
      ...initialFilterTable,
      keyword: ""
    }
    const urlParam = getUrlParsingPageFilter(param)
    history.push({ search: urlParam })
  }

  function uiDate(e) {
    return (
      <div>
        <div>{e.submitted_date ? timeStampToDate(e.submitted_date, "dd-MM-yyyy") : "-"}</div>
        <div>{e.submitted_date ? timeStampToDate(e.submitted_date, "hh:mm:ss") : "-"}</div>
      </div>
    )
  }

  function uiActions(e) {
    return (
      <div>
        {privileges.indexOf(PRIVILEGE_ENUM.EDIT_PLANT_TYPE) > -1 && (
          <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Edit</Tooltip>}>
            <div
              className="btn btn-icon btn-detail btn-hover-secondary btn-sm mx-3"
              onClick={() => onClickEditPlantType(e)}>
              <EditSvgIcon />
            </div>
          </OverlayTrigger>
        )}
      </div>
    )
  }

  function poupComponentForm() {
    return (
      <Form.Group className={"mb-3"}>
        <Form.Label>
          {t("label.input_plant_name")} <span className={"text-danger"}>*</span>
        </Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          name="name"
          className={formik.errors.name && "is-invalid"}
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder={t("label.input_plant_name")}
        />
        <Form.Group controlId="exampleForm.ControlSelect1" className={"mt-4"}>
          <Form.Label>
            {t("table_header.user")} <span className={"text-danger"}>*</span>
          </Form.Label>
          <Form.Control
            as="select"
            placeholder={"Pilih tipe penggguna"}
            name={"user"}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.user}>
            <option disabled value={""}>
              {t("placeholder.select_user_type")}
            </option>
            {listPlantTypeUser &&
              listPlantTypeUser.length &&
              listPlantTypeUser.map((item, i) => (
                <option key={i} value={item.value}>
                  {item.label}
                </option>
              ))}
          </Form.Control>
        </Form.Group>
      </Form.Group>
    )
  }

  function onClickAddPlantType() {
    setEditId(undefined)
    setIsShowPoup(true)
    formik.setValues(initialValue)
  }

  function onCancelEditCreatePlantType() {
    setIsShowPoup(false)
    formik.setValues(initialValue)
  }

  return (
    <div>
      <PopupQuestion
        textCancel={t("button.cancel")}
        textOk={t("button.continue")}
        onOk={() => formik.handleSubmit()}
        onCancel={onCancelEditCreatePlantType}
        title={t(EditId ? "label.edit_plant_type" : "label.add_plant_type").toUpperCase()}
        show={isShowPoup}
        bodyCustom={poupComponentForm()}
        disable={disableButtonSubmit}
      />
      <div className={"filter-container"}>
        <div className="d-flex flex-wrap justify-content-between align-items-center mb-10">
          <Search
            isActiveSearch={isActiveSearch}
            placeholder={t("placeholder.insert_plant_type")}
            onSubmitForm={onSearch}
            initialValues={{ keyword: searchValue }}
            name={"keyword"}
            innerRef={formRef}
            trigerReset={handleResetSearch}
          />
        </div>
      </div>
      <Card className="card-shadowless">
        <CardBody>
          {General.loading ? (
            <TableSkeleton column={6} row={10} />
          ) : MasterData?.pageData?.length === 0 ? (
            <>
              <EmptyCard
                body={
                  isActiveSearch
                    ? t("messages.response.data_not_found")
                    : t("messages.response.empty_state")
                }
              />
            </>
          ) : (
            ""
          )}
          <TableView
            dataTable={MasterData?.pageData}
            currentPage={MasterData?.page?.currentPage}
            currentElement={MasterData?.page?.currentElement}
            totalCount={MasterData?.page?.totalCount}
            loadingGetData={false}
            callbackAfterPageChange={changePage}
            columnProperties={tableColumns}
          />
        </CardBody>
      </Card>
    </div>
  )
}