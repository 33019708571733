import React from "react"
import { Link } from "react-router-dom"
import { Typography } from "@material-ui/core"

export function BreadCrumbs({ items }) {
  if (!items || !items.length) {
    return ""
  }

  return (
    <ul className="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold my-2 p-0">
      {items.map((item, index) => (
        <li className="breadcrumb-item" key={index}>
          {item.pathname ? (
            <Link className="text-muted " to={{ pathname: item.pathname }}>
              {" "}
              {item.title}{" "}
            </Link>
          ) : (
            <Typography color="textPrimary">
              <div className={"text-truncate mw-500"}>{item.title}</div>
            </Typography>
          )}
        </li>
      ))}
    </ul>
  )
}
