export const buildSearchParams = (
  search,
  { existingParams = null, searchKey = "stringFilter" }
) => {
  let s = ""

  if (search) {
    s = `${searchKey}=${search}`
  }

  return existingParams + (existingParams.includes("?") ? "&" : "?") + s
}

export const linkWithCtrl = e => {
  const click = window.open(e, "_blank")
  return click.focus()
}
