import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useSubheader } from "../../../../../../_metronic/layout/index.js"
import { Create } from "./step/Create.jsx"
import * as Yup from "yup"
import {
  FORM_NEED,
  getMaximumErrorString,
  getMinimumErrorString,
  getStringErrorMassage
} from "../../../../../helpers/StringCollection.js"
import { TabsInputComponent } from "../../../../../../component/index.jsx"
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers/index.js"
import swal from "sweetalert"
import {
  createPackage,
  getDetailPackage,
  updatePackage
} from "../../../../../../redux/actions/MasterDataAction.jsx"
import { RowModule } from "../../../../../../component/atoms/RowModule/RowModule.jsx"
import { PopupQuestion } from "../../../../../../component/atoms/Popup/index.jsx"
import { useTranslation } from "react-i18next"
import { ILLUSTRATION_ASSETS_V2 } from "../../../../../../constants/ImageConstants.js"
import { MASTER_DATA } from "../../../../../../constants/InitTypeConstants.js"
import { RoutesConstants } from "../../../../../../constants/RoutesConstants.js"

export const CreatePackage = ({
  history,
  match: {
    params: { id }
  }
}) => {
  const subHeader = useSubheader()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { General, MasterData } = useSelector((state) => state)
  const formRef = useRef()
  const [isValid, setIsValid] = useState(true)
  const [showModalConfirmation, setShowModalConfirmation] = useState(false)
  const [detail, setDetail] = useState({
    treatment_list: []
  })
  const stepsTitle = [null]
  const stepsDescription = [null]
  const [initialValue, setInitialValue] = useState({
    package_name: "",
    plant_type_id: "",
    description: "",
    regional_id: "",
    treatment_id: []
  })
  const [swalMessage, setSwalMessage] = useState("")
  const [swalIcon, setSwallIcon] = useState("")
  const [dataForm, setDataForm] = useState()
  const createEditFlow = [
    <Create
      detail={detail}
      key={0}
      isReadOnly={false}
      setIsValid={setIsValid}
      setFormValues={setInitialValue}
      edit={id}
    />
  ]
  useEffect(() => {
    if (id) {
      dispatch(getDetailPackage(id))
    }
  }, [id])

  useEffect(() => {
    if (id) {
      if (MasterData.data) {
        setDetail(MasterData.data)
      }
    } else {
      setDetail(undefined)
    }
  }, [MasterData])
  useEffect(() => {
    if (General.isFinishUpdateOrDelete) {
      if (swalMessage) {
        swal({
          icon: swalIcon,
          text: swalMessage
        })
      }
      if (General?.actionInitType === MASTER_DATA.CREATE_PACKAGE) {
        history.push("/master/package")
      } else {
        history.push(RoutesConstants.MASTER_DATA.PACKAGE.DETAIL(MasterData?.messages?.id))
      }
    }
  }, [General.isFinishUpdateOrDelete, MasterData])
  const ValidationScheme = [
    Yup.object().shape({
      package_name: Yup.string()
        .min(3, getMinimumErrorString(3))
        .max(100, getMaximumErrorString(100))
        .required(getStringErrorMassage("Nama Paket ", FORM_NEED.harusDiIsi)),
      plant_type_id: Yup.string().required(
        getStringErrorMassage("Tipe Tanaman ", FORM_NEED.harusDiIsi)
      ),
      regional_id: Yup.string().required(getStringErrorMassage("Regional ", FORM_NEED.harusDiIsi)),
      description: Yup.string()
        .required(getStringErrorMassage("Deskripsi", FORM_NEED.harusDiIsi))
        .max(100, getMaximumErrorString(100))
        .matches("^.*$", "Tidak Boleh di Enter")
    })
  ]
  function onSubmitForm(e) {
    const { values, submited } = e
    setSwalMessage(id ? "Pembaruan data paket berhasil." : "Data paket berhasil ditambah.")
    setSwallIcon(
      toAbsoluteUrl(
        id ? ILLUSTRATION_ASSETS_V2.IL_POSITIVE_DIPERBARUI : ILLUSTRATION_ASSETS_V2.IL_POSITIVE_ADD
      )
    )
    if (submited) {
      setShowModalConfirmation(true)
      const formData = {
        name: values?.package_name,
        plant_type_id: values?.plant_type_id,
        description: values?.description,
        regional_id: values?.regional_id,
        treatment_id: values?.treatment_id
      }
      setDataForm(formData)
    }
  }

  function submitData() {
    if (id) {
      dispatch(updatePackage(dataForm, id))
    } else {
      dispatch(createPackage(dataForm))
    }
  }

  useEffect(() => {
    if (detail) {
      subHeader.setTitle(id ? "Edit Paket" : "Tambah Paket")
      subHeader.setButton(buttonSubmited)
      if (id) {
        subHeader.setBreadcrumbs([
          {
            pathname: "/master/package",
            title: "Paket Penyemprotan"
          },
          {
            pathname: "/master/package/list/list/" + id,
            title: detail?.name
          },
          {
            title: "Edit Paket"
          }
        ])
        let list_treatment_selected = detail?.treatment_list?.map(({ id }, key) => {
          return { id: key.toString(), value: id }
        })
        let list_treatment = detail?.treatment_list?.map(({ id }) => id)
        setInitialValue({
          package_name: detail?.name,
          plant_type_id: detail?.plant_type_id
            ? detail?.plant_type_id
            : "687de59d-78c2-4a70-93cd-3722a93ac8d1",
          description: detail?.desc,
          treatment_id_selected: list_treatment_selected ? list_treatment_selected : [],
          treatment_id: list_treatment ? list_treatment : [],
          regional_id: detail?.regional_id || ""
        })
      } else {
        subHeader.setBreadcrumbs([
          {
            pathname: "/master/package",
            title: "Paket Penyemprotan"
          },
          {
            title: "Tambah Paket"
          }
        ])
      }
    }
  }, [detail, id])

  useEffect(() => {
    subHeader.setButton(buttonSubmited)
  }, [isValid])

  const buttonSubmited = (
    <button
      type="button"
      disabled={isValid}
      className={isValid ? "btn button-disabled" : "btn btn-primary"}
      onClick={() => {
        if (formRef.current && !isValid) {
          formRef.current.handleSubmit()
        }
      }}>
      Kirim
    </button>
  )

  function componentModalConfirmation() {
    return (
      <RowModule>
        <p className={"swal-text p-4"} style={{ fontSize: "14px" }}>
          {t(id ? "messages.confirmation.edit_package" : "messages.confirmation.add_package")}
        </p>
      </RowModule>
    )
  }

  return (
    <>
      <PopupQuestion
        onCancel={() => setShowModalConfirmation(false)}
        textCancel={t("button.cancel")}
        textOk={t("button.continue")}
        title={t(id ? "label.edit_package" : "label.add_package").toUpperCase()}
        onOk={submitData}
        show={showModalConfirmation}
        bodyCustom={componentModalConfirmation()}
      />
      <TabsInputComponent
        isEditTabs={false}
        steps={stepsTitle}
        stepDescription={stepsDescription}
        formInitialValues={initialValue}
        formId="createPaket"
        innerRef={formRef}
        componentTab={createEditFlow}
        validationSchema={ValidationScheme}
        onSubmitForm={onSubmitForm}
      />
    </>
  )
}