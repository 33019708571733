import React from "react"

export const InlineText = ({ title, value, hasIcon, icon, className, type }) => {
  return (
    <>
      {type === "between" ? (
        <div className={`d-flex align-items-start ${className}`}>
          <div className="col-4">{title}</div>
          <div>:</div>
          <div className="col">{value}</div>
        </div>
      ) : (
        <div className={`d-flex align-items-center ${className}`}>
          <div className="font-weight-bold text-dark-50 font-size-sm d-flex align-items-start">
            <span className={"text-dark-50"}>{title}</span>
            <span className={"mx-1"}>:</span>
            <div
              className="font-weight-bolder font-size-sm text-dark ml-2 d-flex flex-1 align-items-center"
              role={"value"}>
              {value}
              {hasIcon && (
                <div className={"kur-bni detail "} style={{ marginLeft: 4 }}>
                  {icon ? <img draggable={"false"} src={icon} alt="icon" /> : " -"}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}
