import { buildSearchParams } from "../app/helpers/Utils"
import { initialFilterTable } from "../component/atoms/Tabel"

export const ENDPOINT = {
  userSetting: {
    login: "/v1/account/web-login",
    checkJwt: "/account/cms/v1/check-token"
  },
  uploadUrl: {
    uploadImage: "/upload/cms/v1"
  },
  mitraData: {
    pending: "/v1/mitra/pending",
    mitraList: "/v1/mitra",
    detail: "/mitra/cms/v1/",
    createMitra: "/v1/mitra",
    approveMitra: "/v1/mitra/update-status/",
    uploadDocumentData: "/v1/mitra/upload-image"
  },
  farmerData: {
    createFarmer: "/v2/farmer/",
    updateFarmer: "/v2/farmer/",
    listFromMitra: "/mitra/cms/v1/farmer/",
    allFarmer: "/farmer/cms/v2",
    panding: "/farmer/cms/v2/pending",
    detailFarmer: "/v2/farmer/detail/",
    approveOrRejectFarmer: "/farmer/cms/v2/update-status/",
    listPetaniByMitra: "/list-farmer/"
  },
  fieldOfficerData: {
    fieldOfficerList: "field-officer/cms/v1", // GET
    listAllMitraCombobox: "mitra/cms/v1/all",
    listAllFoCombobox: "field-officer/cms/v1/all",
    fieldOfficerProfile: "account/v1/profile", // GET
    createFieldOfficer: "field-officer/cms/v1", // POST
    updateFieldOfficer: "field-officer/cms/v1/", // PUT
    detailFieldOfficer: "field-officer/cms/v1/", // GET
    FOListMitra: "field-officer/v1/list-mitra", // GET
    FOAssignMitra: "field-officer/cms/v1/assign-mitra" // POST
  },
  helpApps: {
    pending: "/help/v1/cms/list-pending",
    listHelp: "/help/v1/cms/list",
    detail: "/help/v1/cms/"
  },
  province: {
    listAll: "/v1/province"
  },
  regency: {
    listRegencyAllByProvince: "/v1/regency/province"
  },
  district: {
    listDistrictAllByRegency: "/v1/district/regency"
  },
  areaData: {
    areaList: "area/cms/v1",
    areaInfoDetail: "v2/area/cms/area-info-detail/",
    listNextActivityArea: "v2/area/mobile/list-next-activity",
    areaPlantingEventDetail: "planting-event/mobile/v1/detail/",
    detailPredictionForm: "/planting-event/mobile/v1/form/",
    submitDataForm: "/planting-event/mobile/v1/form/submit/"
  }
}

export function getUrlParsing(onGoingParameter, value, parameter) {
  if (parameter !== undefined) {
    if (parameter !== null) {
      if (parameter !== "" && (parameter.length > 0 || parameter != 0)) {
        if (
          onGoingParameter === undefined ||
          onGoingParameter === null ||
          onGoingParameter === ""
        ) {
          onGoingParameter = "?" + value + "=" + encodeURIComponent(parameter)
        } else {
          onGoingParameter = onGoingParameter + "&" + value + "=" + encodeURIComponent(parameter)
        }
      }
    }
  }
  return onGoingParameter
}

//array url parsing
export function getUrlParsingMap(onGoingParameter, value, parameter) {
  if (parameter !== undefined) {
    if (parameter !== null) {
      if (parameter !== "" && (parameter.length > 0 || parameter != 0)) {
        if (
          onGoingParameter === undefined ||
          onGoingParameter === null ||
          onGoingParameter === ""
        ) {
          onGoingParameter = "?" + value + "=" + encodeURIComponent(parameter)
        } else {
          if (Array.isArray(parameter)) {
            parameter.forEach(
              (item) =>
                (onGoingParameter = onGoingParameter + "&" + value + "=" + encodeURIComponent(item))
            )
          } else {
            onGoingParameter = onGoingParameter + "&" + value + "=" + encodeURIComponent(parameter)
          }
        }
      }
    }
  }
  return onGoingParameter
}

export function getUrlParsingPage(pagableRequest, search = undefined) {
  if (pagableRequest) {
    const { pageNumber, pageSize } = pagableRequest
    let param = ""
    param = getUrlParsing(param, "size", pageSize)
    param = getUrlParsing(param, "page", pageNumber - 1)
    return buildSearchParams(search, {
      existingParams: param,
      searchKey: "string_filter"
    })
  }
  return buildSearchParams(search, {
    existingParams: getUrlParsingPage(initialFilterTable),
    searchKey: "string_filter"
  })
}

export function getUrlParsingPageFilter(pagableRequest) {
  if (pagableRequest) {
    const {
      pageNumber,
      date,
      month,
      year,
      pageSize,
      string_filter,
      keyword,
      province_id,
      regency_id,
      district_id,
      sub_district_id,
      mitra_id,
      min_area,
      max_area,
      sort,
      search,
      activity_type,
      areaName,
      start_date,
      end_date,
      plant_type_id,
      farmer_id,
      param_filter,
      planting_status,
      activity_date,
      status,
      mitra_status,
      area_status,
      farmer_status,
      merchant_user_id,
      regional_id,
      mitra_type
    } = pagableRequest
    let param = ""
    param = getUrlParsing(param, "size", pageSize)
    param = getUrlParsing(param, "page", pageNumber - 1)
    param = getUrlParsing(param, "province_id", province_id)
    param = getUrlParsing(param, "regency_id", regency_id)
    param = getUrlParsing(param, "district_id", district_id)
    param = getUrlParsing(param, "sub_district_id", sub_district_id)
    param = getUrlParsing(param, "string_filter", string_filter)
    param = getUrlParsing(param, "keyword", keyword)
    param = getUrlParsing(param, "mitra_id", mitra_id)
    param = getUrlParsing(param, "min_area", min_area)
    param = getUrlParsing(param, "max_area", max_area)
    param = getUrlParsing(param, "activity_type", activity_type)
    param = getUrlParsing(param, "areaName", areaName)
    param = getUrlParsing(param, "start_date", start_date)
    param = getUrlParsing(param, "end_date", end_date)
    param = getUrlParsing(param, "plant_type_id", plant_type_id)
    param = getUrlParsing(param, "farmer_id", farmer_id)
    param = getUrlParsing(param, "sort", sort)
    param = getUrlParsing(param, "search", search)
    param = getUrlParsing(param, "param", param_filter)
    param = getUrlParsing(param, "planting_status", planting_status)
    param = getUrlParsing(param, "month", month)
    param = getUrlParsing(param, "year", year)
    param = getUrlParsing(param, "date", date)
    param = getUrlParsing(param, "activity_date", activity_date)
    param = getUrlParsing(param, "status", status)
    param = getUrlParsing(param, "farmer_status", farmer_status)
    param = getUrlParsing(param, "mitra_status", mitra_status)
    param = getUrlParsing(param, "area_status", area_status)
    param = getUrlParsing(param, "merchant_user_id", merchant_user_id)
    param = getUrlParsing(param, "regional_id", regional_id)
    param = getUrlParsing(param, "mitra_type", mitra_type)

    return param
  }
  return getUrlParsingPageFilter(initialFilterTable)
}

export function getUrlParsingRole(value) {
  if (value) {
    let param = ""
    param = getUrlParsing(param, "param", value)
    return param
  }
  return getUrlParsingRole(initialFilterTable)
}

export function getUrlParsingLog(value) {
  if (value) {
    const { start_date, end_date, id } = value
    let param = ""
    param = getUrlParsing(param, "start_date", start_date)
    param = getUrlParsing(param, "end_date", end_date)
    param = getUrlParsing(param, "id", id)
    return param
  }
}

export function getUrlParsingActivity(value) {
  if (value) {
    const { type, variety_id, start_date, end_date } = value
    let param = ""
    param = getUrlParsing(param, "type", type)
    param = getUrlParsing(param, "variety_id", variety_id)
    param = getUrlParsing(param, "start_date", start_date)
    param = getUrlParsing(param, "end_date", end_date)
    return param
  } else {
    return ""
  }
}

export function getUrlParsingCategoryItem(pagableRequest) {
  if (pagableRequest) {
    const {
      pageNumber,
      pageSize,
      string_filter,
      reason_id,
      plant_type_id,
      sort,
      machine_owner,
      machine_type,
      item_shape
    } = pagableRequest
    let param = ""
    param = getUrlParsing(param, "page", pageNumber - 1)
    param = getUrlParsing(param, "size", pageSize)
    param = getUrlParsing(param, "reason_id", reason_id)
    param = getUrlParsing(param, "sort", sort)
    param = getUrlParsing(param, "string_filter", string_filter)
    param = getUrlParsing(param, "plant_type_id", plant_type_id)
    param = getUrlParsing(param, "machine_owner", machine_owner)
    param = getUrlParsing(param, "machine_type", machine_type)
    param = getUrlParsing(param, "item_shape", item_shape)
    return param
  }
}

export function getUrlParsingSync(code) {
  if (code) {
    let param = ""
    param = getUrlParsing(param, "code", code)
    return param
  }
}

export function getUrlParsingStatus(value) {
  if (value) {
    const { status } = value
    let param = ""
    param = getUrlParsing(param, "status", status)
    return param
  }
  return ""
}

export function getUrlParsingCalenderStatus(value) {
  if (value) {
    const { start_date, end_date, team_id_list } = value
    let param = ""
    param = getUrlParsing(param, "start_date", start_date)
    param = getUrlParsing(param, "end_date", end_date)
    param = getUrlParsing(param, "team_id_list", team_id_list)
    return param
  }
}

export function getUrlParsingSubmission(value) {
  if (value) {
    const {
      keyword,
      mitra_id,
      sub_district_id,
      variety_id,
      female_planting_start_date,
      female_planting_end_date,
      sort,
      pageSize,
      pageNumber
    } = value
    let param = ""
    param = getUrlParsing(param, "keyword", keyword)
    param = getUrlParsing(param, "mitra_id", mitra_id)
    param = getUrlParsing(param, "sub_district_id", sub_district_id)
    param = getUrlParsing(param, "variety_id", variety_id)
    param = getUrlParsing(param, "female_planting_start_date", female_planting_start_date)
    param = getUrlParsing(param, "female_planting_end_date", female_planting_end_date)
    param = getUrlParsing(param, "sort", sort)
    param = getUrlParsing(param, "size", pageSize)
    param = getUrlParsing(param, "page", pageNumber - 1)
    return param
  }
}