import React from "react"
import TableView, { formatterRowComponent } from "../../../../../../component/atoms/Tabel/index.jsx"
import { Card, CardBody } from "../../../../../../_metronic/_partials/controls/index.js"
import { sortCaret } from "../../../../../../_metronic/_helpers/index.js"
import { loadUserLogin } from "../../../../../service/userManagementAction.js"
import { timeStampToDate } from "../../../../../helpers/DateHelper.js"
import { toMeter } from "../../../../../helpers/TextHelper.js"
import { linkWithCtrl } from "../../../../../helpers/Utils.js"
import { EmptyCard, TableSkeleton } from "../../../../../../component/index.jsx"
import { useTranslation } from "react-i18next"
import { PRIVILEGE_ENUM } from "../../../../../../constants/PrivilegeConstants.js"

export const AreaList = ({ data, general, changePage, history, isFilter }) => {
  const privileges = loadUserLogin().privilege
  const { t } = useTranslation()

  const uiCode = (e) => {
    return (
      <>
        <p className="text-dark font-weight-bolder mb-1 font-size-lg">{e?.area_name ?? "-"}</p>
        <span className="text-muted font-weight-bold d-block">
          {e?.area_size ? toMeter(e?.area_size) : "-"}
        </span>
      </>
    )
  }
  const uiFarmer = (e) => {
    return (
      <>
        <p className="text-dark font-weight-bolder mb-1 font-size-lg">
          {e?.farmer_name ? e?.farmer_name : "-"}
        </p>
        <span className="text-muted font-weight-bold d-block">
          {e?.farmer_code ? e?.farmer_code : "-"}
        </span>
      </>
    )
  }
  const uiMitra = (e) => {
    return (
      <>
        <p className="text-dark font-weight-bolder mb-1 font-size-lg">{e?.mitra_name ?? "-"}</p>
        <span className="text-muted font-weight-bold d-block">{e?.mitra_code ?? "-"}</span>
      </>
    )
  }
  const uiPlant = (e) => {
    return (
      <>
        <p className="text-dark font-weight-bolder mb-1 font-size-lg">
          {e?.plant_type_name ? e?.plant_type_name : "-"}
        </p>
        <span className="text-muted font-weight-bold d-block">
          {e?.plant_type_varietas ? e?.plant_type_varietas : "-"}
        </span>
      </>
    )
  }
  const uiType = (e) => {
    return (
      <>
        <p className="text-dark font-weight-bolder mb-1 font-size-lg">{e?.activity_name ?? "-"}</p>
      </>
    )
  }
  const uiCreatedDate = (e) => {
    const dateConvert = e?.submitted_date ? timeStampToDate(e?.submitted_date, "dd-MM-yyyy") : "-"
    const TimeConvert = e?.submitted_date ? timeStampToDate(e?.submitted_date, "HH:mm:ss") : "-"

    return (
      <>
        <p className="text-dark mb-1 font-size-lg">
          {dateConvert} <br /> {TimeConvert}
        </p>
      </>
    )
  }

  const linkDetail = (area_id, type, id, e) => {
    const url = "/lahan/active/" + area_id + "/" + type + "/" + id

    if (e?.ctrlKey) {
      linkWithCtrl(url)
    } else {
      history.push(url)
    }
  }

  const columns = [
    {
      dataField: "plantingSeason.area.name",
      text: t("table_header.area_name"),
      sort: false,
      sortCaret: sortCaret,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiCode
      }
    },
    {
      dataField: "plantingSeason.area.farmer.name",
      text: t("table_header.farmer"),
      sort: true,
      sortCaret: sortCaret,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiFarmer
      }
    },
    {
      dataField: "plantingSeason.area.farmer.account.mitra.name",
      text: t("table_header.mitra"),
      sort: true,
      sortCaret: sortCaret,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiMitra
      }
    },
    {
      dataField: "plant_name",
      text: t("table_header.planting_type"),
      sort: false,
      sortCaret: sortCaret,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiPlant
      }
    },
    {
      dataField: "activity_name",
      text: t("table_header.activity_type"),
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiType
      },
      headerStyle: () => {
        return { width: "20%" }
      }
    },
    {
      dataField: "createdDate",
      text: t("table_header.created_date"),
      sort: true,
      sortCaret: sortCaret,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiCreatedDate
      }
    }
  ]

  return (
    <>
      <Card className="card-shadowless">
        <CardBody>
          {general.loading ? (
            <TableSkeleton column={6} row={10} />
          ) : data?.pageDataActivity?.length == 0 ? (
            <EmptyCard
              body={
                isFilter
                  ? t("messages.response.data_not_found")
                  : t("messages.response.empty_state")
              }
            />
          ) : (
            ""
          )}
          <TableView
            callbackAfterPageChange={changePage}
            columnProperties={columns}
            dataTable={data.pageDataActivity}
            currentPage={data.pageActivity.currentPage}
            currentElement={data.pageActivity.currentElement}
            totalCount={data.pageActivity.totalCount}
            loadingGetData={general.loading}
            ignoreSinglePage
            rowClasses={() => {
              return "cursor-pointer"
            }}
            rowEvents={{
              onClick: (e, row) => {
                if (
                  privileges.indexOf(
                    PRIVILEGE_ENUM["DETAIL_ACTIVITY_" + row?.activity_type_enum?.toUpperCase()]
                  ) > -1 &&
                  e.target.cellIndex < 6
                ) {
                  linkDetail(row?.area_id, row?.activity_type_enum?.toLowerCase(), row.id, e)
                }
              }
            }}
          />
        </CardBody>
      </Card>
    </>
  )
}