import React, { useEffect, useRef, useState } from "react"
import * as Yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { FormBulkSchedule } from "./step/FormBulkSchedule.jsx"
import { FormBulkPreview } from "./step/FormBulkPreview.jsx"
import { useSubheader } from "../../../../../../_metronic/layout/index.js"
import { RoutesConstants } from "../../../../../../constants/RoutesConstants.js"
import {
  getDroneTeam,
  SavedGlobalListScheduleOrder
} from "../../../../../../redux/actions/OrderAction.jsx"
import { FORM_NEED, getStringErrorMassage } from "../../../../../helpers/StringCollection.js"
import { TabsInputComponent } from "../../../../../../component/index.jsx"
import { ROUTING_STATUS } from "../../../../../../constants/StringConstant.js"

export const ApprovalBulkPascaTanam = () => {
  const { Order } = useSelector((state) => state)
  const history = useHistory()
  const { t } = useTranslation()
  const stepTitle = ["Jadwal Penyemprotan", "Preview"]
  const stepDesc = ["Penjadwalan Aktivitas Penyemprotan", "ReviewIndividual & Submit"]

  const [dataApprovalBulk, setDataApprovalBulk] = useState(undefined)
  const [listSelected, setListSelected] = useState(undefined)
  const [dataTeamSelected, setDataTeamSelected] = useState(undefined)
  const [initialValue] = useState({
    team_id: "",
    spraying_date_list: [],
    rejection_enum_list: [],
    rejection_reason: ""
  })

  const formBulk = [
    <FormBulkSchedule
      key={1}
      dataTeamSelected={(e) => setDataTeamSelected(e)}
      listSelected={changeListSelected}
      data={dataApprovalBulk}
    />,
    <FormBulkPreview
      key={2}
      dataTeamSelected={dataTeamSelected}
      listSelectedDate={listSelected}
      data={dataApprovalBulk}
    />
  ]

  const formRef = useRef()
  const subheader = useSubheader()

  function changeListSelected(e) {
    setListSelected(e)
  }

  const breadcrumbData = [
    {
      pathname: RoutesConstants.ORDER.LIST_PASCA_PLANTING_BY_STATUS(
        ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.PENDING
      ),
      title: t("label.scheduling")
    },
    {
      pathname: RoutesConstants.ORDER.LIST_PASCA_PLANTING_BY_STATUS(
        ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.PENDING
      ),
      title: t("label.pasca_planting")
    },
    {
      pathname: RoutesConstants.ORDER.LIST_PASCA_PLANTING_BY_STATUS(
        ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.PENDING
      ),
      title: t("label.pending")
    },
    {
      title: t("label.bulk_scheduling")
    }
  ]

  const dispatch = useDispatch()
  useEffect(() => {
    if (Order.listDataInitFormApproveBulk) {
      if (Order.listDataInitFormApproveBulk.length === 0) {
        dispatch(SavedGlobalListScheduleOrder([]))
        history.push(
          RoutesConstants.ORDER.LIST_PASCA_PLANTING_BY_STATUS(
            ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.PENDING
          )
        )
      } else {
        setDataApprovalBulk(Order.listDataInitFormApproveBulk)
      }
    } else {
      dispatch(SavedGlobalListScheduleOrder([]))
      history.push(
        RoutesConstants.ORDER.LIST_PASCA_PLANTING_BY_STATUS(
          ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.PENDING
        )
      )
    }
  }, [Order])

  useEffect(() => {
    subheader.setBreadcrumbs(breadcrumbData)
    dispatch(getDroneTeam())
  }, [])

  const validationSchema = [
    Yup.object().shape({
      team_id: Yup.string().required(getStringErrorMassage("Tim Drone", FORM_NEED.harusDiIsi))
    })
  ]

  function onSubmitBulk() {
    // alert("submit");
  }

  return (
    <>
      {dataApprovalBulk && (
        <TabsInputComponent
          isEditTabs={false}
          formId="form_bulk"
          steps={stepTitle}
          stepDescription={stepDesc}
          formInitialValues={initialValue}
          validationSchema={validationSchema}
          innerRef={formRef}
          componentTab={formBulk}
          onSubmitForm={onSubmitBulk}
        />
      )}
    </>
  )
}