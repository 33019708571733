import React from "react"
import PropTypes from "prop-types"
import { Card, CardBody } from "../../../_metronic/_partials/controls"
import { toAbsoluteUrl } from "../../../_metronic/_helpers"
import SVG from "react-inlinesvg"

export const EmptyCard = ({ body, bodyCustom, image, subtitle }) => {
  return (
    <Card className="card-shadowless">
      <CardBody className="py-20 text-center">
        {(body || bodyCustom) && (
          <>
            {body ? (
              <>
                <SVG
                  src={toAbsoluteUrl(image ? image : "/media/svg/icons/Detail/farmer-lahan.svg")}
                />
                <div className="text-center mt-12 font-weight-bolder mb-1 font-size-lg ">
                  {body}
                </div>
              </>
            ) : (
              bodyCustom
            )}
          </>
        )}
        {subtitle && <div className={"color-gray"}>{subtitle}</div>}
      </CardBody>
    </Card>
  )
}

EmptyCard.propTypes = {
  body: PropTypes.string
}