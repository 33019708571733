import React, { useEffect, useRef, useState } from "react"
import { useSubheader } from "../../../../../_metronic/layout"
import * as Yup from "yup"
import {
  FORM_NEED,
  getMaximumErrorString,
  getMinimumErrorString,
  getStringErrorMassage
} from "../../../../helpers/StringCollection.js"
import { TabsInputComponent } from "../../../../../component"
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers"
import { useDispatch, useSelector } from "react-redux"
import { CreateEditForm } from "./Form/CreateEditForm"
import {
  createTeamDrone,
  GetDetailHeaderTeamDrone
} from "../../../../../redux/actions/FieldOfficerAction"
import swal from "sweetalert"
import { PopupQuestion } from "../../../../../component/atoms/Popup"
import { RowModule } from "../../../../../component/atoms/RowModule/RowModule.jsx"
import { ILUSTRATION_CONSTANS } from "../../../../../constants/ImageConstants"
import { RoutesConstants } from "../../../../../constants/RoutesConstants"
import { FIELD_OFFICER_ROLE_ENUM } from "../../../../../constants/StringConstant"
import { useTranslation } from "react-i18next"

export const CreateEditTeamDrone = ({
  history,
  match: {
    params: { action, id }
  }
}) => {
  const subHeader = useSubheader()
  const { t } = useTranslation()
  const [isValid, setIsValid] = useState(true)
  const formRef = useRef()
  const dispatch = useDispatch()
  const { General, FieldOfficer } = useSelector((state) => state)
  const [initialValue, setInitialValue] = useState({
    name: "",
    member_list_1: "",
    member_list_2: "",
    description: ""
  })
  const stepsTitle = [null]
  const stepsDescription = [null]
  const [swalMessage, setSwalMessage] = useState("")
  const [swalIcon, setSwallIcon] = useState("")
  const [showModalDialog, setShowModalDialog] = useState(false)
  const [showModalDialogCancel, setShowModalDialogCancel] = useState(false)

  useEffect(() => {
    if (id) {
      dispatch(GetDetailHeaderTeamDrone(id))
    }
  }, [])

  useEffect(() => {
    if (FieldOfficer?.detail?.id) {
      let memberList = FieldOfficer?.detail?.member_list.map((value) => value.id)
      setInitialValue({
        name: FieldOfficer?.detail?.name,
        member_list_1: memberList[0],
        member_list_2: memberList[1],
        description: FieldOfficer?.detail?.description
      })
    } else {
      setInitialValue({
        name: "",
        member_list_1: "",
        member_list_2: "",
        description: ""
      })
    }
  }, [FieldOfficer.detail])

  const buttonSubmited = (
    <button
      type="button"
      disabled={isValid}
      className="btn btn-primary"
      onClick={() => setShowModalDialog(true)}>
      Submit
    </button>
  )

  const buttonCancel = (
    <button
      type="button"
      className="btn btn-secondary-info mr-4"
      onClick={() => setShowModalDialogCancel(true)}>
      Cancel
    </button>
  )

  const createEditFlow = [
    <CreateEditForm
      key={0}
      isReadOnly={false}
      setIsValid={setIsValid}
      id={id}
      btnSubmit={buttonSubmited}
      btnCancel={buttonCancel}
    />
  ]

  const ValidationScheme = [
    Yup.object().shape({
      name: Yup.string()
        .min(3, getMinimumErrorString(3))
        .max(100, getMaximumErrorString(100))
        .required(getStringErrorMassage("Nama ", FORM_NEED.harusDiIsi)),
      member_list_1: Yup.string().required(
        getStringErrorMassage("Anggota Team 1 ", FORM_NEED.harusDiIsi)
      )
    })
  ]

  useEffect(() => {
    subHeader.setButton(buttonSubmited)
    subHeader.setButton(null)
    subHeader.setBreadcrumbs([
      {
        pathname: RoutesConstants.FIELD_OFFICER.LIST_FO(FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER),
        title: "FO"
      },
      {
        pathname: RoutesConstants.FIELD_OFFICER.LIST_FO_AGRONOMIS("list-fo"),
        title: "FO Agronomist"
      },
      {
        pathname: RoutesConstants.FIELD_OFFICER.LIST_FO_AGRONOMIS("team-drone"),
        title: "Drone Pilot"
      },
      {
        title: action === "new" ? "Tambah Tim" : "Edit Tim"
      }
    ])
  }, [])

  function onSubmitForm(e) {
    const { values, submited } = e
    setSwalMessage(
      id
        ? t("messages.response.team_drone_change_success")
        : t("messages.response.team_drone_add_success")
    )
    setSwallIcon(toAbsoluteUrl(ILUSTRATION_CONSTANS.IL_SUCCESS))

    if (submited) {
      const formData = {
        name: values?.name,
        member_list: values?.member_list_2
          ? [values?.member_list_1, values?.member_list_2]
          : [values?.member_list_1],
        description: values?.description
      }
      if (id) {
        dispatch(createTeamDrone(formData, id))
      } else {
        dispatch(createTeamDrone(formData))
      }
    }
  }

  useEffect(() => {
    if (General.isFinishUpdateOrDelete) {
      if (swalMessage) {
        swal({
          icon: swalIcon,
          text: swalMessage
        })
      }
      setShowModalDialog(false)
      history.push(RoutesConstants.FIELD_OFFICER.LIST_FO_AGRONOMIS("team-drone"))
    }
  }, [General.isFinishUpdateOrDelete])

  return (
    <>
      <PopupQuestion
        disable={isValid}
        onCancel={() => setShowModalDialog(false)}
        textCancel={"Batal"}
        textOk={"Lanjut"}
        title={action === "new" ? "TAMBAH TIM" : "EDIT TIM"}
        onOk={() => formRef.current.handleSubmit()}
        show={showModalDialog}
        bodyCustom={
          <RowModule>
            <p className={"swal-text p-4"}>
              {id
                ? formRef?.current?.values?.member_list_1 != initialValue.member_list_1 ||
                  formRef?.current?.values?.member_list_2 != initialValue.member_list_2
                  ? FieldOfficer?.detail?.has_schedule
                    ? t("messages.confirmation.team_create_with_schedule")
                    : t("messages.confirmation.team_edit")
                  : t("messages.confirmation.team_edit")
                : t("messages.confirmation.team_create")}
            </p>
          </RowModule>
        }
      />
      <PopupQuestion
        onCancel={() => setShowModalDialogCancel(false)}
        textCancel={"Batal"}
        textOk={"Lanjut"}
        title={action === "new" ? "Cancel Tambah Tim" : "Cancel Edit Tim"}
        onOk={() => history.push(RoutesConstants.FIELD_OFFICER.LIST_FO_AGRONOMIS("team-drone"))}
        show={showModalDialogCancel}
        bodyCustom={
          <RowModule>
            <p className={"swal-text p-4"}>
              {id
                ? t("messages.confirmation.team_edit_cancel")
                : t("messages.confirmation.team_create_cancel")}
            </p>
          </RowModule>
        }
      />
      <TabsInputComponent
        isEditTabs={false}
        steps={stepsTitle}
        stepDescription={stepsDescription}
        formInitialValues={initialValue}
        formId="createTim"
        innerRef={formRef}
        componentTab={createEditFlow}
        validationSchema={ValidationScheme}
        onSubmitForm={onSubmitForm}
      />
    </>
  )
}