import React from "react"
import { AppBar, Paper, Tab, Tabs } from "@material-ui/core"
import { useEffect } from "react"
import { useState } from "react"
import { Button } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { setAssignTab } from "../../../redux/actions/FieldOfficerAction"
import { isEmptyObj } from "../../../app/helpers/TextHelper.js"

export function TabsComponent({
  changeTab = () => {},
  labelTab,
  componentTab,
  header,
  initValue = 0
}) {
  const [value, setValue] = useState(initValue)

  function handleChange(event, newValue) {
    setValue(newValue)
    changeTab(newValue)
  }

  function getTab(value) {
    return componentTab[value]
  }

  return (
    <>
      {header ?? <></>}
      <Paper square>
        <AppBar position="static" color="transparent">
          <Tabs value={value} indicatorColor="primary" textColor="primary" onChange={handleChange}>
            {labelTab.map((item, i) => (
              <Tab key={i} label={item} />
            ))}
          </Tabs>
        </AppBar>
      </Paper>
      <br />
      {getTab(value)}
    </>
  )
}

export function TabsAgendaComponent({ labelTab, componentTab, header, initValue = 0 }) {
  const [value, setValue] = useState(initValue)

  function handleChange(event, newValue) {
    setValue(newValue)
  }

  function getTab(value) {
    return componentTab[value]
  }

  return (
    <>
      {header ?? <></>}
      <Paper square className={"tabAgenda"}>
        <AppBar position="static" color="transparent">
          <Tabs value={value} indicatorColor="primary" textColor="primary" onChange={handleChange}>
            {labelTab.map((item, i) => (
              <Tab key={i} label={item} />
            ))}
          </Tabs>
        </AppBar>
      </Paper>
      <br />
      {getTab(value)}
    </>
  )
}

export function TabsComponentRedirect({ labelTab, componentTab, header, initValue = 0 }) {
  const { FieldOfficer } = useSelector(state => state)
  const dispatch = useDispatch()
  const [value, setValue] = useState(initValue)

  function handleChange(event, newValue) {
    dispatch(setAssignTab(newValue))
  }

  useEffect(() => {
    setValue(FieldOfficer.assignTab)
  }, [FieldOfficer.assignTab])

  function getTab(value) {
    return componentTab[value]
  }

  return (
    <>
      {header ?? <></>}
      <Paper square>
        <AppBar position="static" color="transparent">
          <Tabs value={value} indicatorColor="primary" textColor="primary" onChange={handleChange}>
            {labelTab.map((item, i) => (
              <Tab key={i} label={item} />
            ))}
          </Tabs>
        </AppBar>
      </Paper>
      <br />
      {getTab(value)}
    </>
  )
}

export function TabsComponentClassic({ header, children = [] }) {
  const [active, setActive] = useState({})

  useEffect(() => {
    if (children.length > 0) {
      if (isEmptyObj(active)) {
        setActive(children[0])
      }
    }
  }, [children])

  return (
    <div>
      {header ?? <></>}
      <Paper square className="p-3">
        {children.length > 0 &&
          children.map(e => (
            <Button
              key={e.id}
              variant="outline-light"
              style={{
                backgroundColor: active.id === e.id ? "grey" : "transparent",
                color: active.id === e.id ? "white" : "black",
                padding: "8px 24px",
                margin: "0 8px",
                fontWeight: active.id === e.id ? "bold" : "normal"
              }}
              onClick={() => setActive(e)}>
              {e.title}
            </Button>
          ))}
      </Paper>
      <div className="mt-6">{active.view}</div>
    </div>
  )
}
