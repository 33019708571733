import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { TabLog, TabsComponent } from "../../../../../component/index.jsx"
import { AreaTab } from "../../detail/tab/AreaTab.jsx"
import { clearDataDetail, getDetailLead } from "../../../../../redux/actions/AreaAction.jsx"
import { useSubheader } from "../../../../../_metronic/layout/index.js"
import { FlatIconAndText } from "../../../../../component/atoms/FlaticonAndText/index.jsx"
import { ENUM_LOG } from "../../../../../constants/InitTypeConstants.js"
import { toThousandConvert } from "../../../../helpers/TextHelper.js"
import { ICON_CONSTANS } from "../../../../../constants/ImageConstants.js"

export const ListAreaDetailLead = ({
  match: {
    params: { id }
  }
}) => {
  const subHeader = useSubheader()
  const dispatch = useDispatch()
  const { Area } = useSelector((state) => state)
  const [detail, setDetail] = useState({})
  const [coordinat, setCoordinat] = useState([])
  useEffect(() => {
    return () => {
      dispatch(clearDataDetail())
    }
  }, [])

  useEffect(() => {
    if (id) {
      dispatch(getDetailLead(id))
    }
  }, [dispatch])

  useEffect(() => {
    if (Area?.data) {
      setDetail(Area?.data)
    }
  }, [Area?.data])

  useEffect(() => {
    if (detail?.lat_longs) {
      setCoordinat(
        detail.lat_longs.map((item) => {
          return {
            lat: item?.lat,
            lng: item?.lng
          }
        })
      )
    }
  }, [detail?.lat_longs])

  useEffect(() => {
    subHeader.setTitle("Calon Lahan list")

    subHeader.setBreadcrumbs([
      {
        pathname: "/lahan/list/map",
        title: "Lahan"
      },
      {
        pathname: "/lahan/list/lead",
        title: "Calon Lahan"
      },
      {
        title: detail?.area_code
      }
    ])
    subHeader.setButton(null)
  }, [detail?.area_code])

  const descriptionLeft = (data) => {
    return (
      <>
        <div className="d-flex flex-wrap mb-4">
          <FlatIconAndText
            text={toThousandConvert(data?.land_area) ?? "-"}
            m2
            svg={ICON_CONSTANS.IC_FIELD_2}
          />
        </div>
        <span className="font-weight-bold text-dark-50">{data?.full_address ?? "-"}</span>
      </>
    )
  }

  const flatIconAndText2Rows = (data) => {
    return (
      <div className="flex-direction-column">
        <div className="flex-grow-1 justify-content-between">
          <span>Submitted By: </span> <b className="ml-4">{data?.submitted_by}</b>
        </div>
        <div className="flex-grow-1 justify-content-between">
          <span>Submitted Time: </span> <b className="ml-4">{data?.submitted_date}</b>
        </div>
      </div>
    )
  }

  const getTabContents = () => {
    const labelList = ["DETAIL LAHAN", "LOG"]

    const componentList = [
      <AreaTab
        key={0}
        titleCardDetail={detail.area_code}
        descriptionLeft={descriptionLeft(detail)}
        descriptionRight={null}
        flatIconAndText2Rows={flatIconAndText2Rows(detail)}
        coordinate={coordinat}
        zoom={16}
        withDocument={false}
        titleImage={ICON_CONSTANS.IC_MAP_PROFILE}
      />,
      <TabLog key={1} historyTabEnum={ENUM_LOG.AREA} id={id} />
    ]

    return {
      labels: labelList,
      contents: componentList
    }
  }

  return (
    <TabsComponent labelTab={getTabContents().labels} componentTab={getTabContents().contents} />
  )
}